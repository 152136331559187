import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError, timer } from 'rxjs';
import { catchError, map, pluck, switchMap } from 'rxjs/operators';
import { ExportFormats } from '@sp-core/agreement-keys/export-formats.enum';
import { ServerStatusCode } from '@sp-core/agreement-keys/server-status-code.enum';
import { HttpInsertionService } from '../../http-services/http-insertion/http-insertion.service';
import { InsertionTypes } from '../../agreement-keys/insertion-types.enum';
import { InsertionHttpAdapter } from '../../http-adapters/insertion-http-adapter';

@Injectable({
  providedIn: 'root',
})
export class InsertionService {
  private readonly downloadAttemptsCount = 10;
  private readonly downloadAttemptsDebounceTime = 2000;
  public errorInsertionHistoryEvent: Subject<any> = new Subject<any>();

  constructor(public httpInsertionService: HttpInsertionService, public insertionHttpAdapter: InsertionHttpAdapter) {}

  public initInsertion(parentId: string, type: InsertionTypes): Observable<IInsertionInit> {
    return this.httpInsertionService.initInsertion(parentId, type).pipe(map((res) => ({ id: res.id })));
  }

  public getInsertion(insertionId: string): Observable<IInsertion> {
    return this.httpInsertionService
      .getInsertion(insertionId)
      .pipe(map((insertion) => this.insertionHttpAdapter.transformToInsertion(insertion)));
  }

  public updateInsertion(insertionId: string, insertion: IPrescribeFormValue): Observable<any> {
    const sendData = this.insertionHttpAdapter.transformToInsertionPatch(insertion);
    return this.httpInsertionService.updateInsertion(insertionId, sendData);
  }

  public updateInsertionDate(insertionId: string, data: { date: string }): Observable<any> {
    const sendData = this.insertionHttpAdapter.transformToInsertionData(data);
    return this.httpInsertionService.updateInsertionDate(insertionId, sendData);
  }

  public getLastInsertion(patientId: string): Observable<ILastInsertion> {
    return this.httpInsertionService
      .getLastInsertion(patientId)
      .pipe(map((res) => this.insertionHttpAdapter.transformToLastInsertion(res)));
  }

  public getInsertionHistory(patientId: string): Observable<InsertionHistory> {
    return this.httpInsertionService
      .getInsertionHistory(patientId)
      .pipe(map((history) => this.insertionHttpAdapter.transformToInsertionHistory(history)));
  }

  public getCerboInsertionHistory(patientId: string): Observable<InsertionHistory> {
    return this.httpInsertionService
      .getCerboInsertionHistory(patientId)
      .pipe(map((history) => this.insertionHttpAdapter.transformToInsertionHistory(history)));
  }

  public getPelletsData(genderId: string): Observable<IPellets> {
    return this.httpInsertionService
      .getPelletsData(genderId)
      .pipe(map((pellets) => this.insertionHttpAdapter.transformToPellets(pellets)));
  }

  public getUnfinishedInsertion(patientId: string): Observable<IUnfinishedInsertionItem[]> {
    return this.httpInsertionService
      .getUnfinishedInsertion(patientId)
      .pipe(
        map((res: ITableApiData<IUnfinishedInsertionItem>) =>
          this.insertionHttpAdapter.transformToUnfinishedInsertion(res?.data as any),
        ),
      );
  }

  public getInsertionHistoryExportFile(patientId: string, data: IInsertionExportByParams): Observable<Blob> {
    return this.httpInsertionService.getInsertionHistoryExportId(data, patientId).pipe(
      pluck('process_id'),
      switchMap((processId: string) => this.getInsertionHistoryFileFlow(processId, patientId)),
      catchError((serverError) => {
        if (serverError.status === ServerStatusCode.validationError) {
          serverError.error = this.insertionHttpAdapter.transformToInsertionExportByParams(serverError.error);
          this.errorInsertionHistoryEvent.next(serverError);
        }
        return throwError(serverError);
      }),
    );
  }

  public getSpecifiedInsertionHistoryExportFile(patientId: string, insertionId: string): Observable<Blob> {
    return this.httpInsertionService
      .getSpecifiedInsertionHistoryExportId(ExportFormats.pdf, patientId, insertionId)
      .pipe(
        pluck('process_id'),
        switchMap((processId: string) => this.getInsertionHistoryFileFlow(processId, patientId)),
      );
  }

  public getInsertionHistoryFileFlow(
    processId: string,
    patientId: string,
    counter: number = this.downloadAttemptsCount,
    delayTime: number = this.downloadAttemptsDebounceTime,
  ): Observable<Blob> {
    return timer(delayTime).pipe(
      switchMap(() => this.httpInsertionService.getInsertionHistoryExportFile(processId, patientId)),
      switchMap((response) => {
        if (response.status !== ServerStatusCode.noContent) {
          return of(response.body);
        }
        if (counter <= 0) {
          return throwError(new Error('Something went wrong. Please try again download file.'));
        }
        return this.getInsertionHistoryFileFlow(processId, patientId, counter - 1, delayTime);
      }),
    );
  }
}
