import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ITableService } from '@sp-shared/components/table';
import { MetaHttpAdapter } from '@sp-core/http-adapters/meta-http-adapter';
import { HttpReferralsService } from '../../http-services/http-referrals/http-referrals.service';
import { ReferralsHttpAdapter } from '../../http-adapters/referrals-http-adapter';

@Injectable({
  providedIn: 'root',
})
export class ReferralsNotesService implements ITableService<IReferralNote> {
  constructor(private httpReferralsService: HttpReferralsService, private referralsHttpAdapter: ReferralsHttpAdapter) {}

  public getTableData(params: HttpParamsOptions): Observable<ITableData<IReferralNote>> {
    return this.httpReferralsService.getReferralsNotes(params).pipe(
      map((data) => ({
        data: data.data.map((item) => this.referralsHttpAdapter.transformToReferralNote(item)),
        meta: MetaHttpAdapter.transformToMeta(data.meta),
      })),
    );
  }
}
