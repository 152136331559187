import { Injectable } from '@angular/core';
import { VerificationHttpAdapter } from '@sp-core/http-adapters/verification-http-adapter';
import { HttpVerificationService } from '@sp-core/http-services/http-verification/http-verification.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthHttpAdapter } from '@sp-core/http-adapters/auth-http-adapter';

@Injectable({
  providedIn: 'root',
})
export class VerificationService {
  constructor(
    public verificationHttpAdapter: VerificationHttpAdapter,
    public httpVerificationService: HttpVerificationService,
    public authHttpAdapter: AuthHttpAdapter,
  ) {}

  public verificationPhone(value: { phone: string; phonePrefixId: string }): Observable<any> {
    const sendData = this.verificationHttpAdapter.transformToVerificationPhone(value);
    return this.httpVerificationService.verificationPhone(sendData);
  }

  public verificationEmail(data: { email: string }): Observable<any> {
    return this.httpVerificationService.verificationEmail(data);
  }

  public sendVerificationCodeForEmail(value: { email: string }, code: string): Observable<any> {
    const sendData = this.verificationHttpAdapter.transformToCodeVerificationForEmail(value, code);
    return this.httpVerificationService.sendVerificationCodeForEmail(sendData);
  }

  public getTwoFADictionariesType(): Observable<ITwoFactorAuthorizationType[]> {
    return this.httpVerificationService
      .getTwoFADictionariesType()
      .pipe(
        map((result) => result.data.map((data) => this.authHttpAdapter.transformToTwoFactorAuthorizationType(data))),
      );
  }
}
