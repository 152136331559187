<form class="reset-password-form auth-form w-100"
      spQaId="reset-password-form_form"
      [formGroup]="restorePasswordForm"
      (ngSubmit)="onSubmit()"
      id="restore-password-form">

  <mat-form-field class="reset-password-form__control w-100">
    <input spQaId="reset-password-form_input_password"
           matInput
           id="password"
           #password
           formControlName="password"
           [placeholder]="'AUTH.PASSWORD' | translate"
           [type]="hide ? 'password' : 'text'">
    <mat-error spErrors [backendError]="serverError"></mat-error>
    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
  </mat-form-field>
  <div class="reset-password-form__password-info-wrap">
    <sp-password-strength [value]="password.value"
                          [min]="minPasswordLength">
    </sp-password-strength>
  </div>

  <mat-form-field class="w-100">
    <input spQaId="reset-password-form_input_password-confirm"
           matInput
           id="password-confirm"
           formControlName="confirmPassword"
           [placeholder]="'AUTH.CONFIRM_PASSWORD' | translate"
           [type]="hideConfirmPassword ? 'password' : 'text'">
    <mat-error spErrors [backendError]="serverError"></mat-error>
    <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
  </mat-form-field>

  <div class="auth-action">
    <button spQaId="restore-password-form_button_submit"
            form="restore-password-form"
            class="w-100 btn-primary-accept"
            [disabled]="restorePasswordForm.invalid">
      {{'BTN_SUBMIT' | translate}}
    </button>
  </div>
</form>
