import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ISignInForm } from '@sp-core/models/forms-models/sign-in-form.interface';

@Component({
  selector: 'sp-sign-in-form',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['./sign-in-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInFormComponent {
  @Input() public signInForm: FormGroup<ISignInForm>;
  @Input() public isSend: boolean;
  @Output() public wasSubmitted: EventEmitter<ISignIn> = new EventEmitter<ISignIn>();

  public hide = true;

  public onSubmit(): void {
    if (this.signInForm.valid) {
      this.wasSubmitted.emit(this.signInForm.getRawValue());
    }
  }
}
