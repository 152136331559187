/* eslint-disable no-constant-condition */
import { ServerValidationErrorKey } from './server-validation-error-key';
import { IErrorMap } from './error-map-interface';

const notAllowedSymbols = '`:-';

export const validationErrorsConfig: IErrorMap = {
  required: 'required field',
  pattern: 'wrong pattern',
  minlength: (expected, currentValue?: any) => `min length ${expected} ${null ? `expected ,${currentValue}` : ''}`,
  maxlength: (expected, currentValue?: any) => `max length ${expected} ${null ? `expected ,${currentValue}` : ''}`,
  min: (expected, currentValue?: any) => `min value ${expected} ${null ? `expected ,${currentValue}` : ''}`,
  max: (expected, currentValue?: any) => `max value ${expected} ${null ? `expected ,${currentValue}` : ''}`,
  recommendedFormat: 'Recommended Format message',
  passwordNotMatch: 'Passwords do not match',
  passwordPatter: 'Password Requirements Not Met',
  startAndEndWhitespace: "String can't start or end with whitespaces",
  startWhitespace: "String can't start with whitespaces",
  email: 'Wrong Email address',
  specialCharacters: `Can use only ${notAllowedSymbols} symbol`,
  noDigital: "Can't use numbers symbol",
  patternUrl: 'Incorrect url address',
  websiteUrl: 'Incorrect website address',
  onlyDigital: 'Can use only digital characters',
  onlyInteger: 'Can use only integer characters',
  emptyString: "String can't contains only whitespaces",
  maxNumberLength: (expected, currentValue?: any) =>
    `max length ${expected} ${null ? `expected ,${currentValue}` : ''}`,
  mask: 'Invalid phone number',
  onlySpecialCharacters: 'Invalid address',
  citySpecialCharacters: 'Invalid city',
  [ServerValidationErrorKey]: (data: any) => data,
  subDomainUrl: 'Incorrect Subdomain URL',
  zipcodeSpecialCharacters: 'Invalid zipcode',
  noZeroValueInBothControls: "Two fields can't be 0 value",
  oneOfTwoControlsIsRequired: 'Link or .pdf file is required',
  slug: 'Invalid value. Only the "-" symbol is available.',
  dateTo: 'Range should be <= 2 year.',
  rangeDateTo: 'Date to should be >= Date from',
};
