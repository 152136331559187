import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[spQaId]',
})
export class QaDirective implements OnInit {
  public readonly attributeName = 'data-cy';

  @Input('spQaId') public staticName: string;

  @Input() public index: string | number;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  public ngOnInit(): void {
    this.initAttribute();
  }

  private initAttribute() {
    if (!this.el || !this.staticName) {
      return;
    }
    let id = this.staticName;
    if (this.index !== undefined) {
      id += `_${this.index}`;
    }
    this.renderer.setAttribute(this.el.nativeElement, this.attributeName, id);
  }
}
