import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '@sp-core-services';
import DateUtils from '@sp-helpers/date-time/date-utils';

@Pipe({
  name: 'userDateFormat',
})
export class UserDateFormatPipe implements PipeTransform {
  constructor(private userService: UserService) {}

  transform(value: string, suffix = ''): string {
    if (!value) {
      return '-';
    }
    const format = this.userService?.user?.dateTimeFormat?.dateFormat || 'm/d/Y';
    const date = DateUtils.format(value, format + suffix);
    return date;
  }
}
