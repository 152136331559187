import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ISignUpForm } from '@sp-core/models/forms-models/sign-up-form.interface';

@Component({
  selector: 'sp-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpFormComponent {
  public hide = true;
  public hideConfirmPassword = true;

  @Input() public formSignUp: FormGroup<ISignUpForm>;
  @Input() public incorrectInfo = false;
  @Input() public token: string;
  @Input() public email: string;
  @Input() public minPasswordLength: number;
  @Output() public submitForm: EventEmitter<ISignUp> = new EventEmitter();

  public onSubmit(): void {
    if (this.formSignUp.valid) {
      this.submitForm.emit(this.formSignUp.getRawValue());
    }
  }
}
