import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  NgZone,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StickyNotesService } from '@sp-core/services/sticky-notes/sticky-notes.service';
import Utils from '@sp-helpers/utils';
import { concat, Observable, Subject, throwError } from 'rxjs';
import { catchError, filter, finalize, last, switchMap, take, takeUntil } from 'rxjs/operators';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { ServerErrorModel } from '@sp-core/models/app-models/server-error.model';
import { ServerStatusCode } from '@sp-core/agreement-keys/server-status-code.enum';
import { InfoPopupService } from '@sp-shared/containers/info-popup/services/info-popup.service';
import { getDialogRelativeOffset } from '../../helpers/multiple-dialog-utils';
import { colorPresets } from '../../helpers/color-presets';

@Component({
  selector: 'sp-sticky-note',
  templateUrl: './sticky-note.component.html',
  styleUrls: ['./sticky-note.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StickyNoteComponent implements OnInit {
  public openedColorMenu = false;

  public readonly colorPresets: string[] = colorPresets;

  public loading = false;

  private destroy$: Subject<any> = new Subject<void>();

  public stickyNote: Partial<IStickyNote> = {};

  public stickyNotesSnapshot: IStickyNote;

  public newCommentText = '';

  @ViewChildren(CdkTextareaAutosize) textareaAutosizeList: QueryList<CdkTextareaAutosize>;

  @Output() handleAction: EventEmitter<any> = new EventEmitter<any>();

  // @ts-ignore
  public trackByFn = (index: number, item: { id: string }): string => item.id;

  constructor(
    public dialogRef: MatDialogRef<StickyNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { stickyNote: IStickyNote; userId: string },
    private stickyNotesService: StickyNotesService,
    private infoPopupService: InfoPopupService,
    private ngZone: NgZone,
    private cd: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.updateStickyNote(this.data.stickyNote);
  }

  public stopIP(event: Event): void {
    event.stopImmediatePropagation();
  }

  public getInitials(stickyNote: any): string {
    if (!stickyNote) {
      return '';
    }
    return `${stickyNote.creatorFirstName[0].toUpperCase()}. ${stickyNote.creatorLastName[0].toUpperCase()}.`;
  }

  public create(): void {
    const data = { ...this.stickyNote, coordinates: this.currentPosition } as IStickyNote;
    this.stickyNotesService
      .createStickyNote(data)
      .pipe(this.requestFlow)
      .subscribe((stickyNote) => {
        this.updateStickyNote(stickyNote);
      });
  }

  public changeBackground(color: string): void {
    this.stickyNote.color = color;
  }

  public cancel(): void {
    if (!this.stickyNote?.title && !this.stickyNote?.text) {
      this.dialogRef.close();
      return;
    }

    this.infoPopupService
      .openInfoPopup({
        title: 'STICKY_NOTES.CANCEL_CONFIRM_TITLE',
        text: ' ',
        actions: {
          accept: 'BTN_YES',
          reject: 'BTN_NO',
        },
      })
      .pipe(filter(Boolean), takeUntil(this.destroy$))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  public update(): void {
    const stickyNoteId = this.stickyNote.id;
    const requestList = [];

    // add request to update or delete comments if changed
    if (this.changedStickyNoteCommentsValue()) {
      this.stickyNote.comments.forEach((current, index) => {
        if (!current.text) {
          requestList.push(this.stickyNotesService.deleteStickyNoteComment(stickyNoteId, current.id));
          return;
        }
        const prevText = this.stickyNotesSnapshot.comments[index].text;
        if (current.text !== prevText) {
          requestList.push(this.stickyNotesService.updateStickyNoteComment(stickyNoteId, current.id, current));
        }
      });
    }

    // add request to create comment if changed
    if (this.newCommentText) {
      const data = { text: this.newCommentText };
      requestList.push(this.stickyNotesService.createStickyNoteComment(stickyNoteId, data));
    }

    // add request to get sticky note if changed only comments
    // or update sticky note if changed comment with text ar title
    const lastRequest = this.changedStickyNoteValue()
      ? this.stickyNotesService.updateStickyNote(this.stickyNote as IStickyNote, stickyNoteId)
      : this.stickyNotesService.getStickyNote(stickyNoteId);
    requestList.push(lastRequest);

    // consistently send requests
    concat(...requestList)
      .pipe(last(), this.requestFlow)
      .subscribe((stickyNote: IStickyNote) => {
        this.newCommentText = '';
        this.updateStickyNote(stickyNote);
      });
  }

  public pin(): void {
    const data = { coordinates: this.currentPosition } as IStickyNote;
    this.stickyNotesService.updateStickyNoteCoordinates(data, this.stickyNote.id).pipe(this.requestFlow).subscribe();
  }

  public minimize(): void {
    this.stickyNotesService
      .minimizeStickyNote(this.stickyNote.id)
      .pipe(this.requestFlow)
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  public updateColor(): void {
    if (!this.stickyNote.id) {
      this.openedColorMenu = false;
      return;
    }
    this.stickyNotesService
      .updateStickyNoteColor(this.stickyNote.color, this.stickyNote.id)
      .pipe(this.requestFlow)
      .subscribe((stickyNote) => {
        this.openedColorMenu = false;
        this.updateStickyNote(stickyNote);
      });
  }

  public discardColor() {
    if (this.stickyNote.color !== this.stickyNotesSnapshot.color) {
      this.stickyNote.color = this.stickyNotesSnapshot.color;
    }
    this.openedColorMenu = false;
  }

  public delete(): void {
    this.infoPopupService
      .openInfoPopup({
        title: 'STICKY_NOTES.DELETE_CONFIRM_TITLE',
        text: 'STICKY_NOTES.DELETE_CONFIRM_TEXT',
        actions: {
          accept: 'BTN_YES',
          reject: 'BTN_NO',
        },
      })
      .pipe(
        filter(Boolean),
        switchMap(() => this.stickyNotesService.deleteStickyNote(this.stickyNote.id)),
        this.requestFlow,
      )
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  public changedValue(): boolean {
    return !!this.newCommentText || this.changedStickyNoteValue() || this.changedStickyNoteCommentsValue();
  }

  public changedStickyNoteValue(): boolean {
    return (
      this.stickyNote?.title !== this.stickyNotesSnapshot?.title ||
      this.stickyNote?.text !== this.stickyNotesSnapshot?.text
    );
  }

  private changedStickyNoteCommentsValue(): boolean {
    return !Utils.isEqual(this.stickyNote?.comments, this.stickyNotesSnapshot?.comments);
  }

  private updateStickyNote(stickyNote: IStickyNote): void {
    if (!stickyNote?.color) {
      // eslint-disable-next-line prefer-destructuring
      stickyNote.color = this.colorPresets[0];
    }
    this.stickyNote = stickyNote;
    this.stickyNotesSnapshot = Utils.deepCopy(stickyNote);
    this.triggerTextareaResize();
  }

  private get currentPosition(): IStickyNoteCoordinates {
    return getDialogRelativeOffset(this.dialogRef);
  }

  private triggerTextareaResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable.pipe(take(1)).subscribe(() => {
      this.textareaAutosizeList.forEach((textareaAutosize) => {
        textareaAutosize.resizeToFitContent(true);
      });
    });
  }

  private requestFlow = (source$: Observable<any>): Observable<any> => {
    this.loading = true;
    return source$.pipe(
      finalize(() => {
        this.loading = false;
        this.cd.detectChanges();
      }),
      takeUntil(this.destroy$),
      catchError((error: ServerErrorModel) => {
        if (error.status === ServerStatusCode.notFoundError) {
          this.dialogRef.close();
        }
        return throwError(error);
      }),
    );
  };
}
