<sp-auth-layout>
  <h2 auth-server-error class="auth-sub-title text-center">
    {{'AUTH.RESTORE_PASSWORD' | translate}}
  </h2>
  <div class="row justify-content-md-center">
    <div class="col-12 col-sm-12 col-md-6">
      <sp-reset-password-form [restorePasswordForm]="restorePasswordForm"
                              [minPasswordLength]="minPasswordLength"
                              [serverError]="serverError"
                              (submitForm)="handlerSubmit($event)"
      >
      </sp-reset-password-form>
    </div>
  </div>
  <div class="row justify-content-md-center auth-m-b">
    <div class="col-12 col-sm-12 col-md-6">
      <div class="d-flex justify-content-center">
        <a class="link-blue text-center" [routerLink]="'/sign-in'">
          {{'AUTH.LOGIN' | translate}}
        </a>
      </div>
    </div>
  </div>
</sp-auth-layout>
