import { Injectable } from '@angular/core';
import TransformServerValidationUtils from '@sp-helpers/validation/transform-server-validation-utils';
import { IUploadFile } from '@sp-core/models/app-models/upload-file.interface';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementsHttpAdapter {
  public selectAllRolesItemId = '10';
  public selectAllCountriesItemId = 'select-all-id';

  public transformToAnnouncement(data: IAnnouncementApi): IAnnouncement {
    return {
      id: data.id,
      isActive: data?.is_active,
      countries: data?.countries?.data,
      userRoles: data?.user_roles?.data,
      name: data?.name,
      description: data?.description,
      content: data?.content,
      date: data?.date,
      isAcknowledged: data?.is_acknowledged,
      attachments: data?.attachments ? data?.attachments?.map((item) => this.transformToAwsUploadData(item)) : null,
    };
  }

  public transformToChangeAnnouncementStatusApi(data: IAnnouncementChangeStatus): IAnnouncementChangeStatusApi {
    return {
      id: data.id,
      is_active: data.isActive,
    };
  }

  public transformToAnnouncementRequestApi(data: IAnnouncementRequest): IAnnouncementRequestApi {
    return {
      is_active: data.isActive,
      countries: data.countries.includes(this.selectAllCountriesItemId) ? [] : data.countries,
      user_roles: data.userRoles.map((id) => `${id}`).includes(this.selectAllRolesItemId) ? [] : data.userRoles,
      name: data.name,
      content: data.content,
      attachments: data?.attachments,
    };
  }

  public transformToAwsUploadData(data: IAwsUploadApi): IAwsUpload {
    return {
      id: data.id,
      awsLink: data.cloud_link,
      src: data.src,
      fileName: data.file_name,
      fileType: data.file_type,
    };
  }

  public transformToAwsUploadApiData(data: IAwsUpload): IAwsUploadApi {
    return {
      id: data.id,
      cloud_link: data.awsLink,
      src: data.src,
      file_name: data.fileName,
      file_type: data.fileType,
    };
  }

  public transformToAnnouncementsUploadFile(data: IAwsUpload[], type: string, isImgFiles = false): IUploadFile[] {
    let filterData: IAwsUpload[];
    if (isImgFiles) {
      filterData = data.filter((file) => file.fileType !== type);
    } else {
      filterData = data.filter((file) => file.fileType === type);
    }
    return this.transformAllFilesToAnnouncementsUploadFile(filterData);
  }

  public transformAllFilesToAnnouncementsUploadFile(data: IAwsUpload[]): IUploadFile[] {
    return data?.map((file) => ({
      id: file.id,
      awsLink: file.awsLink,
      src: file.src,
      file: null,
      file_type: file.fileType,
      file_name: file.fileName,
    }));
  }

  public transformToAnnouncementFormError(errors: any): any {
    const res = {};
    const keysAnnouncementForm = (key) => {
      const errorCountriesPath = 'countries.';
      const errorIsActivePath = 'is_active';
      const errorUserRolesPath = 'user_roles.';

      const listKeys = {
        name: 'name',
        description: 'description',
        content: 'content',
      };

      if (`${key}`.startsWith(errorCountriesPath)) {
        // @ts-ignore
        return key.replace(/^(countries.)(\S*)/, () => `countries`);
      }

      if (`${key}`.startsWith(errorIsActivePath)) {
        // @ts-ignore
        return key.replace(/^(is_active)(\S*)/, () => `isActive`);
      }

      if (`${key}`.startsWith(errorUserRolesPath)) {
        // @ts-ignore
        return key.replace(/^(user_roles.)(\S*)/, () => `userRoles`);
      }

      return listKeys[key] ? listKeys[key] : key;
    };

    Object.keys(errors).forEach((errorKey) => {
      res[keysAnnouncementForm(errorKey)] = TransformServerValidationUtils.getFirsError(errors[errorKey]);
    });
    return res;
  }
}
