import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConfig } from '../../api-config';

@Injectable({
  providedIn: 'root',
})
export class HttpPermissionsService {
  constructor(private http: HttpClient) {}

  public getPermission(httpParams: HttpParamsOptions, params: ApiParams): Observable<ITableApiData<IPermissionsApi>> {
    return this.http.get<ITableApiData<IPermissionsApi>>(`${ApiConfig.rolesPath}/${params.roleId}/permissions`, {
      params: httpParams,
    });
  }

  public getPermissions(roleId: number): Observable<IDataListApi<IPermissionsApi>> {
    return this.http.get<IDataListApi<IPermissionsApi>>(`${ApiConfig.rolesPath}/${roleId}/permissions/all`);
  }
}
