/* tslint:disable */
/* eslint-disable */
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ServerValidationErrorKey } from '@sp-helpers/validation/server-validation-error-key';
import Utils from '@sp-helpers/utils';

export default class ServerValidationUtils {
  public static setServerValidationErrorToControl(error: string | string[], control: AbstractControl): void {
    if (!error || !control || !(control instanceof UntypedFormControl)) {
      return;
    }
    const errorMessage = Utils.isArray(error) ? error[0] : error;
    control.setErrors({ name: ServerValidationErrorKey, expectedValue: errorMessage }, { emitEvent: true });
    control.markAllAsTouched();
  }

  public static setServerValidationErrorsToForm(errors: IServerValidationErrors | null, form: UntypedFormGroup): void {
    if (!form || !errors || Utils.isEmpty(errors)) {
      return;
    }
    Object.keys(errors).forEach((path: string) => {
      const error = errors[path];
      const control = form.get(path);
      ServerValidationUtils.setServerValidationErrorToControl(error, control);
    });
  }

  public static getControlName(c: AbstractControl): string | null {
    if (!c.parent) {
      return null;
    }
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find((name) => c === formGroup[name]) || null;
  }

  public static getControlPath(c: AbstractControl, path: string = ''): string | null {
    path = ServerValidationUtils.getControlName(c) + path;
    if (c.parent && ServerValidationUtils.getControlName(c.parent)) {
      path = `.${path}`;
      return ServerValidationUtils.getControlPath(c.parent, path);
    }
    return path;
  }
}
