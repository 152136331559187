<!--TODO: Add this library instead custom code after support latest angular versions issue https://github.com/angular-material-extensions/password-strength/issues/407-->
<!--<mat-password-strength #passwordComponent class="w-100 d-flex"-->
<!--                       [password]="value"-->
<!--                       [min]="min">-->
<!--</mat-password-strength>-->
<!--&lt;!&ndash;Password's strength info&ndash;&gt;-->
<!--<mat-password-strength-info *ngIf="!isSafari; else customPasswordStrengthInfo"-->
<!--                            class="w-100 d-flex"-->
<!--                            [passwordComponent]="passwordComponent">-->
<!--</mat-password-strength-info>-->

<!--<ng-template #customPasswordStrengthInfo>-->
<!--  <sp-password-strength-info class="w-100 d-flex" [passwordComponent]="passwordComponent">-->
<!--  </sp-password-strength-info>-->
<!--</ng-template>-->

<!--<password-strength-meter [password]="value" [minPasswordLength]="min" enableFeedback />-->

<div class="password-strength">
  <div class="password-strength__strength">
    <div class="password-strength__strength-bar mt-2">
      <div class="password-strength__point" [style.background-color]="bar0"></div>
      <div class="password-strength__point" [style.background-color]="bar1"></div>
      <div class="password-strength__point" [style.background-color]="bar2"></div>
      <div class="password-strength__point" [style.background-color]="bar3"></div>
      <div class="password-strength__point" [style.background-color]="bar4"></div>
    </div>

    <ng-container *ngIf="isShowMessage">
      <p class="text-center mb-0" [style.color]="messageColor" *ngIf="passwordData?.length">{{ message }}</p>
    </ng-container>
  </div>

  <div class="password-strength__icon-bar">
    <mat-card class="password-strength__icon-bar-card">
      <div class="password-strength__icon-bar-item mb-1">
        <mat-icon class="password-strength__icon" *ngIf="getLowerLettersValidation(); else error" color="primary">done</mat-icon>
        <ng-template #error>
          <mat-icon class="password-strength__icon" color="warn">error</mat-icon>
        </ng-template>
        <span class="password-strength__icon-bar-text">contains at least one lower character</span>
      </div>
      <div class="password-strength__icon-bar-item mb-1">
        <mat-icon class="password-strength__icon" *ngIf="getUpperCaseValidation(); else error" color="primary">done</mat-icon>
        <ng-template #error>
          <mat-icon class="password-strength__icon" color="warn">error</mat-icon>
        </ng-template>
        <span class="password-strength__icon-bar-text">contains at least one upper character</span>
      </div>
      <div class="password-strength__icon-bar-item mb-1">
        <mat-icon class="password-strength__icon" *ngIf="getDigitValidation(); else error" color="primary">done</mat-icon>
        <ng-template #error>
          <mat-icon class="password-strength__icon" color="warn">error</mat-icon>
        </ng-template>
        <span class="password-strength__icon-bar-text">contains at least one digit character</span>
      </div>
      <div class="password-strength__icon-bar-item mb-1">
        <mat-icon class="password-strength__icon" *ngIf="getSpecialCharValidation(); else error" color="primary">done</mat-icon>
        <ng-template #error>
          <mat-icon class="password-strength__icon" color="warn">error</mat-icon>
        </ng-template>
        <span class="password-strength__icon-bar-text">contains at least one special character</span>
      </div>
      <div class="password-strength__icon-bar-item mb-1">
        <mat-icon class="password-strength__icon" *ngIf="getMinLengthValidation(); else error" color="primary">done</mat-icon>
        <ng-template #error>
          <mat-icon class="password-strength__icon" color="warn">error</mat-icon>
        </ng-template>
        <span class="password-strength__icon-bar-text">contains at least {{min}} characters</span>
      </div>
    </mat-card>
  </div>
</div>
