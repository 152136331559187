import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { environment } from '@sp-environments/environment';
import { ApiConfig } from '@sp-core/api-config';
import { TranslateDefaultLanguageService } from '@sp-core/translate-services/translate-default-language.service';
import { TRANSLATE_SOURS } from '@sp-core/translate-services/translate-token';

@Injectable()
export class MultiTranslateHttpLoader implements TranslateLoader {
  public resources: any = [
    {
      prefix: '/assets/i18n/',
      suffix: '.json',
    },
  ];

  public hostUrl = 'http://localhost:4200';

  public contentHeader: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  });

  constructor(private http: HttpClient, public injector: Injector) {
    if (injector) {
      this.resources = injector.get(TRANSLATE_SOURS);
    }
    if (environment.production) {
      this.hostUrl = `${ApiConfig.basePath}`;
    }
  }

  public getTranslation(lang: string): any {
    const queryList: Observable<any>[] = this.resources.map((config) =>
      this.http.get(`${config.prefix}${lang}${config.suffix}`, { headers: this.contentHeader }),
    );
    return forkJoin(queryList).pipe(map((response) => response.reduce((a, b) => Object.assign(a, b))));
  }
}

export const translateLoader = (http: HttpClient, injector: Injector) => new MultiTranslateHttpLoader(http, injector);

export const getDefaultLanguage = () => {
  const listLanguages = ['en', 'ru'];
  let language: string;
  if (navigator) {
    language = (navigator.language || (navigator as any).userLanguage).slice(0, 3);
    if (!listLanguages.includes(language)) {
      language = 'en';
    }
  }
  // return only english for now because we don't have any language
  return 'en';
};

export const setLanguage =
  (translateService: TranslateService, translateDefaultLanguage: TranslateDefaultLanguageService) => () =>
    new Promise<void>((resolve) => {
      const language = getDefaultLanguage();

      translateDefaultLanguage.setDefaultLanguage(language);
      translateService.setDefaultLang(language);
      resolve();
    });
