<form class="sign-in-form w-100"
      spQaId="required-change-password_form"
      [formGroup]="requiredChangePasswordForm"
      (ngSubmit)="onSubmit()"
      id="sign-in-form">
  <div class="sign-up-form-control">
    <label class="sign-in-label" for="user-name">
      {{'AUTH.USER_NAME' | translate}}
    </label>
    <mat-form-field class="w-100">
      <input spQaId="required-change-password-form_input_user-name" matInput id="user-name" formControlName="email"
             [placeholder]="'AUTH.EMAIL' | translate">
      <mat-error spErrors></mat-error>
    </mat-form-field>
  </div>

  <div class="sign-in-form-control password-block">

    <div class="form-control">
      <mat-form-field class="w-100">
        <input spQaId="required-change-password-form_input_current-password"
               matInput
               formControlName="currentPassword"
               [placeholder]="'SETTINGS.OLD_PASSWORD' | translate"
               [type]="hideCurrentPassword ? 'password' : 'text'">
        <mat-error spErrors></mat-error>
        <mat-icon matSuffix
                  (click)="hideCurrentPassword = !hideCurrentPassword">{{hideCurrentPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      </mat-form-field>
    </div>
    <div class="form-control password-block">
      <mat-form-field class="w-100">
        <input spQaId="required-change-password-form_input_new-password"
               matInput
               #password
               formControlName="newPassword"
               [placeholder]="'SETTINGS.NEW_PASSWORD' | translate"
               [type]="hideNewPassword ? 'password' : 'text'">
        <mat-error spErrors></mat-error>
        <mat-icon matSuffix
                  (click)="hideNewPassword = !hideNewPassword">{{hideNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      </mat-form-field>
      <sp-password-strength [value]="password.value"
                            [min]="minPasswordLength">
      </sp-password-strength>
    </div>
    <div class="form-control">
      <mat-form-field class="w-100">
        <input spQaId="required-change-password-form_input_new-password-confirm"
               matInput
               id="password-confirm"
               formControlName="newPasswordConfirm"
               [placeholder]="'SETTINGS.CONFIRM_PASSWORD' | translate"
               [type]="hideConfirmPassword ? 'password' : 'text'">
        <mat-error spErrors></mat-error>
        <mat-icon matSuffix
                  (click)="hideConfirmPassword = !hideConfirmPassword">{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      </mat-form-field>
    </div>

  </div>


  <div class="login-form-action">
    <button spQaId="required-change-password-form_button_submit"
            type="submit"
            form="sign-in-form"
            class="w-100 btn-primary-accept"
            [disabled]="requiredChangePasswordForm.invalid || isSend">
      {{'BTN_SUBMIT' | translate}}
    </button>
  </div>
</form>

