import { Component, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sp-patent-popup',
  templateUrl: './patent-popup.component.html',
  styleUrls: ['./patent-popup.component.scss'],
})
export class PatentPopupComponent {
  constructor(@Optional() public dialogRef: MatDialogRef<PatentPopupComponent>) {}

  public close(patient?: IPatient): void {
    this.dialogRef.close(patient);
  }
}
