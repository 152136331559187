import { Injectable } from '@angular/core';
import DateUtils from '@sp-helpers/date-time/date-utils';

@Injectable({
  providedIn: 'root',
})
export class ReportsHttpAdapter {
  public transformToReport(data: IAdminReportsApi): IAdminReports {
    return {
      userName: data?.user_name,
      userRole: data?.user_role,
      practiceName: data?.practice_name,
      country: data?.country,
      patientName: data?.patient_name,
      gender: data?.gender,
      insertionType: data?.insertion_type,
      testosteroneCalculatedValue: data?.recommended?.testosterone,
      estradiolCalculatedValue: data?.recommended?.estradiol,
      testosteroneGiven: data?.given?.testosterone || null,
      estradiolGiven: data?.given?.estradiol || null,
      anastrozoleGiven: data?.given?.testosterone_anastrozole || null,
      calculationDate: data?.calculation_date,
      lots: data?.lots,
    };
  }

  public transformToProcessId(data: IExportProcessIdApi): IExportProcessId {
    return {
      processId: data.process_id,
    };
  }

  public transformToReportProviders(data: IAdminReportProvidersApi): IAdminReportProviders {
    return {
      id: data?.id,
      email: data?.email,
      firstName: data?.first_name,
      lastName: data?.last_name,
      fullName: data?.full_name,
      practice: {
        practiceId: data?.practice?.practice_id,
        practiceName: data?.practice?.practice_name,
        isActive: data?.practice?.is_active,
      },
    };
  }

  public transformToFilterApiData(
    data: IAdminReportProviderFilterRequestData,
  ): IAdminReportProviderFilterRequestDataApi {
    return {
      search: {
        name: data?.search?.name,
        practice_id: data?.search?.practiceId,
        role: data?.search?.role,
      },
    };
  }

  public transformToFrequencies(data: { key: string; title: string }): IAdminReportFrequencies {
    return {
      key: data?.key,
      title: data?.title,
    };
  }

  public transformSingleReportToApi(data: ISingleReport): ISingleReportApi {
    return {
      date_start: DateUtils.format(data.rangeDate.begin, 'YYYY-MM-DD'),
      date_end: data.rangeDate.end !== null ? DateUtils.format(data.rangeDate.end, 'YYYY-MM-DD') : null,
      practices: data.practices,
      providers: data.providers,
      format: data.format,
      emails: data.emails,
    };
  }

  public transformRecurringReportToApi(data: IRecurringReport): IRecurringReportApi {
    return {
      frequency: data.frequency,
      ...this.transformSingleReportToApi(data),
    };
  }

  public transformToSingleReport(data: ISingleReportResponseApi): ISingleReportResponse {
    return {
      id: data?.id,
      rangeDate: {
        begin: data?.date_start,
        end: data?.date_end,
      },
      practices: data?.practices?.data,
      providers: data?.providers?.data.map((item) => this.transformToReportProviders(item)),
      format: data?.format,
      emails: data?.emails,
    };
  }

  public transformToRecurringReport(data: IRecurringReportResponseApi): IRecurringReportResponse {
    return {
      type: data?.type,
      frequency: data?.frequency,
      ...this.transformToSingleReport(data),
    };
  }

  public transformToSingleOrRecurringApiReport(
    data: IRecurringReport & ISingleReport,
  ): IRecurringReportApi | ISingleReportApi {
    if (data && data?.frequency) {
      return this.transformRecurringReportToApi(data);
    } else {
      return this.transformSingleReportToApi(data);
    }
  }
}
