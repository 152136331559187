import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@sp-core-services';
import { TokenService } from '@sp-core/services/token/token.service';
import { ApiConfig } from '@sp-core/api-config';
// @ts-ignore
import { WindowService } from '@sp-core/services/window/window.service';
import { PermissionsService } from '@sp-core/services/permissions/permissions.service';
import { ServerStatusCode } from '@sp-core/agreement-keys/server-status-code.enum';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private tokenService: TokenService,
    private authService: AuthService,
    private windowService: WindowService,
    private permissionsService: PermissionsService,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const path = window.location.pathname + window.location.search;
    const cerboPath: any = 'cerbo';
    if (path.includes(cerboPath) && path.includes('link_patient')) {
      this.authService.savedRout = path;
    }

    if (req.url.includes(ApiConfig.signInPath) || req.url.includes(ApiConfig.signUpPath)) {
      const newReq = req.clone({
        setHeaders: {
          'Client-Host': this.windowService.getBaseHref(),
        },
      });
      return next.handle(newReq);
    }
    let authReq;
    if (req.url.includes(ApiConfig.AWSs3Path)) {
      authReq = req.clone({
        setHeaders: {
          'Content-Type': 'application/octet-stream',
          'Access-Control-Allow-Origin': '*',
          'Client-Host': this.windowService.getBaseHref(),
          'Client-Role': this.permissionsService.role?.key || '',
        },
      });
    } else {
      authReq = req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Client-Host': this.windowService.getBaseHref(),
          'Client-Role': this.permissionsService.role?.key || '',
          Authorization: this.tokenService.accessToken ? `Bearer ${this.tokenService.accessToken}` : '',
        },
      });
    }
    return next.handle(authReq).pipe(catchError((err: HttpErrorResponse) => this.handle401(err)));
  }

  private handle401(err: HttpErrorResponse): Observable<any> {
    if (err instanceof HttpErrorResponse && err.status === ServerStatusCode.authorizeError) {
      this.authService.clearData();
      return of(null);
    }
    return throwError(err);
  }
}
