<button spQaId="confirm-popup_button_close" class="close" (click)="clickClose(false)">
</button>
<h1 spQaId="confirm-popup_title" class="common-popup-title">{{data?.title}}</h1>
<h2 spQaId="confirm-popup_sub-title" class="common-popup-title" *ngIf="data.text">
  {{data.text | translate}}
</h2>
<div mat-dialog-content class="confirm-action">
  <button spQaId="confirm-popup_button_yes" class="btn-primary-accept" (click)="clickClose(true)" cdkFocusInitial>{{'BTN_YES'| translate}}</button>
  <button spQaId="confirm-popup_button_no" class="btn-purple" (click)="clickClose(false)">{{'BTN_NO' | translate}}</button>
</div>
