import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StickyNotesServiceHttpAdapter {
  public transformToStickyNote(data: IStickyNoteResponseApi): IStickyNote {
    return {
      id: data.id,
      creatorId: data.creator_id,
      creatorFirstName: data.creator_first_name,
      creatorLastName: data.creator_last_name,
      title: data.title,
      text: data.text,
      coordinates: {
        x: data.coordinates.x,
        y: data.coordinates.y,
      },
      color: data.color,
      comments: data.comments.data.map((comment) => this.transformToStickyNoteComment(comment)),
      createdAt: data.created_at,
      updatedAt: data.updated_at,
    };
  }

  public transformToStickyNoteComment(data: IStickyNoteCommentApi): IStickyNoteComment {
    return {
      id: data.id,
      creatorId: data.creator_id,
      creatorFirstName: data.creator_first_name,
      creatorLastName: data.creator_last_name,
      text: data.text,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
    };
  }

  public transformToStickyNoteRequest(data: IStickyNote): IStickyNoteRequestApi {
    return {
      title: data.title,
      text: data.text,
      color: data.color,
      context: {
        name: data.context.name,
        param: data.context.param,
      },
      coordinates: this.transformToStickyNoteCoordinatesRequest(data.coordinates),
    };
  }

  public transformToStickyNoteUpdateRequest(data: IStickyNote): IStickyNoteRequestApi {
    return {
      title: data.title,
      text: data.text,
      color: data.color,
    };
  }

  public transformContextToQueryParams(context: IStickyNoteContext): HttpParamsOptions {
    return {
      'search[context]': context.name,
      'search[param]': context.param || null,
      'sort[updated_at]': 'asc',
    };
  }

  public transformToStickyNoteCoordinatesRequest(data: IStickyNoteCoordinates): IStickyNoteCoordinatesApi {
    return {
      x: data.x,
      y: data.y,
    };
  }
}
