import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { first } from 'rxjs/operators';

import { InfoPopupOptions } from '@sp-shared/containers/info-popup/interfaces/info-popup-options.interface';
import { PopupService } from './popup.service';
import { InfoPopupComponent } from '../containers/info-popup/info-popup.component';
import { IInfoPopupData } from '../interfaces/info-popup-data-interface';

@Injectable()
export class InfoPopupService {
  constructor(public dialog: MatDialog) {}

  public openInfoPopup(
    info?: IInfoPopupData,
    options: InfoPopupOptions = { width: 'auto', disableClose: false },
  ): Observable<boolean> {
    const data: IInfoPopupData = {
      title: info && (info.title || info.title === '') ? info.title : 'CALC.RECOMMENDATION',
      text: info && info.text ? info.text : 'CALC.PSA',
      actions: {
        accept: info && info.actions && info.actions.accept ? info.actions.accept : '', // 'CALC.ACCEPT'
        reject: info && info.actions && info.actions.reject ? info.actions.reject : '', // 'CALC.REJECT'
        acknowledge: info && info.actions && info.actions.acknowledge ? info.actions.acknowledge : 'ACKNOWLEDGE',
      },
    };
    const dialogRef = this.dialog.open(InfoPopupComponent, {
      data,
      ...this.fetchOptions(options),
    });
    return dialogRef.afterClosed().pipe(first()) as Observable<boolean>;
  }

  public openPopupWithTemplate(
    // @ts-ignore
    data: IInfoPopupData<T>,
    options: InfoPopupOptions = { width: 'auto', disableClose: false },
  ): PopupService {
    const dialogRef = this.dialog.open(InfoPopupComponent, {
      data,
      ...this.fetchOptions(options),
    });

    dialogRef.afterClosed().pipe(first());

    return new PopupService(dialogRef, data);
  }

  public closeDialog(): void {
    this.dialog.closeAll();
  }

  private fetchOptions({
    width,
    disableClose,
  }: // @ts-ignore
  InfoPopupOptions): Pick<MatDialogConfig<IInfoPopupData<T>>, 'width' | 'disableClose'> {
    return {
      width: width ? `${width}px` : 'auto',
      disableClose,
    };
  }
}
