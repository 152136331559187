import { InsertionTypes } from '@sp-core/agreement-keys/insertion-types.enum';
import { SymptomsKey } from '@sp-core/agreement-keys/symptoms-key.enum';

export default class CalcUtils {
  public static prepareNestedArray(data: any[], columnCounter: number): any[][] {
    if (!data) {
      return null;
    }
    const itemInTheBloc: number = Math.ceil(data.length / columnCounter);
    return data.reduce((previousValue: any[], currentValue) => {
      if (previousValue.length === 0) {
        previousValue.push([currentValue]);
        return previousValue;
      }
      if (previousValue && previousValue[previousValue.length - 1].length < itemInTheBloc) {
        previousValue[previousValue.length - 1].push(currentValue);
        return previousValue;
      }
      previousValue.push([currentValue]);
      return previousValue;
    }, []);
  }

  public static getRequiredLabs(labs: ILabs[]): Map<string, ILabs> {
    return new Map(labs.filter((lab) => lab.isRequired).map((lab) => [lab.id, lab]));
  }

  public static addMostRecentData(obj: any, data: ICalcFormRowData): ICalculationPatchDataApi {
    if (data.insertionType === InsertionTypes.returnInsertion) {
      return {
        ...obj,
        estradiol_previous_dose: data.mostRecentDosage.estradiol,
        testosterone_previous_dose: data.mostRecentDosage.testosterone,
      };
    }
    return obj;
  }

  public static transformToSymptomHistory(data: ISymptomApi[]): ISymptomsHistory {
    if (!data) {
      return null;
    }
    const includesFieldsSymptoms: string[] = [SymptomsKey.female_premenopausal, SymptomsKey.female_postmenopausal];
    const requiredFields = [];
    const symptomHistory: ISymptom[] = data.map((item) => {
      if (includesFieldsSymptoms.includes(item.key)) {
        requiredFields.push(item.id);
      }
      return {
        id: item.id,
        title: item.title,
        genderId: item.gender_id,
        isRequired: item.is_required,
        key: item.key,
        type: item.type,
      };
    });

    return {
      symptomHistory,
      requiredFields,
    };
  }

  public static createDictionariesValueMap(symptoms: ISymptoms, labs: ILabs[]): IDictionariesMap {
    const mapSymptoms: any = {};
    if (symptoms.history) {
      CalcUtils.createMapForKeyId(symptoms.history, mapSymptoms);
    }
    if (symptoms.symptom) {
      CalcUtils.createMapForKeyId(symptoms.symptom, mapSymptoms);
    }
    const mapLabs = CalcUtils.createMapForKeyId(labs, {});
    return {
      symptom: mapSymptoms,
      labs: mapLabs,
    };
  }

  public static createMapForKeyId(data: ISymptom[] | INumericSymptom[] | ILabs[], map: any): IMapKeyValue {
    data.forEach((item) => {
      map[item.key] = item.id;
    });
    return map;
  }

  public static addRadioTypeToSymptomsHistory(data: ISymptomApi[]): ISymptomsHistory {
    const radioBtnTypeSymptoms: string[] = [
      SymptomsKey.female_oopherectomy_unilateral,
      SymptomsKey.female_oophorectomy_bilateral,
      SymptomsKey.female_premenopausal,
      SymptomsKey.female_postmenopausal,
    ];
    const radioGroups: ISymptom[] = [];

    const symptomHistory = CalcUtils.transformToSymptomHistory(data);
    const radioBtnSymptomsHistory = symptomHistory.symptomHistory
      .map((item) => {
        if (radioBtnTypeSymptoms.includes(item.key)) {
          const newItem = {
            ...item,
            isRadioGroup: true,
          };
          radioGroups.push(newItem);
          return {
            ...newItem,
          };
        } else {
          return {
            ...item,
            isRadioGroup: false,
          };
        }
      })
      .filter((item) => !item.isRadioGroup);

    const symptomsWithRadioGroup = radioGroups
      .reduce((acc, item, index) => {
        if (index % 2 == 1) {
          acc.push({
            ...item,
            radioGroup: {
              itemOne: { ...item, checked: false },
              itemTwo: { ...acc[index - 1], checked: false },
            },
          });
        } else {
          acc.push(item);
        }
        return acc;
      }, [])
      .filter((item) => item.radioGroup);

    return {
      ...symptomHistory,
      symptomHistory: [...radioBtnSymptomsHistory, ...symptomsWithRadioGroup],
      requiredFields: symptomHistory.requiredFields,
    };
  }
}
