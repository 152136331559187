import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IResetPasswordForm } from '@sp-core/models/forms-models/reset-password-form.interface';

@Component({
  selector: 'sp-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordFormComponent {
  public hide = true;
  public hideConfirmPassword = true;

  @Input() public restorePasswordForm: FormGroup<IResetPasswordForm>;
  @Input() public minPasswordLength: number;
  @Input() public serverError: IServerValidationErrors;
  @Output() public submitForm: EventEmitter<IRestorePassword> = new EventEmitter<IRestorePassword>();

  public onSubmit(): void {
    if (this.restorePasswordForm.valid) {
      this.submitForm.emit(this.restorePasswordForm.getRawValue());
    }
  }
}
