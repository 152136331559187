import { Injectable } from '@angular/core';
import { HttpCalcService } from '@sp-core/http-services/http-calc/http-calc.service';
import { Observable, of, throwError } from 'rxjs';
import { CalcHttpAdapter } from '@sp-core/http-adapters/calc-http-adapter';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ServerErrorModel } from '@sp-core/models/app-models/server-error.model';
import { ServerStatusCode } from '@sp-core/agreement-keys/server-status-code.enum';

@Injectable({
  providedIn: 'root',
})
export class CalcService {
  constructor(
    public httpCalcService: HttpCalcService,
    public httpCalcAdapter: CalcHttpAdapter,
    public router: Router,
  ) {}

  public getCalcDictionary(patientGenderId: string): Observable<ICalcDictionaries> {
    return this.httpCalcService
      .getCalcDictionary(patientGenderId)
      .pipe(map((calcDictionary) => this.httpCalcAdapter.transformToCalcDictionaries(calcDictionary)));
  }

  public calculationInit(data: ICalculationInit): Observable<ICalculationInitResponse> {
    const sendData = this.httpCalcAdapter.transformToCalcInit(data);
    return this.httpCalcService
      .calculationInit(sendData)
      .pipe(map((calculation) => this.httpCalcAdapter.transformToInitCalc(calculation)));
  }

  public getCalculation(id: string): Observable<ICalculationCommon> {
    return this.httpCalcService.getCalculation(id).pipe(
      map((calculation) => this.httpCalcAdapter.transformToCalculation(calculation)),
      catchError(() => {
        this.router.navigateByUrl('/contact-us');
        return of(null);
      }),
    );
  }

  public calculationPatch(formRowData: ICalcFormRowData, calculationId: string): Observable<ICalculationResult> {
    const sendData = this.httpCalcAdapter.transformToPatchCalculation(formRowData);
    return this.httpCalcService.calculationPatch(sendData, calculationId).pipe(
      map((res) => this.httpCalcAdapter.transformToCalculationResult(res)),
      catchError((serverError: ServerErrorModel) => {
        if (serverError.status === ServerStatusCode.validationError) {
          serverError.validationErrors = this.httpCalcAdapter.transformToCalcFormError(serverError.validationErrors);
        }
        return throwError(serverError);
      }),
    );
  }

  public getLastFinishedCalculation(patientId: string): Observable<ICommonEditableData> {
    return this.httpCalcService.getLastFinishedCalculation(patientId).pipe(
      map((res) => this.httpCalcAdapter.transformToCommonEditableData(res)),
      catchError(() => {
        this.router.navigateByUrl('/contact-us');
        return of(null);
      }),
    );
  }

  public getTypeOfInjection(patientId: string, type: string): Observable<IPatientTypeOfInjection> {
    return this.httpCalcService
      .getTypeOfInjection(patientId, type)
      .pipe(map((res) => this.httpCalcAdapter.transformToTypeOfInjection(res)));
  }

  public getDosingResponsibilityAgreement(): Observable<IDosingResponsibilityAgreement> {
    return this.httpCalcService
      .getDosingResponsibilityAgreement()
      .pipe(map((res) => this.httpCalcAdapter.transformToDosingResponsibilityAgreement(res)));
  }

  public sendDosingResponsibilityAgreement(agreementId: string): Observable<void> {
    return this.httpCalcService.sendDosingResponsibilityAgreement(agreementId);
  }

  public getCalculatorReturnParams(patientId: string, calculationId: string): Observable<ICalculationReturnParams> {
    return this.httpCalcService
      .getCalculatorReturnParams(patientId, calculationId)
      .pipe(map((res) => this.httpCalcAdapter.transformToCalculatorReturnParams(res)));
  }
}
