<sp-auth-layout>
  <h2 auth-sub-title *ngIf="!shouldChooseRole" class="auth-sub-title text-center">
    {{'AUTH.LOGIN' | translate}}
  </h2>
  <div auth-server-error class="col-12 d-flex justify-content-center">
    <div class="sign-in-form-control">
      <mat-error class="token-error text-center" *ngIf="serverError">{{serverError}}</mat-error>
    </div>
  </div>

  <div class="row justify-content-md-center" *ngIf="!shouldChooseRole else roles">
    <div class="col-12 col-sm-12 col-md-6">
      <sp-sign-in-form [signInForm]="signInForm"
                       [isSend]="isSend"
                       (wasSubmitted)="onSubmit()"
      >

      </sp-sign-in-form>
    </div>
  </div>

  <ng-template #roles>
    <div class="row justify-content-md-center">
      <div class="col-12 col-sm-12">
        <h2 class="welcome-title choose-role text-center">
          {{'AUTH.I_WOULD_LIKE_TO_LOGIN_AS_A' | translate}}
        </h2>
        <h3 class="welcome-sub-title text-center">({{'AUTH.SELECT_ONE_BELOW' | translate}})</h3>

        <ng-container *ngIf="shouldChooseRole && user?.roles?.length < 4">
          <ng-container *ngTemplateOutlet="multipleChooseRole"></ng-container>
        </ng-container>

        <ng-container *ngIf="shouldChooseRole && user?.roles?.length === 4">
          <div class="simple-provider-role-wrap">
            <ng-container *ngTemplateOutlet="multipleChooseRole"></ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="shouldChooseRole && user?.roles?.length === 5">
          <div class="master-admin-role-wrap">
            <ng-container *ngTemplateOutlet="multipleChooseRole"></ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="shouldChooseRole && user?.roles?.length > 5">
          <div class="limited-role-wrap">
            <ng-container *ngTemplateOutlet="multipleChooseRole"></ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>

  <div class="row justify-content-md-center auth-m-b" *ngIf="!shouldChooseRole">
    <div class="col-12 col-sm-12 col-md-6">
      <div class="d-flex justify-content-center">
        <a class="forgot-password link-blue text-center" [routerLink]="'/forgot-password'">
          {{'AUTH.FORGOT_PASSWORD' | translate}}
        </a>
      </div>
      <p class="text-center">
        {{'AUTH.DONT_HAVE_AN_ACCOUNT' | translate}}
        <a class="link-blue" [routerLink]="'/contact-us'">
          {{'AUTH.CONTACT_US' | translate}}
        </a>
      </p>
    </div>
  </div>
</sp-auth-layout>

<ng-template #multipleChooseRole>
  <div class="role__block-wrap d-flex">
    <div class="role__block" *ngFor="let role of user?.roles"
         (click)="updateRole(role)">
      <div class="role__block-img-wrap">
        <img class="role__block-img"
             [alt]="role?.key"
             [src]="'assets/images/' + userRolesImages[role?.key]">
      </div>
      <p class="role__title">
        {{role?.key === userRoles.provider ? 'Medical Provider' : role?.name}}
      </p>
    </div>
  </div>
</ng-template>
