import { Injectable } from '@angular/core';
import { CalcHttpAdapter } from '@sp-core/http-adapters/calc-http-adapter';
import { PatientHttpAdapter } from '@sp-core/http-adapters/patient-http-adapter';
import DateUtils from '@sp-helpers/date-time/date-utils';
import { InsertionHttpAdapter } from '@sp-core/http-adapters/insertion-http-adapter';

@Injectable({
  providedIn: 'root',
})
export class ConsultHttpAdapter {
  constructor(
    public readonly _calcAdapter: CalcHttpAdapter,
    public readonly _patientHttpAdapter: PatientHttpAdapter,
    public readonly _insertionHttpAdapter: InsertionHttpAdapter,
  ) {}

  public transformToConsultData(data: IConsultApi): IConsult {
    return {
      id: data.id,
      creatorId: data.creator_id,
      status: data.status,
      providerName: data.creator_name,
      role: data.role,
      patientName: data.patient_name,
      practiceName: data.practice_name,
      assignee: data.assignee_name,
      date: data.submitted_date,
      isAssigned: true,
      recentReplyDate: data.recent_reply_date,
    };
  }

  public transformToConsultToAssign(data: IProviderApi): IProviderAssignData {
    return {
      id: data.id,
      practiceName: data.practice.practice_name,
      providerName: `${data.first_name} ${data.last_name}`,
      email: data.email,
      practiceId: data?.practice.practice_id,
    };
  }

  public transformToRequestConsultApi(data: IConsultRequest): IConsultRequestApi {
    let transformedData: IConsultRequestApi = {
      patient_id: data.patientForm.id,
      current_meds: data.currentMeds,
      hormones_application_term: data.howLongUsingHormones,
      hormones_rejection_term: data.howLongStopUsingHormones,
      consult_insertion_type: data.typeConsultInsertion,
      last_insertion_date: DateUtils.formatDateToApi(data.lastInsertionDate),
      hormones: data.useNonPellet ? this._calcAdapter.transformToHormonesApi(data.hormones) : [],
      symptoms: this._calcAdapter.transformToSymptomsApi(data.symptoms, data.symptomHistory),
      stress_factor_id: data.stressFactor,
      problem_factor_id: data.problemFactor,
      additional_info: data.additionsInfo,
      estradiol_estimation: data.estradiol,
      testosterone_estimation: data.testosterone,
      comments: data.additionsCommentsOrQuestion,
    };
    transformedData = this._calcAdapter.setProgesterone(data.isProgesteroneTaken, transformedData, data.progesterone);
    return transformedData;
  }

  public transformToMyConsultRequests(data: IMyConsultRequestApi): IMyConsultRequest {
    return {
      id: data.id,
      patientName: data.patient_name,
      patientBirthDate: data.patient_birth_date,
      date: data.submitted_date,
      status: data.status,
      isMyRequests: true,
    };
  }

  public transformToAllConsultRequests(data: IConsultAllRequestApi): IConsultAllRequest {
    return {
      assigneeName: data?.assignee_name,
      creatorId: data?.creator_id,
      creatorName: data?.creator_name,
      id: data?.id,
      patientName: data?.patient_name,
      practiceName: data?.practice_name,
      status: data?.status,
      submittedDate: data?.submitted_date,
      patientBirthDate: data?.patient_birth_date,
      recentReplyDate: data?.recent_reply_date,
    };
  }

  public transformToRequestAnswerApi(data: IConsultRequestAnswer): IConsultRequestAnswerApi {
    return {
      id: data?.id || null,
      is_suggested_dose_not_applicable: data.isSuggestedDoseNotApplicable,
      estradiol_suggested: data?.estradiol || null,
      testosterone_suggested: data?.testosterone || null,
      medication_suggestions: data.medicationSuggestions,
      note: data.note,
      comment: data.comment,
    };
  }

  public transformToRequestAnswer(data: IConsultRequestAnswerApi): IConsultRequestAnswer {
    return {
      isSuggestedDoseNotApplicable: data.is_suggested_dose_not_applicable,
      estradiol: data.estradiol_suggested,
      testosterone: data.testosterone_suggested,
      medicationSuggestions: data.medication_suggestions,
      note: data.note,
      comment: data.comment,
      id: data?.id,
    };
  }

  public transformToConsultMedicationSuggestion(item: IMedicationSuggestionApi): IMedicationSuggestion {
    return {
      id: item.id,
      key: item.key,
      title: item.title,
    };
  }

  public transformToConsult(data: ISavedConsultApi): IConsultRequest {
    let result: any = {
      isResolved: data.request.is_resolved,
      patientForm: this._patientHttpAdapter.transformToPatient(data.request.patient),
      currentMeds: data.request.current_meds,
      useNonPellet: !!data?.request?.present_hormones?.length,
      hormones: this._calcAdapter.transformToPresentHormones(data.request.present_hormones), // present_hormones
      howLongUsingHormones: data.request.hormones_application_term,
      howLongStopUsingHormones: data.request.hormones_rejection_term,
      typeConsultInsertion: data.request.consult_insertion_type,
      lastInsertionDate: data.request.last_insertion_date,
      problemFactor: data.request.problem_factor_id,
      stressFactor: data.request.stress_factor_id,
      additionsInfo: data.request.additional_info,
      estradiol: data.request.estradiol_estimation,
      testosterone: data.request.testosterone_estimation,
      additionsCommentsOrQuestion: data.request.comments,
      symptoms: data.request.symptoms ? data.request.symptoms.symptom : [],
      symptomHistory: data.request.symptoms ? data.request.symptoms.history : [],
    };
    result = this._calcAdapter.addToCalculationCommonProgesterone(result, data.request as any);
    result = this.handlerLabsTable(result, data);
    result = this.handlerInsertionHistory(result, data);
    result = this.handlerResponseConsult(result, data);
    return result;
  }

  handlerLabsTable(result: IConsultRequest, data: ISavedConsultApi): IConsultRequest {
    const transformToLabs = (labsApi: INestedLabsApi[]): INestedLabs[] =>
      labsApi.map((labs) => ({
        date: labs.date,
        labs: this._calcAdapter.transformToLabForm(labs.labs),
      }));
    return {
      ...result,
      labsTable: {
        data: transformToLabs(data.request.labs_table.data),
      },
    };
  }

  private handlerInsertionHistory(result: IConsultRequest, data: ISavedConsultApi): IConsultRequest {
    return {
      ...result,
      insertionHistory: this._insertionHttpAdapter.transformToInsertionHistory(data.request.insertions),
    };
  }

  private handlerResponseConsult(result: IConsultRequest, data: ISavedConsultApi): IConsultRequest {
    return {
      ...result,
      consultResponse: {
        id: data?.response?.id || null,
        isSuggestedDoseNotApplicable: data?.response?.is_suggested_dose_not_applicable || false,
        testosterone: data?.response?.testosterone_suggested || null,
        estradiol: data?.response?.estradiol_suggested || null,
        medicationSuggestions: data?.response?.medication_suggestions || null,
        note: data?.response?.note || null,
        comment: data?.response?.comment || null,
      },
    };
  }

  public handlerConsultMessages(data: IConsultMessageApi): IConsultMessage {
    return {
      id: data.id,
      creatorId: data.creator_id,
      creatorName: data.creator_name,
      consulterName: data.consulter_name,
      message: data.message,
      dateTime: data.datetime,
    };
  }

  public transformToOrganizationInfo(data: IConsultOrganizationInfoApi): IConsultOrganizationInfo {
    return {
      assigneeInfo: {
        userName: data.assignee.user_name,
        practiceName: data.assignee.practice_name,
        assignedDate: data.assignee.assigned_date,
        resolvedDate: data.assignee.resolved_date,
      },
      consultOfficeInformation: {
        userName: data.creator.user_name,
        practiceName: data.creator.practice_name,
        userType: data.creator.user_type,
        consultDate: data.creator.consult_date,
      },
    };
  }
}
