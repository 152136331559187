import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { IInfoPopupData } from '@sp-shared/containers/info-popup/interfaces/info-popup-data-interface';
import { InfoPopupComponent } from '@sp-shared/containers/info-popup/containers/info-popup/info-popup.component';
import { DosingResponsibilityAgreementType } from '@sp-core/agreement-keys/dosing-responsibility-agreement-type.enum';
import { ActivityService } from '@sp-core/services';
import { HttpAgreementsService } from '../../http-services/http-agreements/http-agreements.service';
import { ActivityEventsActionsEnum } from '../../agreement-keys/activity-events-actions.enum';

@Injectable({
  providedIn: 'root',
})
export class AgreementsService {
  constructor(
    public httpAgreementsService: HttpAgreementsService,
    private activityService: ActivityService,
    private dialog: MatDialog,
  ) {}

  public getDosingResponsibilityAgreement(httpParams: HttpParamsOptions): Observable<IDosingResponsibilityAgreement> {
    return this.httpAgreementsService.getDosingResponsibilityAgreement(httpParams).pipe(
      // @ts-ignore
      // eslint-disable-next-line consistent-return
      map((res) => {
        if (res && res?.data && res?.data?.length) {
          return this.transformToDosingResponsibilityAgreement(res?.data[0]);
        }
      }),
    );
  }

  public sendDosingResponsibilityAgreement(agreementId: string): Observable<void> {
    return this.httpAgreementsService.sendDosingResponsibilityAgreement(agreementId);
  }

  public getDosingResponsibilityAgreementFlow(httpParams: HttpParamsOptions): Observable<boolean> {
    return this.getDosingResponsibilityAgreement(httpParams).pipe(
      switchMap((res: IDosingResponsibilityAgreement) => {
        if (!res) {
          return of(true);
        }
        return Object.values(httpParams)[0] === DosingResponsibilityAgreementType.EmptyLotsResponsibilityDisclaimer
          ? this.getConfirmDosingResponsibilityAgreementFlow(
              res,
              ActivityEventsActionsEnum.responsibilityDisclaimerEmptyLotsViewed,
              false,
            )
          : this.getConfirmDosingResponsibilityAgreementFlow(
              res,
              ActivityEventsActionsEnum.responsibilityDisclaimerViewed,
              true,
            );
      }),
    );
  }

  private transformToDosingResponsibilityAgreement(
    data: IDosingResponsibilityAgreementApi,
  ): IDosingResponsibilityAgreement {
    if (!data) {
      return null;
    }
    return {
      agreementId: data.id,
      message: data.text,
      type: data.type,
    };
  }

  private getConfirmDosingResponsibilityAgreementFlow(
    agreement: IDosingResponsibilityAgreement,
    activityEventAction: ActivityEventsActionsEnum,
    isCloseBtn,
  ): Observable<boolean> {
    const confirmPopupTitle =
      activityEventAction === ActivityEventsActionsEnum.responsibilityDisclaimerEmptyLotsViewed
        ? 'CALC.LOT_NUMBER_DISCLAIMER'
        : 'CALC.DOSING_RESPONSIBILITY_DISCLAIMER';
    const dialogRef = this.getConfirmPopup(agreement.message, confirmPopupTitle, isCloseBtn);
    return dialogRef.afterOpened().pipe(
      switchMap(() => this.activityService.trackActivityEvent(activityEventAction)),
      switchMap(() => dialogRef.afterClosed()),
      switchMap((answer) => {
        if (answer) {
          return this.sendDosingResponsibilityAgreement(agreement.agreementId).pipe(map(() => true));
        }
        return of(false);
      }),
    );
  }

  private getConfirmPopup(text: string, title = '', isCloseBtn) {
    const data: IInfoPopupData = {
      title,
      text,
      actions: {
        acknowledge: 'ACKNOWLEDGE',
      },
      isCloseBtn,
    };
    return this.dialog.open(InfoPopupComponent, { disableClose: true, data });
  }
}
