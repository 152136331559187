import { Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TokenService } from '@sp-core/services/token/token.service';
import { PermissionsService } from '@sp-core/services/permissions/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard {
  constructor(
    private router: Router,
    private tokenService: TokenService,
    private permissionsService: PermissionsService,
  ) {}

  public canActivate(): Observable<UrlTree | boolean> {
    return combineLatest([this.tokenService.accessToken$, this.permissionsService.role$]).pipe(
      take(1),
      map(([isActiveSession, role]) => {
        if (isActiveSession && role) {
          return this.router.parseUrl('/');
        }
        return true;
      }),
    );
  }
}
