import { Injectable } from '@angular/core';
import { HttpProviderService } from '@sp-core/http-services/http-provider/http-provider.service';
import { ITableService } from '@sp-shared/components/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MetaHttpAdapter } from '@sp-core/http-adapters/meta-http-adapter';
import { ProviderHttpAdapter } from '@sp-core/http-adapters/provider-http-adapter';

@Injectable({
  providedIn: 'root',
})
export class ProviderService implements ITableService<IProvider> {
  constructor(private httpProvider: HttpProviderService, private providerHttpAdapter: ProviderHttpAdapter) {}

  public getTableData(params: HttpParamsOptions): Observable<ITableData<IProvider>> {
    return this.httpProvider.getListProvider(params).pipe(
      map((data) => ({
        data: data.data.map((item) => this.providerHttpAdapter.transformToProvider(item)),
        meta: MetaHttpAdapter.transformToMeta(data.meta),
      })),
    );
  }

  public getProvider(id: string): Observable<IProvider> {
    return this.httpProvider.getProvider(id).pipe(map((data) => this.providerHttpAdapter.transformToProvider(data)));
  }

  public updateProvider(data: ISingleProvider): Observable<IProviderApi> {
    const sendData: ISingeProviderApi = this.providerHttpAdapter.transformToProviderSingleApi(data);
    return this.httpProvider.updateProvider(sendData);
  }

  public toggleCertificates(data: ISingleProvider): Observable<IProviderApi> {
    const sendData: ICertificateSingeApi = this.providerHttpAdapter.transformToCertificateSinge(data);
    const providerApi: ISingeProviderApi = { id: data.id, ...sendData };
    return this.httpProvider.updateProvider(providerApi);
  }

  public toggleStatusUser(data: ISingleProvider): Observable<IProviderApi> {
    const providerApi: ISingeProviderApi = { id: data.id, is_active: data.isActive };
    return this.httpProvider.updateProvider(providerApi);
  }

  public toggleCanConsult(data: ISingleProvider): Observable<IProviderApi> {
    const providerApi: ISingeProviderApi = { id: data.id, is_can_consult: data.isCanConsult };
    return this.httpProvider.updateProvider(providerApi);
  }

  public inviteProvider(data: ISingleProvider): Observable<void> {
    const sendData = this.providerHttpAdapter.transformToProviderSingleApi(data);
    return this.httpProvider.inviteProvider(sendData);
  }

  public getSingeProvider(provider: IProvider): ISingleProvider {
    return this.providerHttpAdapter.transformToSingleProvider(provider);
  }

  public prepareStaff(provider: IProvider): ISingleProvider {
    return this.providerHttpAdapter.transformToSingleProvider(provider);
  }

  public getRoles(isExcludeLimitedRoles = 0): Observable<IRoleApi[]> {
    return this.httpProvider
      .getRoles(isExcludeLimitedRoles)
      .pipe(map((data) => data.data.map((role) => this.providerHttpAdapter.transformToRole(role))));
  }

  public reassignProviderConsultOpenConsultsAndMedicalQuestions(
    currentProviderId: string,
    assignProviderId: string,
  ): Observable<void> {
    return this.httpProvider.reassignProviderConsultOpenConsultsAndMedicalQuestions(
      currentProviderId,
      assignProviderId,
    );
  }
}
