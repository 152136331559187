import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConfig } from '@sp-core/api-config';

@Injectable({
  providedIn: 'root',
})
export class HttpStickyNotesService {
  constructor(private http: HttpClient) {}

  public getStickyNotes(httpParams: HttpParamsOptions): Observable<ITableApiData<IStickyNoteResponseApi>> {
    return this.http.get<ITableApiData<IStickyNoteResponseApi>>(`${ApiConfig.stickyNotesPath}`, {
      params: httpParams,
    });
  }

  public getStickyNote(id: string): Observable<IStickyNoteResponseApi> {
    return this.http.get<IStickyNoteResponseApi>(`${ApiConfig.stickyNotesPath}/${id}`);
  }

  public createStickyNote(data: IStickyNoteRequestApi): Observable<IStickyNoteResponseApi> {
    return this.http.post<IStickyNoteResponseApi>(ApiConfig.stickyNotesPath, data);
  }

  public updateStickyNote(data: IStickyNoteRequestApi, id: string): Observable<IStickyNoteResponseApi> {
    return this.http.patch<IStickyNoteResponseApi>(`${ApiConfig.stickyNotesPath}/${id}`, data);
  }

  public updateStickyNoteCoordinates(data: IStickyNoteCoordinatesApi, id: string): Observable<IStickyNoteResponseApi> {
    return this.http.patch<IStickyNoteResponseApi>(`${ApiConfig.stickyNotesPath}/${id}/coordinates`, data);
  }

  public updateStickyNoteColor(color: string, id: string): Observable<IStickyNoteResponseApi> {
    return this.http.patch<IStickyNoteResponseApi>(`${ApiConfig.stickyNotesPath}/${id}/color`, { color });
  }

  public deleteStickyNote(id: string): Observable<void> {
    return this.http.delete<void>(`${ApiConfig.stickyNotesPath}/${id}`);
  }

  public minimizeStickyNote(id: string): Observable<void> {
    return this.http.put<void>(`${ApiConfig.stickyNotesPath}/${id}/minimize`, {});
  }

  public createStickyNoteComment(
    stickyNoteId: string,
    data: IStickyNoteCommentRequestApi,
  ): Observable<IStickyNoteCommentApi> {
    return this.http.post<IStickyNoteResponseApi>(`${ApiConfig.stickyNotesPath}/${stickyNoteId}/comments`, data);
  }

  public updateStickyNoteComment(
    stickyNoteId: string,
    id: string,
    data: IStickyNoteCommentRequestApi,
  ): Observable<IStickyNoteResponseApi> {
    return this.http.patch<IStickyNoteResponseApi>(`${ApiConfig.stickyNotesPath}/${stickyNoteId}/comments/${id}`, data);
  }

  public deleteStickyNoteComment(stickyNoteId: string, id: string): Observable<void> {
    return this.http.delete<void>(`${ApiConfig.stickyNotesPath}/${stickyNoteId}/comments/${id}`);
  }
}
