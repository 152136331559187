import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import {
  RegExpValidator,
  strNoStartAndEndWhitespace,
  specialCharacters,
  url,
  digital,
  urlWithOutProtocol,
  integer,
  numbers,
  allowedOnlySpecialCharacters,
  citySpecialCharacters,
  subDomainUrl,
  zipCodeAllowLettersNumbersSpaceDash,
} from './regexp';
import Utils from '../utils';
import { IError } from './error-inteface';

export class CValidators extends Validators {
  constructor() {
    super();
  }

  public static required(control: AbstractControl): IError | null {
    if (!control) {
      return null;
    }
    if (control.value === '' || control.value === 'undefined' || control.value === null) {
      return CValidators.createErrorMessage('required');
    }
    return null;
  }

  public static emptyArray(control: AbstractControl): IError | null {
    if (!control) {
      return null;
    }
    if (control.value && control.value.length === 0) {
      return CValidators.createErrorMessage('required');
    }
    return null;
  }

  public static includesSomeRequiredFieldsInArray(data: any[]): (control: AbstractControl) => IError | null {
    return (control: AbstractControl) => {
      if (!control) {
        return null;
      }
      const checkedVal = control.value && control.value.length > 0 && Utils.isArray(control.value);

      if (checkedVal) {
        const includesValue = data.some((item) => control.value.includes(item));
        if (includesValue) {
          return null;
        }
      }
      if (checkedVal) {
        return CValidators.createErrorMessage('required');
      }
      return null;
    };
  }

  public static emptyString(c: AbstractControl): IError | null {
    if (!c || !c.value) {
      return null;
    }

    if (c.value && (c.value as string).trim() === '') {
      return CValidators.createErrorMessage('emptyString');
    }
    return null;
  }

  public static minLength(value: number): ValidatorFn {
    return (c: AbstractControl): IError | null => {
      if (!c || !c.value) {
        return null;
      }
      if (c.value.length < value) {
        return CValidators.createErrorMessage('minlength', value, c.value);
      }
      return null;
    };
  }

  public static maxLength(value: number): ValidatorFn {
    return (c: AbstractControl): IError | null => {
      if (!c || !c.value) {
        return null;
      }
      if (c.value.length > value) {
        return CValidators.createErrorMessage('maxlength', value, c.value.length);
      }
      return null;
    };
  }

  public static maxNumberLength(value: number): ValidatorFn {
    return (c: AbstractControl): IError | null => {
      if (!c || !c.value) {
        return null;
      }
      if (`${c.value}`.length > value) {
        return CValidators.createErrorMessage('maxNumberLength', value, `${c.value}`.length);
      }
      return null;
    };
  }

  public static min(value: number): ValidatorFn {
    return (c: AbstractControl): IError | null => {
      if (!c || !c.value) {
        return null;
      }
      if (c.value < value) {
        return CValidators.createErrorMessage('min', value, c.value);
      }
      return null;
    };
  }

  public static max(value: number): ValidatorFn {
    return (c: AbstractControl): IError | null => {
      if (!c || !c.value) {
        return null;
      }
      if (c.value > value) {
        return CValidators.createErrorMessage('max', value, c.value);
      }
      return null;
    };
  }

  public static email(control: AbstractControl): IError | null {
    if (!control || !control.value) {
      return null;
    }
    // eslint-disable-next-line no-useless-escape
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // tslint:disable-line

    const value: string = control?.value;
    if (!re.test(value?.toLowerCase())) {
      return CValidators.createErrorMessage('email');
    }
    return null;
  }

  public static slug(control: AbstractControl): IError | null {
    if (!control || !control.value) {
      return null;
    }
    // eslint-disable-next-line no-useless-escape
    const re = /^[a-zA-Z0-9\-]+$/; // tslint:disable-line

    if (!re.test(control.value.toLowerCase())) {
      return CValidators.createErrorMessage('slug');
    }
    return null;
  }

  public static emailWithWhitespaceNoEnd(control: AbstractControl): IError | null {
    if (!control || !control.value) {
      return null;
    }
    // eslint-disable-next-line no-useless-escape
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(\s)*$/; // tslint:disable-line

    if (!re.test(control.value.toLowerCase())) {
      return CValidators.createErrorMessage('email');
    }
    return null;
  }

  public static createErrorMessage(name: string, expectedValue?: any, currentValue?: any): IError {
    const result: IError = { name };
    if (expectedValue || expectedValue === 0) {
      result.expectedValue = expectedValue;
    }
    if (expectedValue || expectedValue === 0) {
      result.currentValue = currentValue;
    }
    return result;
  }

  public static mustMatch(controlName: string, matchingControlName: string): ValidatorFn {
    // @ts-ignore
    return (formGroup: AbstractControl): IError | null => {
      const control = (formGroup as any).controls[controlName];
      const matchingControl = (formGroup as any).controls[matchingControlName];

      if (matchingControl.errors && matchingControl.errors.name !== 'passwordNotMatch') {
        // return if another validator has already found an error on the matchingControl
        return null;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        const error: IError = { name: 'passwordNotMatch' };
        matchingControl.setErrors(error);
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  public static passwordMatchPattern(c: AbstractControl): IError | null {
    if (!c || !c.value) {
      return null;
    }

    const res: boolean = Object.values(RegExpValidator).every((reg: RegExp) => reg.test(c.value));

    if (!res) {
      return CValidators.createErrorMessage('passwordPatter');
    }
    return null;
  }

  public static noStartAndEndWhitespace(c: AbstractControl): IError | null {
    if (!c || !c.value) {
      return null;
    }
    const res: boolean = strNoStartAndEndWhitespace.test(c.value);

    if (!res) {
      return CValidators.createErrorMessage('startAndEndWhitespace');
    }
    return null;
  }

  public static specialCharacters(c: AbstractControl): IError | null {
    if (!c || !c.value) {
      return null;
    }
    const res: boolean = specialCharacters.test(c.value);

    if (res) {
      return CValidators.createErrorMessage('specialCharacters');
    }
    return null;
  }

  public static noDigital(c: AbstractControl): IError | null {
    if (!c || !c.value) {
      return null;
    }
    const res: boolean = RegExpValidator.digit.test(c.value);

    if (res) {
      return CValidators.createErrorMessage('noDigital');
    }
    return null;
  }

  public static onlyDigital(c: AbstractControl): IError | null {
    if (!c || !c.value) {
      return null;
    }
    const res: boolean = digital.test(c.value);

    if (!res) {
      return CValidators.createErrorMessage('onlyDigital');
    }
    return null;
  }

  public static onlyNumbers(c: AbstractControl): IError | null {
    if (!c || !c.value) {
      return null;
    }
    const res = numbers.test(c.value.toString());

    if (!res) {
      return CValidators.createErrorMessage('onlyInteger');
    }
    return null;
  }

  public static onlyInteger(c: AbstractControl): IError | null {
    if (!c || !c.value) {
      return null;
    }
    const res: boolean = integer.test(c.value);

    if (!res) {
      return CValidators.createErrorMessage('onlyInteger');
    }
    return null;
  }

  public static checkUrl(c: AbstractControl): IError | null {
    if (!c || !c.value) {
      return null;
    }
    if (!url.test(c.value)) {
      return CValidators.createErrorMessage('patternUrl');
    }
    return null;
  }

  public static checkWebsite(c: AbstractControl): IError | null {
    if (!c || !c.value) {
      return null;
    }
    if (!urlWithOutProtocol.test(c.value)) {
      return CValidators.createErrorMessage('websiteUrl');
    }
    return null;
  }

  public static subDomainUrl(c: AbstractControl): IError | null {
    if (!c || !c.value) {
      return null;
    }
    if (!subDomainUrl.test(c.value)) {
      return CValidators.createErrorMessage('subDomainUrl');
    }
    return null;
  }

  public static allowedOnlySpecialCharacters(c: AbstractControl): IError | null {
    if (!c || !c.value) {
      return null;
    }
    if (!allowedOnlySpecialCharacters.test(c.value)) {
      return CValidators.createErrorMessage('onlySpecialCharacters');
    }
    return null;
  }

  public static citySpecialCharacters(c: AbstractControl): IError | null {
    if (!c || !c.value) {
      return null;
    }
    if (!citySpecialCharacters.test(c.value)) {
      return CValidators.createErrorMessage('citySpecialCharacters');
    }
    return null;
  }

  public static zipcodeSpecialCharacters(c: AbstractControl): IError | null {
    if (!c || !c.value) {
      return null;
    }
    if (!zipCodeAllowLettersNumbersSpaceDash.test(c.value)) {
      return CValidators.createErrorMessage('zipcodeSpecialCharacters');
    }
    return null;
  }

  public static noZeroValueInBothControls(firstControlName: string, secondControlName: string): ValidatorFn {
    return (formGroup: AbstractControl): IError | null => {
      const firstControl = (formGroup as any).controls[firstControlName];
      const secondControl = (formGroup as any).controls[secondControlName];

      if (firstControl.errors && firstControl.errors.name !== 'noZeroValueInBothControls') {
        return null;
      }

      if (secondControl.errors && secondControl.errors.name !== 'noZeroValueInBothControls') {
        return null;
      }

      if (firstControl.value === 0 && secondControl.value === 0) {
        const error: IError = { name: 'noZeroValueInBothControls' };
        firstControl.setErrors(error);
        secondControl.setErrors(error);
      } else if (firstControl.value !== 0) {
        firstControl.setErrors(null);
        secondControl.setErrors(null);
      } else if (secondControl.value !== 0) {
        firstControl.setErrors(null);
        secondControl.setErrors(null);
      }
    };
  }

  public static oneOfTwoControlsIsRequired(firstControlName: string, secondControlName: string): ValidatorFn {
    return (formGroup: AbstractControl): IError | null => {
      const firstControl = (formGroup as any).controls[firstControlName];
      const secondControl = (formGroup as any).controls[secondControlName];

      if (firstControl.errors && firstControl.errors.name !== 'oneOfTwoControlsIsRequired') {
        return null;
      }

      if (secondControl.errors && secondControl.errors.name !== 'oneOfTwoControlsIsRequired') {
        return null;
      }

      const isEmptyArray = (control: AbstractControl): boolean => {
        return control.value && control.value.length === 0;
      };

      const isNoValue = (control: AbstractControl): boolean => {
        return control.value === '' || control.value === 'undefined' || control.value === null;
      };
      if (isEmptyArray(firstControl) || isNoValue(secondControl)) {
        const error: IError = { name: 'oneOfTwoControlsIsRequired' };
        firstControl.setErrors(error);
      }

      if ((firstControl.value && firstControl.value.length > 0) || secondControl.value) {
        firstControl.setErrors(null);
      }
    };
  }

  public static yearRangeValidator(firstControlName: string, secondControlName: string): ValidatorFn {
    return (formGroup: AbstractControl): IError | null => {
      const firstControl = (formGroup as any).controls[firstControlName];
      const secondControl = (formGroup as any).controls[secondControlName];
      const dateFrom: Date = new Date(firstControl.value);
      const dateTo: Date = new Date(secondControl.value);
      const dateFromPlus1Year: Date = Utils.addYear(dateFrom, 2);

      if (firstControl.errors && firstControl.errors.name !== 'dateTo') {
        return null;
      }

      if (dateTo > dateFromPlus1Year) {
        const error: IError = { name: 'dateTo' };
        secondControl.setErrors(error);
        secondControl.markAllAsTouched();
      } else {
        secondControl.setErrors(null);
      }
    };
  }

  public static rangeDateValidator(firstControlName: string, secondControlName: string): ValidatorFn {
    return (formGroup: AbstractControl): IError | null => {
      const firstControl = (formGroup as any).controls[firstControlName];
      const secondControl = (formGroup as any).controls[secondControlName];
      const dateFrom: Date = new Date(firstControl.value);
      const dateTo: Date = new Date(secondControl.value);

      if (firstControl.errors && firstControl.errors.name !== 'rangeDateTo') {
        return null;
      }

      if (secondControl.errors && secondControl.errors.name !== 'rangeDateTo') {
        return null;
      }

      if (dateFrom > dateTo) {
        const error: IError = { name: 'rangeDateTo' };
        secondControl.setErrors(error);
        secondControl.markAllAsTouched();
      } else {
        secondControl.setErrors(null);
      }
    };
  }

  public static emailChip(control: AbstractControl): IError | null {
    if (!control || !control.value) {
      return null;
    }
    if (Utils.isArray(control.value)) return;
    // eslint-disable-next-line no-useless-escape
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // tslint:disable-line

    const value: string = control?.value;
    if (!re.test(value?.toLowerCase())) {
      return CValidators.createErrorMessage('email');
    }
    return null;
  }
}
