<sp-auth-layout>
  <h2 auth-sub-title class="auth-sub-title text-center">
    {{'AUTH.REGISTRATION' | translate}}
  </h2>
  <div auth-server-error class="col-12 justify-content-center">
    <div class="sign-up-form-control">
      <mat-error class="token-error text-center" *ngIf="!token"> {{'ERROR_INVITE_TOKEN' | translate}}</mat-error>
      <mat-error class="token-error text-center" *ngIf="serverIncorrectToken"> {{serverIncorrectToken}}</mat-error>
    </div>
  </div>

  <div class="row justify-content-md-center">
    <div class="col-12 col-sm-12 col-md-6">
      <sp-sign-up-form
        (submitForm)="handlerSubmit($event)"
        [formSignUp]="formSignUp"
        [token]="token"
        [email]="email"
        [minPasswordLength]="minPasswordLength"
      >
      </sp-sign-up-form>
    </div>
  </div>

  <div class="row justify-content-md-center auth-m-b">
    <div class="col-12 col-sm-12 col-md-6">
      <div class="d-flex justify-content-center">
        <a class="link-blue text-center" [routerLink]="'/sign-in'">
          {{'AUTH.LOGIN' | translate}}
        </a>
      </div>
    </div>
  </div>
</sp-auth-layout>
