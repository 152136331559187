export const colorPresets: string[] = [
  '#fde85c',
  '#FFE082',
  '#FFCC80',
  '#FFAB91',
  '#BCAAA4',
  '#EEEEEE',
  '#B0BEC5',
  '#EF9A9A',
  '#F48FB1',
  '#CE93D8',
  '#B39DDB',
  '#9FA8DA',
  '#90CAF9',
  '#81D4FA',
  '#80DEEA',
  '#80CBC4',
  '#A5D6A7',
  '#C5E1A5',
  '#E6EE9C',
  '#FFF59D',
  '#FFFF00',
  '#FFD740',
  '#FFAB40',
  '#FF6E40',
];
