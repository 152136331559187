import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProviderHttpAdapter {
  public transformToProvider(data: IProviderApi): IProvider {
    return {
      id: data.id,
      isActive: data.is_active,
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      practice: this.transformToPractice(data?.practice),
      roles: data.roles.map((role) => this.transformToRole(role)),
      transgenderTraining: data?.certificates?.transgender_training || false,
      isLocked: data?.is_locked,
      phone: data.phone,
      phoneCode: {
        id: data.phone_code?.id,
        prefix: data.phone_code?.prefix,
        countryId: data.phone_code?.country?.id,
        countryKey: data.phone_code?.country?.key,
        countryTitle: data.phone_code?.country?.title,
      },
      isCanConsult: data.is_can_consult,
      isHasConsult: data.is_has_consult,
    };
  }

  public transformToPractice(data: { practice_name: string; practice_id: string; is_active: boolean }): {
    practiceName: string;
    practiceId: string;
    isActive: boolean;
  } {
    if (!data) {
      return null;
    }
    return {
      practiceName: data.practice_name,
      practiceId: data.practice_id,
      isActive: data.is_active,
    };
  }

  public transformToRole(role: IRoleApi): IRole {
    if (!role) {
      return null;
    }
    return {
      id: role.id,
      key: role.key,
      name: role.name,
    };
  }

  public transformToProviderSingleApi(provider: ISingleProvider): ISingeProviderApi {
    return {
      id: provider.id,
      is_active: provider.isActive,
      first_name: provider.firstName,
      last_name: provider.lastName,
      email: provider.email,
      practice: this.transformToProviderPracticeApi(provider.practice),
      role_ids: provider.roleIds,
      link: provider.link,
      certificates: this.transformToCertificates(provider.transgenderTraining),
      is_locked: provider.isLocked,
      lock_reason: provider.lockedReason,
      is_can_consult: provider.isCanConsult,
      practice_id: provider.practiceId,
    };
  }

  public transformToProviderPracticeApi(data: IProviderPractice): IProviderPracticeApi {
    if (!data) {
      return undefined;
    }
    return {
      is_active: data?.isActive,
      practice_id: data?.practiceId,
      practice_name: data?.practiceName,
    };
  }

  public transformToCertificates(data: boolean): ICertificateApi {
    if (data === undefined) {
      return undefined;
    }
    return {
      transgender_training: data,
    };
  }

  public transformToCertificateSinge(data: ISingleProvider): ICertificateSingeApi {
    return {
      certificates: {
        transgender_training: data.transgenderTraining,
      },
    };
  }

  public transformToSingleProvider(provider: IProvider): ISingleProvider {
    return {
      id: provider.id,
      isActive: provider.isActive,
      firstName: provider.firstName,
      lastName: provider.lastName,
      email: provider.email,
      roleIds: provider.roles.map((role) => role.id),
      transgenderTraining: provider.transgenderTraining,
      isLocked: provider?.isLocked,
      lockedReason: provider?.lockedReason,
      phone: provider.phone,
      phoneCode: {
        id: provider.phoneCode?.id,
        prefix: provider.phoneCode?.prefix,
        countryId: provider.phoneCode?.countryId,
        countryKey: provider.phoneCode?.countryKey,
        countryTitle: provider.phoneCode?.countryTitle,
      },
      isCanConsult: provider.isCanConsult,
      practice: {
        practiceName: provider?.practice?.practiceName,
        practiceId: provider?.practice?.practiceId,
        isActive: provider?.practice?.isActive,
      },
      isHasConsult: provider?.isHasConsult,
    };
  }

  public transformToUser(userApi: IUserApi): IUser {
    return {
      roles: userApi.roles.map((role) => this.transformToRole(role)),
      isMultipleRoles: userApi?.roles?.length > 1,
      practice: {
        isActive: userApi?.practice?.is_active,
        practiceName: userApi?.practice?.practice_name,
        practiceId: userApi?.practice?.practice_id,
      },
      firstName: userApi.first_name,
      lastName: userApi.last_name,
      isActive: userApi.is_active,
      id: userApi.id,
      email: userApi.email,
      dateTimeFormat: this.transformToDateTimeFormat(userApi.datetime_format),
      timeZone: this.transformToTimeZone(userApi.timezone),
      measurementPreferences: this.transformToMeasurementPreferences(userApi.measurement_preferences),
      transgenderTraining: userApi?.certificates?.transgender_training,
      lockedReason: userApi?.lock_reason,
      isLocked: userApi?.is_locked,
      phone: userApi.phone,
      phoneCode: {
        id: userApi.phone_code?.id,
        prefix: userApi.phone_code?.prefix,
        countryId: userApi.phone_code?.country?.id,
        countryKey: userApi.phone_code?.country?.key,
        countryTitle: userApi.phone_code?.country?.title,
      },
      isShowChangePassword: userApi.is_show_change_password,
      isCanConsult: userApi.is_can_consult,
      isHasConsult: userApi.is_has_consult,
    };
  }

  public transformToDateTimeFormat(date: IDatetimeFormatApi): IDatetimeFormat {
    return {
      id: date.id,
      key: date.key,
      title: date.title,
      timeFormat: date.time_format,
      dateFormat: this.formatToUppercase(date.date_format),
    };
  }

  public formatToUppercase(format: string): string {
    return format.toUpperCase();
  }

  public transformToTimeZone(data: ITimeZoneApi): ITimeZone {
    return {
      id: data.id,
      title: data.title,
    };
  }

  public transformToMeasurementPreferences(date: IMeasurementPreferencesApi): IMeasurementPreferences {
    return {
      id: date.id,
      key: date.key,
      title: date.title,
    };
  }

  public transformToBlockedHistory(data: IBlockedUserHistoryApi): IBlockedUserHistory {
    return {
      reason: data.lock_reason,
      isLocked: data.is_locked,
      date: data.date,
      id: data.id,
      author: data.author,
    };
  }
}
