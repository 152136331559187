import { Injectable } from '@angular/core';
import { HttpPatientService } from '@sp-core/http-services/http-patient/http-patient.service';
import { Observable, ObservedValueOf, OperatorFunction, throwError } from 'rxjs';
import { catchError, map, pluck } from 'rxjs/operators';
import { PatientHttpAdapter } from '@sp-core/http-adapters/patient-http-adapter';
import { ITableService } from '@sp-shared/components/table';
import { MetaHttpAdapter } from '@sp-core/http-adapters/meta-http-adapter';
import { ServerStatusCode } from '@sp-core/agreement-keys/server-status-code.enum';
import { ServerErrorModel } from '@sp-core/models/app-models/server-error.model';

@Injectable({
  providedIn: 'root',
})
export class PatientService implements ITableService<IPatient> {
  constructor(
    public readonly _patientHttpService: HttpPatientService,
    public readonly _patientHttpAdapter: PatientHttpAdapter,
  ) {}

  public getGenders(): Observable<IGender[]> {
    return this._patientHttpService
      .getGender()
      .pipe(map((data) => data.data.map((gender) => this._patientHttpAdapter.transformToGender(gender))));
  }

  public getTableData(params: HttpParamsOptions): Observable<ITableData<IPatient>> {
    return this._patientHttpService.getPatients(params).pipe(
      map((data) => ({
        data: data.data.map((item) => this._patientHttpAdapter.transformToPatient(item)),
        meta: MetaHttpAdapter.transformToMeta(data.meta),
      })),
    );
  }

  public getRace(): Observable<IRace[]> {
    return this._patientHttpService
      .getRace()
      .pipe(map((data) => data.data.map((race) => this._patientHttpAdapter.transformToRace(race))));
  }

  public createPatient(patient: IPatientForm): Observable<IPatient> {
    const sendData = this._patientHttpAdapter.transformToPatientApi(patient);
    return this._patientHttpService.createPatient(sendData).pipe(
      map((res) => this._patientHttpAdapter.transformToPatient(res)),
      this.errorPatientHandler(),
    );
  }

  public updatePatient(patient: IPatientForm): Observable<IPatientApi> {
    const sendData = this._patientHttpAdapter.transformToPatientApi(patient);
    return this._patientHttpService.updatePatient(sendData, patient.id).pipe(this.errorPatientHandler());
  }

  public transformToPatientForm(patient: IPatient): IPatientForm {
    return this._patientHttpAdapter.transformToPatientForm(patient);
  }

  public getPatient(id: string): Observable<IPatient> {
    return this._patientHttpService
      .getPatient(id)
      .pipe(map((patient) => this._patientHttpAdapter.transformToPatient(patient)));
  }

  public deleteLastVisitedPatient(id: string): Observable<void> {
    return this._patientHttpService.deleteLastVisitedPatient(id);
  }

  public changePatientActiveStatus(id: string, isActive: boolean): Observable<void> {
    return this._patientHttpService.changePatientActiveStatus(id, isActive);
  }

  public errorPatientHandler(): any {
    return catchError((serverError: ServerErrorModel) => {
      if (serverError.status === ServerStatusCode.validationError) {
        serverError.validationErrors = this._patientHttpAdapter.transformToPatientFormError(
          serverError.validationErrors,
        );
      }
      return throwError(() => serverError);
    });
  }

  public getProviderList(patientId: string, search?: string): Observable<IProviderList[]> {
    return this._patientHttpService.getProviderList(patientId, search).pipe(
      pluck('data'),
      map((providers) => providers.map((provider) => this._patientHttpAdapter.transformToProviderList(provider))),
    );
  }
}
