<sp-auth-layout>
  <h2 auth-server-error class="auth-sub-title text-center">
    {{'AUTH.FORGOT_PASS' | translate}}
  </h2>

  <div class="row justify-content-md-center">
    <div class="col-12 col-sm-12 col-md-6">
      <sp-forgot-password-form [forgotPassword]="forgotPasswordForm"
                               [serverError]="serverError"
                               (wasSubmitted)="onSubmit()"
      >
      </sp-forgot-password-form>
    </div>
  </div>
  <div class="row justify-content-md-center auth-m-b">
    <div class="col-12 col-sm-12 col-md-6">
      <div class="d-flex justify-content-center">
        <a class="link-blue text-center" [routerLink]="'/sign-in'">
          {{'AUTH.LOGIN' | translate}}
        </a>
      </div>
    </div>
  </div>
</sp-auth-layout>
