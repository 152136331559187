import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PermissionsService } from '@sp-core/services';

@Component({
  selector: 'sp-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthLayoutComponent {
  constructor(private permissionsService: PermissionsService) {}

  public get currentUserRole(): IRole {
    return this.permissionsService.role;
  }
}
