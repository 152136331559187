import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PermissionsService } from '@sp-core-services';
import { UserPermissions, UserPermissionsType } from '@sp-core/dictionary-types/user-permissions.enum';

@Directive({
  selector: '[spCanView]',
})
export class CanViewDirective implements OnInit, OnDestroy {
  public destroy$: Subject<void> = new Subject();

  private listPermissions: UserPermissionsType[];

  private permissions: Map<string, IPermissions>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionsService: PermissionsService,
  ) {}

  public ngOnInit(): void {
    this.subscribeToPermissions();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  // used array of keys UserPermission enum
  @Input()
  public set spCanView(groups: UserPermissionsType[]) {
    this.listPermissions = groups;
    this.initView();
  }

  private subscribeToPermissions(): void {
    this.permissionsService.permissions$.pipe(takeUntil(this.destroy$)).subscribe((permissions) => {
      this.permissions = permissions;
      this.initView();
    });
  }

  private initView(): void {
    const permitForView = this.checkPermission();
    this.createOrClearView(permitForView);
  }

  private checkPermission(): boolean {
    if (this.permissions && Array.isArray(this.listPermissions)) {
      return this.checkArrayGroups(this.listPermissions);
    }
    // it used for nonUser init view
    return false;
  }

  private createOrClearView(permitForView: boolean): void {
    this.viewContainer.clear();
    if (permitForView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  private checkArrayGroups(groups: UserPermissionsType[], firstState = false): boolean {
    let result = firstState;
    if (groups.some((permission) => this.permissions.has(UserPermissions[permission]))) {
      result = !firstState;
    }
    return result;
  }
}
