<div class="patent-popup">
  <button spQaId="patient-popup_button_close" class="close" (click)="close()"></button>

  <h1 class="common-popup-title text-center">{{'PATENT_PAGE' | translate}}</h1>

  <div class="info-block">
    <p class="title-text">
      {{'CALC.SUBJECT' | translate}}:
    </p>
    <p class="info-text">
      {{'CALC.PATENT_ONE' | translate}}
    </p>
    <p class="info-text">
      {{'CALC.PATENT_TWO' | translate}}
    </p>
    <p class="info-text">
      {{'CALC.PATENT_THERE' | translate}}
    </p>
    <p class="info-text">
      {{'CALC.PATENT_FOUR' | translate}}
    </p>
  </div>
</div>
