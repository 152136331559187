/* tslint:disable */
/* eslint-disable */

import { MonoTypeOperatorFunction, pipe, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Utils from '../utils';
import { ServerErrorModel } from '@sp-core/models/app-models/server-error.model';
import { ServerStatusCode } from '@sp-core/agreement-keys/server-status-code.enum';

export default class TransformServerValidationUtils {
  public static getFirsError(error: string[]): string {
    return Utils.isArray(error) ? error[0] : null;
  }

  public static catchAndTransformValidationError<T = any, D = any>(
    transformFn: (data: T) => D,
  ): MonoTypeOperatorFunction<any> {
    return pipe(
      catchError((serverError: ServerErrorModel) => {
        return throwError(TransformServerValidationUtils.checkAndTransformValidationError(serverError, transformFn));
      }),
    );
  }

  public static checkAndTransformValidationError<T = any, D = any>(
    serverError: ServerErrorModel,
    transformFn: (data: T) => D,
  ): ServerErrorModel {
    if (serverError.status === ServerStatusCode.validationError) {
      serverError.validationErrors = TransformServerValidationUtils.transformValidationError(
        serverError.validationErrors,
        transformFn,
      );
    }
    return serverError;
  }

  public static transformValidationError<T = any, D = any>(
    validationErrors: IServerValidationErrors,
    transformFn: (data: T) => D,
  ): IServerValidationErrors {
    if (!validationErrors || Utils.isEmpty(validationErrors) || !transformFn) {
      return validationErrors;
    }
    const jsonObject = TransformServerValidationUtils.unflatten(validationErrors);
    const transformedJsonObject = transformFn(jsonObject);
    return TransformServerValidationUtils.flatten(transformedJsonObject);
  }

  private static flatten(objectOrArray, prefix = '') {
    const nestElement = (prev, value, key) => {
      if (!value) {
        return prev;
      }
      return typeof value === 'object'
        ? { ...prev, ...TransformServerValidationUtils.flatten(value, `${prefix}${key}.`) }
        : { ...prev, ...{ [`${prefix}${key}`]: value } };
    };

    return Array.isArray(objectOrArray)
      ? objectOrArray.reduce(nestElement, {})
      : Object.keys(objectOrArray).reduce((prev, element) => nestElement(prev, objectOrArray[element], element), {});
  }

  private static unflatten(data: any): any {
    if (Object(data) !== data) {
      return data;
    }
    if (Array.isArray(data)) {
      return data.length > 0 ? data[0] : '';
    }
    const result = {};
    let cur;
    let prop;
    let idx;
    let last;
    let temp;
    for (const p in data) {
      (cur = result), (prop = ''), (last = 0);
      do {
        idx = p.indexOf('.', last);
        temp = p.substring(last, idx !== -1 ? idx : undefined);
        cur = cur[prop] || (cur[prop] = !isNaN(parseInt(temp)) ? [] : {});
        prop = temp;
        last = idx + 1;
      } while (idx >= 0);
      const value = data[p];
      cur[prop] = Array.isArray(value) ? value[0] : value;
    }
    return result[''];
  }
}
