export enum Roles {
  masterAdmin = 'master_admin',
  provider = 'provider',
  supportStaff = 'support_staff',
  practiceAdmin = 'practice_admin',
  dosingProvider = 'dosing_provider',
  limitedProvider = 'limited_provider',
  limitedPracticeAdmin = 'limited_practice_admin',
  limitedSupportStaff = 'limited_support_staff',
}
