import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConfig } from '@sp-core/api-config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpPatientService {
  constructor(public readonly _http: HttpClient) {}

  public getGender(): Observable<IDataListApi<IGenderApi>> {
    return this._http.get<IDataListApi<IGenderApi>>(ApiConfig.genderPath);
  }

  public getRace(): Observable<IDataListApi<IRaceApi>> {
    return this._http.get<IDataListApi<IRaceApi>>(ApiConfig.racesPath);
  }

  public createPatient(patient: INewPatientRequestApi): Observable<IPatientApi> {
    return this._http.post<IPatientApi>(ApiConfig.patientPath, patient);
  }

  public updatePatient(patient: INewPatientRequestApi, id: string): Observable<IPatientApi> {
    return this._http.patch<IPatientApi>(`${ApiConfig.patientPath}/${id}`, patient);
  }

  public getPatients(httpParams: HttpParamsOptions): Observable<ITableApiData<IPatientApi>> {
    return this._http.get<ITableApiData<IPatientApi>>(ApiConfig.patientPath, { params: httpParams });
  }

  public getPatient(id: string): Observable<IPatientApi> {
    return this._http.get<IPatientApi>(`${ApiConfig.patientPath}/${id}`);
  }

  public deleteLastVisitedPatient(id: string): Observable<void> {
    return this._http.delete<void>(`${ApiConfig.lastVisitedPatientPath}/${id}`);
  }

  public changePatientActiveStatus(id: string, isActive: boolean): Observable<void> {
    return this._http.patch<void>(`${ApiConfig.patientPath}/${id}/status`, { is_active: isActive });
  }

  public getProviderList(patientId: string, search = ''): Observable<{ data: IProviderListApi[] }> {
    const allProviders = [
      {
        id: 'allProviders',
        name: 'All Providers',
      },
    ];
    return this._http
      .get<{ data: IProviderListApi[] }>(`${ApiConfig.patientPath}/${patientId}/providers`, {
        params: { search },
      })
      .pipe(map((res) => ({ data: [...allProviders, ...res.data] })));
  }
}
