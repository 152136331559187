import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConfig } from '@sp-core/api-config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpActivityService {
  constructor(private http: HttpClient) {}

  public getActivities(httpParams: HttpParamsOptions): Observable<ITableApiData<IActivityApi>> {
    return this.http.get<ITableApiData<IActivityApi>>(`${ApiConfig.activityPath}`, {
      params: httpParams,
    });
  }

  public getChangLog(id: string): Observable<IDataListApi<IChangeLogApi>> {
    return this.http.get<IDataListApi<IChangeLogApi>>(`${ApiConfig.activityPath}/${id}`);
  }

  public trackActivityEvent(eventData: IActivityEventApi): Observable<void> {
    return this.http.post<void>(`${ApiConfig.activityPath}`, eventData);
  }

  public getActivitiesTypes(): Observable<IActivityTypeApi[]> {
    return this.http.get<{ data: IActivityTypeApi[] }>(`${ApiConfig.activityPath}/types`).pipe(map((res) => res.data));
  }
}
