<nav class="footer-nav-menu">
  <a class="footer-nav-menu__link"
     routerLinkActive="active"
     routerLink="settings"
     spQaId="footer-nav-menu_link_settings"
     *spCanView="['profileGet']"
  >
    {{'NAV.MY_SETTINGS' | translate}}
  </a>
  <a class="footer-nav-menu__link"
     routerLinkActive="active"
     routerLink="contact-us"
     spQaId="footer-nav-menu_link_contact-us"
     *spCanView="['contactUsGet']"
  >
    {{'NAV.CONTACT_US' | translate}}
  </a>
<!--  <ng-container *spCanView="['menuHelp']">-->
<!--    <a class="footer-nav-menu__link"-->
<!--       target="_blank"-->
<!--       *ngIf="user && role"-->
<!--       [spQaId]="role?.key === userRoles.practiceAdmin ? 'footer-nav-menu_link_educational-center' : 'footer-nav-menu_link_help'"-->
<!--       [href]="role?.key === userRoles.practiceAdmin ? 'https://dosaggio.co/contact' : 'https://dosaggio.co/'"-->
<!--    >-->
<!--      {{ role?.key === userRoles.practiceAdmin ? ('NAV.EDUCATIONAL_CENTER' | translate) : ('NAV.HELP' | translate) }}-->
<!--    </a>-->
<!--  </ng-container>-->
  <a class="footer-nav-menu__link"
     routerLinkActive="active"
     routerLink="home"
     spQaId="footer-nav-menu_link_home"
     *spCanView="['dashboard']"
  >
    {{'NAV.HOME' | translate}}
  </a>
</nav>
