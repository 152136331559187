import { ChangeDetectionStrategy, Component, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Roles } from '@sp-core/agreement-keys/roles.enum';
import { PermissionsService } from '@sp-core-services';

@Component({
  selector: 'sp-order-info-popup',
  templateUrl: './order-info-popup.component.html',
  styleUrls: ['./order-info-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderInfoPopupComponent {
  public role: typeof Roles = Roles;
  public commonCreateAccountLink = 'https://www.compoundconnection.com/register.aspx?sr=16';
  public dosingProviderCreateAccountLink = 'https://www.compoundconnection.com/register.aspx?sr=23';

  constructor(
    @Optional() public dialogRef: MatDialogRef<OrderInfoPopupComponent>,
    private permissionsService: PermissionsService,
  ) {}

  public closed(): void {
    this.dialogRef.close();
  }

  public get currentUserRole(): IRole {
    return this.permissionsService.role;
  }
}
