import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConfig } from '../../api-config';

@Injectable({
  providedIn: 'root',
})
export class HttpProviderService {
  constructor(public readonly _http: HttpClient) {}

  public getListProvider(httpParams: HttpParamsOptions): Observable<ITableApiData<IProviderApi>> {
    return this._http.get<ITableApiData<IProviderApi>>(`${ApiConfig.employeesPath}`, {
      params: httpParams,
    });
  }

  public getProvider(id: string): Observable<IProviderApi> {
    return this._http.get<IProviderApi>(`${ApiConfig.employeesPath}/${id}`);
  }

  public updateProvider(data: ISingeProviderApi): Observable<IProviderApi> {
    return this._http.patch<IProviderApi>(`${ApiConfig.employeesPath}/${data.id}`, data);
  }

  public toggleCertificates(data: ICertificateSingeApi, employeeId: string): Observable<any> {
    return this._http.patch<any>(`${ApiConfig.employeesPath}/${employeeId}/certificates`, data);
  }

  public toggleStatusUser(data: IStatusApi, employeeId: string): Observable<any> {
    return this._http.patch<any>(`${ApiConfig.employeesPath}/${employeeId}/active_status`, data);
  }

  public inviteProvider(provider: ISingeProviderApi): Observable<void> {
    return this._http.post<void>(ApiConfig.inviteUserPath, provider);
  }

  public getRoles(isExcludeLimitedRoles = 0): Observable<IDataListApi<IRoleApi>> {
    return this._http.get<IDataListApi<IRoleApi>>(ApiConfig.inviteRolesPath, {
      params: { is_exclude_limited_roles: isExcludeLimitedRoles },
    });
  }

  public getHistoriesProviderBlocked(
    httpParams: HttpParamsOptions,
    userId: string,
  ): Observable<ITableApiData<IBlockedUserHistoryApi>> {
    return this._http.get<ITableApiData<IBlockedUserHistoryApi>>(`${ApiConfig.employeesPath}/${userId}/locks`, {
      params: httpParams,
    });
  }

  public reassignProviderConsultOpenConsultsAndMedicalQuestions(
    currentProviderId: string,
    assignProviderId: string,
  ): Observable<void> {
    return this._http.patch<void>(`${ApiConfig.employeesPath}/${currentProviderId}/re-assign`, {
      user_id: assignProviderId,
    });
  }
}
