import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { OrderInfoPopupComponent } from './order-info-popup.component';
import { QaModule } from '../../directives/data-cy/qa.module';

@NgModule({
  declarations: [OrderInfoPopupComponent],
  imports: [CommonModule, MatDialogModule, TranslateModule, QaModule],
  exports: [OrderInfoPopupComponent],
})
export class OrderInfoPopupModule {}
