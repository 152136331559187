<nav class="user-nav-menu">
  <ng-container *ngFor="let item of menu">
    <sp-user-nav-item class="user-nav-menu__item"
                      *spCanView="item.whoCanView"
                      [item]="item"
                      [role]="role"
                      [user]="user"
    >
    </sp-user-nav-item>
  </ng-container>
</nav>
