import { Component, Input } from '@angular/core';

@Component({
  selector: 'sp-pre-loader',
  templateUrl: './pre-loader.component.html',
  styleUrls: ['./pre-loader.component.scss'],
})
export class PreLoaderComponent {
  @Input() public diameter = 50;
}
