import { Injectable, TemplateRef } from '@angular/core';
import { takeWhile, tap } from 'rxjs/operators';
import { fromEvent, merge, Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { DialogPosition } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import {
  dragDialogFlow$,
  getDialogContainerRef,
  getDialogOverlay,
  getDialogRelativeOffset,
} from '../helpers/multiple-dialog-utils';
import { Position } from '../models/position.interface';
import { MultipleDialogPositionConfig } from '../models/multiple-dialog-position-config.interface';
import { MultipleDialogConfig } from '../models/multiple-dialog-config.interface';

@Injectable({
  providedIn: 'root',
})
export class MultipleDialogService {
  private readonly positionConfig: MultipleDialogPositionConfig = {
    baseZIndex: 600,
    autoPositionOffsetX: 1,
    autoPositionOffsetY: 4,
  };

  private readonly defaultConfig: MultipleDialogConfig = {
    scrollStrategy: new NoopScrollStrategy(),
    hasBackdrop: false,
    autoFocus: false,
    disableClose: true,
    draggable: false,
  };

  public lastAutoPositionDialog: { dialogId: string; position: Position } = null;

  public lastZIndex = 0;

  public currentFrontDialogId;

  public context;

  public openedDialogsMap = new Map<string, MatDialogRef<any>>();

  constructor(private dialog: MatDialog) {}

  public closeAll(): void {
    if (this.openedDialogsMap.size === 0) {
      return;
    }
    this.openedDialogsMap.forEach((openedStickyNoteRef) => openedStickyNoteRef.close());
    this.openedDialogsMap.clear();
    this.lastZIndex = 0;
  }

  public open<T, D = any, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config: MultipleDialogConfig<D> = {},
  ): MatDialogRef<T, R> {
    const configPosition = this.prepareConfigPosition(config);
    const position = configPosition || this.prepareAutoPosition();
    const mergedConfig: MultipleDialogConfig = {
      ...this.defaultConfig,
      ...config,
      position,
    };
    const dialogRef = this.dialog.open<T, D>(componentOrTemplateRef, mergedConfig);
    if (!configPosition) {
      this.lastAutoPositionDialog = {
        dialogId: dialogRef.id,
        position: getDialogRelativeOffset(dialogRef),
      };
    }
    this.setLastZIndex(dialogRef);
    this.openedDialogsMap.set(dialogRef.id, dialogRef);
    this.listenDialogEvents(dialogRef, mergedConfig);
    return dialogRef;
  }

  private prepareConfigPosition(config: MultipleDialogConfig): DialogPosition {
    if (config?.position) {
      return config.position;
    }

    if (config?.relativePosition) {
      return { top: `${config?.relativePosition?.y || 0}vh`, left: `${config?.relativePosition?.x || 0}vw` };
    }

    return null;
  }

  private prepareAutoPosition(): DialogPosition {
    if (!this.lastAutoPositionDialog || this.lastAutoPositionDialog.dialogId !== this.currentFrontDialogId) {
      return null;
    }
    const dialogRef = this.openedDialogsMap.get(this.lastAutoPositionDialog.dialogId);

    if (!dialogRef) {
      return null;
    }

    const currentPosition = getDialogRelativeOffset(dialogRef);
    if (
      currentPosition.x !== this.lastAutoPositionDialog.position.x ||
      currentPosition.y !== this.lastAutoPositionDialog.position.y
    ) {
      return null;
    }

    return {
      top: `${this.lastAutoPositionDialog.position.y + this.positionConfig.autoPositionOffsetY}vh`,
      left: `${this.lastAutoPositionDialog.position.x + this.positionConfig.autoPositionOffsetX}vw`,
    };
  }

  private listenDialogEvents(dialogRef: MatDialogRef<any>, config: MultipleDialogConfig) {
    const events = [this.changeZIndexOnSelectionFlow$(dialogRef), this.autoRemoveAfterClosedFlow$(dialogRef)];

    if (config.draggable) {
      events.push(dragDialogFlow$(dialogRef));
    }

    merge(...events)
      .pipe(takeWhile(() => this.openedDialogsMap.has(dialogRef.id)))
      .subscribe();
  }

  private changeZIndexOnSelectionFlow$(dialogRef: MatDialogRef<any>): Observable<any> {
    const dialogContainerHTMLElement = getDialogContainerRef(dialogRef).nativeElement;
    const { id } = dialogRef;
    const events = [
      fromEvent(dialogContainerHTMLElement, 'mousedown'),
      fromEvent(dialogContainerHTMLElement, 'touchstart'),
      fromEvent(dialogContainerHTMLElement, 'click'),
    ];
    return merge(...events).pipe(
      tap(() => {
        if (this.currentFrontDialogId === id) {
          return;
        }
        this.setLastZIndex(dialogRef);
      }),
    );
  }

  private autoRemoveAfterClosedFlow$(dialogRef: MatDialogRef<any>): Observable<any> {
    return dialogRef.afterClosed().pipe(
      tap(() => {
        this.openedDialogsMap.delete(dialogRef.id);
      }),
    );
  }

  private setLastZIndex(dialogRef): void {
    this.currentFrontDialogId = dialogRef.id;
    this.lastZIndex += 1;
    getDialogOverlay(dialogRef).style.zIndex = (this.positionConfig.baseZIndex + this.lastZIndex).toString();
  }
}
