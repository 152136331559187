import { Injectable } from '@angular/core';
import { ResourceTypeEnum } from '../agreement-keys/resource-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ResourcesHttpAdapter {
  public selectAllRolesItemId = '10';
  public selectAllCountriesItemId = 'select-all-id';

  public transformToResource(data: IResourceApi): IResource {
    return {
      id: data.id,
      title: data.title,
      isActive: data.is_active,
      countries: data?.countries?.data,
      userRoles: data?.user_roles?.data,
      description: data?.description,
      createdAt: data?.created_at,
      publishedDate: data?.published_date,
      isShowNotification: data?.is_show_notification,
      type: data?.type,
      isViewed: data.is_viewed,
      files:
        data?.files?.data?.length && (data.type === ResourceTypeEnum.files || data.type === ResourceTypeEnum.mixed)
          ? data?.files?.data?.map((item) => this.transformToAwsUploadData(item))
          : null,
      links: data?.links,
    };
  }

  public transformToAwsUploadData(data: IAwsUploadApi): IAwsUpload {
    return {
      id: data.id,
      awsLink: data.cloud_link || null,
      src: data.src,
      fileName: data.file_name,
      fileType: data.file_type,
    };
  }

  public transformToResourceRequestApiData(data: IResourceRequest): IResourceRequestApi {
    return {
      title: data.title,
      is_active: data.isActive,
      countries: data.countries.includes(this.selectAllCountriesItemId) ? [] : data.countries,
      user_roles: data.userRoles.map((id) => `${id}`).includes(this.selectAllRolesItemId) ? [] : data.userRoles,
      description: data.description,
      links: data?.links,
      files: data?.files,
      published_date: data?.publishedDate,
      is_show_notification: data?.isShowNotification,
    };
  }

  public transformToResourceChangeStatusApi(data: IResourceChangeStatus): IResourceChangeStatusApi {
    return {
      id: data.id,
      is_active: data.isActive,
    };
  }
}
