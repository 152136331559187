import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MetaHttpAdapter } from '@sp-core/http-adapters/meta-http-adapter';
import { HttpMedicalQuestionsService } from '../../http-services/http-medical-questions/http-medical-questions.service';
import { MedicalQuestionsHttpAdapter } from '../../http-adapters/medical-questions-http-adapter';

@Injectable({
  providedIn: 'root',
})
export class MedicalQuestionsService {
  constructor(
    public readonly _httpMedicalQuestionsService: HttpMedicalQuestionsService,
    public readonly _medicalQuestionsHttpAdapter: MedicalQuestionsHttpAdapter,
  ) {}

  public getTableData(params: HttpParamsOptions): Observable<ITableData<IMedicalQuestion>> {
    return this._httpMedicalQuestionsService.getMedicalQuestions(params).pipe(
      map((data) => ({
        data: data.data.map((item) => this._medicalQuestionsHttpAdapter.transformToMedicalQuestion(item)),
        meta: MetaHttpAdapter.transformToMeta(data.meta),
      })),
    );
  }

  public createMedicalQuestion(data: IMedicalQuestionCreate): Observable<IMedicalQuestion> {
    const sendData = this._medicalQuestionsHttpAdapter.transformToMedicalQuestionCreateApi(data);
    return this._httpMedicalQuestionsService
      .createMedicalQuestion(sendData)
      .pipe(map((res) => this._medicalQuestionsHttpAdapter.transformToMedicalQuestion(res)));
  }

  public getMedicalQuestionDetail(questionId: string): Observable<IMedicalQuestion> {
    return this._httpMedicalQuestionsService
      .getMedicalQuestionDetail(questionId)
      .pipe(map((data) => this._medicalQuestionsHttpAdapter.transformToMedicalQuestion(data)));
  }

  public reopenMedicalQuestion(questionId: string): Observable<void> {
    return this._httpMedicalQuestionsService.reopenMedicalQuestion(questionId);
  }

  public resolveMedicalQuestion(questionId: string): Observable<void> {
    return this._httpMedicalQuestionsService.resolveMedicalQuestion(questionId);
  }
}
