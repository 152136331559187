import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConfig } from '@sp-core/api-config';

@Injectable({
  providedIn: 'root',
})
export class HttpCalcService {
  constructor(public readonly _http: HttpClient) {}

  public getCalcDictionary(patientGenderId: string): Observable<ICalcDictionariesApi> {
    return this._http.get<ICalcDictionariesApi>(`${ApiConfig.calcDictionaryPath}/gender/${patientGenderId}`);
  }

  public calculationInit(data: ICalculationInitApi): Observable<ICalculationInitResponseApi> {
    return this._http.post<ICalculationInitResponseApi>(ApiConfig.calculatorPath, data);
  }

  public getCalculation(id: string): Observable<ICalculationCommonApi> {
    return this._http.get<ICalculationCommonApi>(`${ApiConfig.calculatorPath}/${id}`);
  }

  public calculationPatch(data: any, calculationId: string): Observable<ICalculationResultApi> {
    return this._http.patch<ICalculationResultApi>(`${ApiConfig.calculatorPath}/${calculationId}`, data);
  }

  public getTypeOfInjection(patientId: string, type: string): Observable<IPatientTypeOfInjectionApi> {
    return this._http.get<IPatientTypeOfInjectionApi>(`${ApiConfig.patientPath}/${patientId}/type/${type}`);
  }

  public getLastFinishedCalculation(patientId: string): Observable<ICommonEditableDataApi> {
    return this._http.get<ICommonEditableDataApi>(`${ApiConfig.patientPath}/${patientId}/last_finished_calculation`);
  }

  public getDosingResponsibilityAgreement(): Observable<IDosingResponsibilityAgreementApi> {
    return this._http.get<IDosingResponsibilityAgreementApi>(`${ApiConfig.agreementsPath}/unsigned/`);
  }

  public sendDosingResponsibilityAgreement(agreementId: string): Observable<void> {
    return this._http.post<void>(`${ApiConfig.agreementsPath}/sign/${agreementId}`, {});
  }

  public getCalculatorReturnParams(patientId: string, calculationId: string): Observable<ICalculationReturnParamsApi> {
    return this._http.get<ICalculationReturnParamsApi>(
      `${ApiConfig.calculatorReturnParamsPath}/${patientId}/return-params/${calculationId}`,
    );
  }
}
