import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AuthService, UserService } from '@sp-core-services';
import { PermissionsService } from '@sp-core/services/permissions/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private permissionsService: PermissionsService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {
    return combineLatest([this.userService.user$, this.permissionsService.permissions$]).pipe(
      take(1),
      switchMap(([existUser, currentPermissions]) => {
        if (!existUser || !currentPermissions) {
          return this.logOutAndRedirectToLogin();
        }
        const { permissions } = route.data;
        if (!permissions.some((permission) => currentPermissions.has(permission))) {
          return this.logOutAndRedirectToLogin();
        }
        return of(true);
      }),
    );
  }

  private logOutAndRedirectToLogin(): Observable<boolean> {
    this.authService.clearData();
    return of(false);
  }
}
