import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorsDirective } from './errors.directive';
import { FormServerValidationErrorDirective } from './form-server-validation-error.directive';

@NgModule({
  declarations: [ErrorsDirective, FormServerValidationErrorDirective],
  exports: [ErrorsDirective, FormServerValidationErrorDirective],
  imports: [CommonModule],
})
export class ErrorsModule {}
