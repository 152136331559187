import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ITableService } from '@sp-shared/components/table';
import { catchError, filter, map } from 'rxjs/operators';
import { MetaHttpAdapter } from '@sp-core/http-adapters/meta-http-adapter';
import { HttpConsultService } from '@sp-core/http-services/http-consult/http-consult.service';
import { CalcHttpAdapter } from '@sp-core/http-adapters/calc-http-adapter';
import { ServerStatusCode } from '@sp-core/agreement-keys/server-status-code.enum';
import { ConsultHttpAdapter } from '../../http-adapters/consult-http-adapter';

@Injectable({
  providedIn: 'root',
})
export class ConsultService implements ITableService<IMyConsultRequest> {
  constructor(
    public readonly _consultHttpAdapter: ConsultHttpAdapter,
    public readonly _httpConsultService: HttpConsultService,
    public readonly _httpCalcAdapter: CalcHttpAdapter,
  ) {}

  public createConsult(data: IConsultRequest): Observable<any> {
    const sendData = this._consultHttpAdapter.transformToRequestConsultApi(data);
    return this._httpConsultService.createConsult(sendData);
  }

  public getTableData(params: HttpParamsOptions): Observable<ITableData<IMyConsultRequest>> {
    return this._httpConsultService.getListMyConsultRequests(params).pipe(
      map((data) => ({
        data: data.data.map((requests) => this._consultHttpAdapter.transformToMyConsultRequests(requests)),
        meta: MetaHttpAdapter.transformToMeta(data.meta),
      })),
    );
  }

  public sendConsultResponse(consultId: string, data: IConsultRequestAnswer): Observable<IConsultRequestAnswer> {
    const sendData = this._consultHttpAdapter.transformToRequestAnswerApi(data);
    return this._httpConsultService.sendConsultResponse(consultId, sendData).pipe(
      map((res: IConsultRequestAnswerApi) => this._consultHttpAdapter.transformToRequestAnswer(res)),
      catchError((serverError) => {
        if (serverError.status === ServerStatusCode.validationError) {
          serverError.validationErrors = this._consultHttpAdapter.transformToRequestAnswer(
            serverError.validationErrors,
          );
        }
        return throwError(serverError);
      }),
    );
  }

  public resolveConsul(consultId: string): Observable<any> {
    return this._httpConsultService.resolveConsul(consultId);
  }

  public reopenConsul(consultId: string): Observable<any> {
    return this._httpConsultService.reopenConsul(consultId);
  }

  public getConsult(consultId: string): Observable<IConsultRequest> {
    return this._httpConsultService
      .getConsult(consultId)
      .pipe(map((response) => this._consultHttpAdapter.transformToConsult(response)));
  }

  public getMedicalSuggestions(): Observable<IMedicationSuggestion[]> {
    return this._httpConsultService
      .getMedicalSuggestions()
      .pipe(
        map((response) =>
          response.data.map((suggestion) =>
            this._consultHttpAdapter.transformToConsultMedicationSuggestion(suggestion),
          ),
        ),
      );
  }

  public getCalculationByDate(patientId: string, date: string): Observable<ICalculationCommon> {
    return this._httpConsultService.getCalculationByDate(patientId, date).pipe(
      filter((calc) => !!calc),
      map((calculation) => this._httpCalcAdapter.transformToCalculation(calculation)),
    );
  }

  public getOrganizationData(consultId: string): Observable<IConsultOrganizationInfo> {
    return this._httpConsultService
      .getOrganizationData(consultId)
      .pipe(map((calculation) => this._consultHttpAdapter.transformToOrganizationInfo(calculation)));
  }
}
