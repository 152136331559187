import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CalcService } from '@sp-core-services';

@Injectable({
  providedIn: 'root',
})
export class CalcGuard {
  private readonly maxEditableAttempts = 3;

  constructor(private calcService: CalcService) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const { patientId } = route.params;
    const calculationId: string = route.params.calculationsId;
    return combineLatest([
      this.calcService.getCalculation(calculationId),
      this.calcService.getLastFinishedCalculation(patientId),
    ]).pipe(
      map(([calculation, lastInsertion]) => {
        // if we create not completed calculation we can go to calculation page
        if (!calculation.isPrescribed) {
          return true;
        }
        // if we had completed calculation we check insertion usage count should be less then maxEditableAttempts
        return lastInsertion ? lastInsertion.insertionUsage < this.maxEditableAttempts : true;
      }),
    );
  }
}
