import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  private behaviorUser$: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null);

  public set user(user: IUser) {
    this.behaviorUser$.next(user);
  }

  public get user(): IUser {
    return this.behaviorUser$.getValue();
  }

  public get user$(): Observable<IUser> {
    return this.behaviorUser$.asObservable();
  }
}
