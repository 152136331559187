import { Injectable } from '@angular/core';
import { ProviderHttpAdapter } from '@sp-core/http-adapters/provider-http-adapter';

@Injectable({
  providedIn: 'root',
})
export class AuthHttpAdapter {
  constructor(private providerHttpAdapter: ProviderHttpAdapter) {}

  public transformToApiSignUp(data: ISignUp): ISignUpApi {
    return {
      token: data.token,
      email: data.email,
      password: data.password,
      password_confirmation: data.confirmPassword,
    };
  }

  public transformToEmail(data: IInvitationApi): IEmail {
    return {
      email: data.email,
    };
  }

  public transformToResetPassword(data: IRestorePassword): IResetPasswordApi {
    return {
      token: data.token,
      password: data.password,
      password_confirmation: data.confirmPassword,
    };
  }

  public transformToResetPasswordError(data: IServerValidationErrors): IServerValidationErrors {
    return {
      token: data.token,
      password: data.password,
      password_confirmation: data.confirmPassword,
    };
  }

  public transformToUser(userApi: IUserApi): IUser {
    return this.providerHttpAdapter.transformToUser(userApi);
  }

  public transformToCheckUserSession(data: ISessionsCheckApi): ISessionsCheck {
    return { sessionsFromAnotherIp: data.sessions_from_another_ip, ip: data.ip };
  }

  public transformToTimeZone(data: ITimeZoneApi): ITimeZone {
    return this.providerHttpAdapter.transformToTimeZone(data);
  }

  public transformToMeasurementPreferences(date: IMeasurementPreferencesApi): IMeasurementPreferences {
    return this.providerHttpAdapter.transformToMeasurementPreferences(date);
  }

  public transformToDateTimeFormat(date: IDatetimeFormatApi): IDatetimeFormat {
    return this.providerHttpAdapter.transformToDateTimeFormat(date);
  }

  public transformToTwoFactorAuthorization(data: ITwoFactorAuthorizationApi): ITwoFactorAuthorization {
    return {
      id: data.type.id,
      key: data.type.key,
      title: data.type.title,
      is2faAvailable: data.is_2fa_available,
    };
  }

  public transformToTwoFactorAuthorizationType(data: ITwoFactorAuthorizationTypeApi): ITwoFactorAuthorizationType {
    return {
      id: data.id,
      key: data.key,
      title: data.title,
    };
  }

  public transformToCheckPassword(data: ICheckExpiredPasswordApi): ICheckExpiredPassword {
    return {
      checkExpiredPassword: data.is_expired_password,
    };
  }

  public transformToRequireChangePassword(data: IRequiredChangePassword): IRequiredChangePasswordApi {
    return {
      email: data.email,
      current_password: data.currentPassword,
      new_password: data.newPassword,
      new_password_confirmation: data.newPasswordConfirm,
    };
  }
}
