import { Injectable } from '@angular/core';
import { ITableService } from '@sp-shared/components/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MetaHttpAdapter } from '@sp-core/http-adapters/meta-http-adapter';
import { HttpPatientService } from '@sp-core/http-services/http-patient/http-patient.service';
import { PatientHttpAdapter } from '@sp-core/http-adapters/patient-http-adapter';

@Injectable({
  providedIn: 'root',
})
export class HomeService implements ITableService<IPatient> {
  constructor(private patientHttpService: HttpPatientService, private patientHttpAdapter: PatientHttpAdapter) {}

  public getTableData(params: HttpParamsOptions): Observable<ITableData<IPatient>> {
    return this.patientHttpService.getPatients(params).pipe(
      map((data) => ({
        data: data.data.map((item) => this.patientHttpAdapter.transformToPatient(item)),
        meta: MetaHttpAdapter.transformToMeta(data.meta),
      })),
    );
  }
}
