import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';

import { LogoModule } from '@sp-shared/components/logo/logo.module';
import { NavModule } from '@sp-shared/components/nav/nav.module';
import { FooterModule } from '@sp-shared/components/footer/footer.module';

import { ITranslationResource } from '@sp-core/translate-services/translation-resource';
import { setLanguage, translateLoader } from '@sp-core/translate-services/multiple-translate-loader-service';
import { TranslateDefaultLanguageService } from '@sp-core/translate-services/translate-default-language.service';
import { TRANSLATE_SOURS } from '@sp-core/translate-services/translate-token';
import { AuthInterceptor } from '@sp-core/interceptors/auth.interceptor';

import { NgxMaskModule } from 'ngx-mask';
import { CanViewModule } from '@sp-shared/directives/can-view/can-view.module';

import { CookieService } from 'ngx-cookie-service';

import { CookiesStorageService } from '@sp-core/services/cookies/cookies-storage.service';
import { TokenService } from '@sp-core/services/token/token.service';
import { PermissionsService, UserService } from '@sp-core-services';
import { AuthHttpAdapter } from '@sp-core/http-adapters/auth-http-adapter';
import { AuthHttpService } from '@sp-core/http-services/auth-http.service';
import { map, switchMap, tap } from 'rxjs/operators';

import { MatSnackBarModule } from '@angular/material/snack-bar';

import { SentryService } from '@sp-core/services/sentry/sentry.service';
import { InactiveModule } from '@sp-shared/directives/inactive/inactive.module';
import { WINDOW_PROVIDERS } from '@sp-core/services/window/window.provider';
import { WindowService } from '@sp-core/services/window/window.service';

import { of } from 'rxjs';
import { HttpErrorInterceptor } from '@sp-core/interceptors/http-error.interceptor';
import { QaModule } from '@sp-shared/directives/data-cy/qa.module';
import { UserNavMenuModule } from '@sp-shared/components/user-nav-menu/user-nav-menu.module';
import { CwrErrorHandler } from '@sp-core/error-handlers/cwr/cwr-error-handler';
import { AuthModule } from './auth/auth.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { StickyNotesModule } from './sticky-notes/sticky-notes.module';

const SOURS: ITranslationResource[] = [
  {
    prefix: '/assets/i18n/common/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/auth/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/practice/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/provider/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/contact-us/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/settings/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/home/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/patient/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/calc/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/calc-recommended/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/reports/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/cerbo/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/roles-permissions/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/activities/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/consult/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/site-note/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/inventory/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/insertion-note-templates/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/cerbo-integrations/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/terms-conditions/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/sticky-notes/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/referrals/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/medical-questions/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/practice-admin-dashboard/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/simple-calculator/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/simple-calc-recommended/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/users/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/invitation/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/announcements/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/resources/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/patient-forms/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/pharmacy/',
    suffix: '.json',
  },
  {
    prefix: '/assets/i18n/knowledge-base/',
    suffix: '.json',
  },
];

function initSession(
  cookiesStorage: CookiesStorageService,
  tokenService: TokenService,
  userService: UserService,
  authHttpAdapter: AuthHttpAdapter,
  authHttp: AuthHttpService,
  permissionsService: PermissionsService,
): () => Promise<IUser | void | IRole> {
  return (): Promise<IUser | void | IRole> => {
    const { token } = cookiesStorage;
    tokenService.accessToken = token;
    if (token) {
      return authHttp
        .getCurrentUser()
        .pipe(
          map((user) => authHttpAdapter.transformToUser(user)),
          tap((user) => {
            userService.user = user;
          }),
          switchMap((user) => {
            const role: IRole = cookiesStorage.role as IRole;
            if (role) {
              return permissionsService.updateRole(role);
            }
            if (!user.isMultipleRoles) {
              return permissionsService.updateRole(user.roles[0]);
            }
            return of(null);
          }),
        )
        .toPromise();
    }
    return new Promise<IUser | void | IRole>((resolve) => resolve());
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthModule,
    LogoModule,
    NavModule,
    FooterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        deps: [HttpClient, Injector],
        useFactory: (httpClient, injector) => translateLoader(httpClient, injector),
      },
    }),
    NgxMaskModule.forRoot(),
    CanViewModule,
    MatSnackBarModule,
    InactiveModule,
    StickyNotesModule,
    QaModule,
    UserNavMenuModule,
  ],
  providers: [
    // must be on top providers
    {
      provide: ErrorHandler,
      useClass: SentryService,
    },
    {
      provide: ErrorHandler,
      useClass: CwrErrorHandler,
    },
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: TRANSLATE_SOURS,
      useValue: SOURS,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setLanguage,
      deps: [TranslateService, TranslateDefaultLanguageService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initSession,
      multi: true,
      deps: [CookiesStorageService, TokenService, UserService, AuthHttpAdapter, AuthHttpService, PermissionsService],
    },
    WINDOW_PROVIDERS,
    WindowService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
