/* eslint-disable */
import { takeUntil, tap } from 'rxjs/operators';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { ElementRef } from '@angular/core';
import { Position } from '../models/position.interface';

export function dragDialogFlow$(matDialogRef: MatDialogRef<any>): Observable<any> {
  // tslint:disable-next-line:variable-name
  let _subscription: Subscription;
  let mouseStart: Position;
  let mouseDelta: Position;
  const offset = getDialogOffset(matDialogRef);
  const container = getDialogOverlay(matDialogRef);

  function onMouseDown(event: MouseEvent) {
    event.stopImmediatePropagation();
    mouseStart = { x: event.pageX, y: event.pageY };

    const mouseup$ = fromEvent(document, 'mouseup');
    _subscription = mouseup$.subscribe((e) => onMouseup(e));

    const mousemove$ = fromEvent(document, 'mousemove')
      .pipe(takeUntil(mouseup$))
      .subscribe((e: MouseEvent) => onMouseMove(e));

    _subscription.add(mousemove$);
  }

  function onMouseMove(event: MouseEvent) {
    event.stopImmediatePropagation();
    mouseDelta = { x: event.pageX - mouseStart.x, y: event.pageY - mouseStart.y };
    const containerBox = container.getBoundingClientRect();
    const top = ((offset.y + mouseDelta.y) / containerBox.height) * 100;
    const left = ((offset.x + mouseDelta.x) / containerBox.width) * 100;
    _updatePosition(top, left);
  }

  function onMouseup(event: Event) {
    event.stopImmediatePropagation();
    if (_subscription) {
      _subscription.unsubscribe();
      _subscription = undefined;
    }

    if (mouseDelta) {
      offset.x += mouseDelta.x;
      offset.y += mouseDelta.y;
    }
  }

  function _updatePosition(top: number, left: number) {
    matDialogRef.updatePosition({
      top: `${top}vh`,
      left: `${left}vw`,
    });
  }

  // @ts-ignore
  return fromEvent(matDialogRef._containerInstance._elementRef.nativeElement, 'mousedown').pipe(
    tap((event: MouseEvent) => onMouseDown(event)),
  );
}

export function getDialogContainerRef(dialogRef: MatDialogRef<any>): ElementRef {
  // @ts-ignore
  return dialogRef?._containerInstance?._elementRef || null;
}

export function getDialogOverlay(dialogRef: MatDialogRef<any>): HTMLElement {
  // @ts-ignore
  return dialogRef?._containerInstance?._elementRef?.nativeElement?.parentNode?.parentNode || null;
}

export function getDialogOffset(matDialogRef: MatDialogRef<any>): Position {
  const box = getDialogContainerRef(matDialogRef).nativeElement.getBoundingClientRect();
  return {
    x: box.left,
    y: box.top,
  };
}

export function getDialogRelativeOffset(matDialogRef: MatDialogRef<any>): Position {
  const box = getDialogContainerRef(matDialogRef).nativeElement.getBoundingClientRect();
  const containerBox = getDialogOverlay(matDialogRef).getBoundingClientRect();
  return {
    x: (box.left / containerBox.width) * 100,
    y: (box.top / containerBox.height) * 100,
  };
}
