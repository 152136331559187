import { Component } from '@angular/core';
import { PermissionsService } from '@sp-core-services';
import { Roles } from '@sp-core/agreement-keys/roles.enum';

@Component({
  selector: 'sp-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
  public userRoles: typeof Roles = Roles;

  constructor(private permissionsService: PermissionsService) {}

  public get currentUserRole(): IRole {
    return this.permissionsService.role;
  }
}
