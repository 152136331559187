import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// TODO: Add this library instead custom code after support latest angular versions issue https://github.com/angular-material-extensions/password-strength/issues/407
// import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { PasswordStrengthComponent } from './password-strength.component';
import { PasswordStrengthInfoComponent } from './password-strength-info/password-strength-info.component';

@NgModule({
  declarations: [PasswordStrengthComponent, PasswordStrengthInfoComponent],
  imports: [
    CommonModule,
    // TODO: Add this library instead custom code after support latest angular versions issue https://github.com/angular-material-extensions/password-strength/issues/407
    // MatPasswordStrengthModule.forRoot(),
    MatIconModule,
    MatCardModule,
  ],
  exports: [PasswordStrengthComponent],
})
export class PasswordStrengthModule {}
