import { Injectable } from '@angular/core';
import { Observable, of, throwError, timer } from 'rxjs';
import { map, pluck, switchMap } from 'rxjs/operators';
import { ITableService } from '@sp-shared/components/table';
import { MetaHttpAdapter } from '@sp-core/http-adapters/meta-http-adapter';
import { ExportFormats } from '@sp-core/agreement-keys/export-formats.enum';
import TransformServerValidationUtils from '@sp-helpers/validation/transform-server-validation-utils';
import { HttpSiteNoteService } from '../../http-services/http-site-note/http-site-note.service';
import { SiteNoteHttpAdapter } from '../../http-adapters/site-note-http-adapter';
import { ServerStatusCode } from '../../agreement-keys/server-status-code.enum';

@Injectable({
  providedIn: 'root',
})
export class SiteNoteService implements ITableService<ISiteNoteItem> {
  private readonly downloadAttemptsCount = 10;

  private readonly downloadAttemptsDebounceTime = 2000;

  constructor(public httpSiteNoteService: HttpSiteNoteService, public siteNoteHttpAdapter: SiteNoteHttpAdapter) {}

  public getTableData(params: HttpParamsOptions): Observable<ITableData<ISiteNoteItem>> {
    return this.httpSiteNoteService.getSiteNotes(params).pipe(
      map((data) => ({
        data: data.data.map((item) => this.siteNoteHttpAdapter.transformToSiteNoteItem(item)),
        meta: MetaHttpAdapter.transformToMeta(data.meta),
      })),
    );
  }

  public getSiteNoteDictionaries(): Observable<ISiteNoteDictionaries> {
    return this.httpSiteNoteService
      .getSiteNoteDictionaries()
      .pipe(map((dictionaries) => this.siteNoteHttpAdapter.transformToSiteNoteDictionaries(dictionaries)));
  }

  public initSiteNote(insertionId: string): Observable<ISiteNote> {
    return this.httpSiteNoteService
      .initSiteNote(insertionId)
      .pipe(map((data) => this.siteNoteHttpAdapter.transformToSiteNote(data)));
  }

  public getSiteNote(id: string): Observable<ISiteNote> {
    return this.httpSiteNoteService
      .getSiteNote(id)
      .pipe(map((data) => this.siteNoteHttpAdapter.transformToSiteNote(data)));
  }

  public updateSiteNote(id: string, data: ISiteNote): Observable<ISiteNote> {
    const sendData = this.siteNoteHttpAdapter.transformToSiteNoteRequest(data);
    return this.httpSiteNoteService.updateSiteNote(id, sendData).pipe(
      map((res) => this.siteNoteHttpAdapter.transformToSiteNote(res)),
      TransformServerValidationUtils.catchAndTransformValidationError((error) =>
        this.siteNoteHttpAdapter.transformToSiteNote(error),
      ),
    );
  }

  public getSiteNoteExportFileFlow(
    processId: string,
    counter: number = this.downloadAttemptsCount,
    delayTime = this.downloadAttemptsDebounceTime,
  ): Observable<Blob> {
    return timer(delayTime).pipe(
      switchMap(() => this.httpSiteNoteService.getSiteNoteExportFile(processId)),
      switchMap((response) => {
        if (response.status !== ServerStatusCode.noContent) {
          return of(response.body);
        }
        if (counter <= 0) {
          return throwError(new Error('Something went wrong. Please try again download file.'));
        }
        return this.getSiteNoteExportFileFlow(processId, counter - 1, delayTime);
      }),
    );
  }

  public getSiteNoteExportFile(id: string): Observable<Blob> {
    return this.httpSiteNoteService.getSiteNoteExportId(ExportFormats.pdf, { id }).pipe(
      pluck('process_id'),
      switchMap((processId: string) => this.getSiteNoteExportFileFlow(processId)),
    );
  }
}
