import { Inject, Injectable, Optional, TemplateRef } from '@angular/core';
import { first } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InfoPopupComponent } from '../containers/info-popup/info-popup.component';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  public opened$ = this.dialogRef.afterOpened().pipe(first());

  constructor(
    private dialogRef: MatDialogRef<InfoPopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  get context() {
    return this.data.context;
  }

  public close() {
    this.dialogRef.close();
  }

  public setHeaderText(headerText: string): void {
    this.data.title = headerText;
  }

  public setTemplate(template: TemplateRef<any>): void {
    this.data.template = template;
  }
}
