import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '@sp-core/services/auth/auth.service';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CValidators } from '@sp-helpers/validation/validators';
import { ServerErrorModel } from '@sp-core/models/app-models/server-error.model';
import { ServerStatusCode } from '@sp-core/agreement-keys/server-status-code.enum';
import { ISignUpForm } from '@sp-core/models/forms-models/sign-up-form.interface';

@Component({
  selector: 'sp-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpComponent implements OnInit, OnDestroy {
  public token: string;
  public formSignUp: FormGroup<ISignUpForm>;
  public serverIncorrectToken: string;
  public email: string;
  public minPasswordLength = 12;
  private destroy$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.checkInviteToken();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public handlerSubmit(data: ISignUp): void {
    this.authService.signUp(data).subscribe(
      () => {
        this.router.navigateByUrl('/sign-in');
      },
      (error: ServerErrorModel) => {
        this.processedServerError(error);
      },
    );
  }

  public checkInviteToken(): void {
    this.getRouterParams()
      .pipe(
        map((params: Params) => params?.token),
        tap((token: string) => {
          this.token = token;
        }),
        switchMap((token: string) => this.sendToken(token)),
      )
      .subscribe(
        (res) => {
          this.email = res.email;
          this.patchSignUpForm({
            email: this.email,
            token: this.token,
          });
          this.cd.markForCheck();
        },
        (serverError: ServerErrorModel) => this.processedServerError(serverError),
      );
  }

  public getRouterParams(): Observable<Params> {
    return this.route.queryParams.pipe(takeUntil(this.destroy$));
  }

  public patchSignUpForm(val: { email?: string; token?: string }): void {
    if (!this.formSignUp) {
      this.createForm();
    }
    this.formSignUp.patchValue(val);
    this.formSignUp.get('email').disable();
  }

  private sendToken(token = ''): Observable<IEmail> {
    return this.authService.getEmailByInvitationToken(token);
  }

  private createForm(): void {
    const validatorsPassword = [
      CValidators.required,
      CValidators.passwordMatchPattern,
      CValidators.noStartAndEndWhitespace,
      CValidators.minLength(12),
      CValidators.maxLength(30),
    ];
    this.formSignUp = new FormGroup<ISignUpForm>(
      {
        email: new FormControl<null | string>(null, [
          CValidators.required,
          CValidators.minLength(7),
          CValidators.maxLength(250),
          CValidators.noStartAndEndWhitespace,
          CValidators.email,
        ]),
        token: new FormControl<null | string>(null, [CValidators.required]),
        password: new FormControl<null | string>(null, validatorsPassword),
        confirmPassword: new FormControl<null | string>(null, validatorsPassword),
      },
      CValidators.mustMatch('password', 'confirmPassword'),
    );
  }

  private processedServerError(error: ServerErrorModel) {
    if (error.status === ServerStatusCode.validationError) {
      const token = error.validationErrors?.token;
      this.serverIncorrectToken = token.join(' ');
      return;
    }

    if (error.status === ServerStatusCode.customError) {
      this.serverIncorrectToken = error.message;
      return;
    }

    this.router.navigateByUrl('/');
  }
}
