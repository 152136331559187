export class SessionService {
  /**
   * Used for retrieve info about the user from localStorage
   *
   * @returns object User or null
   */
  public static get userInfo(): IUser {
    return JSON.parse(localStorage.getItem('sp.user'));
  }

  /**
   * Used to record info about user in the localStorage
   *
   * @param data - object User
   */
  public static set userInfo(data: IUser) {
    localStorage.setItem('sp.user', JSON.stringify(data));
  }

  /**
   * Used to record info about user in the localStorage
   *
   * @param data - string token
   */
  public static set token(data: string) {
    localStorage.setItem('sp.token', JSON.stringify(data));
  }

  /**
   * Get token from LS, used in guards, for checking if the user is logged in
   *
   * @returns token
   */
  public static get token(): string {
    const data = JSON.parse(localStorage.getItem('sp.token'));
    return data ? data.token : null;
  }

  /**
   * Used for retrieve info about the nextUrl from localStorage
   *
   * @returns object URL or null
   */
  public static get nextUrl(): string {
    return JSON.parse(localStorage.getItem('sp.nextUrl'));
  }

  /**
   * Used to record info about nextUrl in the localStorage
   *
   * @param data - object string
   */
  public static set nextUrl(data: string) {
    localStorage.setItem('sp.nextUrl', JSON.stringify(data));
  }

  public static clearStorage(): void {
    localStorage.clear();
  }
}
