import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, share, switchMap } from 'rxjs/operators';
import { PermissionsService } from '@sp-core-services';
import { Observable, of } from 'rxjs';
import { StickyNoteContextNameEnum } from '@sp-core/agreement-keys/sticky-note-context-name.enum';
import { UserPermissions } from '@sp-core/dictionary-types/user-permissions.enum';
import Utils from '@sp-helpers/utils';

@Injectable({
  providedIn: 'root',
})
export class StickyNotesContextService {
  private readonly config: { [key: string]: string } = {
    [StickyNoteContextNameEnum.dashboard]: '/home',
    [StickyNoteContextNameEnum.patient]: '/patients/:param',
    [StickyNoteContextNameEnum.myConsults]: '/consult',
    [StickyNoteContextNameEnum.ownPractice]: '/admin/own-practice',
    [StickyNoteContextNameEnum.practiceEmployees]: '/admin/practice/employees',
    [StickyNoteContextNameEnum.roles]: '/admin/roles',
    [StickyNoteContextNameEnum.permissions]: '/admin/roles/:param/permissions',
    [StickyNoteContextNameEnum.reports]: '/admin/reports',
    [StickyNoteContextNameEnum.activities]: '/admin/activities',
    [StickyNoteContextNameEnum.inventory]: '/inventory',
    [StickyNoteContextNameEnum.inventoryLog]: '/inventory/:param',
  };

  constructor(private router: Router, private permissionsService: PermissionsService) {}

  public getContext$(): Observable<IStickyNoteContext> {
    return this.hasPermission$().pipe(
      switchMap((hasPermission) => {
        if (hasPermission) {
          return this.getContextByNavigation$();
        }
        return of(null);
      }),
      distinctUntilChanged(Utils.isEqual),
      share(),
    );
  }

  private hasPermission$(): Observable<boolean> {
    return this.permissionsService.permissions$.pipe(
      map((permissions) => permissions && permissions.has(UserPermissions.stickyNotesManage)),
    );
  }

  private getContextByNavigation$(): Observable<IStickyNoteContext> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => {
        const path = event.url.split('?')[0];
        return this.getContextByConfig(path, this.config);
      }),
      share(),
    );
  }

  private getContextByConfig(path, config): IStickyNoteContext {
    for (const name in config) {
      if (name in config) {
        const comparePath: string = config[name];
        if (comparePath === path) {
          return { name, param: null };
        }
        const contextWithParam = this.getContextWithParam(name, path, comparePath);
        if (contextWithParam) {
          return contextWithParam;
        }
      }
    }
    return null;
  }

  private getContextWithParam(name: string, path: string, comparePath: string): IStickyNoteContext {
    if (!comparePath.includes(':param')) {
      return null;
    }
    const pathArray = path.split('/');
    const comparePathArray = comparePath.split('/');
    if (pathArray.length !== comparePathArray.length) {
      return null;
    }
    const param = this.getParamWithCompare(comparePathArray, pathArray);
    return param ? { name, param } : null;
  }

  private getParamWithCompare(comparePathArray: string[], pathArray: string[]) {
    let param = null;
    for (let i = 0; i < comparePathArray.length; i += 1) {
      if (comparePathArray[i] === ':param') {
        param = pathArray[i];
        break;
      }
      if (pathArray[i] !== comparePathArray[i]) {
        return null;
      }
    }
    return param;
  }
}
