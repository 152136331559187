import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '@sp-core-services';
import { ServerErrorModel } from '@sp-core/models/app-models/server-error.model';
import { ServerStatusCode } from '@sp-core/agreement-keys/server-status-code.enum';
import { RequiredChangePasswordFormService } from '../services/required-change-password-form.service';
import { IChangePasswordForm } from '@sp-core/models/forms-models/change-password-form.interface';

@Component({
  selector: 'sp-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  public requiredChangePasswordForm: FormGroup<IChangePasswordForm>;
  public isSend = false;
  public serverError = null;
  public destroy$: Subject<void> = new Subject<void>();

  constructor(
    private requiredChangePasswordFormService: RequiredChangePasswordFormService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.initChangePasswordForm();
    this.subscribeToRouterParams();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public onSubmit(): void {
    if (this.isSend || this.requiredChangePasswordForm.invalid) {
      return;
    }

    this.authService.requiredChangPassword(this.requiredChangePasswordForm.getRawValue()).subscribe(
      () => {
        this.redirectToLogin();
      },
      (error: ServerErrorModel) => {
        if (error.status === ServerStatusCode.customError) {
          this.serverError = error.message;
        }
      },
    );
  }

  public initChangePasswordForm(): void {
    this.requiredChangePasswordForm = this.requiredChangePasswordFormService.getRequiredChangePasswordForm();
  }

  public subscribeToRouterParams(): void {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.email) {
        this.requiredChangePasswordFormService.patchRequiredChangePasswordForm(this.requiredChangePasswordForm, {
          email: params.email,
        });
      } else {
        this.redirectToLogin();
      }
    });
  }

  public redirectToLogin(): void {
    this.router.navigateByUrl('/sign-in');
  }
}
