import * as FileSaver from 'file-saver';

export default class FileUtils {
  public static get blobTypes() {
    return [
      { formatName: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', formatType: 'xlsx' },
      { formatName: 'application/pdf', formatType: 'pdf' },
      { formatName: 'application/zip', formatType: 'zip' },
    ];
  }

  public static saveBlob(fileData: Blob): void {
    const formatEntity = FileUtils.blobTypes.find((blobType) => blobType.formatName === fileData.type);
    if (formatEntity) {
      const format = formatEntity.formatType;
      const blob = new Blob([fileData], { type: fileData.type });
      FileSaver.saveAs(blob, `export.${format}`);
    }
  }
}
