<button class="close" (click)="clickClose({logOut: false})">
</button>
<h1 mat-dialog-title class="confirm-title" *ngIf="data?.title">
  <span class="confirm-title-text">{{data?.title}}</span>
</h1>
<h2 class="confirm-text">
  {{'AUTH.THE_DURATION_OF_YOUR_SESSION_IS' | translate}}
  {{date?.minutes}}:{{date?.seconds}}
  .
  {{'AUTH.DO_YOU_WANT_TO_CONTINUE_YOUR_WORK' | translate}}
</h2>
<div mat-dialog-content class="confirm-action">
  <button class="btn-primary-accept" (click)="clickClose({logOut: false})" cdkFocusInitial>{{'BTN_YES'| translate}}</button>
  <button class="btn-purple" (click)="clickClose({logOut: true}); saveRoute()">{{'BTN_NO' | translate}}</button>
</div>
