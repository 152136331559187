<button *ngIf="data?.isCloseBtn" spQaId="info-popup_button_close" class="close" (click)="clickClose(false)">
</button>

<h1 class="common-popup-title text-center" *ngIf="data.title">
    {{data?.title | translate}}
</h1>

<ng-container [ngTemplateOutlet]="data?.template || defaultInfoTemplate "></ng-container>

<ng-template #defaultInfoTemplate>
  <h2 class="text" [class.pt-3]="!data?.title" *ngIf="data.text" [innerHTML]="data.text | translate">
</h2>
<div mat-dialog-content
     class="confirm-action justify-content-center"
     *ngIf="data.actions?.acknowledge && !data.actions?.accept && !data.actions?.reject">
  <ng-container *ngIf="data.actions.acknowledge === 'AUTH.LOGIN'; else acknowledge">
    <button spQaId="info-popup_button_acknowledge"
            class="btn-primary-accept d-flex align-items-center"
            (click)="clickClose(true)"
            cdkFocusInitial>
      <span>{{data.actions.acknowledge | translate}}</span>
    </button>
  </ng-container>
  <ng-template #acknowledge>
    <button spQaId="info-popup_button_acknowledge"
            class="btn-primary-accept acknowledge-btn d-flex align-items-center"
            (click)="clickClose(true)"
            cdkFocusInitial>
      <span class="acknowledge-btn-text">{{data.actions.acknowledge | translate}}</span>
      <div class="icon-wrap acknowledge-icon-wrap">
        <img alt="icon" src="assets/images/check.svg">
      </div>
    </button>
  </ng-template>
</div>

  <div mat-dialog-content
       class="confirm-action"
       [class.single-action]="data.acceptOnly"
       *ngIf="data.actions.accept && data.actions.reject || data.acceptOnly">
    <button spQaId="info-popup_button_accept"
            class="btn-primary-accept"
            (click)="clickClose(true)"
            cdkFocusInitial>
      {{data.actions.accept | translate}}
    </button>
    <button spQaId="info-popup_button_reject"
            class="btn-purple"
            *ngIf="!data.acceptOnly"
            (click)="clickClose(false)">
      {{data.actions.reject | translate}}
    </button>
  </div>

</ng-template>
