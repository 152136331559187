export abstract class CommonGenerator<T, B> {
  protected static instance: any;
  protected obgGenerator: Generator<T>;
  protected constructor(protected attributes: B) {}

  public clearGenerator(): void {
    this.obgGenerator = null;
  }

  public get generator(): Generator<T> {
    if (!this.obgGenerator) {
      return this.createGenerator(this.attributes);
    }
    return this.obgGenerator;
  }

  abstract createGenerator(array: B): Generator<T>;
}
