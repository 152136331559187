import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AuthService } from '@sp-core-services';
import { Router } from '@angular/router';
import { CValidators } from '@sp-helpers/validation/validators';
import { environment } from '@sp-environments/environment';
import { NotificationService } from '@sp-core/services/notification/notification.service';
import { take, takeUntil } from 'rxjs/operators';
import { ServerErrorModel } from '@sp-core/models/app-models/server-error.model';
import { ServerStatusCode } from '@sp-core/agreement-keys/server-status-code.enum';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { IForgotPasswordFrom } from '@sp-core/models/forms-models/forgot-password-from.interface';

@Component({
  selector: 'sp-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  public forgotPasswordForm: FormGroup<IForgotPasswordFrom>;
  public serverError: IServerValidationErrors;
  public destroy$: Subject<void> = new Subject<void>();

  constructor(
    private authService: AuthService,
    private router: Router,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.createForm();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public createForm(): void {
    this.forgotPasswordForm = new FormGroup<IForgotPasswordFrom>({
      email: new FormControl<null | string>(null, [
        CValidators.required,
        CValidators.minLength(7),
        CValidators.maxLength(250),
        CValidators.noStartAndEndWhitespace,
        CValidators.email,
      ]),
    });
  }

  public onSubmit(): void {
    const link = this.getLink();
    this.authService
      .forgotPassword({
        ...this.forgotPasswordForm.getRawValue(),
        link,
      })
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          if (response && typeof response === 'string') {
            this.showNotification(response);
          } else {
            const text = this.translateTextMessage('AUTH.CHECK_YOUR_EMAIL_WE_SEND_YOU_A_LINK_FOR_RESET_PASSWORD');
            this.showNotification(text);
          }
          this.serverError = null;
          this.router.navigateByUrl('/sign-in');
        },
        (error: ServerErrorModel) => {
          if (error.status === ServerStatusCode.validationError) {
            this.serverError = error.validationErrors;
            this.cd.detectChanges();
          }
        },
      );
  }

  private getLink(): string {
    return environment.frontHost + environment.resetPassword;
  }

  private showNotification(text: string): void {
    this.notificationService.showNotification(text, 'success');
  }

  public translateTextMessage(text: string): string {
    let translatedText;
    this.translate
      .get(text)
      .pipe(takeUntil(this.destroy$))
      .subscribe((translation) => {
        translatedText = translation;
      });
    return translatedText;
  }
}
