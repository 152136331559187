import { environment } from '@sp-environments/environment';
import { HttpKnowledgeBaseService } from '@sp-core/http-services/http-knowledge-base/http-knowledge-base.service';

export class ApiConfig {
  public static basePath: string = environment.apiHost;
  public static baseAPIPath = `${ApiConfig.basePath}api/v1/`;
  public static inviteTokenPath = `${ApiConfig.baseAPIPath}auth/register/token`;
  public static signUpPath = `${ApiConfig.baseAPIPath}auth/register`;
  public static signInPath = `${ApiConfig.baseAPIPath}auth/login`;
  public static checkSessionsPath = `${ApiConfig.baseAPIPath}auth/check/sessions`;
  public static logoutPath = `${ApiConfig.baseAPIPath}auth/logout`;
  public static practicePath = `${ApiConfig.baseAPIPath}practices`;
  public static practiceOwnPath = `${ApiConfig.baseAPIPath}profile/practice`;
  public static inventoryPath = `${ApiConfig.baseAPIPath}practice/inventory`;
  public static inventoryLogPath = `${ApiConfig.baseAPIPath}practice/inventory/log`;
  public static countriesPath = `${ApiConfig.baseAPIPath}countries`;
  public static userPath = `${ApiConfig.baseAPIPath}auth/profile`;
  public static forgotPasswordPath = `${ApiConfig.baseAPIPath}auth/forgot`;
  public static resetPasswordPath = `${ApiConfig.baseAPIPath}auth/reset`;
  public static resetPasswordTokenPath = `${ApiConfig.baseAPIPath}auth/restore/token`;
  public static inviteUserPath = `${ApiConfig.baseAPIPath}auth/invite`;
  public static profilePath = `${ApiConfig.baseAPIPath}auth/profile`;
  public static timezonesPath = `${ApiConfig.baseAPIPath}timezones`;
  public static dateTimePath = `${ApiConfig.baseAPIPath}datetime_formats`;
  public static measurementPath = `${ApiConfig.baseAPIPath}measurement_preferences`;
  public static rolesPath = `${ApiConfig.baseAPIPath}roles`;
  public static inviteRolesPath = `${ApiConfig.baseAPIPath}invite/roles`;
  public static genderPath = `${ApiConfig.baseAPIPath}genders`;
  public static racesPath = `${ApiConfig.baseAPIPath}races`;
  public static patientPath = `${ApiConfig.baseAPIPath}patients`;
  public static referralsPath = `${ApiConfig.baseAPIPath}referrals`;
  public static referralsNotesPath = `${ApiConfig.baseAPIPath}referrals_notes`;
  public static lastVisitedPatientPath = `${ApiConfig.patientPath}/last_visited`;
  public static calcDictionaryPath = `${ApiConfig.baseAPIPath}calculator/dictionaries`;
  public static calculatorPelletsPath = `${ApiConfig.baseAPIPath}calculator/pellets`;
  public static pelletsPath = `${ApiConfig.baseAPIPath}dictionaries/pellets`;
  public static calculatorPath = `${ApiConfig.baseAPIPath}calculator`;
  public static insertionsPath = `${ApiConfig.baseAPIPath}insertions`;
  public static insertionTemplatesPath = `${ApiConfig.baseAPIPath}insertion_templates`;
  public static insertionSiteNotePath = `${ApiConfig.baseAPIPath}site_check_notes`;
  public static insertionSiteNoteDictionariesPath = `${ApiConfig.insertionSiteNotePath}/dictionaries`;
  public static agreementsPath = `${ApiConfig.baseAPIPath}agreements`;
  public static reportsPath = `${ApiConfig.baseAPIPath}reports/calculator/usage`;
  public static reportsExportPath = `${ApiConfig.baseAPIPath}reports/calculator/usage/export`;
  public static activityPath = `${ApiConfig.baseAPIPath}reports/activities`;
  public static cerboPath = `${ApiConfig.baseAPIPath}cerbo`;
  public static cerboIntegrationsPath = `${ApiConfig.baseAPIPath}cerbo/integrations`;
  public static labConfigPath = `${ApiConfig.baseAPIPath}labs/config/gender`;
  public static phoneVerificationPath = `${ApiConfig.baseAPIPath}auth/profile/phone/verification`;
  public static emailVerificationPath = `${ApiConfig.baseAPIPath}auth/profile/email/verification`;
  public static phonePrefixesPath = `${ApiConfig.baseAPIPath}phone/codes`;
  public static security2faPath = `${ApiConfig.baseAPIPath}auth/security/2fa`;
  public static security2faTypesPath = `${ApiConfig.baseAPIPath}dictionaries/security/2fa`;
  public static checkPasswordPath = `${ApiConfig.baseAPIPath}auth/check/password`;
  public static consultPath = `${ApiConfig.baseAPIPath}employee/consults`;
  public static medicationSuggestionsPath = `${ApiConfig.baseAPIPath}dictionary/medication_suggestions`;
  public static employeesPath = `${ApiConfig.baseAPIPath}employees`;
  public static stickyNotesPath = `${ApiConfig.baseAPIPath}sticky_notes`;
  public static medicalQuestionsPath = `${ApiConfig.baseAPIPath}medical-questions`;
  public static medicalQuestionsProviderListPath = `${ApiConfig.baseAPIPath}medical-questions/provider`;
  public static calculatorReturnParamsPath = `${ApiConfig.baseAPIPath}patients`;
  public static simpleCalculatorDictionaryPath = `${ApiConfig.baseAPIPath}simple-calculator/dictionaries`;
  public static simpleCalculatorPath = `${ApiConfig.baseAPIPath}simple-calculator`;
  public static invitationPath = `${ApiConfig.baseAPIPath}invitations`;
  public static reportFrequenciesPath = `${ApiConfig.baseAPIPath}dictionary/reports/frequencies`;
  public static reportPracticesPath = `${ApiConfig.baseAPIPath}reports/practices`;
  public static reportProvidersPath = `${ApiConfig.baseAPIPath}reports/providers`;
  public static reportSinglePath = `${ApiConfig.baseAPIPath}reports/single`;
  public static reportRecurringPath = `${ApiConfig.baseAPIPath}reports/recurring`;
  public static reportPath = `${ApiConfig.baseAPIPath}reports`;
  public static reportPreviewPath = `${ApiConfig.baseAPIPath}reports/preview`;
  public static announcementsPath = `${ApiConfig.baseAPIPath}announcements`;
  public static attachmentsPath = `${ApiConfig.baseAPIPath}attachments`;
  public static AWSs3Path = `https://s3`;
  public static resourcesPath = `${ApiConfig.baseAPIPath}resources`;
  public static storagePath = `${ApiConfig.baseAPIPath}storage`;
  public static patientFormsPath = `${ApiConfig.baseAPIPath}patient-forms`;
  public static pharmacyPath = `${ApiConfig.baseAPIPath}pharmacy`;
  public static knowledgeBasePath = `${ApiConfig.baseAPIPath}knowledge-base`;
  public static knowledgeBaseCategoriesPath = `${ApiConfig.baseAPIPath}knowledge-base/categories`;
  public static adminKnowledgeBasePath = `${ApiConfig.baseAPIPath}admin/knowledge-base`;
  public static adminKnowledgeBaseCategoriesPath = `${ApiConfig.baseAPIPath}admin/knowledge-base/categories`;
  public static adminKnowledgeBaseArticlePath = `${ApiConfig.baseAPIPath}admin/knowledge-base/articles`;
  public static knowledgeBaseArticlePath = `${ApiConfig.baseAPIPath}knowledge-base/articles`;
}
