import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConfig } from '@sp-core/api-config';

@Injectable({
  providedIn: 'root',
})
export class HttpAgreementsService {
  constructor(private http: HttpClient) {}

  public getDosingResponsibilityAgreement(
    httpParams: HttpParamsOptions,
  ): Observable<{ data: IDosingResponsibilityAgreementApi[] }> {
    return this.http.get<{ data: IDosingResponsibilityAgreementApi[] }>(`${ApiConfig.agreementsPath}/unsigned/`, {
      params: httpParams,
    });
  }

  public sendDosingResponsibilityAgreement(agreementId: string): Observable<void> {
    return this.http.post<void>(`${ApiConfig.agreementsPath}/sign/${agreementId}`, {});
  }
}
