import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@sp-core/api-config';

@Injectable({
  providedIn: 'root',
})
export class HttpPracticeService {
  constructor(private http: HttpClient) {}

  public getListPractices(httpParams: HttpParamsOptions): Observable<ITableApiData<IPracticeApi>> {
    return this.http.get<ITableApiData<IPracticeApi>>(`${ApiConfig.practicePath}`, {
      params: httpParams,
    });
  }

  public getCountries(): Observable<IDataListApi<ICountryApi>> {
    return this.http.get<IDataListApi<ICountryApi>>(`${ApiConfig.countriesPath}`);
  }

  public getRegion(id: string): Observable<IDataListApi<IRegionApi>> {
    return this.http.get<IDataListApi<IRegionApi>>(`${ApiConfig.countriesPath}/${id}/regions`);
  }

  public createPractice(practice: ISinglePracticeApi): Observable<any> {
    return this.http.post<any>(`${ApiConfig.practicePath}`, practice);
  }

  public updatePractice(practice: ISinglePracticeApi, id: string): Observable<any> {
    return this.http.patch<any>(`${ApiConfig.practicePath}/${id}`, practice);
  }

  public getPractice(id: string): Observable<IPracticeApi> {
    return this.http.get<IPracticeApi>(`${ApiConfig.practicePath}/${id}`);
  }

  public getOwnPractice(): Observable<IPracticeApi> {
    return this.http.get<IPracticeApi>(`${ApiConfig.practiceOwnPath}`);
  }

  public getBlob(processId: string): Observable<Blob> {
    return this.http.get<Blob>(`${ApiConfig.practicePath}/export/${processId}`, {
      responseType: 'blob' as 'json',
    });
  }
}
