import { Injectable, Inject } from '@angular/core';
import { WINDOW } from './window.provider';

@Injectable()
export class WindowService {
  constructor(@Inject(WINDOW) private window: Window) {}

  public getHostname(): string {
    return this.window.location.hostname;
  }

  public getProtocol(): string {
    return this.window.location.protocol;
  }

  public getPort(): string {
    return this.window.location.port;
  }

  public encodeURIComponent(data: any): string {
    // @ts-ignore
    return this.window?.encodeURIComponent(data);
  }

  public open(url: string, name = '_blank'): void {
    this.window.open(url, name);
  }

  public getBaseHref(): string {
    return `${this.getProtocol()}//${this.getHostname()}${this.getPort() ? `:${this.getPort()}` : ''}`;
  }
}
