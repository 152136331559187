<div class="logo-menu">
  <section class="container">
    <div class="col-12 pr-0 pl-0">
      <div *ngIf="!!currentUserRole" class="logo-menu__wrap">
        <a [routerLink]="currentUserRole?.key === userRoles.practiceAdmin ? 'admin/dashboard' : '/'" class="logo-menu__link logo">
          <img src="assets/images/logos/logo.svg" alt="logo">
        </a>
        <ng-content select="[nav-user-menu]"></ng-content>
      </div>
    </div>
  </section>
</div>
