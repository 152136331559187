export class MetaHttpAdapter {
  public static transformToMeta(data: IMetaApi): IMeta {
    return {
      record_count: data?.pagination?.total,
      page_count: data?.pagination?.total_pages,
      current_page: data?.pagination?.current_page,
      links: data?.pagination?.links,
    };
  }
}
