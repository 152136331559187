import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '@sp-core-services';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { CValidators } from '@sp-helpers/validation/validators';
import { ServerErrorModel } from '@sp-core/models/app-models/server-error.model';
import { ServerStatusCode } from '@sp-core/agreement-keys/server-status-code.enum';
import { IResetPasswordForm } from '@sp-core/models/forms-models/reset-password-form.interface';

@Component({
  selector: 'sp-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  public destroy$: Subject<void> = new Subject();
  public restorePasswordForm: FormGroup<IResetPasswordForm>;
  public token: string;
  public minPasswordLength = 12;
  public serverError: IServerValidationErrors;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.createRestorePasswordForm();
    this.getToken();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public getRouterParams(): Observable<Params> {
    return this.route.queryParams.pipe(takeUntil(this.destroy$));
  }

  public handlerSubmit(data: IRestorePassword): void {
    this.authService.resetPassword(data).subscribe(
      () => {
        this.router.navigateByUrl('/sign-in');
      },
      (error: ServerErrorModel) => {
        this.handlerServerError(error);
      },
    );
  }

  private getToken(): void {
    this.getRouterParams()
      .pipe(
        map((data: Params) => data.token),
        tap((token) => {
          this.token = token;
        }),
        switchMap((token) => this.confirmResetPasswordToken(token)),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (res: boolean) => {
          if (!res) {
            this.redirectToLogin();
          }
          this.patchToken();
        },
        () => {
          this.redirectToLogin();
        },
      );
  }

  private confirmResetPasswordToken(token: string): Observable<boolean> {
    return this.authService.confirmResetPasswordToken(token);
  }

  private redirectToLogin(): void {
    this.router.navigateByUrl('/sign-in');
  }

  private createRestorePasswordForm(): void {
    const validatorsPassword = [
      CValidators.required,
      CValidators.passwordMatchPattern,
      CValidators.noStartAndEndWhitespace,
      CValidators.minLength(12),
      CValidators.maxLength(30),
    ];
    this.restorePasswordForm = new FormGroup<IResetPasswordForm>(
      {
        token: new FormControl<null | string>(null),
        password: new FormControl<null | string>(null, validatorsPassword),
        confirmPassword: new FormControl<null | string>(null, validatorsPassword),
      },
      CValidators.mustMatch('password', 'confirmPassword'),
    );
  }

  private patchToken(): void {
    this.restorePasswordForm.get('token').patchValue(this.token);
  }

  private handlerServerError(error: ServerErrorModel): void {
    if (error.status === ServerStatusCode.validationError) {
      this.serverError = error.validationErrors;
      this.cd.detectChanges();
    }
  }
}
