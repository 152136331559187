import { Injectable } from '@angular/core';
import DateUtils from '@sp-helpers/date-time/date-utils';

@Injectable({
  providedIn: 'root',
})
export class SiteNoteHttpAdapter {
  public transformToSiteNoteDictionaries(
    data: ISiteNoteDictionariesApi,
    transformItemFn = (item) => item,
  ): ISiteNoteDictionaries {
    const transform = (item) => (item && item?.length > 0 ? transformItemFn(item) : []);
    return {
      bandaging: transform(data?.bandaging),
      tenderness: transform(data?.tenderness),
      fluctuance: transform(data?.fluctuance),
      evaluationOfSiteSelection: transform(data?.evaluation_of_site_selection),
    };
  }

  public transformToSiteNote(data: ISiteNoteResponseApi): ISiteNote {
    return {
      id: data?.id,
      insertionDate: data?.insertion_date,
      appointmentDate: data?.appointment_date,
      patient: data?.patient ? this.transformToPatient(data?.patient) : null,
      creator: this.transformToSiteNoteProvider(data?.creator),
      siteCheckNumber: data?.site_check_number,
      given: this.transformToSiteNoteHormones(data?.given),
      insertionProvider: this.transformToSiteNoteProvider(data?.insertion_provider),
      isDividedDose: data?.is_divided_dose,
      side: data?.side,
      location: data?.location,
      anesthesia: {
        withEpinephrine: data?.anesthesia?.with_epinephrine,
        withoutEpinephrine: data?.anesthesia?.without_epinephrine,
      },
      bandaging: data?.bandaging || {},
      isDifficultyNotations: data?.is_difficulty_notations,
      patientComplaints: this.transformToPatientComplains(data?.patient_complaints),
      isPelletsReplaced: data?.is_pellets_replaced,
      isExpulsionVisitDocumented: data?.is_expulsion_visit_documented,
      examinerAssessment: this.transformToExaminerAssessment(data?.examiner_assessment),
      managementPlan: {
        antibiotics: data?.management_plan?.antibiotics,
        labsNeeded: data?.management_plan?.labs_needed,
        rtc: data?.management_plan?.rtc,
        woundCareInstructions: data?.management_plan?.wound_care_instructions,
        otherOne: data?.management_plan?.other_one,
        otherTwo: data?.management_plan?.other_two,
      },
      note: data?.note,
      updateCounter: data?.update_counter,
      insertion: {
        id: data?.insertion?.id,
        updateCounter: data?.insertion?.update_counter,
        lots: data?.insertion?.lots,
        side: data?.insertion?.side,
        location: data?.insertion?.location,
        anesthesia: {
          withEpinephrine: data?.insertion?.anesthesia?.with_epinephrine,
          withoutEpinephrine: data?.insertion?.anesthesia?.without_epinephrine,
        },
        bandaging: data?.insertion?.bandaging || {},
      },
    };
  }

  public transformToSiteNoteItem(data: ISiteNoteItemApi): ISiteNoteItem {
    return {
      id: data?.id,
      appointmentDate: data?.appointment_date,
      creator: this.transformToSiteNoteProvider(data?.creator),
      isEditable: data.is_editable,
    };
  }

  public transformToSiteNoteReportItem(data: ISiteNoteReportItemApi): ISiteNoteReportItem {
    return {
      insertionDate: data?.insertion_date,
      isPelletsReplaced: data?.is_pellets_replaced,
      patient: data?.patient ? this.transformToPatient(data?.patient) : null,
      previousSiteCheck: data?.previous_site_check,
      id: data?.id,
      appointmentDate: data?.appointment_date,
      creator: this.transformToSiteNoteProvider(data?.creator),
      isEditable: data?.is_editable,
    };
  }

  transformToPatientComplains(data: any): any {
    return data
      ? {
          painAtTimeOfInsertion: data?.pain_at_time_of_insertion || {},
          painAfterInsertion: data?.pain_after_insertion || {},
          purulentDrainage: data?.purulent_drainage || {},
          bleedingFromSite: data?.bleeding_from_site || {},
          pusComingFromIncision: data?.pus_coming_from_incision || {},
          rednessAtAroundSite: data?.redness_at_around_site || {},
          pelletExpulsion: {
            selected: data?.pellet_expulsion?.selected || null,
            additional: this.transformToSiteNoteHormones(data?.pellet_expulsion?.additional),
          },
          otherPatientComplaints: data?.other_patient_complaints || {},
        }
      : {};
  }

  transformToExaminerAssessment(data: any): any {
    return data
      ? {
          cellulitis: data?.cellulitis || {},
          tenderness: data?.tenderness || {},
          purulentDrainage: data?.purulent_drainage || {},
          fluctuance: data?.fluctuance || {},
          pelletExtrusionExtraction: data?.pellet_extrusion_extraction || {},
          howManyPelletsExtruded: data?.how_many_pellets_extruded || {},
          evaluationOfSiteSelection: data?.evaluation_of_site_selection || {},
          otherContributingFactors: data?.other_contributing_factors || {},
        }
      : {};
  }

  public transformToPatient(patient: Partial<IPatientApi>): Partial<IPatient> {
    return {
      id: patient?.id,
      firstName: patient.first_name,
      lastName: patient.last_name,
      middleInitial: patient?.middle_initial,
      gender: patient.gender,
      genderId: patient.gender.id,
      heightOne: patient?.height ? patient?.height[0] : null,
      heightTwo: patient?.height ? patient?.height[1] : null,
      weight: patient?.weight,
      bmi: patient?.bmi,
      age: patient?.age,
    };
  }

  public transformToSiteNoteRequest(data: ISiteNote): ISiteNoteRequestApi {
    return {
      appointment_date: DateUtils.formatDateToApi(data.appointmentDate),
      is_divided_dose: data.isDividedDose,
      side: data.side,
      location: data.location,
      anesthesia: {
        with_epinephrine: data.anesthesia.withEpinephrine,
        without_epinephrine: data.anesthesia.withoutEpinephrine,
      },
      bandaging: data.bandaging,
      is_difficulty_notations: data.isDifficultyNotations,
      patient_complaints: {
        pain_at_time_of_insertion: data?.patientComplaints.painAtTimeOfInsertion,
        pain_after_insertion: data?.patientComplaints.painAfterInsertion,
        bleeding_from_site: data?.patientComplaints.bleedingFromSite,
        pus_coming_from_incision: data?.patientComplaints.pusComingFromIncision,
        redness_at_around_site: data?.patientComplaints.rednessAtAroundSite,
        pellet_expulsion: {
          selected: data?.patientComplaints?.pelletExpulsion?.selected,
          additional: this.transformToSiteNoteHormonesApi(data?.patientComplaints?.pelletExpulsion?.additional),
        },
        other_patient_complaints: data?.patientComplaints?.otherPatientComplaints,
      },
      examiner_assessment: {
        cellulitis: data.examinerAssessment.cellulitis,
        tenderness: data.examinerAssessment.tenderness,
        fluctuance: data.examinerAssessment.fluctuance,
        pellet_extrusion_extraction: data.examinerAssessment.pelletExtrusionExtraction,
        how_many_pellets_extruded: data.examinerAssessment.howManyPelletsExtruded,
        evaluation_of_site_selection: data.examinerAssessment.evaluationOfSiteSelection,
        other_contributing_factors: data.examinerAssessment.otherContributingFactors,
        purulent_drainage: data.examinerAssessment.purulentDrainage,
      },
      management_plan: {
        antibiotics: data.managementPlan.antibiotics,
        wound_care_instructions: data.managementPlan.woundCareInstructions,
        labs_needed: data.managementPlan.labsNeeded,
        rtc: data.managementPlan.rtc,
        other_one: data.managementPlan.otherOne,
        other_two: data.managementPlan.otherTwo,
      },
      note: data.note,
      is_pellets_replaced: data.isPelletsReplaced,
      is_expulsion_visit_documented: data.isExpulsionVisitDocumented,
    };
  }

  public transformToSiteNoteProvider(data: ICreatorApi): ICreator {
    return {
      firstName: data?.first_name || '',
      lastName: data?.last_name || '',
    };
  }

  public transformToSiteNoteHormones(data: IReportsDosageGivenApi): IPelletsTotal {
    const res = {} as IPelletsTotal;
    if (data) {
      res.estradiol = data?.estradiol;
      res.testosterone = data?.testosterone;
    }
    if (data?.testosterone_anastrozole) {
      res.anastrozole = data?.testosterone_anastrozole;
    }
    return res;
  }

  public transformToSiteNoteHormonesApi(data: IPelletsTotal): IReportsDosageGivenApi {
    if (!data) {
      return null;
    }
    return {
      estradiol: data.estradiol,
      testosterone: data.testosterone,
    };
  }
}
