import { Component, Input, OnChanges, SimpleChange, ViewEncapsulation } from '@angular/core';
import { RegExpValidator } from '@sp-helpers/validation/regexp';

@Component({
  selector: 'sp-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PasswordStrengthComponent implements OnChanges {
  public passwordData: string;
  public bar0: string;
  public bar1: string;
  public bar2: string;
  public bar3: string;
  public bar4: string;
  private colors = ['#ff80ab', '#0AABB8', '#0AABB8', '#0AABB8', '#0AABB8'];

  public message: string;
  public messageColor: string;

  @Input() set value(data: string) {
    this.passwordData = data;
  }

  public get value(): string {
    return this.passwordData;
  }

  @Input() min: number;
  @Input() isShowMessage = false;

  public ngOnChanges(): void {
    this.setBarColors(4, '#ffcdd2');

    if (this.value) {
      const pwdStrength = this.checkStrength();

      const color = this.getColor(pwdStrength);
      this.setBarColors(color.index, color.color);

      switch (pwdStrength) {
        case 10:
          this.message = 'Poor';
          break;
        case 20:
          this.message = 'Not Good';
          break;
        case 30:
          this.message = 'Average';
          break;
        case 40:
          this.message = 'Good';
          break;
        case 50:
          this.message = 'Good';
          break;
      }
    } else {
      this.message = '';
    }
  }

  public checkStrength(): number {
    let force = 0;

    const lowerLetters = this.getLowerLettersValidation();
    const upperLetters = this.getUpperCaseValidation();
    const numbers = this.getDigitValidation();
    const symbols = this.getSpecialCharValidation();
    const minLength = this.getMinLengthValidation();

    const flags = [lowerLetters, upperLetters, numbers, symbols, minLength];

    let passedMatches = 0;
    for (const flag of flags) {
      passedMatches += flag === true ? 1 : 0;
    }

    force += 2 * this.passwordData.length + (this.passwordData.length >= 10 ? 1 : 0);
    force += passedMatches * 10;

    force = this.passwordData.length <= 6 ? Math.min(force, 10) : force;

    force = passedMatches === 1 ? Math.min(force, 10) : force;
    force = passedMatches === 2 ? Math.min(force, 20) : force;
    force = passedMatches === 3 ? Math.min(force, 30) : force;
    force = passedMatches === 4 ? Math.min(force, 40) : force;
    force = passedMatches === 5 ? Math.min(force, 50) : force;

    return force;
  }

  private getColor(strength: number) {
    let index = 0;

    if (strength === 10) {
      index = 0;
    } else if (strength === 20) {
      index = 1;
    } else if (strength === 30) {
      index = 2;
    } else if (strength === 40) {
      index = 3;
    } else if (strength === 50) {
      index = 4;
    } else {
      index = 5;
    }

    this.messageColor = this.colors[index];

    return {
      index: index + 1,
      color: this.colors[index],
    };
  }

  private setBarColors(count: number, color: string) {
    for (let n = 0; n < count; n++) {
      (this as any)['bar' + n] = color;
    }
  }

  public getLowerLettersValidation(): boolean {
    return RegExpValidator.lowerCase.test(this.passwordData);
  }

  public getUpperCaseValidation(): boolean {
    return RegExpValidator.upperCase.test(this.passwordData);
  }

  public getDigitValidation(): boolean {
    return RegExpValidator.digit.test(this.passwordData);
  }

  public getSpecialCharValidation(): boolean {
    return RegExpValidator.specialChar.test(this.passwordData);
  }

  public getMinLengthValidation(): boolean {
    return this.passwordData?.length >= this.min;
  }
}
