import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ConfirmPopupComponent } from '@sp-shared/containers/confirm-popup/confirm-popup/confirm-popup.component';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AuthService, PermissionsService } from '@sp-core-services';
import { Roles } from '@sp-core/agreement-keys/roles.enum';
import { IUserNavMenu } from '../../models/user-nav-menu.interface';
import { OrderInfoPopupComponent } from '@sp-shared/components/order-info-popup/order-info-popup.component';

@Component({
  selector: 'sp-user-nav-item',
  templateUrl: './user-nav-item.component.html',
  styleUrls: ['./user-nav-item.component.scss'],
})
export class UserNavItemComponent implements OnInit, OnDestroy {
  public imgSrc: string;
  public destroy$: Subject<void> = new Subject();
  public userRoles: typeof Roles = Roles;
  public open: boolean;
  public roleIcon = 'assets/images/user-role-blue.svg';
  public arrowIcon = 'assets/images/arrow-down.svg';

  @Input() public item: IUserNavMenu;
  @Input() public user: IUser;
  @Input() public role: IRole;

  constructor(
    private authService: AuthService,
    public dialog: MatDialog,
    private permissionsService: PermissionsService,
    private cd: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.imgSrc = this.item.icon;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public openDialogConfirmLogOut(): void {
    const text = 'ARE_YOU_SURE_YOU_WANT_TO_LOG_OUT';
    this.getConfirmDialog(text).subscribe((result) => {
      if (result) {
        this.logOut();
      }
    });
  }

  private getConfirmDialog(text: string): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: { text },
    });

    return dialogRef.afterClosed().pipe(takeUntil(this.destroy$));
  }

  public logOut(): void {
    this.authService.logout().pipe(takeUntil(this.destroy$)).subscribe();
  }

  public updateRole(role: IRole) {
    this.open = !this.open;
    if (this.role.id === role.id) {
      return;
    }
    const text = `Are you sure you want to change role to '${role.name}'?`;
    this.getConfirmDialog(text)
      .pipe(
        filter(Boolean),
        takeUntil(this.destroy$),
        switchMap(() => {
          return this.permissionsService.updateRole(role);
        }),
      )
      .subscribe(() => {
        this.authService.handlerNavigation(role);
        this.cd.detectChanges();
      });
  }

  public openOrderPopup(): void {
    this.dialog.open(OrderInfoPopupComponent, {
      disableClose: true,
      panelClass: 'order-info',
    });
  }
}
