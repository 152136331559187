<div class="container-fluid">
  <div class="row top-nav-background">
    <div class="col col-no-padding top-nav-wrap">
      <nav class="top-nav d-flex" *ngIf="user || includeRoute('sign-in')">
        <div class="mobile-menu-text">
          {{'MENU' | translate}}
        </div>

        <div class="block-action">
          <button spQaId="nav_button_toggle-menu"
                  class="btn-toggle" (click)="toggleClick()">
            <i class="material-icons" *ngIf="!open">
              menu
            </i>
            <i class="material-icons" *ngIf="open">
              close
            </i>
          </button>
        </div>
        <div class="wrap-nav d-flex w-100" [ngClass]="{
        open: open, 'wrap-nav-direction': role?.key !== userRoles.practiceAdmin
        }">
          <div class="top-nav-left d-flex nav-item">
            <ng-container *ngFor="let menu of leftMenu">
              <ng-container *ngIf="(menu?.dropMenuGroup) && (menu?.dropMenuGroupName !== navItemMenuGroupTypesEnum.usersAndRolesForPracticeOwn && role?.key !== userRoles.limitedPracticeAdmin); else itemMenu">
                <a class="nav-link dropdown-nav-item"
                   *spCanView="menu.whoCanView"
                   [ngClass]="{'active-drop': checkIsDropMenuGroupActiveRouter(menu.items), 'reset': isResetClass}"
                   routerLinkActive="active"
                   (click)="closeMenu()"
                   (menuClosed)="isResetClass = true"
                   [spQaId]="'nav_link_' + menu.dropMenuGroupName"
                   [matMenuTriggerFor]="dropDown"
                   #topItem="matMenuTrigger">
                  <span class="nav-item__text">{{menu.dropMenuGroupName | translate}}
                    <div class="nav-item__arrow-icon ml-1"
                         [ngClass]="{'active': checkIsDropMenuGroupActiveRouter(menu.items)}"
                    ></div>
                  </span>
                </a>
                <ng-template #dropDownItems>
                  <ng-container *ngFor="let item of menu.items">
                    <a class="nav-item__item"
                       routerLinkActive="active"
                       mat-menu-item
                       [routerLink]="item.link"
                       *spCanView="item.whoCanView"
                       (click)="closeMenu()"
                       [spQaId]="'nav_item_' + item?.key">
                      {{item.name | translate}}
                    </a>
                  </ng-container>
                </ng-template>

                <mat-menu #dropDown="matMenu" xPosition="after" class="dropdown-nav-item">
                  <ng-container *ngTemplateOutlet="dropDownItems"></ng-container>
                </mat-menu>

                <div class="dropdown-nav-mobile">
                  <ng-container *ngTemplateOutlet="dropDownItems"></ng-container>
                </div>

              </ng-container>

              <ng-template #menuItem>
                <ng-container *ngIf="menu.dropMenuGroup !== navItemMenuGroupTypesEnum.usersAndRolesForPracticeOwn">
                  <a class="nav-link"
                     [ngClass]="{'nav-with-badge': menu.link === 'release-notes', 'badge-pr': menu.link === 'release-notes'}"
                     routerLinkActive="active"
                     [routerLink]="menu.link"
                     *spCanView="menu.whoCanView"
                     (click)="closeMenu()"
                     [spQaId]="'nav_link_' + menu?.key">
                    {{menu.name | translate}}
                  </a>
                </ng-container>
              </ng-template>

              <ng-template #itemMenu>
                <ng-container *ngIf="menu.link !== 'release-notes'">
                  <ng-container *ngTemplateOutlet="menuItem"></ng-container>
                </ng-container>

                <div class="position-relative" *ngIf="menu.link === 'release-notes' && role?.key === userRoles.practiceAdmin">
                  <sp-badge *ngIf="resourcesQuantity"
                            class="badge"
                            [badgeType]="BadgeTypeEnum.quantity"
                            [quantity]="resourcesQuantity"
                  ></sp-badge>
                  <ng-container *ngTemplateOutlet="menuItem"></ng-container>
                </div>

                <ng-container *ngIf="menu.dropMenuGroup === navItemMenuGroupTypesEnum.usersAndRolesForPracticeOwn">
                  <a class="nav-link"
                     routerLinkActive="active"
                     [routerLink]="menu.items[0].link"
                     *spCanView="menu.items[0].whoCanView"
                     (click)="closeMenu()"
                     [spQaId]="'nav_link_' + menu.items[0]?.key">
                    {{menu.items[0].name | translate}}
                  </a>
                </ng-container>
              </ng-template>
            </ng-container>
            <div class="mobile-menu">
              <a class="nav-link"
                 routerLinkActive="active"
                 routerLink="settings"
                 spQaId="nav_link_settings"
                 *spCanView="['profileGet']"
                 (click)="closeMenu()"
              >
                {{'NAV.MY_SETTINGS' | translate}}
              </a>
              <a class="nav-link"
                 routerLinkActive="active"
                 routerLink="contact-us"
                 spQaId="nav_link_contact-us"
                 *spCanView="['contactUsGet']"
                 (click)="closeMenu()"
              >
                {{'NAV.CONTACT_US' | translate}}
              </a>
              <a class="nav-link"
                 routerLinkActive="active"
                 spQaId="nav_link_open-order-info-popup"
                 *spCanView="['dashboard']"
                 (click)="openOrderPopup()"
              >
                {{'NAV.ORDER' | translate}}
              </a>
              <a class="nav-link mobile-menu"
                 href="https://dosaggio.co/contact"
                 *spCanView="['contactUsGet']"
                 (click)="closeMenu()"
                 spQaId="nav_link_help"
              >
                {{'NAV.HELP' | translate}}
              </a>
            </div>
            <ng-container *spCanView="['menuHelp']">
              <a class="mobile-menu nav-link"
                 target="_blank"
                 rel="noopener noreferrer"
                 *ngIf="user && role"
                 [href]="role?.key === userRoles.practiceAdmin ? 'https://dosaggio.co/contact' : 'https://dosaggio.tawk.help/'"
                 (click)="closeMenu()"
                 [spQaId]="role?.key === userRoles.practiceAdmin ? 'nav_link_educational-center' : 'nav_link_help'"
              >
                {{ role?.key === userRoles.practiceAdmin ? ('NAV.EDUCATIONAL_CENTER' | translate) : ('NAV.HELP' | translate) }}
              </a>
            </ng-container>
          </div>
          <div class="top-nav-right d-flex">
            <ng-container *ngIf="!user || !role">
              <ng-container *ngFor="let item of rightMenu">
                <a class="nav-link"
                   routerLinkActive="active"
                   [routerLink]="item.link"
                   *ngIf="includeRoute(item.link)"
                   (click)="closeMenu()"
                >
                  {{item.name | translate}}
                </a>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="user && role">
              <a *ngIf="stickyNotesContext"
                 (mouseover)="notesIcon = 'assets/images/notes-white.svg'"
                 (mouseout)="notesIcon = 'assets/images/notes.svg'"
                 class="nav-link"
                 spQaId="nav_link_notes"
                 (click)="addNote()">
                <img class="nav-icon-user"
                     alt="user-role"
                     [src]="notesIcon">
                <span>Notes</span>
              </a>
              <div class="mobile-menu">
                <a class="nav-link"
                   (menuOpened)="toggleRoleMenu(true)"
                   (menuClosed)="toggleRoleMenu(false)"
                   (mouseover)="roleIcon = 'assets/images/user-role-white.svg';
                   arrowIcon = 'assets/images/arrow-down-white.svg'"
                   (mouseout)="roleIcon = isOpenRoleMenu ? 'assets/images/user-role-white.svg' : 'assets/images/user-role.svg';
                   arrowIcon = isOpenRoleMenu ? 'assets/images/arrow-up-white.svg' : 'assets/images/arrow-down.svg'"
                   [class.disabled]="!user?.isMultipleRoles"
                   [ngClass]="{'active': isOpenRoleMenu}"
                   spQaId="nav_link_set-role"
                   [matMenuTriggerFor]="roleMenu">
                  <img class="nav-icon-user"
                       alt="user-role"
                       [src]="roleIcon">
                  <span class="role-text">{{role.name}}</span>
                  <img *ngIf="user?.roles?.length > 1" class="nav-icon-user"
                       alt="arrow"
                       [src]="arrowIcon">
                </a>
                <mat-menu #roleMenu="matMenu" xPosition="after">
                  <ng-container *ngFor="let role of user.roles">
                    <button [spQaId]="'nav_button_set-role-' + role?.key"
                            mat-menu-item
                            (click)="updateRole(role)">
                      {{role.name}}
                    </button>
                  </ng-container>
                </mat-menu>
              </div>
              <div class="mobile-menu">
                <a class="nav-link"
                   (click)="openDialogConfirmLogOut()">
                  {{'LOGOUT' | translate}}
                </a>
              </div>
            </ng-container>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>
<div class="overlay" *ngIf="open" (click)="closeMenu()"></div>
