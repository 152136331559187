import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, map, take, tap } from 'rxjs/operators';
import FileUtils from '@sp-helpers/file-utils';
import { NotificationService } from '../notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class DownloadControllerService {
  constructor(private notificationService: NotificationService) {}

  private downloadingMapSubject: BehaviorSubject<{ [key: string]: Observable<Blob> }> = new BehaviorSubject({});

  private orderedList: string[] = [];

  private processing = false;

  public get downloadingMap(): { [key: string]: Observable<Blob> } {
    return this.downloadingMapSubject.value;
  }

  public get downloadingMap$(): Observable<{ [key: string]: Observable<Blob> }> {
    return this.downloadingMapSubject.asObservable();
  }

  public add(key: string, request: Observable<Blob>) {
    if (this.has(key)) {
      return;
    }

    this.downloadingMapSubject.next({ ...this.downloadingMapSubject.value, [key]: request });
    this.orderedList.push(key);
    this.checkAndSendRequest();
  }

  public has(key: string): boolean {
    return key in this.downloadingMap;
  }

  public has$(key: string): Observable<boolean> {
    return this.downloadingMapSubject.pipe(
      map((downloadingMap) => key in downloadingMap),
      distinctUntilChanged(),
    );
  }

  private checkAndSendRequest(): void {
    if (this.processing || this.orderedList.length === 0) {
      return;
    }
    const key = this.orderedList[0];
    this.sendRequest(key, this.downloadingMap[key]);
  }

  private sendRequest(key: string, request: Observable<Blob>) {
    this.processing = true;
    request
      .pipe(
        take(1),
        tap((blob) => FileUtils.saveBlob(blob)),
        catchError((error: Error) => {
          if (error instanceof Error && error?.message) {
            this.notificationService.showNotification(error.message, 'warning');
          }
          return throwError(error);
        }),
        finalize(() => {
          this.delete(key);
          this.processing = false;
          this.checkAndSendRequest();
        }),
      )
      .subscribe();
  }

  private delete(key: string) {
    const updatedValue = this.downloadingMapSubject.value;
    delete updatedValue[key];
    this.downloadingMapSubject.next({ ...updatedValue });
    this.orderedList.shift();
  }
}
