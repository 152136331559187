<div class="main-wrap" id="main-app-wrap" [ngClass]="{'over': isOnReleaseNoteRoute}">
  <section class="main-view" spInactive
           [inActiveTime]="inActiveTime"
           [ngClass]="{'role-bg': !!currentUserRole, 'no-role-bg': !currentUserRole, 'z-index-1': currentUserRole}">
    <div class="header-img-wrap"
         [style.height]="!currentUserRole ? '650px' : '310px'"
         [ngClass]="{'z-index-min': currentUserRole}">
      <img class="header-img"
           [ngClass]="{'z-index-min': currentUserRole}"
           [src]="!!currentUserRole ? appHeader : noAuthHeader"
           alt="header-bg">
    </div>
    <sp-logo>
      <sp-user-nav-menu nav-user-menu></sp-user-nav-menu>
    </sp-logo>
    <section class="container">
      <sp-nav [ngClass]="{'d-none': !currentUserRole}"></sp-nav>
    </section>
    <div class="mb-3 router-wrap">
      <router-outlet></router-outlet>
    </div>
  </section>
  <sp-footer>
    <sp-footer-nav-menu footer-nav-menu></sp-footer-nav-menu>
  </sp-footer>
</div>
