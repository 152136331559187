import { Pipe, PipeTransform } from '@angular/core';
import DateUtils from '../../../helpers/date-time/date-utils';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(value = '', format = 'L'): string {
    if (!value) {
      return '-';
    }
    return DateUtils.format(value, format);
  }
}
