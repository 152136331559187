<div class="auth-layout">
  <div class="container">
    <div class="row">
      <div class="col-12 justify-content-center">
        <h1 class="auth-title text-center">
          {{'AUTH.WELCOME_TITLE' | translate}}
        </h1>
        <div class="d-flex justify-content-center">
          <div *ngIf="!currentUserRole" class="logo text-center">
            <img src="assets/images/logos/logo.svg" alt="logo">
          </div>
        </div>
        <ng-content select="[auth-sub-title]"></ng-content>
        <ng-content select="[auth-server-error]"></ng-content>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
</div>
