import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConfig } from '@sp-core/api-config';
import { AutoReportEnum } from '@sp-core/agreement-keys/auto-report.enum';
import { AdminReportsTablePathEnum } from '@sp-core/agreement-keys/admin-reports-table-path.enum';

@Injectable({
  providedIn: 'root',
})
export class HttpReportsService {
  constructor(public readonly _http: HttpClient) {}

  public getListReports(httpParams: HttpParamsOptions): Observable<ITableApiData<IAdminReportsApi>> {
    return this._http.get<ITableApiData<IAdminReportsApi>>(ApiConfig.reportsPath, {
      params: httpParams,
    });
  }

  public getExportProcessId(exportFormat: string, httpParams: HttpParamsOptions): Observable<IExportProcessIdApi> {
    return this._http.post<IExportProcessIdApi>(
      `${ApiConfig.reportsExportPath}`,
      {
        format: exportFormat,
      },
      {
        params: httpParams,
      },
    );
  }

  public getExportPharmacyProcessId(
    exportFormat: string,
    httpParams: HttpParamsOptions,
  ): Observable<IExportProcessIdApi> {
    return this._http.post<IExportProcessIdApi>(
      `${ApiConfig.reportPath}/pharmacy/export`,
      {
        format: exportFormat,
      },
      {
        params: httpParams,
      },
    );
  }

  public getBlob(processId: string): Observable<Blob> {
    return this._http.get<Blob>(`${ApiConfig.reportsExportPath}/${processId}`, {
      responseType: 'blob' as 'json',
    });
  }

  public getPharmacyBlob(processId: string): Observable<Blob> {
    return this._http.get<Blob>(`${ApiConfig.reportPath}/pharmacy/export/${processId}`, {
      responseType: 'blob' as 'json',
    });
  }

  public getReportsFrequencies(): Observable<IAdminReportFrequenciesApi> {
    return this._http.get<IAdminReportFrequenciesApi>(ApiConfig.reportFrequenciesPath);
  }

  public getReportsPractices(httpParams: HttpParamsOptions): Observable<ITableApiData<IAdminReportPractices>> {
    return this._http.get<ITableApiData<IAdminReportPractices>>(ApiConfig.reportPracticesPath, { params: httpParams });
  }

  public getReportsProviders(
    filterData: IAdminReportProviderFilterRequestDataApi,
    httpParams?: HttpParamsOptions,
  ): Observable<ITableApiData<IAdminReportProvidersApi>> {
    return this._http.post<ITableApiData<IAdminReportProvidersApi>>(ApiConfig.reportProvidersPath, filterData, {
      params: httpParams,
    });
  }

  public createSingleReport(
    data: ISingleReportApi,
    type: AdminReportsTablePathEnum,
  ): Observable<ISingleReportResponseApi> {
    return this._http.post<ISingleReportResponseApi>(`${ApiConfig.reportSinglePath}/${type}`, data);
  }

  public createRecurringReport(
    data: IRecurringReportApi,
    type: AdminReportsTablePathEnum,
  ): Observable<IRecurringReportResponseApi> {
    return this._http.post<IRecurringReportResponseApi>(`${ApiConfig.reportRecurringPath}/${type}`, data);
  }

  public getRecurringReports(httpParams: HttpParamsOptions): Observable<ITableApiData<IRecurringReportResponseApi>> {
    return this._http.get<ITableApiData<IRecurringReportResponseApi>>(ApiConfig.reportPath, {
      params: httpParams,
    });
  }

  public getRecurringReport(id: string): Observable<IRecurringReportResponseApi> {
    return this._http.get<IRecurringReportResponseApi>(`${ApiConfig.reportPath}/${id}`);
  }

  public editRecurringReport(id: string, data: IRecurringReportApi): Observable<IRecurringReportResponseApi> {
    return this._http.put<IRecurringReportResponseApi>(`${ApiConfig.reportPath}/${id}`, data);
  }

  public deleteReport(id: string): Observable<void> {
    return this._http.delete<void>(`${ApiConfig.reportPath}/${id}`);
  }

  public previewReport(
    httpParams: HttpParamsOptions,
    data: IRecurringReportApi | ISingleReportApi,
    reportType: AutoReportEnum,
    type: AdminReportsTablePathEnum,
  ): Observable<ITableApiData<IAdminReportsApi>> {
    return this._http.post<ITableApiData<IAdminReportsApi>>(`${ApiConfig.reportPreviewPath}/${type}`, data, {
      params: httpParams,
    });
  }

  public previewSiteNoteReport(
    httpParams: HttpParamsOptions,
    data: IRecurringReportApi | ISingleReportApi,
    reportType: AutoReportEnum,
    type: AdminReportsTablePathEnum,
  ): Observable<ITableApiData<ISiteNoteReportItemApi>> {
    return this._http.post<ITableApiData<ISiteNoteReportItemApi>>(`${ApiConfig.reportPreviewPath}/${type}`, data, {
      params: httpParams,
    });
  }

  public getPharmaciesReportsList(httpParams: HttpParamsOptions): Observable<ITableApiData<IPharmacyApplicationApi>> {
    return this._http.get<ITableApiData<IPharmacyApplicationApi>>(`${ApiConfig.reportPath}/pharmacy`, {
      params: httpParams,
    });
  }
}
