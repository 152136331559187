import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RolesPermissionsAdapter {
  public transformToRoles(data: IRoleApi): IRole {
    return {
      id: data.id,
      name: data.name,
      key: data.key,
      description: data.description,
    };
  }

  public transformToPermissions(data: IPermissionsApi): IPermissions {
    return {
      id: data.id,
      name: data.name,
      key: data.key,
    };
  }
}
