import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MetaHttpAdapter } from '@sp-core/http-adapters/meta-http-adapter';
import { ConsultHttpAdapter } from '@sp-core/http-adapters/consult-http-adapter';
import { map } from 'rxjs/operators';
import { ITableService } from '@sp-shared/components/table';
import { HttpConsultService } from '../../http-services/http-consult/http-consult.service';

@Injectable({
  providedIn: 'root',
})
export class ConsultAssignPopupService implements ITableService<IProviderAssignData> {
  constructor(
    public readonly _httpConsultService: HttpConsultService,
    public readonly _consultHttpAdapter: ConsultHttpAdapter,
  ) {}

  public getTableData(params: HttpParamsOptions): Observable<ITableData<IProviderAssignData>> {
    return this._httpConsultService.getAssignEmployees(params).pipe(
      map((response) => ({
        data: response.data.map((item) => this._consultHttpAdapter.transformToConsultToAssign(item)),
        meta: MetaHttpAdapter.transformToMeta(response.meta),
      })),
    );
  }

  public assignConsultToUser(consultId: string, userId: string): Observable<any> {
    return this._httpConsultService.assignConsultToUser(consultId, userId);
  }
}
