import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ApiConfig } from '@sp-core/api-config';

@Injectable({
  providedIn: 'root',
})
export class HttpMedicalQuestionsService {
  constructor(public readonly _http: HttpClient) {}

  public getMedicalQuestions(params: HttpParamsOptions): Observable<ITableApiData<IMedicalQuestionApi>> {
    return this._http.get<ITableApiData<IMedicalQuestionApi>>(ApiConfig.medicalQuestionsProviderListPath, { params });
  }

  public getAdminMedicalQuestions(params: HttpParamsOptions): Observable<ITableApiData<IMedicalQuestionApi>> {
    return this._http.get<ITableApiData<IMedicalQuestionApi>>(ApiConfig.medicalQuestionsPath, { params });
  }

  public createMedicalQuestion(data: IMedicalQuestionCreateApi): Observable<IMedicalQuestionApi> {
    return this._http.post<IMedicalQuestionApi>(ApiConfig.medicalQuestionsPath, data);
  }

  public getMedicalQuestionDetail(questionId: string): Observable<IMedicalQuestionApi> {
    return this._http.get<IMedicalQuestionApi>(`${ApiConfig.medicalQuestionsPath}/${questionId}`);
  }

  public assignProviderToMedicalQuestion(questionId: string, providerId: string): Observable<void> {
    return this._http.patch<void>(`${ApiConfig.medicalQuestionsPath}/${questionId}/assign`, { user_id: providerId });
  }

  public getMessages(
    questionId: string,
    params: HttpParamsOptions,
  ): Observable<ITableApiData<IMedicalQuestionMessageApi>> {
    return this._http.get<ITableApiData<IMedicalQuestionMessageApi>>(
      `${ApiConfig.medicalQuestionsPath}/${questionId}/messages`,
      { params },
    );
  }

  public sendMessage(questionId: string, data: { message: string }): Observable<void> {
    return this._http.post<void>(`${ApiConfig.medicalQuestionsPath}/${questionId}/messages`, data);
  }

  public reopenMedicalQuestion(questionId: string): Observable<void> {
    return this._http.post<void>(`${ApiConfig.medicalQuestionsPath}/${questionId}/reopen`, {});
  }

  public resolveMedicalQuestion(questionId: string): Observable<void> {
    return this._http.post<void>(`${ApiConfig.medicalQuestionsPath}/${questionId}/resolve`, {});
  }
}
