/* tslint:disable:variable-name */
/* eslint-disable no-underscore-dangle,no-param-reassign */
import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Injector, Input, OnDestroy } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatFormField } from '@angular/material/form-field';
import { IError } from '@sp-helpers/validation/error-inteface';
import { IErrorMap } from '@sp-helpers/validation/error-map-interface';
import { validationErrorsConfig } from '@sp-helpers/validation/validation-errors-configs';
import ServerValidationUtils from '@sp-helpers/validation/server-validation-utils';

@Directive({
  selector: '[spErrors]',
})
export class ErrorsDirective implements OnDestroy, AfterViewInit {
  /**
   * will be run error strategy if we pass touched properties to the input
   * and it changes from false to true
   */
  @Input()
  public set touche(touched: boolean) {
    if (touched) {
      this.runMatchError();
    }
  }

  /**
   * use it if need add, replace or override errors on validationErrorsConfig
   */
  @Input()
  public set errorsConfig(map: IErrorMap) {
    if (map) {
      this._errorsConfig = Object.assign(this._errorsConfig, map);
    }
  }

  public get errorsConfig(): IErrorMap {
    return this._errorsConfig;
  }

  @Input()
  public set backendError(errors) {
    if (!errors || !this.currentControl) {
      return;
    }
    if (!this.controlPath) {
      this.controlPath = ServerValidationUtils.getControlPath(this.currentControl);
    }
    const error = this.controlPath in errors ? errors[this.controlPath] : null;
    if (!error) {
      return;
    }
    ServerValidationUtils.setServerValidationErrorToControl(error, this.currentControl);
    this.cd.detectChanges();
  }

  @Input() public ignoredCustomErrors = false;

  private _errorsConfig: IErrorMap;

  private destroy$: Subject<any> = new Subject();

  private currentControl: AbstractControl;

  private controlPath: string;

  constructor(private cd: ChangeDetectorRef, private element: ElementRef, private injector: Injector) {
    this._errorsConfig = validationErrorsConfig;
  }

  @Input() public control: AbstractControl;

  @Input() public controlName: string;

  public ngAfterViewInit(): void {
    this.currentControl = this.getControl();
    this.listenControlStatusChanges(this.currentControl);
  }

  private listenControlStatusChanges(control: AbstractControl) {
    if (!control) {
      return;
    }
    // if status is touched on start, then show the error immediately
    if (control.touched) {
      this.runMatchError();
    }
    control.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.runMatchError());
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private getControl(): AbstractControl {
    if (this.control) {
      return this.control;
    }
    const matFormField: any = this.injector.get(MatFormField, null);
    if (matFormField) {
      return matFormField?._control?.ngControl?.control;
    }

    if (this.controlName) {
      const controlContainer: any = this.injector.get(ControlContainer, null);
      return controlContainer.form.get(this.controlName);
    }
    return null;
  }

  private runMatchError(): void {
    const error: IError = this.currentControl.errors as any;
    const message = this.getErrorMessage(error);
    const { textContent } = this.element?.nativeElement;
    if (textContent !== message) {
      this.element.nativeElement.textContent = message;
    }
  }

  private getErrorMessage(error: IError): string | null {
    if (!error) {
      return null;
    }
    this.maskHandler(error);
    const message: string | any = this.errorsConfig[error?.name] || this.errorsConfig[Object.keys(error)[0]];
    this.throwError(error, message);

    if (typeof message === 'function') {
      return message(error?.expectedValue, error?.currentValue);
    }
    return message;
  }

  private throwError(error: any, message: any): void {
    if (error && !message && !this.ignoredCustomErrors) {
      throw new Error(`
      ERROR from errors directive.
      There are no errors in mapErrors with the given key.
      Key is ${error.name}.
      Add new errors to mapErrors.`);
    }
  }

  private maskHandler(error: any): void {
    if ((error as any).mask) {
      error.name = 'mask';
    }
  }
}
