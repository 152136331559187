export enum SymptomsKey {
  'female_acne' = 'female_acne',
  'female_facial_hair' = 'female_facial_hair',
  'female_hot_flashes' = 'female_hot_flashes',
  'female_insomnia' = 'female_insomnia',
  'female_night_sweats' = 'female_night_sweats',
  'female_hair_loss' = 'female_hair_loss',
  'female_vaginal_dryness' = 'female_vaginal_dryness',
  'female_abortions_number' = 'female_abortions_number',
  'female_live_births_number' = 'female_live_births_number',
  'female_pregnancies_number' = 'female_pregnancies_number',
  'female_active_liver_disease' = 'female_active_liver_disease',
  'female_breast_cancer' = 'female_breast_cancer',
  'female_cervical_cancer' = 'female_cervical_cancer',
  'female_endometrial_cancer' = 'female_endometrial_cancer',
  'female_fibrocystic_breast_disease' = 'female_fibrocystic_breast_disease',
  'female_heavy_menses_fibroids' = 'female_heavy_menses_fibroids',
  'female_ovarian_cancer' = 'female_ovarian_cancer',
  'female_pcos' = 'female_pcos',
  'female_renal_disease' = 'female_renal_disease',
  'female_hysterectomy' = 'female_hysterectomy',
  'female_postmenopausal' = 'female_postmenopausal',
  'female_premenopausal' = 'female_premenopausal',
  'male_active_liver_disease' = 'male_active_liver_disease',
  'male_bph' = 'male_bph',
  'male_colon_cancer' = 'male_colon_cancer',
  'male_diabetes' = 'male_diabetes',
  'male_renal_disease' = 'male_renal_disease',
  'male_hypertension' = 'male_hypertension',
  'male_prostate_cancer' = 'male_prostate_cancer',
  'male_testicular_cancer' = 'male_testicular_cancer',
  'male_slow_urine_flow' = 'male_slow_urine_flow',
  'female_oophorectomy_bilateral' = 'female_oophorectomy_bilateral',
  'female_oopherectomy_unilateral' = 'female_oopherectomy_unilateral',

  'ftm_acne' = 'ftm_acne',
  'ftm_active_liver_disease' = 'ftm_active_liver_disease',
  'ftm_breast_cancer' = 'ftm_breast_cancer',
  'ftm_cervical_cancer' = 'ftm_cervical_cancer',
  'ftm_decreased_libido' = 'ftm_decreased_libido',
  'ftm_depression' = 'ftm_depression',
  'ftm_endometrial_cancer' = 'ftm_endometrial_cancer',
  'ftm_facial_hair' = 'ftm_facial_hair',
  'ftm_fatigue' = 'ftm_fatigue',
  'ftm_fibrocystic_breast_disease' = 'ftm_fibrocystic_breast_disease',
  'ftm_hair_loss' = 'ftm_hair_loss',
  'ftm_heavy_menses_fibroids' = 'ftm_heavy_menses_fibroids',
  'ftm_hepatits_c' = 'ftm_hepatits_c',
  'ftm_hiv' = 'ftm_hiv',
  'ftm_hot_flashes' = 'ftm_hot_flashes',
  'ftm_hysterectomy' = 'ftm_hysterectomy',
  'ftm_insomnia' = 'ftm_insomnia',
  'ftm_joint_pain' = 'ftm_joint_pain',
  'ftm_memory_loss' = 'ftm_memory_loss',
  'ftm_mental_confusion' = 'ftm_mental_confusion',
  'ftm_metabolic_syndrome' = 'ftm_metabolic_syndrome',
  'ftm_migraines' = 'ftm_migraines',
  'ftm_mood_irritability_changes' = 'ftm_mood_irritability_changes',
  'ftm_night_sweats' = 'ftm_night_sweats',
  'ftm_oophorectomy_bilateral' = 'ftm_oophorectomy_bilateral',
  'ftm_oophorectomy_unilateral' = 'ftm_oophorectomy_unilateral',
  'ftm_ovarian_cancer' = 'ftm_ovarian_cancer',
  'ftm_pcos' = 'ftm_pcos',
  'ftm_postmenopausal' = 'ftm_postmenopausal',
  'ftm_premenopausal' = 'ftm_premenopausal',
  'ftm_renal_disease' = 'ftm_renal_disease',
  'ftm_swelling' = 'ftm_swelling',
  'ftm_vaginal_dryness' = 'ftm_vaginal_dryness',
  'ftm_weight_gain' = 'ftm_weight_gain',

  'mtf_active_liver_disease' = 'mtf_active_liver_disease',
  'mtf_bph' = 'mtf_bph',
  'mtf_colon_cancer' = 'mtf_colon_cancer',
  'mtf_decreased_libido' = 'mtf_decreased_libido',
  'mtf_depression' = 'mtf_depression',
  'mtf_diabetes' = 'mtf_diabetes',
  'mtf_fatigue' = 'mtf_fatigue',
  'mtf_hepatits_c' = 'mtf_hepatits_c',
  'mtf_hiv' = 'mtf_hiv',
  'mtf_hypertension' = 'mtf_hypertension',
  'mtf_joint_pain' = 'mtf_joint_pain',
  'mtf_loss_erectile_capacity' = 'mtf_loss_erectile_capacity',
  'mtf_memory_loss' = 'mtf_memory_loss',
  'mtf_mental_confusion' = 'mtf_mental_confusion',
  'mtf_metabolic_syndrome' = 'mtf_metabolic_syndrome',
  'mtf_mood_irritability_changes' = 'mtf_mood_irritability_changes',
  'mtf_prostate_cancer' = 'mtf_prostate_cancer',
  'mtf_renal_disease' = 'mtf_renal_disease',
  'mtf_slow_urine_flow' = 'mtf_slow_urine_flow',
  'mtf_testicular_cancer' = 'mtf_testicular_cancer',
  'mtf_weight_gain' = 'mtf_weight_gain',
}
