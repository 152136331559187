import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConfig } from '@sp-core/api-config';

@Injectable({
  providedIn: 'root',
})
export class HttpLabsService {
  constructor(private http: HttpClient) {}

  public getListLabs(httpParams: HttpParamsOptions, params: ApiParams): Observable<ITableApiData<ILabDrawApi>> {
    return this.http.get<ITableApiData<ILabDrawApi>>(`${ApiConfig.patientPath}/${params.patientId}/lab_draws`, {
      params: httpParams,
    });
  }

  public getLabsConfig(genderId: string, paramsApi?: { [key: string]: any }): Observable<ISeparateLabsDataApi> {
    return this.http.get<ISeparateLabsDataApi>(`${ApiConfig.labConfigPath}/${genderId}`, {
      params: paramsApi,
    });
  }

  public createLabs(labs: ISeparateLabsFormDataApi, patientId: string): Observable<ISeparateLabsDrawApi> {
    return this.http.post<ISeparateLabsDrawApi>(`${ApiConfig.patientPath}/${patientId}/lab_draws`, labs);
  }

  public getLabDraw(patientId: string, labDrawId: string, paramsApi?: ApiParams): Observable<ISeparateLabsDrawApi> {
    return this.http.get<ISeparateLabsDrawApi>(
      `${ApiConfig.patientPath}/${patientId}/lab_draws/${labDrawId}`,
      paramsApi ? { params: paramsApi } : {},
    );
  }

  public updateLabsDraw(labs: ISeparateLabsFormDataApi, patientId: string, labsDrawId: string): Observable<any> {
    return this.http.patch(`${ApiConfig.patientPath}/${patientId}/lab_draws/${labsDrawId}`, labs);
  }

  public getUsedDates(patientId: string): Observable<LabsUsedDateApiResponse> {
    return this.http.get<LabsUsedDateApiResponse>(`${ApiConfig.patientPath}/${patientId}/lab_draws/used_dates`);
  }

  public getLabsDrawDates(patientId: string): Observable<LabsUsedDateApiResponse> {
    return this.http.get<LabsUsedDateApiResponse>(`${ApiConfig.patientPath}/${patientId}/lab_draws/list`);
  }
}
