import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@sp-environments/environment';
import { seCookiesItem } from '@sp-helpers/set-cokies-item';

enum CookiesKeys {
  role = 'role',
  token = 'token',
}

@Injectable({
  providedIn: 'root',
})
export class CookiesStorageService {
  constructor(private cookieService: CookieService) {}

  public set token(token: string) {
    this.set(CookiesKeys.token, token, 365);
  }

  public setRememberMeHash(hash: string, email: string) {
    this.set(`${email}.rememberMeHash`, hash, 15);
  }

  public getRememberMeHash(email: string): string {
    return this.cookieService.get(`${email}.rememberMeHash`);
  }

  public clearRememberMeHash(email: string): void {
    this.cookieService.delete(`${email}.rememberMeHash`, null);
  }

  public get token(): string {
    return this.cookieService.get(CookiesKeys.token);
  }

  public clearToken(): void {
    this.cookieService.delete(CookiesKeys.token, null);
  }

  public clearAuthCookies(): void {
    this.cookieService.delete(CookiesKeys.role, null);
    this.cookieService.delete(CookiesKeys.token, null);
  }

  public get role(): IRole | null {
    const role = this.cookieService.get(CookiesKeys.role);
    return role ? JSON.parse(role) : null;
  }

  public set role(data: IRole) {
    if (!data) {
      this.clearAuthCookies();
      return;
    }
    this.set(CookiesKeys.role, JSON.stringify(data), 365);
  }

  private set(name: string, value: string, expires: number) {
    // TODO: change it, when ngx-cookies library fix problem setting in localchost
    if (environment.production) {
      this.cookieService.set(name, value, expires, '/', null, true);
    } else {
      seCookiesItem(name, value, expires, null, null, null);
    }
  }
}
