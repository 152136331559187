<div class="container-fluid bg-footer" *ngIf="includeRoute()">
  <section class="container">
    <div class="row">
      <div class="col col-no-padding">
        <footer class="footer" *ngIf="user" spQaId="footer-container_footer">
          <ng-content select="[footer-nav-menu]"></ng-content>
          <p class="disclaimer-p">
          <span>
          {{'DISCLAIMER' | translate}}
        </span>
          </p>
          <p class="disclaimer-p">
            {{'USE_OF_WEBSITE' | translate}}
            <a class="disclaimer-link" routerLink="/terms-and-conditions/sottopelle">
              {{'PRIVACY_POLICY' | translate}}.
            </a>
            {{'DISCLAIMER_TWO' | translate}}
            <a class="disclaimer-link" (click)="openDialogPatent()">
              {{'PATENT_PAGE' | translate}}.
            </a>
          </p>
          <p class="disclaimer-p">
          <span>
            {{'DISCLAIMER_HIPAA_ONE' | translate}}
            <a class="disclaimer-link" routerLink="/terms-and-conditions/hipaa/privacy">
              {{'DISCLAIMER_HIPAA_TWO' | translate}}
            </a>
            {{'DISCLAIMER_HIPAA_THREE' | translate}}
            <a class="disclaimer-link" routerLink="/terms-and-conditions/hipaa/security">
              {{'DISCLAIMER_HIPAA_FOUR' | translate}}
            </a>
            {{'DISCLAIMER_HIPAA_FIVE' | translate}}
          </span>
          </p>
          <p class="text-center">
            {{'NON_ACTIVE_USER' | translate}}
          </p>
          <p class="text-center" spQaId="footer-container_p_copyright">
            {{'NON_ACTIVE_USER_TWO' | translate}}
          </p>
        </footer>
        <footer class="footer" *ngIf="!user">
          <p class="text-center">
            {{'NON_ACTIVE_USER' | translate}}
          </p>
          <p class="text-center" spQaId="footer-container_p_copyright">
            {{'NON_ACTIVE_USER_TWO' | translate}}
          </p>
        </footer>
      </div>
    </div>
  </section>
</div>

