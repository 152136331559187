import { IUserNavMenu } from '@sp-shared/components/user-nav-menu/models/user-nav-menu.interface';

export const userNavMenuConfig: IUserNavMenu[] = [
  {
    hoverIcon: 'assets/images/settings-white.svg',
    icon: 'assets/images/settings.svg',
    name: 'NAV.MY_SETTINGS',
    link: 'settings',
    whoCanView: ['profileGet'],
    action: 'route',
    key: 'settings',
  },
  {
    hoverIcon: 'assets/images/bag-hover.svg',
    icon: 'assets/images/bag.svg',
    name: 'NAV.ORDER',
    link: '',
    whoCanView: ['dashboard'],
    action: 'openOrderPopup',
    key: 'order',
  },
  {
    hoverIcon: 'assets/images/phone-white.svg',
    icon: 'assets/images/phone.svg',
    name: 'NAV.CONTACT_US',
    link: 'contact-us',
    whoCanView: ['contactUsGet'],
    action: 'route',
    key: 'contact-us',
  },
  // {
  //   hoverIcon: 'assets/images/search-white.svg',
  //   icon: 'assets/images/search.svg',
  //   name: 'NAV.HELP',
  //   link: 'https://dosaggio.co/contact',
  //   whoCanView: ['menuHelp'],
  //   action: 'externalLink',
  //   key: 'help',
  // },
  {
    hoverIcon: 'assets/images/user-role-white-top-menu.svg',
    icon: 'assets/images/user-role-blue.svg',
    name: '',
    link: '',
    whoCanView: ['profileGet'],
    action: 'dropdownRole',
  },
  {
    hoverIcon: 'assets/images/logout-white.svg',
    icon: 'assets/images/logout.svg',
    name: 'LOGOUT',
    link: '',
    whoCanView: ['profileGet'],
    action: 'logout',
    key: 'logout',
  },
];
