import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@sp-core/api-config';
import { NotificationService } from '@sp-core-services';
import { catchError } from 'rxjs/operators';
import { ServerErrorModel } from '@sp-core/models/app-models/server-error.model';
import { ServerStatusCode } from '@sp-core/agreement-keys/server-status-code.enum';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes(ApiConfig.baseAPIPath)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        let serverError: ServerErrorModel;

        if (this.isBlobError(errorResponse)) {
          this.parseErrorBlob(errorResponse.error).subscribe((err) => {
            const mapErrorResponse = {
              ...errorResponse,
              error: JSON.parse(err),
            };
            serverError = new ServerErrorModel(mapErrorResponse);
            this.commonErrorFlow(serverError);
          });
        } else {
          serverError = new ServerErrorModel(errorResponse);
          this.commonErrorFlow(serverError);
        }
        return throwError(serverError);
      }),
    );
  }

  private commonErrorFlow(serverError: ServerErrorModel): void {
    switch (true) {
      case serverError.status === ServerStatusCode.commonError:
      case serverError.status === ServerStatusCode.authorizeError:
      case serverError.status === ServerStatusCode.forbiddenError:
      case serverError.status === ServerStatusCode.notFoundError:
        this.showServerErrorMessage(serverError?.message);
        break;
      case serverError.status >= 500 && serverError.status < 600: // 5xx errors
        if (serverError.message) {
          console.error(serverError.message);
        }
        this.showServerErrorMessage(serverError.message);
        break;
      default:
        break;
    }
  }

  private isBlobError(err: any) {
    return err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === 'application/json';
  }

  private parseErrorBlob(blob: Blob): Observable<any> {
    return new Observable((obs) => {
      if (!(blob instanceof Blob)) {
        obs.error(new Error('`blob` must be an instance of File or Blob.'));
        return;
      }

      const reader = new FileReader();
      reader.onerror = (err) => obs.error(err);
      reader.onabort = (err) => obs.error(err);
      reader.onload = () => obs.next(reader.result);
      reader.onloadend = () => obs.complete();
      reader.readAsText(blob);
    });
  }

  private showServerErrorMessage(message = 'Something went wrong.'): void {
    this.notificationService.showNotification(message, 'warning');
  }
}
