import { Injectable } from '@angular/core';
import { HttpActivityService } from '@sp-core/http-services/http-activity/http-activity.service';
import { ActivityHttpAdapterService } from '@sp-core/http-adapters/activity-http-adapter.service';
import { ITableService } from '@sp-shared/components/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MetaHttpAdapter } from '@sp-core/http-adapters/meta-http-adapter';

@Injectable({
  providedIn: 'root',
})
export class ActivityService implements ITableService {
  constructor(
    public httpActivityService: HttpActivityService,
    public activityHttpAdapter: ActivityHttpAdapterService,
  ) {}

  public getTableData(params: HttpParamsOptions): Observable<ITableData<IActivity>> {
    return this.httpActivityService.getActivities(params).pipe(
      map((data) => ({
        data: data.data.map((activity) => this.activityHttpAdapter.transformToActivity(activity)),
        meta: MetaHttpAdapter.transformToMeta(data.meta),
      })),
    );
  }

  public getChangLog(activityId): Observable<IChangeLog[]> {
    return this.httpActivityService
      .getChangLog(activityId)
      .pipe(map((data) => this.activityHttpAdapter.transformToChangeLog(data.data)));
  }

  public trackActivityEvent(eventName: string): Observable<void> {
    const eventData = this.activityHttpAdapter.transformToActivityEventApi(eventName);
    return this.httpActivityService.trackActivityEvent(eventData);
  }

  public getActivitiesTypes(): Observable<IActivityType[]> {
    return this.httpActivityService
      .getActivitiesTypes()
      .pipe(map((data) => data.map((item) => this.activityHttpAdapter.transformToActivityType(item))));
  }
}
