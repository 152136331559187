import { AnnouncementsPopupGenerator } from './announcements-popup-generator';

export default class AnnouncementsPopupFactoryGeneratorService {
  public static createGenerator(type: string, attributes: IAnnouncementListPopup[]): AnnouncementsPopupGenerator {
    switch (type) {
      case 'generatorAnnouncementsPopups': {
        return AnnouncementsPopupGenerator.getInstance(attributes);
      }
      default: {
        throw new Error(
          `Warning FactoryGeneratorService correct type now only generatorCalcRecommendedPopups; Passed type is ${type}`,
        );
      }
    }
  }
}
