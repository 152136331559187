import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private token$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public set accessToken(token: string) {
    this.token$.next(token);
  }

  public get accessToken(): string {
    return this.token$.getValue();
  }

  public get accessToken$(): Observable<string> {
    return this.token$.asObservable();
  }
}
