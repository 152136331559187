<sp-auth-layout>
  <h2 auth-server-error class="auth-sub-title text-center">
    {{'AUTH.REQUIRED_CHANGE_PASSWORD' | translate}}&nbsp;
    <p>{{'AUTH.PLEASE_CHANGE_YOUR_PASSWORD_FOR_HAVING_ACCESS' | translate}}</p>
  </h2>
  <div auth-server-error class="col-12 justify-content-center">
    <div class="sign-in-form-control">
      <mat-error class="token-error text-center" *ngIf="serverError">{{serverError}}</mat-error>
    </div>
  </div>

  <div class="row justify-content-md-center">
    <div class="col-12 col-sm-12 col-md-6">
      <sp-required-change-password-form [requiredChangePasswordForm]="requiredChangePasswordForm"
                                        [isSend]="isSend"
                                        (wasSubmitted)="onSubmit()">
      </sp-required-change-password-form>
    </div>
  </div>
  <div class="row justify-content-md-center auth-m-b">
    <div class="col-12 col-sm-12 col-md-6">
      <div class="d-flex justify-content-center">
        <a class="link-blue text-center" [routerLink]="'/sign-in'">
          {{'AUTH.LOGIN' | translate}}
        </a>
      </div>
    </div>
  </div>
</sp-auth-layout>
