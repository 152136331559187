import moment from 'moment';
import { Moment } from 'moment';

import { DateTime } from 'luxon';

export default class DateUtils {
  public static add(date: DateTime = DateTime.local(), time: number, unit: string, format?: string): string {
    return format ? date.plus({ [unit]: time }).toFormat(format) : date.plus({ [unit]: time }).toISODate();
  }

  public static addDays(date: DateTime = DateTime.local(), days: number, format?: string): string {
    return DateUtils.add(date, days, 'days', format);
  }

  public static format(time?: moment.MomentInput, format?: string): string {
    return moment(time).format(format);
  }

  public static isSame(
    time1?: moment.MomentInput,
    time2?: moment.MomentInput,
    granularity?: moment.unitOfTime.StartOf,
  ): boolean {
    return moment(time1).isSame(time2, granularity);
  }

  public static diff(
    time1: moment.MomentInput,
    time2: moment.MomentInput,
    unitOfTime?: moment.unitOfTime.Diff,
  ): number {
    return moment(time1).diff(time2, unitOfTime);
  }

  public static diffDays(time1: moment.MomentInput, time2: moment.MomentInput): number {
    const diffDays = DateUtils.diff(time1, time2, 'day');
    return diffDays <= 0 ? 0 : diffDays;
  }

  public static diffWeeks(time1: moment.MomentInput, time2: moment.MomentInput): number {
    const DAYS_IN_WEEK = 7;
    const MIDDLE_OF_WEEK = 3;
    const diffDays = DateUtils.diff(time1, time2, 'day');
    if (diffDays <= 0) {
      return 0;
    }
    const remainderDays = diffDays % DAYS_IN_WEEK;
    const roundedWeeks = remainderDays > MIDDLE_OF_WEEK ? 1 : 0;
    return (diffDays - remainderDays) / DAYS_IN_WEEK + roundedWeeks;
  }

  public static formatDateToApi(date: moment.MomentInput): string {
    return date ? DateUtils.format(date, 'L') : null;
  }

  public static calculateAge(date: moment.MomentInput): number {
    const birthday: Moment = moment(date);
    const checkFormatAndExistingDate = birthday && birthday.format && !Number.isNaN(birthday.valueOf());
    return checkFormatAndExistingDate ? moment().diff(birthday, 'years') : 0;
  }
}
