import { CommonGenerator } from '@sp-shared/modules/form-conditions/common-generator';

export class AnnouncementsPopupGenerator extends CommonGenerator<IAnnouncementGeneratorData, IAnnouncementListPopup[]> {
  constructor(protected attributes: IAnnouncementListPopup[]) {
    super(attributes);
    if (AnnouncementsPopupGenerator.instance) {
      throw new Error('Instantiation failed: use AnnouncementsPopupGenerator.getInstance() instead of new.');
    }
    AnnouncementsPopupGenerator.instance = this;
  }

  public static getInstance(attributes: IAnnouncementListPopup[]): AnnouncementsPopupGenerator {
    if (AnnouncementsPopupGenerator.instance) {
      AnnouncementsPopupGenerator.instance.attributes = attributes;
      return AnnouncementsPopupGenerator.instance;
    }
    AnnouncementsPopupGenerator.instance = new AnnouncementsPopupGenerator(attributes);
    return AnnouncementsPopupGenerator.instance;
  }

  public createGenerator(array: IAnnouncementListPopup[]): Generator<IAnnouncementGeneratorData> {
    function* generateSequence(): Generator<IAnnouncementGeneratorData> {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < array.length; i++) {
        yield {
          ...array[i],
          index: i + 1,
          length: array.length,
        };
      }
    }
    this.obgGenerator = generateSequence();
    return this.obgGenerator;
  }

  public removeInstance(): void {
    AnnouncementsPopupGenerator.instance = null;
  }
}
