import { IPracticeForm } from '@sp-core/models/app-models/practice-form-interface';
import { Injectable } from '@angular/core';
import DateUtils from '@sp-helpers/date-time/date-utils';
import TransformServerValidationUtils from '@sp-helpers/validation/transform-server-validation-utils';

@Injectable({
  providedIn: 'root',
})
export class PracticeHttpAdapter {
  public transformToPractice(data: IPracticeApi): IPractice {
    if (!data) {
      return null;
    }
    return {
      id: data.id,
      isActive: data.is_active,
      name: data.name,
      email: data.email,
      address: data.address,
      phoneNumber: data.phone_number,
      owner: data.owner,
      website: data.website,
      region: this.transformToState(data.region),
      country: this.transformToCountry(data.country),
      city: data.city,
      zip: data.zip,
      phonePrefix: data.phone_prefix,
      dueDate: data.due_date,
      distance: data?.distance,
    };
  }

  public transformToSinglePracticeApi(data: IPracticeForm): ISinglePracticeApi {
    const res: ISinglePracticeApi = {
      name: data.name,
      is_active: data.isActive,
      email: data.email,
      address: data.address,
      phone_number: data.phone,
      owner: data.practiceOwner,
      website: data.website,
      country_id: data.countryId,
      region_id: data.regionId,
      city: data.city,
      zip: data.zip,
    };

    if (data?.dueDate !== undefined) {
      res.due_date = DateUtils.formatDateToApi(data?.dueDate);
    }
    if (data?.isActive !== undefined) {
      res.is_active = !!data?.isActive;
    }
    return res;
  }

  public transformToCountry(data: ICountryApi): ICountry {
    return {
      id: data.id,
      key: data.key,
      title: data.title,
    };
  }

  public transformToState(data: IRegionApi): IRegion {
    return {
      id: data.id,
      title: data.title,
    };
  }

  public transformToPracticeForm(practice: IPractice): IPracticeForm {
    return {
      name: practice.name,
      countryId: practice.country.id,
      regionId: practice.region.id,
      city: practice.city,
      zip: practice.zip,
      phone: practice.phoneNumber,
      address: practice.address,
      email: practice.email,
      website: practice.website,
      practiceOwner: practice.owner,
      id: practice.id,
      isActive: practice.isActive,
      dueDate: practice.dueDate,
    };
  }

  public transformToPracticeFormError(errors: IServerValidationErrors): IServerValidationErrors {
    const result = {};
    const listKeys = (key) => {
      const keys = {
        name: 'name',
        address: 'address',
        country_id: 'countryId',
        region_id: 'regionId',
        due_date: 'dueDate',
        zip: 'zip',
        phone: 'phone',
        city: 'city',
      };

      return keys[key] ?? key;
    };

    Object.keys(errors).forEach((errorKey) => {
      result[listKeys(errorKey)] = TransformServerValidationUtils.getFirsError(errors[errorKey]);
    });
    return result;
  }
}
