import { Injectable } from '@angular/core';
import { ITableService } from '@sp-shared/components/table/data-source/table-service-interface';
import { Observable, throwError } from 'rxjs';
import { HttpPracticeService } from '@sp-core/http-services/http-practice.service';
import { catchError, map } from 'rxjs/operators';
import { PracticeHttpAdapter } from '@sp-core/http-adapters/practice-http-adapter';
import { MetaHttpAdapter } from '@sp-core/http-adapters/meta-http-adapter';
import { IPracticeForm } from '@sp-core/models/app-models/practice-form-interface';
import { ServerStatusCode } from '@sp-core/agreement-keys/server-status-code.enum';
import { ServerErrorModel } from '@sp-core/models/app-models/server-error.model';

@Injectable({
  providedIn: 'root',
})
export class PracticeService implements ITableService<IPractice> {
  constructor(private httpPractice: HttpPracticeService, private practiceHttpAdapter: PracticeHttpAdapter) {}

  public getTableData(params: HttpParamsOptions): Observable<ITableData<IPractice>> {
    return this.httpPractice.getListPractices(params).pipe(
      map((data) => ({
        data: data.data.map((practice) => this.practiceHttpAdapter.transformToPractice(practice)),
        meta: MetaHttpAdapter.transformToMeta(data.meta),
      })),
    );
  }

  public getMessages(params: HttpParamsOptions): Observable<ITableData<IPractice>> {
    return this.httpPractice.getListPractices(params).pipe(
      map((data) => ({
        data: data.data.map((practice) => this.practiceHttpAdapter.transformToPractice(practice)),
        meta: MetaHttpAdapter.transformToMeta(data.meta),
      })),
    );
  }

  public createPractice(practice: IPracticeForm): Observable<any> {
    const sendData: ISinglePracticeApi = this.practiceHttpAdapter.transformToSinglePracticeApi(practice);
    return this.httpPractice.createPractice(sendData).pipe(this.backendErrorPractice());
  }

  public updatePractice(practice: IPracticeForm, id: string): Observable<any> {
    const sendData: ISinglePracticeApi = this.practiceHttpAdapter.transformToSinglePracticeApi(practice);
    return this.httpPractice.updatePractice(sendData, id).pipe(this.backendErrorPractice());
  }

  public getCountries(): Observable<ICountry[]> {
    return this.httpPractice.getCountries().pipe(map((data) => data.data));
  }

  public getRegion(countryId: string): Observable<IRegion[]> {
    return this.httpPractice.getRegion(countryId).pipe(map((data) => data.data));
  }

  public transformToPracticeForm(practice: IPractice): IPracticeForm {
    return this.practiceHttpAdapter.transformToPracticeForm(practice);
  }

  public getPractice(id: string): Observable<IPractice> {
    return this.httpPractice
      .getPractice(id)
      .pipe(map((practice) => this.practiceHttpAdapter.transformToPractice(practice)));
  }

  public getOwnPractice(): Observable<IPractice> {
    return this.httpPractice
      .getOwnPractice()
      .pipe(map((responce) => this.practiceHttpAdapter.transformToPractice(responce)));
  }

  public getOwnPracticeFormData(): Observable<IPracticeForm> {
    return this.httpPractice.getOwnPractice().pipe(
      map((responce) => this.practiceHttpAdapter.transformToPractice(responce)),
      map((practice) => this.practiceHttpAdapter.transformToPracticeForm(practice)),
    );
  }

  public initCountriesMap(countries: ICountry[]): ICountryMap {
    const result: ICountryMap = {};
    countries.forEach((country) => {
      result[country.id] = { key: country.key };
    });
    return result;
  }

  private backendErrorPractice(): any {
    return catchError((serverError: ServerErrorModel) => {
      if (serverError.status === ServerStatusCode.validationError) {
        serverError.validationErrors = this.practiceHttpAdapter.transformToPracticeFormError(
          serverError.validationErrors,
        );
      }
      return throwError(serverError);
    });
  }

  public getExportedPractice(processId: string): Observable<Blob> {
    return this.httpPractice.getBlob(processId);
  }
}
