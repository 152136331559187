import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@sp-core/api-config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpSettingsService {
  constructor(private http: HttpClient) {}

  public updateProfile(data: IChangePasswordApi | IProfileApi): Observable<IUserApi> {
    return this.http.patch<IUserApi>(ApiConfig.profilePath, data);
  }

  public getTimeZone(): Observable<IDataListApi<ITimeZoneApi>> {
    return this.http.get<IDataListApi<ITimeZoneApi>>(ApiConfig.timezonesPath);
  }

  public getDateTime(): Observable<IDataListApi<IDatetimeFormatApi>> {
    return this.http.get<IDataListApi<IDatetimeFormatApi>>(ApiConfig.dateTimePath);
  }

  public getMeasurement(): Observable<IDataListApi<IMeasurementPreferencesApi>> {
    return this.http.get<IDataListApi<IMeasurementPreferencesApi>>(ApiConfig.measurementPath);
  }

  public toggle2FA(isActive: boolean): Observable<any> {
    return this.http.patch<any>(`${ApiConfig.security2faPath}/status`, { is_enabled: isActive });
  }

  public getStatus2FA(): Observable<I2FAStatusApi> {
    return this.http.get<I2FAStatusApi>(`${ApiConfig.security2faPath}/status`);
  }
}
