import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConfig } from '@sp-core/api-config';

@Injectable({
  providedIn: 'root',
})
export class HttpConsultService {
  constructor(public readonly _http: HttpClient) {}

  public getUnassignedConsult(httpParams: HttpParamsOptions): Observable<ITableApiData<IConsultApi>> {
    return this._http.get<ITableApiData<IConsultApi>>(`${ApiConfig.baseAPIPath}consults`, { params: httpParams });
  }

  public getAssignedConsult(httpParams: HttpParamsOptions): Observable<ITableApiData<IConsultApi>> {
    return this._http.get<ITableApiData<IConsultApi>>(`${ApiConfig.baseAPIPath}consults`, { params: httpParams });
  }

  public getAssignEmployees(httpParams: HttpParamsOptions): Observable<ITableApiData<IProviderApi>> {
    return this._http.get<ITableApiData<IProviderApi>>(`${ApiConfig.employeesPath}`, { params: httpParams });
  }

  public getListMyConsultRequests(httpParams: HttpParamsOptions): Observable<ITableApiData<IMyConsultRequestApi>> {
    return this._http.get<ITableApiData<IMyConsultRequestApi>>(`${ApiConfig.consultPath}/requests`, {
      params: httpParams,
    });
  }

  public getListMyAssignedConsultRequests(httpParams: HttpParamsOptions): Observable<ITableApiData<IConsultApi>> {
    return this._http.get<ITableApiData<IConsultApi>>(`${ApiConfig.consultPath}/assignation`, { params: httpParams });
  }

  public sendConsultResponse(consultId: string, data: IConsultRequestAnswerApi): Observable<IConsultRequestAnswerApi> {
    return this._http.post<IConsultRequestAnswerApi>(`${ApiConfig.consultPath}/${consultId}/response`, data);
  }

  public resolveConsul(consultId: string): Observable<any> {
    return this._http.patch(`${ApiConfig.consultPath}/${consultId}/resolve`, {});
  }

  public reopenConsul(consultId: string): Observable<any> {
    return this._http.patch(`${ApiConfig.consultPath}/${consultId}/reopen`, {});
  }

  public replyConsult(consultId: string, data: { message: string }): Observable<any> {
    return this._http.post(`${ApiConfig.consultPath}/${consultId}/messages`, data);
  }

  public getMessages(consultId: string, httpParams: HttpParamsOptions): Observable<ITableApiData<IConsultMessageApi>> {
    return this._http.get<ITableApiData<IConsultMessageApi>>(`${ApiConfig.consultPath}/${consultId}/messages`, {
      params: httpParams,
    });
  }

  public getConsult(consultId: string): Observable<ISavedConsultApi> {
    return this._http.get<ISavedConsultApi>(`${ApiConfig.consultPath}/${consultId}`);
  }

  public createConsult(data: IConsultRequestApi): Observable<any> {
    return this._http.post(`${ApiConfig.consultPath}`, data);
  }

  public getMedicalSuggestions(): Observable<IDataListApi<IMedicationSuggestionApi>> {
    return this._http.get<IDataListApi<IMedicationSuggestionApi>>(ApiConfig.medicationSuggestionsPath);
  }

  public getCalculationByDate(patientId: string, date: string): Observable<ICalculationCommonApi> {
    return this._http.get<ICalculationCommonApi>(`${ApiConfig.patientPath}/${patientId}/calculations`, {
      params: { date },
    });
  }

  public assignConsultToUser(consultId: string, userId: string): Observable<any> {
    return this._http.patch(`${ApiConfig.baseAPIPath}consults/${consultId}/assign`, { user_id: userId });
  }

  public getOrganizationData(consultId: string): Observable<IConsultOrganizationInfoApi> {
    return this._http.get<IConsultOrganizationInfoApi>(`${ApiConfig.baseAPIPath}consults/${consultId}/info`);
  }

  public getAllConsultsList(httpParams: HttpParamsOptions): Observable<ITableApiData<IConsultAllRequestApi>> {
    return this._http.get<ITableApiData<IConsultAllRequestApi>>(`${ApiConfig.consultPath}`, {
      params: httpParams,
    });
  }
}
