import { Injectable } from '@angular/core';
import { AnnouncementsHttpAdapter } from '@sp-core/http-adapters/announcements-http-adapter';
import { HttpAnnouncementsService } from '@sp-core/http-services/http-announcements/http-announcements.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MetaHttpAdapter } from '@sp-core/http-adapters/meta-http-adapter';
import { ServerErrorModel } from '@sp-core/models/app-models/server-error.model';
import { ServerStatusCode } from '@sp-core/agreement-keys/server-status-code.enum';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementsService {
  constructor(
    public readonly _announcementsHttpAdapter: AnnouncementsHttpAdapter,
    public readonly _httpAnnouncementsService: HttpAnnouncementsService,
  ) {}

  public getTableData(params: HttpParamsOptions): Observable<ITableData<IAnnouncement>> {
    return this._httpAnnouncementsService.getAnnouncements(params).pipe(
      map((data) => ({
        data: data.data.map((item) => this._announcementsHttpAdapter.transformToAnnouncement(item)),
        meta: MetaHttpAdapter.transformToMeta(data.meta),
      })),
    );
  }

  public changeAnnouncementStatus(data: IAnnouncementChangeStatus): Observable<IAnnouncement> {
    const announcementStatusApiData = this._announcementsHttpAdapter.transformToChangeAnnouncementStatusApi(data);
    return this._httpAnnouncementsService
      .changeAnnouncementStatus(announcementStatusApiData)
      .pipe(map((res) => this._announcementsHttpAdapter.transformToAnnouncement(res)));
  }

  public deleteAnnouncement(id: string): Observable<void> {
    return this._httpAnnouncementsService.deleteAnnouncement(id);
  }

  public getListOfNewAnnouncements(): Observable<string[]> {
    return this._httpAnnouncementsService.getListOfNewAnnouncements();
  }

  public getAnnouncementById(id: string): Observable<IAnnouncement> {
    return this._httpAnnouncementsService
      .getAnnouncementById(id)
      .pipe(map((res) => this._announcementsHttpAdapter.transformToAnnouncement(res)));
  }

  public setAnnouncementAcknowledgeStatus(id: string): Observable<void> {
    return this._httpAnnouncementsService.setAnnouncementAcknowledgeStatus(id);
  }

  public createAnnouncement(data: IAnnouncementRequest): Observable<IAnnouncement> {
    const formData = this._announcementsHttpAdapter.transformToAnnouncementRequestApi(data);
    return this._httpAnnouncementsService.createAnnouncement(formData).pipe(
      map((res) => this._announcementsHttpAdapter.transformToAnnouncement(res)),
      catchError((serverError: ServerErrorModel) => {
        if (serverError.status === ServerStatusCode.validationError) {
          serverError.validationErrors = this._announcementsHttpAdapter.transformToAnnouncementFormError(
            serverError.validationErrors,
          );
        }
        return throwError(serverError);
      }),
    );
  }

  public updateAnnouncement(id: string, data: IAnnouncementRequest): Observable<IAnnouncement> {
    const formData = this._announcementsHttpAdapter.transformToAnnouncementRequestApi(data);
    return this._httpAnnouncementsService.updateAnnouncement(id, formData).pipe(
      map((res) => this._announcementsHttpAdapter.transformToAnnouncement(res)),
      catchError((serverError: ServerErrorModel) => {
        if (serverError.status === ServerStatusCode.validationError) {
          serverError.validationErrors = this._announcementsHttpAdapter.transformToAnnouncementFormError(
            serverError.validationErrors,
          );
        }
        return throwError(serverError);
      }),
    );
  }

  public replaceContentSrcToAwsLink(content: string, files: IAwsUpload[], type: string): string {
    if (files.length === 0) {
      return content;
    }
    const transformFiles = this._announcementsHttpAdapter.transformToAnnouncementsUploadFile(files, type, true);
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(content, 'text/html');

    transformFiles.forEach((file) => {
      Array.from(htmlDoc.querySelectorAll('img')).forEach((img) => {
        if (img.src.includes(file.src)) {
          img.src = file.awsLink;
          img.style.maxWidth = '100%';
        }
      });
    });

    return `${htmlDoc.body.innerHTML}`;
  }
}
