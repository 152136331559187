import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConfig } from '@sp-core/api-config';

@Injectable({
  providedIn: 'root',
})
export class HttpSiteNoteService {
  constructor(private http: HttpClient) {}

  public getSiteNoteDictionaries(): Observable<ISiteNoteDictionariesApi> {
    return this.http.get<ISiteNoteDictionariesApi>(ApiConfig.insertionSiteNoteDictionariesPath);
  }

  public initSiteNote(insertionId: string): Observable<ISiteNoteResponseApi> {
    return this.http.post<ISiteNoteResponseApi>(ApiConfig.insertionSiteNotePath, { insertion_id: insertionId });
  }

  public getSiteNotes(httpParams: HttpParamsOptions): Observable<ITableApiData<ISiteNoteItemApi>> {
    return this.http.get<ITableApiData<ISiteNoteItemApi>>(`${ApiConfig.insertionSiteNotePath}`, {
      params: httpParams,
    });
  }

  public getSiteNote(id: string): Observable<ISiteNoteResponseApi> {
    return this.http.get<ISiteNoteResponseApi>(`${ApiConfig.insertionSiteNotePath}/${id}`);
  }

  public updateSiteNote(id: string, data: ISiteNoteRequestApi): Observable<ISiteNoteResponseApi> {
    return this.http.patch<ISiteNoteResponseApi>(`${ApiConfig.insertionSiteNotePath}/${id}`, data);
  }

  public getSiteNotesReport(httpParams: HttpParamsOptions): Observable<ITableApiData<ISiteNoteReportItemApi>> {
    return this.http.get<ITableApiData<ISiteNoteReportItemApi>>(`${ApiConfig.insertionSiteNotePath}/reports`, {
      params: httpParams,
    });
  }

  public getSiteNoteExportId(exportFormat: string, httpParams: HttpParamsOptions): Observable<IExportProcessIdApi> {
    return this.http.post<IExportProcessIdApi>(
      `${ApiConfig.insertionSiteNotePath}/export`,
      {
        format: exportFormat,
      },
      {
        params: httpParams,
      },
    );
  }

  public getSiteNoteExportFile(processId: string): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(`${ApiConfig.insertionSiteNotePath}/export/${processId}`, {
      responseType: 'blob' as 'json',
      observe: 'response',
    });
  }
}
