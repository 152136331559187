import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConfig } from '@sp-core/api-config';
import { InsertionTypes } from '../../agreement-keys/insertion-types.enum';

@Injectable({
  providedIn: 'root',
})
export class HttpInsertionService {
  constructor(private http: HttpClient) {}

  public initInsertion(parentId: string, type: InsertionTypes): Observable<IInsertionInitApi> {
    return this.http.post<IInsertionInitApi>(`${ApiConfig.insertionsPath}`, { parent_id: parentId, type });
  }

  public getInsertion(insertionId: string): Observable<IInsertionApi> {
    return this.http.get<IInsertionApi>(`${ApiConfig.insertionsPath}/${insertionId}`);
  }

  public updateInsertion(insertionId: string, insertion: IPrescribeValueApi): Observable<IInsertionApi> {
    return this.http.patch<IInsertionApi>(`${ApiConfig.insertionsPath}/${insertionId}`, insertion);
  }

  public updateInsertionDate(insertionId: string, data: { date: string }): Observable<void> {
    return this.http.patch<void>(`${ApiConfig.insertionsPath}/${insertionId}/date`, data);
  }

  public getInsertionHistory(patientId: string): Observable<InsertionHistoryApi> {
    return this.http.get<InsertionHistoryApi>(`${ApiConfig.patientPath}/${patientId}/insertions/history`);
  }

  public getCerboInsertionHistory(patientId: string): Observable<InsertionHistoryApi> {
    return this.http.get<InsertionHistoryApi>(
      `${ApiConfig.patientPath}/${patientId}/cerbo_historical_insertions/table`,
    );
  }

  public getLastInsertion(patientId: string): Observable<ILastInsertionApi> {
    return this.http.get<ILastInsertionApi>(`${ApiConfig.patientPath}/${patientId}/last_insertion`);
  }

  public getPelletsData(genderId: string): Observable<IPelletsApi> {
    return this.http.get<IPelletsApi>(`${ApiConfig.calculatorPelletsPath}/gender/${genderId}`);
  }

  public getUnfinishedInsertion(patientId: string): Observable<ITableApiData<IUnfinishedInsertionItem>> {
    return this.http.get<ITableApiData<IUnfinishedInsertionItem>>(
      `${ApiConfig.patientPath}/${patientId}/insertions/unfinished`,
    );
  }

  public getInsertionHistoryExportId(
    data: IInsertionExportByParams,
    patientId: string,
    httpParams?: HttpParamsOptions,
  ): Observable<IExportProcessIdApi> {
    return this.http.post<IExportProcessIdApi>(
      `${ApiConfig.patientPath}/${patientId}/insertions/export`,
      {
        ...data,
      },
      {
        params: httpParams,
      },
    );
  }

  public getSpecifiedInsertionHistoryExportId(
    exportFormat: string,
    patientId: string,
    insertionId: string,
    httpParams?: HttpParamsOptions,
  ): Observable<IExportProcessIdApi> {
    return this.http.post<IExportProcessIdApi>(
      `${ApiConfig.patientPath}/${patientId}/insertions/${insertionId}/export`,
      {
        format: exportFormat,
      },
      {
        params: httpParams,
      },
    );
  }

  public getInsertionHistoryExportFile(processId: string, patientId: string): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(`${ApiConfig.patientPath}/${patientId}/insertions/export/${processId}`, {
      responseType: 'blob' as 'json',
      observe: 'response',
    });
  }
}
