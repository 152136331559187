import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpReportsService } from '@sp-core/http-services/http-reports/http-reports.service';
import { ITableService } from '@sp-shared/components/table';
import { ReportsHttpAdapter } from '@sp-core/http-adapters/reports-http-adapter';
import { MetaHttpAdapter } from '@sp-core/http-adapters/meta-http-adapter';
import { AdminReportsTablePathEnum } from '@sp-core/agreement-keys/admin-reports-table-path.enum';

@Injectable({
  providedIn: 'root',
})
export class ReportsService implements ITableService {
  constructor(
    public readonly _httpReportsService: HttpReportsService,
    public readonly _reportsHttpAdapter: ReportsHttpAdapter,
  ) {}

  public getTableData(params: HttpParamsOptions): Observable<ITableData<IAdminReports>> {
    return this._httpReportsService.getListReports(params).pipe(
      map((data) => ({
        data: data.data.map((item) => this._reportsHttpAdapter.transformToReport(item)),
        meta: MetaHttpAdapter.transformToMeta(data.meta),
      })),
    );
  }

  public exportTable(exportFormat: string, filters: HttpParamsOptions): Observable<IExportProcessId> {
    return this._httpReportsService
      .getExportProcessId(exportFormat, filters)
      .pipe(map((res: IExportProcessIdApi) => this._reportsHttpAdapter.transformToProcessId(res)));
  }

  public exportPharmacy(exportFormat: string, filters: HttpParamsOptions): Observable<IExportProcessId> {
    return this._httpReportsService
      .getExportPharmacyProcessId(exportFormat, filters)
      .pipe(map((res: IExportProcessIdApi) => this._reportsHttpAdapter.transformToProcessId(res)));
  }

  public getExportedTable(processId: string): Observable<Blob> {
    return this._httpReportsService.getBlob(processId);
  }

  public getPharmacyExportedTable(processId: string): Observable<Blob> {
    return this._httpReportsService.getPharmacyBlob(processId);
  }

  public getReportsFrequencies(): Observable<IAdminReportFrequencies[]> {
    return this._httpReportsService
      .getReportsFrequencies()
      .pipe(map((data) => data.data.map((item) => this._reportsHttpAdapter.transformToFrequencies(item))));
  }

  public getReportsPractices(params?: HttpParamsOptions): Observable<ITableData<IAdminReportPractices>> {
    return this._httpReportsService.getReportsPractices(params).pipe(
      map((data) => ({
        data: data.data.map((item) => item),
        meta: MetaHttpAdapter.transformToMeta(data.meta),
      })),
    );
  }

  public getReportsProviders(
    filterData: IAdminReportProviderFilterRequestData,
    params?: HttpParamsOptions,
  ): Observable<ITableData<IAdminReportProviders>> {
    const filterApiData: IAdminReportProviderFilterRequestDataApi =
      this._reportsHttpAdapter.transformToFilterApiData(filterData);
    return this._httpReportsService.getReportsProviders(filterApiData, params).pipe(
      map((data) => ({
        data: data.data.map((item) => this._reportsHttpAdapter.transformToReportProviders(item)),
        meta: MetaHttpAdapter.transformToMeta(data.meta),
      })),
    );
  }

  public createSingleReporting(
    data: ISingleReport,
    type: AdminReportsTablePathEnum,
  ): Observable<ISingleReportResponse> {
    const transformData = this._reportsHttpAdapter.transformSingleReportToApi(data);
    return this._httpReportsService
      .createSingleReport(transformData, type)
      .pipe(map((res) => this._reportsHttpAdapter.transformToSingleReport(res)));
  }

  public createRecurringReport(
    data: IRecurringReport,
    type: AdminReportsTablePathEnum,
  ): Observable<IRecurringReportResponse> {
    const transformData = this._reportsHttpAdapter.transformRecurringReportToApi(data);
    return this._httpReportsService
      .createRecurringReport(transformData, type)
      .pipe(map((res) => this._reportsHttpAdapter.transformToRecurringReport(res)));
  }

  public getRecurringReport(id: string): Observable<IRecurringReportResponse> {
    return this._httpReportsService
      .getRecurringReport(id)
      .pipe(map((res) => this._reportsHttpAdapter.transformToRecurringReport(res)));
  }

  public editRecurringReport(id: string, data: IRecurringReport): Observable<IRecurringReportResponse> {
    const transformData = this._reportsHttpAdapter.transformRecurringReportToApi(data);
    return this._httpReportsService
      .editRecurringReport(id, transformData)
      .pipe(map((res) => this._reportsHttpAdapter.transformToRecurringReport(res)));
  }

  public deleteReport(id: string): Observable<void> {
    return this._httpReportsService.deleteReport(id);
  }
}
