import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ITableService } from '@sp-shared/components/table';
import { MetaHttpAdapter } from '../../http-adapters/meta-http-adapter';
import { HttpStickyNotesService } from '../../http-services/http-sticky-notes/http-sticky-notes.service';
import { StickyNotesServiceHttpAdapter } from '../../http-adapters/sticky-notes-http-adapter';

@Injectable({
  providedIn: 'root',
})
export class StickyNotesService implements ITableService<IStickyNote> {
  constructor(
    private httpStickyNotesService: HttpStickyNotesService,
    private stickyNotesServiceHttpAdapter: StickyNotesServiceHttpAdapter,
  ) {}

  public getTableData(params: HttpParamsOptions): Observable<ITableData<IStickyNote>> {
    return this.httpStickyNotesService.getStickyNotes(params).pipe(
      map((data) => ({
        data: data.data.map((item) => this.stickyNotesServiceHttpAdapter.transformToStickyNote(item)),
        meta: MetaHttpAdapter.transformToMeta(data.meta),
      })),
    );
  }

  public getStickyNotes(context: IStickyNoteContext): Observable<IStickyNote[]> {
    const params = this.stickyNotesServiceHttpAdapter.transformContextToQueryParams(context);
    return this.httpStickyNotesService
      .getStickyNotes(params)
      .pipe(map((data) => data.data.map((item) => this.stickyNotesServiceHttpAdapter.transformToStickyNote(item))));
  }

  public getStickyNote(id: string): Observable<IStickyNote> {
    return this.stickyNoteRequest(this.httpStickyNotesService.getStickyNote(id));
  }

  public createStickyNote(data: IStickyNote): Observable<IStickyNote> {
    const sendData = this.stickyNotesServiceHttpAdapter.transformToStickyNoteRequest(data);
    return this.stickyNoteRequest(this.httpStickyNotesService.createStickyNote(sendData));
  }

  public updateStickyNote(data: IStickyNote, id: string): Observable<IStickyNote> {
    const sendData = this.stickyNotesServiceHttpAdapter.transformToStickyNoteUpdateRequest(data);
    return this.stickyNoteRequest(this.httpStickyNotesService.updateStickyNote(sendData, id));
  }

  public updateStickyNoteCoordinates(data: IStickyNote, id: string): Observable<IStickyNote> {
    const sendData = this.stickyNotesServiceHttpAdapter.transformToStickyNoteCoordinatesRequest(data.coordinates);
    return this.stickyNoteRequest(this.httpStickyNotesService.updateStickyNoteCoordinates(sendData, id));
  }

  public updateStickyNoteColor(color: string, id: string): Observable<IStickyNote> {
    return this.stickyNoteRequest(this.httpStickyNotesService.updateStickyNoteColor(color, id));
  }

  public deleteStickyNote(id: string): Observable<void> {
    return this.httpStickyNotesService.deleteStickyNote(id);
  }

  public minimizeStickyNote(id: string): Observable<void> {
    return this.httpStickyNotesService.minimizeStickyNote(id);
  }

  public createStickyNoteComment(
    stickyNoteId: string,
    data: IStickyNoteCommentRequestApi,
  ): Observable<IStickyNoteComment> {
    return this.stickyNoteCommentRequest(this.httpStickyNotesService.createStickyNoteComment(stickyNoteId, data));
  }

  public updateStickyNoteComment(
    stickyNoteId: string,
    id: string,
    data: IStickyNoteCommentRequestApi,
  ): Observable<IStickyNoteComment> {
    return this.stickyNoteCommentRequest(this.httpStickyNotesService.updateStickyNoteComment(stickyNoteId, id, data));
  }

  public deleteStickyNoteComment(stickyNoteId: string, id: string): Observable<void> {
    return this.httpStickyNotesService.deleteStickyNoteComment(stickyNoteId, id);
  }

  private stickyNoteRequest(request: Observable<IStickyNoteResponseApi>): Observable<IStickyNote> {
    return request.pipe(map((response) => this.stickyNotesServiceHttpAdapter.transformToStickyNote(response)));
  }

  private stickyNoteCommentRequest(request: Observable<IStickyNoteCommentApi>): Observable<IStickyNoteComment> {
    return request.pipe(map((response) => this.stickyNotesServiceHttpAdapter.transformToStickyNoteComment(response)));
  }
}
