import { Injectable } from '@angular/core';
import { ITableService } from '@sp-shared/components/table';
import { HttpPermissionsService } from '@sp-core/http-services/http-permissions/http-permissions.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MetaHttpAdapter } from '@sp-core/http-adapters/meta-http-adapter';
import { RolesPermissionsAdapter } from '@sp-core/http-adapters/roles-permissions-adapter';
import { CookiesStorageService } from '@sp-core/services';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService implements ITableService<IPermissions> {
  private currentRole$: BehaviorSubject<IRole> = new BehaviorSubject<IRole>(null);
  private currentPermissions$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private httpPermissionsService: HttpPermissionsService,
    private rolesPermissionsAdapter: RolesPermissionsAdapter,
    private cookiesStorage: CookiesStorageService,
  ) {}

  public clearAuthData(): void {
    this.setAuthData(null, null);
  }

  public setAuthData(role: IRole, permission: any): void {
    this.currentRole$.next(role);
    this.currentPermissions$.next(permission);
    this.cookiesStorage.role = role;
  }

  public updateRole(role: IRole): Observable<IRole> {
    return this.getPermissions(role.id).pipe(
      tap((permission) => {
        this.setAuthData(role, permission);
      }),
    );
  }

  public set role(role: IRole) {
    this.currentRole$.next(role);
  }

  public get role(): IRole {
    return this.currentRole$.getValue();
  }

  public get role$(): Observable<IRole> {
    return this.currentRole$.asObservable();
  }

  public get permissions(): Map<string, IPermissions> {
    return this.currentPermissions$.getValue();
  }

  public get permissions$(): Observable<Map<string, IPermissions>> {
    return this.currentPermissions$.asObservable();
  }

  public checkPermissions(data: string[]): boolean {
    return data.some((permission) => this.permissions.has(permission));
  }

  public getTableData(params: HttpParamsOptions, apiParams: ApiParams): Observable<ITableData<IPermissions>> {
    return this.httpPermissionsService.getPermission(params, apiParams).pipe(
      map((data) => ({
        data: data.data.map((item) => this.rolesPermissionsAdapter.transformToPermissions(item)),
        meta: MetaHttpAdapter.transformToMeta(data.meta),
      })),
    );
  }

  public getPermissions(id: number): Observable<any> {
    return this.httpPermissionsService.getPermissions(id).pipe(
      map((response) => {
        const mapPermissions = new Map();
        response.data.forEach((permission) => {
          mapPermissions.set(permission?.key, permission);
        });

        return mapPermissions;
      }),
    );
  }
}
