import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CanViewModule } from '@sp-shared/directives/can-view/can-view.module';
import { ConfirmPopupModule } from '@sp-shared/containers/confirm-popup/confirm-popup.module';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { QaModule } from '@sp-shared/directives/data-cy/qa.module';
import { NavComponent } from './nav.component';
import { ToggleDirective } from './directives/toggle.directive';
import { BadgeModule } from '@sp-shared/components/badge/badge.module';
import { OrderInfoPopupModule } from '@sp-shared/components/order-info-popup/order-info-popup.module';

@NgModule({
  declarations: [NavComponent, ToggleDirective],
  exports: [NavComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    CanViewModule,
    ConfirmPopupModule,
    FormsModule,
    MatMenuModule,
    MatIconModule,
    QaModule,
    BadgeModule,
    OrderInfoPopupModule,
  ],
})
export class NavModule {}
