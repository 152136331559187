import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QaDirective } from './qa.directive';

@NgModule({
  declarations: [QaDirective],
  exports: [QaDirective],
  imports: [CommonModule],
})
export class QaModule {}
