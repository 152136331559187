import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConfig } from '@sp-core/api-config';

@Injectable({
  providedIn: 'root',
})
export class AuthHttpService {
  constructor(private http: HttpClient) {}

  public signUp(data: ISignUpApi): Observable<any> {
    return this.http.post<any>(`${ApiConfig.signUpPath}`, data);
  }

  public getEmailByInvitationToken(httpParams: HttpParams): Observable<IInvitationApi> {
    return this.http.get<IInvitationApi>(`${ApiConfig.inviteTokenPath}`, {
      params: httpParams,
    });
  }

  public confirmResetPasswordToken(httpParams: HttpParams): Observable<IResetPasswordTokenApi> {
    return this.http.get<IResetPasswordTokenApi>(ApiConfig.resetPasswordTokenPath, {
      params: httpParams,
    });
  }

  public forgotPassword(data: { email: string; link: string }): Observable<any> {
    return this.http.post<ISignInApi>(ApiConfig.forgotPasswordPath, data);
  }

  public signIn(data: ISignIn): Observable<ISignInApi> {
    return this.http.post<ISignInApi>(ApiConfig.signInPath, { ...data });
  }

  public getCurrentUser(): Observable<IUserApi> {
    return this.http.get<IUserApi>(ApiConfig.userPath);
  }

  public resetPassword(data: IResetPasswordApi): Observable<null> {
    return this.http.post<null>(ApiConfig.resetPasswordPath, data);
  }

  public logOut(): Observable<any> {
    return this.http.post<any>(ApiConfig.logoutPath, {});
  }

  public checkSessionUser(data: ISignIn): Observable<ISessionsCheckApi> {
    return this.http.post<ISessionsCheckApi>(ApiConfig.checkSessionsPath, data);
  }

  public getUserType2FANonLogin(data: ISignIn): Observable<ITwoFactorAuthorizationApi> {
    return this.http.post<ITwoFactorAuthorizationApi>(ApiConfig.security2faPath, data);
  }

  public changeUserType2FA(data: { type_id: string }): Observable<any> {
    return this.http.patch(ApiConfig.security2faPath, data);
  }

  public getUserType2FA(): Observable<ITwoFactorAuthorizationTypeApi> {
    return this.http.get<ITwoFactorAuthorizationTypeApi>(ApiConfig.security2faPath);
  }

  public sendAuthCode(data: ISignIn): Observable<any> {
    return this.http.post(`${ApiConfig.security2faPath}/code`, data);
  }

  public checkExpiredPassword(data: ISignIn): Observable<ICheckExpiredPasswordApi> {
    return this.http.post<ICheckExpiredPasswordApi>(ApiConfig.checkPasswordPath, data);
  }

  public requiredChangPassword(data: IRequiredChangePasswordApi): Observable<ICheckExpiredPasswordApi> {
    return this.http.patch<ICheckExpiredPasswordApi>(ApiConfig.checkPasswordPath, data);
  }
}
