import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService, PermissionsService, UserService } from '../services';
import { Roles } from '@sp-core/agreement-keys/roles.enum';
import { UserPermissions, UserPermissionsType } from '@sp-core/dictionary-types/user-permissions.enum';

@Injectable({
  providedIn: 'root',
})
export class RoleAndPermissionLimitedPracticeAdminGuard {
  public userRoles: typeof Roles = Roles;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private permissionsService: PermissionsService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {
    const { permissions } = route.data;
    if (
      this.permissionsService.role?.key === this.userRoles.limitedPracticeAdmin &&
      permissions.includes(UserPermissions.rolesAndPermissions)
    ) {
      return this.logOutAndRedirectToLogin();
    }
    return of(true);
  }

  private logOutAndRedirectToLogin(): Observable<boolean> {
    this.authService.clearData();
    return of(false);
  }
}
