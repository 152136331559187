export enum ServerStatusCode {
  noContent = 204,
  commonError = 400,
  authorizeError = 401,
  forbiddenError = 403,
  notFoundError = 404,
  notAcceptable = 406,
  validationError = 422,
  customError = 409, // processed locally in components
}
