import { Subject, timer } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';

export class Timer {
  public expiredMinutes = 5; // min

  public date: { minutes: any; seconds: any };

  public currentTime = new Date();

  public expiredTime = new Date(this.currentTime);

  public stop$: Subject<void> = new Subject<void>();

  constructor(public minutes: number) {
    this.setExpiredMinutes(minutes);
  }

  public setExpiredMinutes(minutes: number): void {
    if (minutes) {
      this.expiredMinutes = minutes;
    }
  }

  public setExpiredTime(): void {
    this.expiredTime.setMinutes(this.currentTime.getMinutes() + this.expiredMinutes);
  }

  public getTime(timeTwo: Date): void {
    const t = Date.parse(timeTwo.toString()) - Date.parse(new Date().toString());
    const seconds = Math.floor((t / 1000) % 60);
    const minutes = Math.floor((t / 1000 / 60) % 60);
    const prepareSeconds = seconds <= 9 ? `0${seconds}` : seconds;
    this.date = {
      minutes,
      seconds: prepareSeconds,
    };
  }

  public runTime(): any {
    this.setExpiredTime();
    timer(0, 1000)
      .pipe(
        takeUntil(this.stop$),
        takeWhile(() => !this.getExpiredTime()),
      )
      .subscribe(() => {
        this.getTime(this.expiredTime);
      });
  }

  public stopTime(): void {
    this.stop$.next();
  }

  public restartTimer(): void {
    this.date = null;
    this.expiredTime = new Date();
    this.expiredTime.setMinutes(new Date().getMinutes() + this.expiredMinutes);
    this.runTime();
  }

  public destroy(): void {
    this.stopTime();
    this.stop$.complete();
  }

  private getExpiredTime(): boolean {
    return this.date?.minutes <= 0 && Number.parseFloat(this.date.seconds) === 0;
  }
}
