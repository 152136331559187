<form class="sign-up-form w-100"
      spQaId="sign-up-form_form"
      *ngIf="formSignUp"
      [formGroup]="formSignUp"
      (ngSubmit)="onSubmit()"
      id="sign-up-form">
  <mat-form-field class="sign-up-form__control w-100">
    <input spQaId="sign-up-form_input_user-name"
           matInput id="user-name"
           formControlName="email"
           [placeholder]="'AUTH.EMAIL' | translate">
    <mat-error spErrors></mat-error>
  </mat-form-field>

  <mat-form-field class="sign-up-form__control w-100">
    <input spQaId="sign-up-form_input_password"
           matInput
           id="password"
           #password
           formControlName="password"
           [placeholder]="'AUTH.PASSWORD' | translate"
           [type]="hide ? 'password' : 'text'">
    <mat-error spErrors></mat-error>
    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
  </mat-form-field>
  <div class="sign-up-form__password-info-wrap">
    <sp-password-strength [value]="password.value"
                          [min]="minPasswordLength">
    </sp-password-strength>
  </div>

  <mat-form-field class="w-100">
    <input spQaId="sign-up-form_input_password-confirm"
           matInput id="password-confirm"
           formControlName="confirmPassword"
           [placeholder]="'AUTH.CONFIRM_PASSWORD' | translate"
           [type]="hideConfirmPassword ? 'password' : 'text'">
    <mat-error spErrors></mat-error>
    <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">
      {{hideConfirmPassword ? 'visibility_off' : 'visibility'}}
    </mat-icon>
  </mat-form-field>

  <div class="auth-action">
    <button spQaId="sign-up-form_button_submit"
            form="sign-up-form"
            class="w-100 btn-primary-accept"
            [disabled]="formSignUp.invalid || !token">
      {{'BTN_SUBMIT' | translate}}
    </button>
  </div>
</form>
