<form class="sign-in-form auth-form w-100"
      spQaId="sign-in-form__form"
      [formGroup]="signInForm"
      (ngSubmit)="onSubmit()"
      id="sign-in-form">
  <div class="sign-in-form__control">
    <mat-form-field class="w-100">
      <input spQaId="sign-in-form_input_email" matInput id="user-name" formControlName="email" [placeholder]="'AUTH.EMAIL' | translate">
      <mat-error spErrors></mat-error>
    </mat-form-field>
  </div>

  <div class="sign-in-form__control password-block">
    <mat-form-field class="w-100">
      <input spQaId="sign-in-form_input_password"
             matInput id="password"
             formControlName="password"
             [placeholder]="'AUTH.PASSWORD' | translate"
             [type]="hide ? 'password' : 'text'">
      <mat-error spErrors></mat-error>
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </mat-form-field>
  </div>
  <div class="auth-action">
    <button spQaId="sign-in-form_button_submit"
            type="submit"
            form="sign-in-form"
            class="w-100 btn-primary-accept"
            [disabled]="signInForm.invalid || isSend">
      {{'BTN_SUBMIT' | translate}}
    </button>
  </div>
</form>
