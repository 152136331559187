import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ActivityHttpAdapterService {
  public transformToActivity(activity: IActivityApi): IActivity {
    return {
      id: activity.id,
      ip: activity.ip,
      event: activity.event,
      date: activity.date,
      userName: activity.user_name,
      role: activity.role,
      practiceName: activity.practice_name,
      targetKey: activity.target.key,
      targetName: activity.target.name,
      isHasChanges: activity.is_has_changes,
      eventType: activity.event_type,
    };
  }

  public transformToChangeLog(changeLog: IChangeLogApi[]): IChangeLog[] {
    return changeLog.map((change) => ({
      id: change.id,
      event: change.event,
      ip: change.ip,
      date: change.date,
      userName: change.user_name,
      role: change.role,
      before: change.before,
      after: change.after,
    }));
  }

  public transformToActivityEventApi(eventName: string): IActivityEventApi {
    return { activity_title: eventName };
  }

  public transformToActivityType(data: IActivityTypeApi): IActivityType {
    return {
      id: data.id,
      title: data.title,
      type: data.type,
    };
  }
}
