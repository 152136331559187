import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import { ForceLogoutPopupComponent } from './containers/force-logout-popup/force-logout-popup.component';
import { InactiveDirective } from './inactive.directive';

@NgModule({
  declarations: [InactiveDirective, ForceLogoutPopupComponent],
  exports: [InactiveDirective, ForceLogoutPopupComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatDividerModule, TranslateModule],
})
export class InactiveModule {}
