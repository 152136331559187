import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import { QaModule } from '@sp-shared/directives/data-cy/qa.module';
import { ConfirmPopupComponent } from './confirm-popup/confirm-popup.component';

@NgModule({
  declarations: [ConfirmPopupComponent],
  exports: [ConfirmPopupComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatDividerModule, TranslateModule, QaModule],
})
export class ConfirmPopupModule {}
