import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
// tslint:disable
@Injectable({
  providedIn: 'root',
})
export class TranslateDefaultLanguageService {
  private language$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public get defaultLanguage$(): Observable<string | unknown> {
    return this.language$.asObservable().pipe(filter(Boolean));
  }

  public setDefaultLanguage(language: string): void {
    this.language$.next(language);
  }
}
