export enum StickyNoteContextNameEnum {
  dashboard = 'dashboard',
  patient = 'patient',
  myConsults = 'my_consults',
  ownPractice = 'own_practice',
  practiceEmployees = 'practice_employees',
  roles = 'roles',
  permissions = 'permissions',
  reports = 'reports',
  activities = 'activities',
  inventory = 'inventory',
  inventoryLog = 'inventory_log',
}
