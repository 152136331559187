import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';

import { AuthHttpService } from '@sp-core/http-services/auth-http.service';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, map, share, tap } from 'rxjs/operators';
import { TokenService } from '@sp-core/services/token/token.service';
import { UserService } from '@sp-core/services/user/user.service';
import { CookiesStorageService } from '@sp-core/services/cookies/cookies-storage.service';
import { MatDialog } from '@angular/material/dialog';
import Utils from '@sp-helpers/utils';
import { SessionService } from '@sp-core/services/session/session.service';
import { PermissionsService } from '@sp-core/services/permissions/permissions.service';
import { ServerStatusCode } from '@sp-core/agreement-keys/server-status-code.enum';
import { AuthHttpAdapter } from '@sp-core/http-adapters/auth-http-adapter';
import HttpParamsUtils from '@sp-helpers/http-params';
import { Roles } from '@sp-core/agreement-keys/roles.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public cerboPath: any = 'cerbo';

  public savedRout: string = null;

  constructor(
    private authHttp: AuthHttpService,
    private router: Router,
    private tokenService: TokenService,
    private userService: UserService,
    private authHttpAdapter: AuthHttpAdapter,
    private cookiesStorage: CookiesStorageService,
    private dialog: MatDialog,
    private permissionsService: PermissionsService,
  ) {}

  public get isActiveSession$(): Observable<boolean> {
    return combineLatest([this.tokenService.accessToken$, this.userService.user$, this.permissionsService.role$]).pipe(
      map(([token, user, role]) => !!token && !!user && !!role),
      distinctUntilChanged(),
      share(),
    );
  }

  public signUp(data: ISignUp): Observable<boolean> {
    const sendData = this.authHttpAdapter.transformToApiSignUp(data);
    return this.authHttp.signUp(sendData).pipe(map((res) => !!res));
  }

  public getEmailByInvitationToken(token: string): Observable<IEmail> {
    const params: HttpParams = HttpParamsUtils.createHttpParams({ token });
    return this.authHttp
      .getEmailByInvitationToken(params)
      .pipe(map((res) => this.authHttpAdapter.transformToEmail(res)));
  }

  public confirmResetPasswordToken(token: string): Observable<boolean> {
    const params: HttpParams = HttpParamsUtils.createHttpParams({ token });
    return this.authHttp.confirmResetPasswordToken(params).pipe(map((res) => !!res));
  }

  public signIn(data: ISignIn): Observable<IUser> {
    return this.authHttp.signIn(data).pipe(
      map((response: ISignInApi) => {
        const user = this.authHttpAdapter.transformToUser(response.user);
        this.cookiesStorage.token = response.accessToken;
        this.tokenService.accessToken = response.accessToken;
        this.userService.user = user;
        this.cookiesStorage.setRememberMeHash(response.remember_me_hash, data.email);
        return user;
      }),
    );
  }

  public checkSessionUser(data: ISignIn): Observable<ISessionsCheck> {
    return this.authHttp
      .checkSessionUser(data)
      .pipe(map((result) => this.authHttpAdapter.transformToCheckUserSession(result)));
  }

  public forgotPassword(data: { email: string; link: string }): Observable<any> {
    return this.authHttp.forgotPassword(data);
  }

  public resetPassword(data: IRestorePassword): Observable<void> {
    const sendData = this.authHttpAdapter.transformToResetPassword(data);
    return this.authHttp.resetPassword(sendData).pipe(
      catchError((serverError) => {
        if (serverError.status === ServerStatusCode.validationError) {
          serverError.validationErrors = this.authHttpAdapter.transformToResetPassword(serverError.validationErrors);
        }
        return throwError(serverError);
      }),
    );
  }

  public logout(): Observable<any> {
    return this.sendLogOut();
  }

  public sendLogOut(): Observable<any> {
    this.savedRout = null;
    return this.authHttp.logOut().pipe(
      map((res) => {
        this.clearData();
        return res;
      }),
      catchError(() => {
        this.clearData();
        return of(null);
      }),
    );
  }

  public handlerRouterNotAdminRedirect(): void {
    this.router.navigateByUrl('/home');
  }

  public clearData(): void {
    SessionService.nextUrl = null;
    this.tokenService.accessToken = null;
    this.userService.user = null;
    this.permissionsService.clearAuthData();
    this.closedDialog();
    if (this.savedRout) {
      this.router.navigate(['/sign-in'], {
        queryParams: {
          next: this.savedRout,
        },
      });
      return;
    }
    this.router.navigate(['/sign-in']);
  }

  public getNextPath(): string {
    return window.location.pathname + window.location.search;
  }

  public saveRoutePath(): void {
    const path = this.getNextPath();
    if (path.includes(this.cerboPath) && path.includes('link_patient') && this.router) {
      this.savedRout = path;
      const parsedUrl = this.router.parseUrl(path)?.queryParams?.next;
      this.setSavedUrl(parsedUrl);
    } else {
      this.clearSavedUrl();
    }
  }

  public getSavedUrl(): string {
    return SessionService.nextUrl;
  }

  public clearSavedUrl(): void {
    SessionService.nextUrl = null;
  }

  public setSavedUrl(url: string): void {
    SessionService.nextUrl = url;
  }

  public closedDialog(): void {
    this.dialog.closeAll();
  }

  public getUser(): Observable<IUser> {
    return this.authHttp.getCurrentUser().pipe(
      map((user) => this.authHttpAdapter.transformToUser(user)),
      tap((user) => {
        this.userService.user = user;
      }),
    );
  }

  public getUserType2FA(): Observable<ITwoFactorAuthorizationType> {
    return this.authHttp
      .getUserType2FA()
      .pipe(map((res) => this.authHttpAdapter.transformToTwoFactorAuthorizationType(res)));
  }

  public getUserType2FANonLogin(data: ISignIn): Observable<ITwoFactorAuthorization> {
    return this.authHttp
      .getUserType2FANonLogin(data)
      .pipe(map((res) => this.authHttpAdapter.transformToTwoFactorAuthorization(res)));
  }

  public changeUserType2FA(data: string): Observable<any> {
    return this.authHttp.changeUserType2FA({ type_id: data });
  }

  public getRememberMeHash(email: string): string {
    return this.cookiesStorage.getRememberMeHash(email);
  }

  public cleanRememberMeHash(email: string): void {
    this.cookiesStorage.clearRememberMeHash(email);
  }

  public sendAuthCode(data: ISignIn): Observable<any> {
    return this.authHttp.sendAuthCode(data);
  }

  public saveAdminPracticeUrl(path: string) {
    const nextPath = this.getNextPath();
    if (this.isAdminPracticeRoute(nextPath)) {
      const params = Utils.getUrlParams(path);
      this.setSavedUrl(this.prepareAdminPracticeUrl(params));
      return;
    }
    if (this.isReferralsPath(nextPath)) {
      this.setSavedUrl(nextPath);
    }
  }

  public isAdminPracticeRoute(url: string): boolean {
    return url.startsWith('/admin/?practice');
  }

  public isReferralsPath(url: string): boolean {
    return url.startsWith('/admin/referrals');
  }

  public prepareAdminPracticeUrl(params: { practice?: string; employeeName?: string; practiceName?: string }): string {
    if (params?.practiceName) {
      return `admin/practices?practiceName=${params.practiceName}`;
    }
    if (params?.practice && params?.employeeName) {
      return `admin/practices/${params.practice}/employees?employeeName=${params.employeeName}`;
    }
    return '';
  }

  public checkExpiredPassword(data: ISignIn): Observable<ICheckExpiredPassword> {
    return this.authHttp
      .checkExpiredPassword(data)
      .pipe(map((res) => this.authHttpAdapter.transformToCheckPassword(res)));
  }

  public requiredChangPassword(data: IRequiredChangePassword): Observable<any> {
    const sendData = this.authHttpAdapter.transformToRequireChangePassword(data);
    return this.authHttp.requiredChangPassword(sendData);
  }

  public handlerNavigation(role: IRole) {
    const url = this.getSavedUrl();
    const isDownloadPath = this.savedRout && this.savedRout.includes('?report');
    if (url) {
      this.clearSavedUrl();
      this.navigateTo(url);
    } else if (role?.key === Roles.masterAdmin) {
      const path = isDownloadPath ? this.savedRout : '/admin/practices';
      this.navigateTo(path);
    } else if (role?.key === Roles.practiceAdmin || role?.key === Roles.limitedPracticeAdmin) {
      const path = isDownloadPath ? this.savedRout : '/admin/dashboard';
      this.navigateTo(path);
    } else if ([Roles.provider, Roles.supportStaff].includes(role?.key)) {
      this.handlerRouterNotAdminRedirect();
    } else if (role?.key === Roles.dosingProvider || role?.key === Roles.limitedProvider || Roles.limitedSupportStaff) {
      this.handlerRouterNotAdminRedirect();
    } else {
      this.navigateTo('/contact-us');
    }
  }

  public navigateByCurrentRole() {
    this.handlerNavigation(this.permissionsService.role);
  }

  private navigateTo(url: string): void {
    this.router.navigateByUrl(`/${url}`);
  }
}
