import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IChangePasswordForm } from '@sp-core/models/forms-models/change-password-form.interface';

@Component({
  selector: 'sp-required-change-password-form',
  templateUrl: './required-change-password-form.component.html',
  styleUrls: ['./required-change-password-form.component.scss'],
})
export class RequiredChangePasswordFormComponent {
  public hideCurrentPassword = true;
  public hideNewPassword = true;
  public hideConfirmPassword = true;
  public minPasswordLength = 12;

  @Input() public requiredChangePasswordForm: FormGroup<IChangePasswordForm>;
  @Input() public isSend: boolean;
  @Output() public wasSubmitted: EventEmitter<IRequiredChangePassword> = new EventEmitter<IRequiredChangePassword>();

  public onSubmit(): void {
    if (this.requiredChangePasswordForm.valid) {
      this.wasSubmitted.emit(this.requiredChangePasswordForm.getRawValue());
    }
  }
}
