import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, switchMap, tap } from 'rxjs';
import { AnnouncementsService } from '@sp-core/services/announcements/announcements.service';
import { AnnouncementPopupComponent } from '@sp-shared/containers/announcement-popup/announcement-popup.component';
import { filter } from 'rxjs/operators';
import { AnnouncementsPopupGenerator } from '@sp-shared/modules/announcements-popup-generator/announcements-popup-generator';
import AnnouncementsPopupFactoryGeneratorService from '@sp-shared/modules/announcements-popup-generator/announcements-popup-factory-generator.service';
import { InfoPopupService } from '@sp-shared/containers/info-popup/services/info-popup.service';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementsAgreementsService {
  public popupsSubject: Subject<any> = new Subject();
  public announcementGenerator: AnnouncementsPopupGenerator;
  public listPopupsData: IAnnouncementListPopup[] = [];

  constructor(
    public readonly _announcementsService: AnnouncementsService,
    public readonly _dialog: MatDialog,
    public readonly _infoPopupService: InfoPopupService,
  ) {}

  public handlerShowPopups(): void {
    this.announcementGenerator = AnnouncementsPopupFactoryGeneratorService.createGenerator(
      'generatorAnnouncementsPopups',
      this.listPopupsData,
    );
    this.popupsSubject.next(this.announcementGenerator.generator.next().value);
  }

  public cleanGenerator(): void {
    if (this.announcementGenerator) {
      this.announcementGenerator.removeInstance();
    }
    this.announcementGenerator = null;
  }

  public resetAnnouncementPopupsData(data?: IAnnouncementGeneratorData): void {
    if (!data) {
      this.announcementGenerator.clearGenerator();
      this.clearPopupList();
    }
  }

  public clearPopupList(): void {
    this.listPopupsData = [];
  }

  public subscribeToShowAnnouncementsPopup(): Observable<any> {
    return this.popupsSubject.pipe(
      tap((data) => this.resetAnnouncementPopupsData(data)),
      filter((data) => !!data),
      switchMap((item: IAnnouncementGeneratorData) => {
        return this.getAnnouncementPopup(item).afterClosed();
      }),
      tap(() => this.popupsSubject.next(this.announcementGenerator.generator.next().value)),
    );
  }

  public getNewAnnouncements(): Observable<any> {
    return this._announcementsService.getListOfNewAnnouncements().pipe(
      filter((res) => !!res),
      tap((ids) => {
        this.listPopupsData = ids.map((item) => ({ id: item }));
        this.handlerShowPopups();
      }),
    );
  }

  public getAnnouncementPopup(data: IAnnouncementGeneratorData): MatDialogRef<AnnouncementPopupComponent> {
    return this._dialog.open(AnnouncementPopupComponent, {
      disableClose: true,
      panelClass: 'announcement-popup',
      data: {
        announcementId: data.id,
        currentIndex: data.index,
        announcementsLength: data.length,
      },
    });
  }

  public cleanStateData(): void {
    this.clearPopupList();
    this.cleanGenerator();
  }
}
