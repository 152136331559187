import { Injectable } from '@angular/core';
import { IUploadFile } from '../models/app-models/upload-file.interface';

@Injectable({
  providedIn: 'root',
})
export class StorageHttpAdapters {
  public transformToAwsUploadData(data: IAwsUploadApi): IAwsUpload {
    return {
      id: data.id,
      awsLink: data.cloud_link,
      src: data.src,
      fileName: data.file_name,
      fileType: data.file_type,
    };
  }

  public transformToAwsUploadApiData(data: IAwsUpload): IAwsUploadApi {
    return {
      id: data.id,
      cloud_link: data.awsLink,
      src: data.src,
      file_name: data.fileName,
      file_type: data.fileType,
    };
  }

  public transformAwsUploadDataToUploadFileData(data: IAwsUpload[]): IUploadFile[] {
    if (data) {
      return data.map((file) => this.transformAwsUploadItemDataToUploadFileItemData(file));
    }
  }

  public transformAwsUploadItemDataToUploadFileItemData(data: IAwsUpload): IUploadFile {
    if (data) {
      return {
        id: data.id,
        awsLink: data.awsLink,
        src: data.src,
        file: null,
        file_type: data.fileType,
        file_name: data.fileName,
      };
    }
  }

  public transformUploadFileDataToAwsUploadData(data: IUploadFile[]): IAwsUpload[] {
    if (data) {
      return data.map((file) => this.transformUploadFileItemDataToAwsUploadItemData(file));
    }
  }

  public transformUploadFileItemDataToAwsUploadItemData(data: IUploadFile): IAwsUpload {
    if (data) {
      return {
        id: data.id,
        awsLink: data.awsLink,
        src: data.src,
        fileType: data.file_type,
        fileName: data.file_name,
      };
    }
  }
}
