import { Injectable } from '@angular/core';
import { VerificationHttpAdapter } from '@sp-core/http-adapters/verification-http-adapter';
import { HttpVerificationService } from '@sp-core/http-services/http-verification/http-verification.service';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PhoneService {
  public readonly defaultCountryKey = 'usa';

  public readonly defaultCountryId = '1b92fcd0-94e5-4a69-9c3f-f84c1437cce2';

  public readonly phoneMaskMap = {
    usa: '(000) 000-0000',
    spain: '(000) 000-000',
    bulgaria: '(000) 000-000',
    cayman_islands: '(000) 000-000',
    republic_of_korea: '(000) 000-000',
    puerto_rico: '(000) 000-000',
    philippines: '(000) 000-000',
    china: '(000) 000-000',
    mexico: '(000) 000-000',
  };

  constructor(
    public verificationHttpAdapter: VerificationHttpAdapter,
    public httpVerificationService: HttpVerificationService,
  ) {}

  public getPhoneCodeMap(key = 'countryId'): Observable<IPhoneCodeMap> {
    return this.httpVerificationService.getPhonePrefixes().pipe(
      map((response) =>
        response.data.reduce((acc, cur) => {
          const item: IPhonePrefixes = this.verificationHttpAdapter.transformToPhone(cur);
          const keyValue = item[key];
          acc[keyValue] = { ...item, mask: this.phoneMaskMap[item.countryKey] };
          return acc;
        }, {}),
      ),
      shareReplay(1),
    );
  }

  public getPhonePrefixes(): Observable<IPhoneResponse> {
    return this.httpVerificationService
      .getPhonePrefixes()
      .pipe(map((data) => this.verificationHttpAdapter.transformToPhoneCode(data.data)));
  }

  public sendVerificationCode(value: { phone: string; phonePrefixId: string }, code: string): Observable<any> {
    const sendData: IVerificationPhoneApi = this.verificationHttpAdapter.transformToCodeVerification(value, code);
    return this.httpVerificationService.sendVerificationCode(sendData);
  }
}
