import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConfig } from '@sp-core/api-config';
import { ReferralStatuses } from '@sp-core/agreement-keys/referral-statuses.enum';

@Injectable({
  providedIn: 'root',
})
export class HttpReferralsService {
  constructor(private http: HttpClient) {}

  public getReferrals(params: HttpParamsOptions): Observable<ITableApiData<IReferralApi>> {
    return this.http.get<ITableApiData<IReferralApi>>(ApiConfig.referralsPath, { params });
  }

  public getReferralsNotes(params: HttpParamsOptions): Observable<ITableApiData<IReferralNoteApi>> {
    return this.http.get<ITableApiData<IReferralNoteApi>>(ApiConfig.referralsNotesPath, { params });
  }

  public assignReferral(id: string, data: IReferralAssignRequestApi): Observable<void> {
    return this.http.post<void>(`${ApiConfig.referralsPath}/${id}/assign`, data);
  }

  public contactReferral(id: string, data: Partial<IReferralContactRequestApi>): Observable<void> {
    return this.http.put<void>(`${ApiConfig.referralsPath}/${id}`, data);
  }

  public editReferral(id: string, data: IReferralEditRequestApi): Observable<void> {
    return this.http.patch<void>(`${ApiConfig.referralsPath}/${id}`, data);
  }

  public updateStatusReferral(id: string, status: ReferralStatuses): Observable<void> {
    return this.http.post<void>(`${ApiConfig.referralsPath}/${id}/status`, { status });
  }

  public getReferralQuizExportId(
    exportFormat: string,
    referralId: string,
    httpParams?: HttpParamsOptions,
  ): Observable<IExportProcessIdApi> {
    return this.http.post<IExportProcessIdApi>(
      `${ApiConfig.referralsPath}/${referralId}/quiz/export`,
      {
        format: exportFormat,
      },
      {
        params: httpParams,
      },
    );
  }

  public getReferralQuizExportFile(processId: string, referralId: string): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(`${ApiConfig.referralsPath}/${referralId}/quiz/export/${processId}`, {
      responseType: 'blob' as 'json',
      observe: 'response',
    });
  }

  public getReferralQuiz(id: string): Observable<IReferralQuizApi[]> {
    return this.http.get<IReferralQuizApi[]>(`${ApiConfig.referralsPath}/${id}/quiz`);
  }
}
