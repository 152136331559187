import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  public message: string;
  public actionButtonText = 'x';
  public action = true;
  public setAutoHide = true;
  public autoHide = 2000;
  public horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  public verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  public addExtraClass = false;

  constructor(private snackBar: MatSnackBar) {}

  private show(
    message: string,
    actionType: 'success' | 'danger' | 'warning' | string = 'success',
    duration = 2000,
    actionText = 'x',
    config?: MatSnackBarConfig,
  ): void {
    this.actionButtonText = actionText || this.actionButtonText;
    const matSnackBarConfig = new MatSnackBarConfig();
    matSnackBarConfig.verticalPosition =
      config && config.verticalPosition ? config.verticalPosition : this.verticalPosition;
    matSnackBarConfig.horizontalPosition =
      config && config.horizontalPosition ? config.horizontalPosition : this.horizontalPosition;
    matSnackBarConfig.duration = this.setAutoHide ? this.autoHide : 0;
    matSnackBarConfig.panelClass = actionType;
    matSnackBarConfig.duration = duration;

    this.snackBar.open(message, this.actionButtonText, matSnackBarConfig);
  }

  public showNotification(text: string, type = 'success'): void {
    this.show(text, type, 9999, 'X', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }

  public showNotificationFromComponent(
    component: any,
    message: string,
    snackType = 'success',
    duration?: number,
  ): void {
    this.snackBar.openFromComponent(component, {
      duration,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      data: { message, snackType },
    });
  }
}
