import { Component, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'sp-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss'],
})
export class OtpInputComponent {
  @Output() otpChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;

  public config = {
    allowNumbersOnly: true,
    length: 5,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '50px',
      height: '50px',
    },
  };

  public onOtpChange(code: string) {
    this.otpChange.emit(code);
  }

  public setVal(val) {
    if (val && this.ngOtpInput) {
      this.ngOtpInput.setValue(val);
    }
  }
}
