import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserDateFormatPipe } from './user-date-format.pipe';

@NgModule({
  declarations: [UserDateFormatPipe],
  exports: [UserDateFormatPipe],
  imports: [CommonModule],
})
export class UserDateFormatModule {}
