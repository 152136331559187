import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { leftMenu, rightMenu } from '@sp-shared/components/nav/menu-data';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService, PermissionsService, UserService } from '@sp-core-services';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmPopupComponent } from '@sp-shared/containers/confirm-popup/confirm-popup/confirm-popup.component';
import { IItemMenu } from '@sp-shared/components/nav/models/item-menu-model';
import { Roles } from '@sp-core/agreement-keys/roles.enum';
import { StickyNotesControllerService } from '../../../sticky-notes/services/sticky-notes-controller.service';
import { ResourcesService } from '@sp-core/services/resources/resources.service';
import { BadgeTypeEnum } from '@sp-core/agreement-keys/badge-type.enum';
import { NavItemMenuGroupTypesEnum } from '@sp-core/agreement-keys/nav-item-menu-group-types.enum';
import { OrderInfoPopupComponent } from '@sp-shared/components/order-info-popup/order-info-popup.component';

@Component({
  selector: 'sp-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NavComponent implements OnInit, OnDestroy {
  public rightMenu: IItemMenu[] = rightMenu;
  public leftMenu: IItemMenu[] = leftMenu;
  public open: boolean;
  public destroy$: Subject<void> = new Subject();
  public currentUrl = '';
  public user: IUser;
  public role: IRole;
  public stickyNotesContext;
  public userRoles: typeof Roles = Roles;
  public notesIcon = 'assets/images/notes.svg';
  public roleIcon = 'assets/images/user-role.svg';
  public arrowIcon = 'assets/images/arrow-down.svg';
  public isOpenRoleMenu = false;
  public resourcesQuantity: number;
  public BadgeTypeEnum = BadgeTypeEnum;
  public isResetClass = false;
  public navItemMenuGroupTypesEnum = NavItemMenuGroupTypesEnum;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private permissionsService: PermissionsService,
    private authService: AuthService,
    private stickyNotesService: StickyNotesControllerService,
    public dialog: MatDialog,
    public readonly _resourcesService: ResourcesService,
  ) {}

  public addNote() {
    this.stickyNotesService.addNote();
  }

  public ngOnInit(): void {
    this.subscribeRouter();
    this.getUser();
    this.getRole();
    this.listenStickyNotesContext();
    this.getNewResourcesQuantity();
    this.onUpdateStatusChanges();
  }

  public toggleClick(): void {
    this.open = !this.open;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public updateRole(role: IRole) {
    this.open = !this.open;
    if (this.role.id === role.id) {
      return;
    }
    const text = `Are you sure you want to change role to '${role.name}'?`;
    this.getConfirmDialog(text)
      .pipe(
        filter(Boolean),
        switchMap(() => this.permissionsService.updateRole(role)),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.authService.handlerNavigation(role);
      });
  }

  public closeMenu() {
    if (this.open) {
      this.open = false;
    }
  }

  public includeRoute(link: string): boolean {
    return this.router.url ? !this.currentUrl.includes(link) : false;
  }

  public logOut(): void {
    this.authService.logout().subscribe();
  }

  public openDialogConfirmLogOut(): void {
    this.closeMenu();
    const text = 'ARE_YOU_SURE_YOU_WANT_TO_LOG_OUT';
    this.getConfirmDialog(text).subscribe((result) => {
      if (result) {
        this.logOut();
      }
    });
  }

  private getConfirmDialog(text: string): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: { text },
    });

    return dialogRef.afterClosed().pipe(takeUntil(this.destroy$));
  }

  private subscribeRouter(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe((event: NavigationEnd) => {
        this.currentUrl = event.url;
        this.cd.detectChanges();
      });
  }

  private getUser(): void {
    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });
  }

  private getRole(): void {
    this.permissionsService.role$.pipe(takeUntil(this.destroy$)).subscribe((role) => {
      this.role = role;
      this.getNewResourcesQuantity();
      this.cd.markForCheck();
    });
  }

  private listenStickyNotesContext() {
    this.stickyNotesService.context$.pipe(takeUntil(this.destroy$)).subscribe((context) => {
      this.stickyNotesContext = context;
      this.cd.detectChanges();
    });
  }

  public toggleRoleMenu(value: boolean): void {
    this.isOpenRoleMenu = value;
    if (value) {
      this.roleIcon = 'assets/images/user-role-white.svg';
      this.arrowIcon = 'assets/images/arrow-up-white.svg';
      this.cd.detectChanges();
    } else {
      this.roleIcon = 'assets/images/user-role.svg';
      this.arrowIcon = 'assets/images/arrow-down.svg';
    }
  }

  public checkIsDropMenuGroupActiveRouter(
    items: {
      link: string;
      name: string;
      whoCanView: string[];
      key: string;
    }[],
  ): boolean {
    const results: boolean[] = [];
    items
      .map((item) => item.link)
      .forEach((item) => {
        if (this.router.url.includes(item)) {
          results.push(true);
        }
      });
    return results.includes(true);
  }

  public getNewResourcesQuantity(): void {
    if (this.role && this.role?.key === Roles.practiceAdmin) {
      this._resourcesService
        .getQuantityOfNewResources()
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.resourcesQuantity = res.qty;
          this.cd.detectChanges();
        });
    }
  }

  public onUpdateStatusChanges(): void {
    this._resourcesService.updateResourceViewedStatus$
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.getNewResourcesQuantity();
      });
  }

  public openOrderPopup(): void {
    this.closeMenu();
    this.dialog.open(OrderInfoPopupComponent, {
      disableClose: true,
      panelClass: 'order-info',
    });
  }
}
