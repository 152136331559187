import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConfig } from '@sp-core/api-config';

@Injectable({
  providedIn: 'root',
})
export class HttpVerificationService {
  constructor(public http: HttpClient) {}

  public verificationPhone(value: IPhoneVerificationApi): Observable<any> {
    return this.http.post(`${ApiConfig.phoneVerificationPath}`, { ...value });
  }

  public sendVerificationCode(data: IVerificationPhoneApi): Observable<any> {
    return this.http.put(`${ApiConfig.phoneVerificationPath}`, { ...data });
  }

  public verificationEmail(data: { email: string }): Observable<any> {
    return this.http.post(`${ApiConfig.emailVerificationPath}`, { ...data });
  }

  public sendVerificationCodeForEmail(data: { code: string; email: string }): Observable<any> {
    return this.http.put(`${ApiConfig.emailVerificationPath}`, { ...data });
  }

  public getPhonePrefixes(): Observable<IDataListApi<IPhonePrefixesApi>> {
    return this.http.get<IDataListApi<IPhonePrefixesApi>>(`${ApiConfig.phonePrefixesPath}`);
  }

  public getTwoFADictionariesType(): Observable<IDataListApi<ITwoFactorAuthorizationTypeApi>> {
    return this.http.get<IDataListApi<ITwoFactorAuthorizationTypeApi>>(ApiConfig.security2faTypesPath);
  }
}
