import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, timer } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { SessionService } from '@sp-core/services/session/session.service';
import { Router } from '@angular/router';

@Component({
  selector: 'sp-force-logout-popup',
  templateUrl: './force-logout-popup.component.html',
  styleUrls: ['./force-logout-popup.component.scss'],
})
export class ForceLogoutPopupComponent implements OnInit, OnDestroy {
  public destroy$: Subject<void> = new Subject<void>();

  public date: { minutes: number; seconds: any };

  public currentTime = new Date();

  public expiredTime = new Date(this.currentTime);

  public expiredMinutes = 5;

  constructor(
    public dialogRef: MatDialogRef<ForceLogoutPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    this.setExpiredTime();
    this.time();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public setExpiredTime(): void {
    this.expiredTime.setMinutes(this.currentTime.getMinutes() + this.expiredMinutes);
  }

  public clickClose(confirm: { logOut: boolean }): void {
    this.dialogRef.close(confirm);
  }

  public saveRoute(): void {
    SessionService.nextUrl = this.router.url;
  }

  public time(): any {
    timer(0, 1000)
      .pipe(
        takeUntil(this.destroy$),
        takeWhile(() => !this.getExpiredTime()),
      )
      .subscribe(() => {
        this.setRemindTime(this.expiredTime);
        this.isTimeExpired();
      });
  }

  public setRemindTime(timeTwo: Date): void {
    const t = Date.parse(timeTwo.toString()) - Date.parse(new Date().toString());
    const seconds = Math.floor((t / 1000) % 60);
    const minutes = Math.floor((t / 1000 / 60) % 60);
    const prepareSeconds = seconds <= 9 ? `0${seconds}` : seconds;
    this.date = {
      minutes,
      seconds: prepareSeconds,
    };
  }

  public isTimeExpired(): void {
    if (this.getExpiredTime()) {
      this.clickClose({ logOut: true });
    }
  }

  private getExpiredTime(): boolean {
    return this.date?.minutes <= 0 && Number.parseFloat(this.date.seconds) === 0;
  }
}
