import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { PermissionsService, UserService } from '@sp-core-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { userNavMenuConfig } from './helpers/user-nav-menu.config';
import { IUserNavMenu } from './models/user-nav-menu.interface';

@Component({
  selector: 'sp-user-nav-menu',
  templateUrl: './user-nav-menu.component.html',
  styleUrls: ['./user-nav-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNavMenuComponent implements OnInit, OnDestroy {
  public user: IUser;
  public role: IRole;
  public menu: IUserNavMenu[] = userNavMenuConfig;
  public destroy$: Subject<void> = new Subject();

  constructor(
    private permissionsService: PermissionsService,
    private cd: ChangeDetectorRef,
    private userService: UserService,
  ) {}

  public ngOnInit(): void {
    this.getRole();
    this.getUser();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private getRole(): void {
    this.permissionsService.role$.pipe(takeUntil(this.destroy$)).subscribe((role) => {
      this.role = role;
      this.cd.markForCheck();
    });
  }

  private getUser(): void {
    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
      this.cd.markForCheck();
    });
  }
}
