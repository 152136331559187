import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

function initTawkToScript(): void {
  const accountId = environment?.tawkToAccountId;
  if (!accountId || !document) {
    return;
  }
  const s1 = document.createElement('script');
  const s0 = document.getElementsByTagName('script')[0];
  s1.async = true;
  s1.src = `https://embed.tawk.to/${accountId}/default`;
  s1.charset = 'UTF-8';
  s1.setAttribute('crossorigin', '*');
  s0.parentNode.insertBefore(s1, s0);
}

function initFreshWorksScript(): void {
  const accountId = environment?.freshWorkId;
  if (!accountId || !document) {
    return;
  }
  const s1 = document.createElement('script');
  const s0 = document.getElementsByTagName('script')[0];
  s1.async = true;
  s1.src = `https://widget.freshworks.com/widgets/${accountId}.js`;
  s1.charset = 'UTF-8';
  s0.parentNode.insertBefore(s1, s0);
}

if (environment.production) {
  enableProdMode();
  // initTawkToScript();
  initFreshWorksScript();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
