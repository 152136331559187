import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ITableService } from '@sp-shared/components/table';
import { MetaHttpAdapter } from '@sp-core/http-adapters/meta-http-adapter';
import { HttpSiteNoteService } from '../../http-services/http-site-note/http-site-note.service';
import { SiteNoteHttpAdapter } from '../../http-adapters/site-note-http-adapter';

@Injectable({
  providedIn: 'root',
})
export class SiteNoteReportService implements ITableService<ISiteNoteReportItem> {
  constructor(public httpSiteNoteService: HttpSiteNoteService, public siteNoteHttpAdapter: SiteNoteHttpAdapter) {}

  public getTableData(params: HttpParamsOptions): Observable<ITableData<ISiteNoteReportItem>> {
    return this.httpSiteNoteService.getSiteNotesReport(params).pipe(
      map((data) => ({
        data: data.data.map((item) => this.siteNoteHttpAdapter.transformToSiteNoteReportItem(item)),
        meta: MetaHttpAdapter.transformToMeta(data.meta),
      })),
    );
  }
}
