import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { QaModule } from '@sp-shared/directives/data-cy/qa.module';
import { CanViewModule } from '@sp-shared/directives/can-view/can-view.module';
import { PatentPopupComponent } from './containers/patent-popup/patent-popup.component';
import { FooterComponent } from './containers/footer/footer.component';
import { FooterNavMenuComponent } from './components/footer-nav-menu/footer-nav-menu.component';

@NgModule({
  declarations: [FooterComponent, PatentPopupComponent, FooterNavMenuComponent],
  exports: [FooterComponent, FooterNavMenuComponent],
  imports: [CommonModule, TranslateModule, RouterModule, MatDialogModule, QaModule, CanViewModule],
})
export class FooterModule {}
