import { HttpParams } from '@angular/common/http';

export default class HttpParamsUtils {
  public static createHttpParams(filters: any): HttpParams {
    if (filters) {
      let params: any = new HttpParams();
      for (const property in filters) {
        if (
          Object.prototype.hasOwnProperty.call(filters, property) &&
          filters[property] !== null &&
          filters[property] !== '' &&
          filters[property] !== undefined
        ) {
          if (Array.isArray(property)) {
            // eslint-disable-next-line no-loop-func
            filters[property].forEach((element) => {
              params = params.append(property, element);
            });
          } else {
            params = params.append(property, filters[property]);
          }
        }
      }
      return params;
    }
    return null;
  }

  public static params(filters): HttpParams {
    if (filters) {
      let params: any = new HttpParams();
      for (const property in filters) {
        if (Object.prototype.hasOwnProperty.call(filters, property) && filters[property] !== null) {
          if (Array.isArray(property)) {
            // eslint-disable-next-line no-loop-func
            filters[property].forEach((element) => {
              params = params.append(property, element);
            });
          } else {
            params = params.append(property, filters[property]);
          }
        }
      }
      return params;
    }
    return null;
  }
}
