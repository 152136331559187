import { Injectable } from '@angular/core';
import { ResourcesHttpAdapter } from '@sp-core/http-adapters/resources-http-adapter';
import { HttpResourcesService } from '@sp-core/http-services/http-resources/http-resources.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MetaHttpAdapter } from '@sp-core/http-adapters/meta-http-adapter';
import { StorageHttpAdapters } from '@sp-core/http-adapters/storage-http-adapter';
import { IResourceResponse } from '@sp-core/models/app-models/resource-response.interface';

@Injectable({
  providedIn: 'root',
})
export class ResourcesService {
  public updateResourceViewedStatus$: Subject<void> = new Subject<void>();

  constructor(
    public readonly _resourcesHttpAdapter: ResourcesHttpAdapter,
    public readonly _httpResourcesService: HttpResourcesService,
    public readonly _storageHttpAdapters: StorageHttpAdapters,
  ) {}

  public getTableData(params: HttpParamsOptions): Observable<ITableData<IResource>> {
    return this._httpResourcesService.getUsersResources(params).pipe(
      map((data) => ({
        data: data.data.map((item) => this._resourcesHttpAdapter.transformToResource(item)),
        meta: MetaHttpAdapter.transformToMeta(data.meta),
      })),
    );
  }

  public createResource(data: IResourceRequest): Observable<IResource> {
    const formData = this._resourcesHttpAdapter.transformToResourceRequestApiData(data);
    return this._httpResourcesService
      .createResource(formData)
      .pipe(map((res) => this._resourcesHttpAdapter.transformToResource(res)));
  }

  public updateResource(id: string, data: IResourceRequest): Observable<IResource> {
    const formData = this._resourcesHttpAdapter.transformToResourceRequestApiData(data);
    return this._httpResourcesService
      .updateResource(id, formData)
      .pipe(map((res) => this._resourcesHttpAdapter.transformToResource(res)));
  }

  public getResourceById(id: string): Observable<IResourceResponse> {
    return this._httpResourcesService.getResourceById(id).pipe(
      map((res) => this._resourcesHttpAdapter.transformToResource(res)),
      map((res) => ({
        ...res,
        files: this._storageHttpAdapters.transformAwsUploadDataToUploadFileData(res.files),
      })),
    );
  }

  public changeResourceStatus(data: IResourceChangeStatus): Observable<IResource> {
    const resourceStatusApiData = this._resourcesHttpAdapter.transformToResourceChangeStatusApi(data);
    return this._httpResourcesService
      .changeResourceStatus(resourceStatusApiData)
      .pipe(map((res) => this._resourcesHttpAdapter.transformToResource(res)));
  }

  public deleteResource(id: string): Observable<void> {
    return this._httpResourcesService.deleteResource(id);
  }

  public getQuantityOfNewResources(): Observable<IResourcesQuantity> {
    return this._httpResourcesService.getQuantityOfNewResources();
  }

  public setResourcesIsViewed(resources: string[]): Observable<void> {
    return this._httpResourcesService.setResourcesIsViewed(resources);
  }
}
