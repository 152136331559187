import { HttpErrorResponse } from '@angular/common/http';
import Utils from '@sp-helpers/utils';

export class ServerErrorModel {
  public status: number;

  public message: string | null;

  public validationErrors: IServerValidationErrors;

  constructor(httpErrorResponse: HttpErrorResponse) {
    this.status = httpErrorResponse.status;
    const errorBody = httpErrorResponse?.error?.error || {};
    this.message = errorBody?.message || null;
    this.validationErrors = Utils.removePropFromObject(errorBody, ['message', 'code']);
  }
}
