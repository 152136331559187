<ng-container [ngSwitch]="item.action">
  <ng-container *ngSwitchCase="'externalLink'">
    <a class="user-nav-item"
       [spQaId]="'nav_link_' + role?.key"
       [href]="role?.key === userRoles.practiceAdmin ? item.link : 'https://dosaggio.co/'"
       (mouseover)="imgSrc = item.hoverIcon"
       target="_blank"
       (mouseout)="imgSrc = item.icon">
      <div class="user-nav-item__icon-wrap">
        <img class="user-nav-item__icon" alt="icon" [src]="imgSrc">
      </div>
      <span class="user-nav-item__text">{{ role?.key === userRoles.practiceAdmin ? ('NAV.EDUCATIONAL_CENTER' | translate) : ('NAV.HELP' | translate) }}</span>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'route'">
    <a class="user-nav-item"
       routerLinkActive="active"
       [spQaId]="'nav_link_' + role.key"
       [routerLink]="item.action === 'route' ? item.link : ''"
       (mouseover)="imgSrc = item.hoverIcon"
       (mouseout)="imgSrc = item.icon">
      <div class="user-nav-item__icon-wrap">
        <img class="user-nav-item__icon" alt="icon" [src]="imgSrc">
      </div>
      <span class="user-nav-item__text">{{item.name | translate}}</span>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'openOrderPopup'">
    <a class="user-nav-item"
       routerLinkActive="active"
       [spQaId]="'nav_link_' + role.key"
       (click)="openOrderPopup()"
       (mouseover)="imgSrc = item.hoverIcon"
       (mouseout)="imgSrc = item.icon">
      <div class="user-nav-item__icon-wrap">
        <img alt="icon" [src]="imgSrc">
      </div>
      <span class="user-nav-item__text">{{item.name | translate}}</span>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'dropdownRole'">
    <a class="user-nav-item"
       (mouseover)="imgSrc = item.hoverIcon"
       (mouseout)="imgSrc = item.icon"
       [class.disabled]="!user?.isMultipleRoles"
       spQaId="nav_link_set-role"
       [matMenuTriggerFor]="roleMenu">
      <div class="user-nav-item__icon-wrap">
        <img class="user-nav-item__icon" alt="icon" [src]="imgSrc">
      </div>
      <span class="user-nav-item__text">{{role.name}}
        <img *ngIf="user?.roles?.length > 1" class="user-nav-item__arrow-icon"
             alt="arrow"
             [src]="arrowIcon">
      </span>
    </a>
    <mat-menu #roleMenu="matMenu" xPosition="after">
      <ng-container *ngFor="let role of user.roles">
        <button [spQaId]="'nav_button_set-role-' + role.key"
                mat-menu-item
                (click)="updateRole(role)">
          {{role.name}}
        </button>
      </ng-container>
    </mat-menu>
  </ng-container>
  <ng-container *ngSwitchCase="'logout'">
    <a class="user-nav-item"
       [spQaId]="'nav_link_' + role.key"
       (click)="openDialogConfirmLogOut()"
       (mouseover)="imgSrc = item.hoverIcon"
       (mouseout)="imgSrc = item.icon">
      <div class="user-nav-item__icon-wrap">
        <img class="user-nav-item__icon" alt="icon" [src]="imgSrc">
      </div>
      <span class="user-nav-item__text">{{item.name | translate}}</span>
    </a>
  </ng-container>

</ng-container>

