<div class="sticky-note__panel"
     cdkDrag
     cdkDragRootElement=".sticky-note"
     cdkDragBoundary=".cdk-global-overlay-wrapper"
     [ngStyle]="{'background-color': stickyNote.color}"
     cdkDragHandle>
  <p *ngIf="stickyNote?.updatedAt" class="sticky-note__date">{{stickyNote?.updatedAt | userDateFormat}}</p>
  <h2 class="sticky-note__title">
    <input spQaId="sticky-note_input_title"
           required
           [disabled]="stickyNote.creatorId && stickyNote.creatorId !== data.userId"
           maxlength="30"
           (mousedown)="stopIP($event)"
           [(ngModel)]="stickyNote.title"
           [placeholder]="'STICKY_NOTES.TITLE_PLACEHOLDER' | translate">
  </h2>
  <div class="sticky-note__text custom-scrollbar" (mousedown)="stopIP($event)">
    <textarea spQaId="sticky-note_textarea_text"
              class="resize-none"
              required
              [disabled]="stickyNote.creatorId && stickyNote.creatorId !== data.userId"
              [(ngModel)]="stickyNote.text"
              [placeholder]="'STICKY_NOTES.TEXT_PLACEHOLDER' | translate"
              maxlength="6000"
              cdkTextareaAutosize
              cdkAutosizeMinRows="1">
    </textarea>
    <p *ngIf="stickyNote?.creatorId" class="sticky-note__initials">- {{getInitials(stickyNote)}}</p>
    <div class="sticky-note__comments">
      <ng-container *ngFor="let comment of stickyNote?.comments; let index = index; trackBy: trackByFn">
        <textarea spQaId="sticky-note_textarea_comment-text"
                  [index]="index"
                  class="resize-none"
                  required
                  [disabled]="comment.creatorId && comment.creatorId !== data.userId"
                  [(ngModel)]="comment.text"
                  [placeholder]="'STICKY_NOTES.TEXT_ADDITIONAL_PLACEHOLDER' | translate"
                  maxlength="6000"
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="1">
        </textarea>
        <p *ngIf="stickyNote?.creatorId" class="sticky-note__initials">- {{getInitials(comment)}}</p>
      </ng-container>
      <textarea spQaId="sticky-note_textarea_new-comment-text"
                *ngIf="stickyNote?.id"
                class="resize-none sticky-note__new-comment-text"
                required
                [(ngModel)]="newCommentText"
                [placeholder]="'STICKY_NOTES.TEXT_ADDITIONAL_PLACEHOLDER' | translate"
                maxlength="6000"
                cdkTextareaAutosize
                cdkAutosizeMinRows="1">
      </textarea>
    </div>
  </div>
  <ng-container *ngIf="stickyNote?.id; else createActions">
    <div class="sticky-note__actions" (mousedown)="stopIP($event)">
      <button *ngIf="changedValue()"
              mat-icon-button
              [matTooltip]="'STICKY_NOTES.SAVE_TOOLTIP' | translate"
              (click)="update()">
        <mat-icon>save</mat-icon>
      </button>
      <button mat-icon-button
              [matTooltip]="'STICKY_NOTES.COLOR_PLACEHOLDER' | translate"
              (click)="openedColorMenu = !openedColorMenu">
        <mat-icon>palette</mat-icon>
      </button>
      <button mat-icon-button
              [matTooltip]="'STICKY_NOTES.PIN_TOOLTIP' | translate"
              (click)="pin()">
        <mat-icon>push_pin</mat-icon>
      </button>
      <button mat-icon-button
              [matTooltip]="'STICKY_NOTES.MINIMIZE_TOOLTIP' | translate"
              (click)="minimize()">
        <mat-icon>minimize</mat-icon>
      </button>
      <button mat-icon-button
              [matTooltip]="'STICKY_NOTES.DELETE_TOOLTIP' | translate"
              (click)="delete()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </ng-container>

  <ng-template #createActions>
    <div class="sticky-note__actions creation" (mousedown)="stopIP($event)">
      <button mat-icon-button
              [matTooltip]="'STICKY_NOTES.COLOR_PLACEHOLDER' | translate"
              (click)="openedColorMenu = !openedColorMenu">
        <mat-icon>palette</mat-icon>
      </button>
      <button mat-icon-button
              [disabled]="!stickyNote?.title || !stickyNote?.text"
              (click)="create()">
        <mat-icon>check_circle</mat-icon>
      </button>
      <button mat-icon-button
              (click)="cancel()">
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </ng-template>
  <div *ngIf="openedColorMenu" class="sticky-note__color-menu" (click)="stopIP($event)">
    <h2 class="sticky-note__title">{{'STICKY_NOTES.COLOR_PLACEHOLDER' | translate}}</h2>
    <div class="sticky-note__color-list custom-scrollbar">
      <ng-container *ngFor="let color of colorPresets">
        <button mat-mini-fab
                class="sticky-note__color-item"
                [ngStyle]="{'background-color': color}"
                (click)="changeBackground(color)">
        </button>
      </ng-container>
    </div>
    <div class="sticky-note__actions creation" (mousedown)="stopIP($event)">
      <button mat-icon-button
              [disabled]="stickyNote?.color === stickyNotesSnapshot?.color"
              (click)="updateColor()">
        <mat-icon>check_circle</mat-icon>
      </button>
      <button mat-icon-button
              (click)="discardColor()">
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </div>
</div>

<div class="sticky-note__load_panel" *ngIf="loading" (mousedown)="stopIP($event)">
  <mat-progress-bar mode="indeterminate" color="yellow"></mat-progress-bar>
</div>
