import { Injectable } from '@angular/core';
import DateUtils from '@sp-helpers/date-time/date-utils';
import TransformServerValidationUtils from '@sp-helpers/validation/transform-server-validation-utils';

@Injectable({
  providedIn: 'root',
})
export class PatientHttpAdapter {
  public transformToGender(date: IGenderApi): IGender {
    return {
      id: date.id,
      key: date.key,
      title: date.title,
    };
  }

  public transformToRace(date: IRaceApi): IRace {
    return {
      id: date.id,
      title: date.title,
    };
  }

  public transformToPatientApi(patient: IPatientForm): INewPatientRequestApi {
    return {
      first_name: patient.firstName && patient.firstName.trim(),
      last_name: patient.lastName && patient.lastName.trim(),
      middle_initial: patient.middleInitial && patient.middleInitial.trim(),
      email: patient.email && patient.email.trim(),
      gender_id: patient.genderId,
      height: [patient.heightOne, patient.heightTwo],
      weight: patient.weight,
      date_of_birth: DateUtils.formatDateToApi(patient.birthday),
      race_id: patient.raceId,
      secondary_email: patient.secondaryEmail,
    };
  }

  public transformToPatient(patient: IPatientApi): IPatient {
    return {
      id: patient.id,
      firstName: patient.first_name,
      lastName: patient.last_name,
      middleInitial: patient.middle_initial,
      email: patient.email,
      gender: this.transformToGender(patient.gender),
      genderId: patient.gender.id,
      birthday: patient.date_of_birth,
      heightOne: patient.height[0],
      heightTwo: patient.height[1],
      weight: patient.weight,
      race: this.transformToRace(patient.race),
      raceId: patient.race.id,
      age: patient.age,
      isImported: patient.is_imported,
      secondaryEmail: patient.secondary_email,
      isActive: patient.is_active,
    };
  }

  public transformToPatientForm(patient: IPatient): IPatientForm {
    return {
      firstName: patient.firstName,
      lastName: patient.lastName,
      middleInitial: patient.middleInitial,
      email: patient.email,
      birthday: patient.birthday,
      age: patient.age,
      heightOne: patient.heightOne,
      heightTwo: patient.heightTwo,
      weight: patient.weight,
      id: patient.id,
      genderId: patient.gender.id,
      raceId: patient.race.id,
      secondaryEmail: patient.secondaryEmail,
    };
  }

  public transformToPatientFormError(errors: any): any {
    const res = {};
    const keysPatientForm = (key) => {
      const listKeys = {
        email: 'email',
        secondary_email: 'secondaryEmail',
        middle_initial: 'middleInitial',
        first_name: 'firstName',
        last_name: 'lastName',
        gender_id: 'genderId',
        'height.0': 'heightOne',
        'height.1': 'heightTwo',
        weight: 'weight',
        date_of_birth: 'birthday',
        race_id: 'raceId',
      };

      return listKeys[key] || key;
    };
    Object.keys(errors).forEach((errorKey) => {
      res[keysPatientForm(errorKey)] = TransformServerValidationUtils.getFirsError(errors[errorKey]);
    });
    return res;
  }

  public transformToProviderList(data: IProviderListApi): IProviderList {
    return {
      id: data.id,
      name: data.name,
    };
  }
}
