import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { PermissionsService, UserService } from '@sp-core-services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Roles } from '@sp-core/agreement-keys/roles.enum';

@Component({
  selector: 'sp-footer-nav-menu',
  templateUrl: './footer-nav-menu.component.html',
  styleUrls: ['./footer-nav-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterNavMenuComponent implements OnInit, OnDestroy {
  public destroy$: Subject<void> = new Subject();
  public userRoles: typeof Roles = Roles;
  public user: IUser;
  public role: IRole;

  constructor(
    private permissionsService: PermissionsService,
    private cd: ChangeDetectorRef,
    private userService: UserService,
  ) {}

  public ngOnInit(): void {
    this.getRole();
    this.getUser();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private getRole(): void {
    this.permissionsService.role$.pipe(takeUntil(this.destroy$)).subscribe((role) => {
      this.role = role;
      this.cd.markForCheck();
    });
  }

  private getUser(): void {
    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
      this.cd.markForCheck();
    });
  }
}
