import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import DateUtils from '@sp-helpers/date-time/date-utils';
import TransformServerValidationUtils from '@sp-helpers/validation/transform-server-validation-utils';
import { CalcFormControlNameEnum } from '../agreement-keys/calc-form-control-name.enum';
import CalcUtils from '@sp-helpers/calc-utils';

@Injectable({
  providedIn: 'root',
})
export class CalcHttpAdapter {
  public readonly columnCountSymptomHistory = 3;
  public readonly columnCountLabs = 2;

  public transformToUsedDate(data: ILabsUsedDateApi[]): ILabsUsedDates {
    const dateMap = {};
    const date = data.map((item) => {
      dateMap[item.date] = true;
      return {
        id: item.id,
        date: item.date,
      };
    });
    return {
      date,
      dateMap,
    };
  }

  public transformToProcessedLabs(dataApi: ISeparateLabsDataApi): IProcessedLabs {
    const processedLabs = this.transformToAnalyses(dataApi.labs);
    return {
      labs: processedLabs,
      prepareLabs: CalcUtils.prepareNestedArray(processedLabs, this.columnCountLabs),
      labMeasurementTypes: this.transformToLabMeasurementTypes(dataApi.lab_measurement_types),
    };
  }

  public transformToCalcDictionaries(data: ICalcDictionariesApi): ICalcDictionaries {
    const history = data.symptoms && data.symptoms.history;
    const symptom = data.symptoms && data.symptoms.symptom;
    const symptomsHistory = CalcUtils.addRadioTypeToSymptomsHistory(history);
    const requiredSymptomsHistoryFields = symptomsHistory.requiredFields;
    const dictionariesSymptoms = this.getGroupSymptoms(
      CalcUtils.transformToSymptomHistory(history)?.symptomHistory,
      symptom,
    );
    const labs = this.transformToAnalyses(data.labs);
    const map = CalcUtils.createDictionariesValueMap(dictionariesSymptoms, labs);
    return {
      map,
      labs,
      requiredSymptomsHistoryFields,
      symptoms: this.getGroupSymptoms(symptomsHistory?.symptomHistory, symptom),
      stressFactor: this.transformToFactor(data.stress_factors),
      problemFactors: this.transformToFactor(data.problem_factors),
      labMeasurementTypes: this.transformToLabMeasurementTypes(data.lab_measurement_types),
      presentNonPelletSources: this.transformToPresentHormoneSources(data.present_hormone_sources.non_pellet),
      additionalHormone: this.transformToAdditionalHormone(data.present_hormone_sources.additional_hormone),
      prepareLabs: CalcUtils.prepareNestedArray(labs, this.columnCountLabs),
      medicationFrequencies: this.transformToMedicationFrequency(data.medication_frequencies),
      administered: this.transformToProgesterone(data.present_hormone_sources.progesterone),
    };
  }

  public getGroupSymptoms(newHistory: ISymptom[], symptom: ISymptomApi[]): ISymptoms {
    return {
      history: newHistory,
      prepareHistory: CalcUtils.prepareNestedArray(newHistory, this.columnCountSymptomHistory),
      prepareSymptom: CalcUtils.prepareNestedArray(symptom, this.columnCountSymptomHistory),
      symptom: this.transformToSymptom(symptom),
    };
  }

  public transformToSymptom(data: ISymptomApi[]): ISymptom[] {
    if (!data) {
      return null;
    }
    return data.map((item) => ({
      id: item.id,
      title: item.title,
      genderId: item.gender_id,
      isRequired: item.is_required,
      key: item.key,
      type: item.type,
    }));
  }

  public transformToFactor(data: IProblemFactorApi[]): IProblemFactor[] {
    return data.map((item) => ({
      id: item.id,
      title: item.title,
      genderId: item.gender_id,
    }));
  }

  public transformToAnalyses(data: ILabsApi[]): ILabs[] {
    return data.map((item) => ({
      id: item.id,
      title: item.title,
      genderId: item.gender_id,
      labMeasurementTypeId: item.measure_id,
      isRequired: item.is_required,
      key: item.key,
      min: item.min,
      max: item.max,
      type: item.type,
    }));
  }

  public transformToLabMeasurementTypes(data: ILabMeasurementTypesApi[]): ILabMeasurementTypes[] {
    return data.map((item) => ({
      id: item.id,
      title: item.title,
    }));
  }

  public transformToPresentHormoneSources(data: IPresentNonPelletSourcesApi[]): IPresentNonPelletSources[] {
    let hormoneGroup = null;
    if (!data) {
      return [];
    }
    const presentNonPellet = this.sortHormoneArrayByAsc(data);
    return presentNonPellet.map((item) => {
      const res: IPresentNonPelletSources = {
        id: item.id,
        hormone: item.hormone,
        title: item.title,
        values: this.transformToHormoneValue(item.values),
      };
      // add hormoneGroup for the firs object for the showing on html
      if (!hormoneGroup || (hormoneGroup && hormoneGroup !== item.hormone)) {
        hormoneGroup = item.hormone;
        res.hormoneGroup = item.hormone;
      }
      return res;
    });
  }

  public transformToAdditionalHormone(data: IAdditionalHormoneApi[]): IAdditionalHormone[] {
    if (!data || !data.length) {
      return [];
    }
    return data.map((item) => ({
      id: item.id,
      hormone: item.hormone,
      title: item.title,
      isRequired: item.is_required || true,
      min: item.min || 0,
      max: item.max || 150,
      type: item.type || 'float',
    }));
  }

  public transformToHormoneValue(hormones: INonPelletValueApi[]): INonPelletValue[] {
    return hormones.map((hormone) => ({ id: hormone.id, value: hormone.value }));
  }

  public transformToInitCalc(data: ICalculationInitResponseApi): ICalculationInitResponse {
    return {
      id: data.id,
    };
  }

  public transformToCalcInit(data: ICalculationInit): ICalculationInitApi {
    return {
      insertion_type: data.insertionType,
      patient_id: data.patientId,
    };
  }

  public transformToCalculation(calculation: ICalculationCommonApi): ICalculationCommon {
    let calculationCommon: any = {
      id: calculation.id,
      patientId: calculation.patient_id,
      age: String(calculation.age),
      height: String(calculation.height),
      weight: String(calculation.weight),
      raceId: calculation.race_id,
      returnVisitCount: String(calculation.return_visit_count),
      insertionType: calculation.insertion_type,
      weeksSinceLastInsertion: String(calculation.weeks_since_last_insertion || '0'),
      usageCount: calculation.usage_count,
      testosteroneCalculatedValue: calculation.testosterone_calculated_value,
      estradiolCalculatedValue: calculation.estradiol_calculated_value,
      problemFactor: calculation.problem_factor_id,
      stressFactor: calculation.stress_factor_id,
      symptoms: calculation.symptoms ? calculation.symptoms.symptom : [],
      presentHormones: this.transformToPresentHormones(calculation.present_hormones),
      symptomHistory: calculation.symptoms ? calculation.symptoms.history : [],
      labDrawId: calculation.lab_draw_id,
      isPrescribed: calculation.is_prescribed,
      isInitial: calculation.is_initial,
    };
    calculationCommon = this.addToCalculationCommonProgesterone(calculationCommon, calculation);

    calculationCommon = this.addToICalculationCommonMostRecentData(calculationCommon, calculation);

    return calculationCommon;
  }

  public transformToPresentHormones(nonPellet: IPresentHormonesApi[]): IPresentHormones {
    if (!nonPellet || !nonPellet.length) {
      return {};
    }
    return nonPellet.reduce((prev, next) => {
      if (next.present_hormone_dose_id) {
        prev[next.present_hormone_source_id] = {
          hormoneId: next.present_hormone_source_id,
          hormoneValueId: next.present_hormone_dose_id,
        };
      }
      return prev;
    }, {});
  }

  public transformToLabForm(labs: ILabsFormApi[]): ILabsForm {
    if (!labs || !labs.length) {
      return {};
    }
    const temp = {};
    labs.forEach((item) => {
      temp[item.id] = {
        [item.id]: String(item.value),
        measureId: item.measure_id,
      };
    });
    return temp;
  }

  public transformToPatchCalculation(data: ICalcFormRowData): ICalculationPatchDataApi {
    let transformedData: ICalculationPatchDataApi = {
      age: data.age,
      height: data.height,
      weight: data.weight,
      race_id: data.raceId,
      symptoms: this.transformToSymptomsApi(data.symptoms, data.symptomHistory, data.numeric),
      labs_draw: {
        id: data.dateOfLabDraw,
        labs: this.transformToLabsApi(data.labs),
      },
      hormones: this.transformToHormonesApi(data.hormones),
      stress_factor_id: data.stressFactor,
      problem_factor_id: data.problemFactor,
      return_visit_count: data.returnVisitCount,
      weeks_since_last_insertion: data.weeksSinceLastInsertion,
      is_prev_booster_insertion: data.isPrevBoosterInsertion,
      last_full_testosterone_insertion: data.lastFullTestosteroneInsertion,
      last_full_estradiol_insertion: data.lastFullEstradiolInsertion,
      booster_testosterone_insertion: data.boosterTestosteroneInsertion,
      booster_estradiol_insertion: data.boosterEstradiolInsertion,
    };
    transformedData = this.handlerFemaleProgesterone(data, transformedData);
    return CalcUtils.addMostRecentData(transformedData, data);
  }

  public transformToSymptomsApi(
    symptoms: string[],
    symptomHistory: string[],
    numerics?: INumericValue,
  ): ISymptomsPatchApi {
    let res = {};
    if (numerics) {
      res = {
        ...numerics,
      };
    }
    // TODO: refactor it
    symptoms.forEach((item) => {
      res[item] = true;
    });
    symptomHistory.forEach((item) => {
      res[item] = true;
    });
    return res;
  }

  public transformToLabsApi(labs: ILabsFormValue): ILabsPatchApi[] {
    const res = [];
    res.push(
      ...Object.keys(labs).map((key) => ({
        lab_id: key,
        value: labs[key][key],
        measure_id: labs[key].measureId,
      })),
    );
    return res;
  }

  public transformToHormonesApi(hormones: IHormonesFormValue): IHormonesPatchApi[] {
    if (!hormones) {
      return [];
    }
    const res = [];
    res.push(
      ...Object.keys(hormones).map((key) => ({
        hormone_id: hormones[key].hormoneId,
        hormone_value_id: hormones[key].hormoneValueId,
      })),
    );
    return res;
  }

  public transformToCalculationResult(data: ICalculationResultApi): ICalculationResult {
    return {
      estradiolDose: data.estradiol_dose,
      testosteroneDose: data.testosterone_dose,
    };
  }

  public transformToTypeOfInjection(data: IPatientTypeOfInjectionApi): IPatientTypeOfInjection {
    return {
      showPopup: data.show_modal,
    };
  }

  public transformToCommonEditableData(data: ICommonEditableDataApi): ICommonEditableData {
    if (!data) {
      return null;
    }
    return {
      lastCalculationId: data.last_calculation_id,
      insertionUsage: data.insertion_usage,
      calculationUsage: data.calculation_usage,
      insertionDate: DateUtils.format(data.insertion_date, 'YYYY-MM-DD'),
    };
  }

  public transformToSeparateLabsApi(data: ISeparateLabsForm): ISeparateLabsFormDataApi {
    return {
      labs: this.transformToLabsApi(data.labs),
      date: DateUtils.formatDateToApi(data.dateOfLabDraw),
    };
  }

  public getFilterLabsForShowing(labsGroup: UntypedFormGroup, calcDictionariesLabs: ILabs[]): ILabs[][] {
    const mapEnteredLabs = {};
    const labsGroupValue = labsGroup.value;
    const mapRequiredLabs = CalcUtils.getRequiredLabs(calcDictionariesLabs);
    Object.keys(labsGroupValue).forEach((key) => {
      if (labsGroupValue[key][key] || mapRequiredLabs.has(key)) {
        mapEnteredLabs[key] = key;
      }
    });
    const labsWithOutEmptyValue: ILabs[] = calcDictionariesLabs.filter((lab) => mapEnteredLabs[lab.id]);
    return CalcUtils.prepareNestedArray(labsWithOutEmptyValue, 2);
  }

  public addToICalculationCommonMostRecentData(
    data: ICalculationCommon,
    calculation: ICalculationCommonApi,
  ): ICalculationCommon {
    if (calculation.testosterone_previous_dose || calculation.estradiol_previous_dose) {
      return {
        ...data,
        [CalcFormControlNameEnum.mostRecentDosage]: {
          [CalcFormControlNameEnum.estradiol]: calculation.estradiol_previous_dose,
          [CalcFormControlNameEnum.testosterone]: calculation.testosterone_previous_dose,
        },
      };
    }
    return data;
  }

  public addToCalculationCommonProgesterone(
    data: ICalculationCommon,
    calculation: ICalculationCommonApi,
  ): ICalculationCommon {
    if (calculation.is_progesterone_taken) {
      return {
        ...data,
        isProgesteroneTaken: calculation.is_progesterone_taken,
        progesterone: {
          frequencyId: calculation.progesterone.frequency_id,
          administeredId: calculation.progesterone.administered_id,
          progesteroneValue: calculation.progesterone.value,
          additionalDetails: calculation.progesterone?.additional_details || null,
          administrationRoute: calculation.progesterone.administration_route || null,
        },
      };
    }
    return {
      ...data,
      isProgesteroneTaken: calculation.is_progesterone_taken,
    };
  }

  public transformToMedicationFrequency(frequencies: IFrequencyApi[]): IFrequency[] {
    if (!frequencies) {
      return null;
    }
    return frequencies.map((frequency) => ({ id: frequency.id, title: frequency.title }));
  }

  public transformToProgesterone(progesterones: IProgesteroneApi[]): IAdministered[] {
    if (!progesterones) {
      return null;
    }
    return progesterones.map((progesterone) => ({
      id: progesterone.id,
      title: progesterone.title,
      hormone: progesterone.hormone,
    }));
  }

  public handlerFemaleProgesterone(
    calcFormData: ICalcFormRowData | IConsultRequest,
    prepareData: ICalculationPatchDataApi,
  ): ICalculationPatchDataApi {
    return this.setProgesterone(calcFormData.isProgesteroneTaken, prepareData, calcFormData.progesterone);
  }

  public setProgesterone(isTaken: boolean, data: any, progesterone: IProgesterone): any {
    return isTaken
      ? {
          ...data,
          is_progesterone_taken: isTaken,
          progesterone: {
            administered_id: progesterone.administeredId,
            frequency_id: progesterone.frequencyId,
            value: progesterone.progesteroneValue,
            additional_details: progesterone?.additionalDetails || null,
            administration_route: progesterone?.administrationRoute || null,
          },
        }
      : {
          ...data,
          is_progesterone_taken: isTaken,
        };
  }

  public transformToCalcFormError(errors: any): any {
    const res = {};
    const keysCalcForm = (key) => {
      const errorLabsPath = 'labs_draw.labs.';
      const listKeys = {
        age: 'age',
        weight: 'weight',
        height: 'height',
        race_id: 'raceId',
        weeks_since_last_insertion: 'weeksSinceLastInsertion',
        return_visit_count: 'returnVisitCount',
        stress_factor_id: 'stressFactor',
        problem_factor_id: 'problemFactor',
        'labs_draw.id': 'dateOfLabDraw',
        'progesterone.administered_id': 'progesterone.administeredId',
        'progesterone.frequency_id': 'progesterone.frequencyId',
        'progesterone.value': 'progesterone.progesteroneValue',
        'progesterone.additional_details': 'progesterone.additional_details',
        'progesterone.administration_route': 'progesterone.administration_route',
      };
      if (`${key}`.startsWith(errorLabsPath)) {
        // @ts-ignore
        return key.replace(/^(labs_draw.labs.)(\S*)/, (match, p1, p2) => `labs.${p2}.${p2}`);
      }
      return listKeys[key] ? listKeys[key] : key;
    };

    Object.keys(errors).forEach((errorKey) => {
      res[keysCalcForm(errorKey)] = TransformServerValidationUtils.getFirsError(errors[errorKey]);
    });
    return res;
  }

  private sortHormoneArrayByAsc(data: any): IPresentNonPelletSourcesApi[] {
    return data.sort((a, b) => a.hormone - b.hormone);
  }

  public transformToDosingResponsibilityAgreement(
    data: IDosingResponsibilityAgreementApi,
  ): IDosingResponsibilityAgreement {
    if (!data) {
      return null;
    }
    return {
      agreementId: data.id,
      message: data.text,
      type: data.type,
    };
  }

  public transformToCalculatorReturnParams(data: ICalculationReturnParamsApi): ICalculationReturnParams {
    return {
      isPrevBoosterInsertion: data.is_prev_booster_insertion,
      lastFullTestosteroneInsertion: data.last_full_testosterone_insertion,
      lastFullEstradiolInsertion: data.last_full_estradiol_insertion,
      boosterTestosteroneInsertion: data.booster_testosterone_insertion,
      boosterEstradiolInsertion: data.booster_estradiol_insertion,
    };
  }
}
