export enum ActivityEventsActionsEnum {
  responsibilityDisclaimerViewed = 'responsibility_disclaimer_viewed',
  responsibilityDisclaimerAcknowledge = 'responsibility_disclaimer_acknowledge',
  privacyPolicyViewed = 'privacy_policy_viewed',
  securityPolicyViewed = 'security_policy_viewed',
  patentsViewed = 'patents_viewed',
  insertionNoteTemplateAcknowledge = 'insertion_note_template_acknowledge',
  responsibilityDisclaimerEmptyLotsViewed = 'empty_lots_disclaimer_viewed',
  kbResponsibilityDisclaimer = 'knowledge_base_disclaimer_viewed',
}
