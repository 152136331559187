import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { CanViewModule } from '@sp-shared/directives/can-view/can-view.module';
import { MatMenuModule } from '@angular/material/menu';
import { QaModule } from '@sp-shared/directives/data-cy/qa.module';
import { OrderInfoPopupModule } from '@sp-shared/components/order-info-popup/order-info-popup.module';
import { UserNavMenuComponent } from './user-nav-menu.component';
import { UserNavItemComponent } from './components/user-nav-item/user-nav-item.component';

@NgModule({
  declarations: [UserNavMenuComponent, UserNavItemComponent],
  imports: [CommonModule, TranslateModule, RouterModule, CanViewModule, MatMenuModule, QaModule, OrderInfoPopupModule],
  exports: [UserNavMenuComponent, UserNavItemComponent],
})
export class UserNavMenuModule {}
