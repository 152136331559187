import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VerificationHttpAdapter {
  public transformToVerificationPhone(value: { phone: string; phonePrefixId: string }): IPhoneVerificationApi {
    return {
      phone: value.phone,
      code_id: value.phonePrefixId,
    };
  }

  public transformToCodeVerification(
    data: { phone: string; phonePrefixId: string },
    code: string,
  ): IVerificationPhoneApi {
    return {
      code,
      phone: data.phone,
      code_id: data.phonePrefixId,
    };
  }

  public transformToCodeVerificationForEmail(data: { email: string }, code: string): { code: string; email: string } {
    return {
      code,
      email: data.email,
    };
  }

  public transformToPhoneCode(data: IPhonePrefixesApi[]): IPhoneResponse {
    let defaultValue: IPhonePrefixes;
    const phonePrefixesMap = {};
    const phonePrefixes: IPhonePrefixes[] = data.map((prefix) => {
      const phone = {
        id: prefix.id,
        prefix: prefix.prefix,
        countryTitle: prefix.country.title,
        countryKey: prefix.country.key,
        countryId: prefix.country.id,
      };
      phonePrefixesMap[phone.id] = phone;
      if (phone.prefix === '+1') {
        defaultValue = phone;
      }
      return phone;
    });
    return {
      defaultValue,
      phonePrefixes,
      phonePrefixesMap,
    };
  }

  public transformToPhone(data: IPhonePrefixesApi): IPhonePrefixes {
    return {
      id: data.id,
      prefix: data.prefix,
      countryTitle: data.country.title,
      countryKey: data.country.key,
      countryId: data.country.id,
    };
  }
}
