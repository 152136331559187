import { Injectable } from '@angular/core';
import { CalcHttpAdapter } from '@sp-core/http-adapters/calc-http-adapter';
import TransformServerValidationUtils from '@sp-helpers/validation/transform-server-validation-utils';

@Injectable({
  providedIn: 'root',
})
export class LabsHttpAdapterService {
  constructor(public httpCalcAdapter: CalcHttpAdapter) {}

  public transformToLab(lab: ILabDrawApi): ILabDraw {
    return {
      id: lab.id,
      date: lab.date,
      isEditable: lab.is_editable,
    };
  }

  public transformToOptionalParams(calculationId: string): { [key: string]: string } | {} {
    return calculationId ? { calculation_id: calculationId } : {};
  }

  public transformToLabDraw(data: ISeparateLabsDrawApi): ISeparateLabsDraw {
    return {
      id: data.id,
      dateOfLabDraw: data.date,
      isEditable: data.is_editable,
      labs: this.httpCalcAdapter.transformToLabForm(data.labs),
    };
  }

  public getOptionalParamsValidation(isRequire: boolean): { [key: string]: boolean } {
    return { patient_labs_validation: !!isRequire };
  }

  public transformToLabsFormError(errors: IServerValidationErrors): IServerValidationErrors {
    const res = {};
    const keysLabs = (key) => {
      const errorLabsPath = 'labs_draw.labs.';
      const listKeys = {
        date: 'dateOfLabDraw',
      };
      if (`${key}`.startsWith(errorLabsPath)) {
        // @ts-ignore
        return key.replace(/^(labs_draw.labs.)(\S*)/, (match, p1, p2) => `labs.${p2}.${p2}`);
      }
      return listKeys[key] ? listKeys[key] : key;
    };

    Object.keys(errors).forEach((errorKey) => {
      res[keysLabs(errorKey)] = TransformServerValidationUtils.getFirsError(errors[errorKey]);
    });
    return res;
  }
}
