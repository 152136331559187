export const environment = {
  env: 'dev',
  production: true,
  apiHost: 'https://api.dev.pelletdose.co/',
  frontHost: 'https://dev.pelletdose.co/',
  inviteRout: 'sign-up?token=',
  resetPassword: 'reset-password?token=',
  dsn: 'https://49756fae12d0454f90be97335f4337c5@sentry.light-it.tools/94',
  tawkToAccountId: '5e56bded298c395d1ce9f885',
  freshWorkId: '154000001417',
};
