<div class="order-info-popup">
  <button spQaId="order-info-popup_button_close" class="close" (click)="closed()">
  </button>

  <div class="order-info-popup__header mb-4">
    <div class="order-info-popup__logo-wrap">
      <img class="order-info-popup__logo" alt="compound-connection-logo" src="assets/images/logos/compound-connection_logo.png">
    </div>
    <h1 class="order-info-popup__title common-popup-title text-center">
      {{'ORDER' | translate}}
    </h1>
  </div>

  <mat-dialog-content class="mb-5">
    <div class="order-info-popup__wrap-content">
      <p class="order-info-popup__info-text mb-5">
        {{'ORDER_SOTTOPELLE_INFO_TEXT' | translate}}
      </p>
      <p class="order-info-popup__description-text mb-3">
        {{'ORDER_SOTTOPELLE_INFO_DESCRIPTION_TEXT' | translate}}
      </p>
      <ul class="order-info-popup__info-list">
        <li class="order-info-popup__info-list-item mb-2">
          <p class="order-info-popup__info-item-title">{{'PHARMACY_PRODUCTS' | translate}}</p>
          <p>{{'PHARMACY_PRODUCTS_TEXT' | translate}}</p>
        </li>
        <li class="order-info-popup__info-list-item mb-2">
          <p class="order-info-popup__info-item-title">{{'COST_SAVINGS' | translate}}</p>
          <p>{{'COST_SAVINGS_TEXT' | translate}}</p>
        </li>
        <li class="order-info-popup__info-list-item mb-2">
          <p class="order-info-popup__info-item-title">{{'TIME_SAVINGS' | translate}}</p>
          <p>{{'TIME_SAVINGS_TEXT' | translate}}</p>
        </li>
        <li class="order-info-popup__info-list-item mb-2">
          <p class="order-info-popup__info-item-title">{{'ONLINE_ORDERING_EFAX' | translate}}</p>
          <p>{{'ONLINE_ORDERING_EFAX_TEXT' | translate}}</p>
        </li>
        <li class="order-info-popup__info-list-item mb-2">
          <p class="order-info-popup__info-item-title">{{'SUPPLIER_RELATIONSHIP_MANAGE' | translate}}</p>
          <p>{{'SUPPLIER_RELATIONSHIP_MANAGE_TEXT' | translate}}</p>
        </li>
      </ul>
    </div>
  </mat-dialog-content>

  <div class="order-info-popup__action mb-5">
    <div class="order-info-popup__action-item mb-4">
      <a class="btn-purple link-btn" spQaId="order-info-popup__button_okay" target="_blank" href="https://www.compoundconnection.com/login.aspx?mode=1">
        {{'NAV.LOGIN' | translate}}
      </a>
    </div>

<!--    <div class="order-info-popup__action-item">-->
<!--      <a class="btn-purple link-btn"-->
<!--         spQaId="order-info-popup__button_okay"-->
<!--         target="_blank"-->
<!--         [href]="currentUserRole?.key === role.dosingProvider ? dosingProviderCreateAccountLink : commonCreateAccountLink">-->
<!--        {{'CREATE_ACCOUNT' | translate}}-->
<!--      </a>-->
<!--    </div>-->

    <div class="order-info-popup__action-item">
      <a class="btn-purple link-btn"
         spQaId="order-info-popup__button_okay"
         target="_blank"
         href="https://www.compoundconnection.com/register.aspx?sr=16">
        {{'CREATE_ACCOUNT' | translate}}
      </a>
    </div>
  </div>

  <div class="order-info-popup__additional-info">
    {{'HAVE_ADDITIONAL_QUESTIONS' | translate}}
    <span class="text-info">323-986-5100</span>
    {{'OR_EMAIL' | translate}}
    <a class="text-info" href="mailto:support@dosaggio.co">
      support&#64;dosaggio.co
    </a>
  </div>
</div>
