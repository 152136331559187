import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ApiConfig } from '@sp-core/api-config';

@Injectable({
  providedIn: 'root',
})
export class HttpResourcesService {
  constructor(public readonly _http: HttpClient) {}

  public getUsersResources(params: HttpParamsOptions): Observable<ITableApiData<IResourceApi>> {
    return this._http.get<ITableApiData<IResourceApi>>(ApiConfig.resourcesPath, { params });
  }

  public createResource(data: IResourceRequestApi): Observable<IResourceApi> {
    return this._http.post<IResourceApi>(ApiConfig.resourcesPath, data);
  }

  public updateResource(id: string, data: IResourceRequestApi): Observable<IResourceApi> {
    return this._http.put<IResourceApi>(`${ApiConfig.resourcesPath}/${id}`, data);
  }

  public getResourceById(id: string): Observable<IResourceApi> {
    return this._http.get<IResourceApi>(`${ApiConfig.resourcesPath}/${id}`);
  }

  public changeResourceStatus(data: IResourceChangeStatusApi): Observable<IResourceApi> {
    return this._http.patch<IResourceApi>(`${ApiConfig.resourcesPath}/${data.id}`, {
      is_active: data.is_active,
    });
  }

  public deleteResource(id: string): Observable<void> {
    return this._http.delete<void>(`${ApiConfig.resourcesPath}/${id}`);
  }

  public getQuantityOfNewResources(): Observable<IResourcesQuantity> {
    return this._http.get<IResourcesQuantity>(`${ApiConfig.resourcesPath}/new`);
  }

  public setResourcesIsViewed(resources: string[]): Observable<void> {
    return this._http.post<void>(`${ApiConfig.resourcesPath}/view`, { resources });
  }
}
