import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MedicalQuestionsHttpAdapter {
  public transformToMedicalQuestion(data: IMedicalQuestionApi): IMedicalQuestion {
    return {
      id: data?.id,
      providerName: data?.creator_name,
      subject: data?.subject,
      description: data?.description,
      creatorId: data?.creator_id || null,
      creatorUserId: data?.creator_user_id || null,
      practiceName: data?.practice_name,
      date: data?.submitted_date,
      status: data?.status,
      assignee: data?.assignee_name,
      assigneeId: data?.assignee_id || null,
      assigneePracticeName: data?.assignee_practice_name || null,
      assigneeUserId: data?.assignee_user_id || null,
      assignedDate: data?.assigned_date || null,
      resolvedDate: data?.resolved_date || null,
      userType: data?.user_type || null,
      recentReplyDate: data?.recent_reply_date || null,
    };
  }

  public transformToMedicalQuestionCreateApi(data: IMedicalQuestionCreate): IMedicalQuestionCreateApi {
    return {
      subject: data?.subject,
      description: data?.description,
    };
  }

  public transformToMedicalQuestionMessage(data: IMedicalQuestionMessageApi): IMedicalQuestionMessage {
    return {
      id: data.id,
      creatorId: data.creator_id,
      creatorName: data.creator_name,
      message: data.message,
      dateTime: data.datetime,
      employeeId: data?.employee_id || null,
      practiceName: data?.practice_name || null,
      medicalQuestionId: data?.medical_question_id || null,
    };
  }
}
