import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AnnouncementsService } from '@sp-core/services/announcements/announcements.service';
import { Subject } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { AnnouncementsHttpAdapter } from '@sp-core/http-adapters/announcements-http-adapter';
import { HttpAnnouncementsService } from '@sp-core/http-services/http-announcements/http-announcements.service';
import { PermissionsService } from '@sp-core-services';
import { Roles } from '@sp-core/agreement-keys/roles.enum';
import { IUploadFile } from '@sp-core/models/app-models/upload-file.interface';

export interface IAnnouncementPopupData {
  announcementId: string;
  currentIndex?: number;
  announcementsLength?: number;
}

@Component({
  selector: 'sp-announcement-popup',
  templateUrl: './announcement-popup.component.html',
  styleUrls: ['./announcement-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementPopupComponent implements OnInit, OnDestroy {
  public announcement: IAnnouncement;
  public isDataLoading = false;
  public destroy$: Subject<void> = new Subject<void>();
  public acceptFileType = 'application/pdf';
  public currentFilesList: IUploadFile[] = [];
  public userRoles: typeof Roles = Roles;

  constructor(
    public readonly _dialogRef: MatDialogRef<AnnouncementPopupComponent>,
    public readonly _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public readonly _data: IAnnouncementPopupData,
    public readonly _announcementsService: AnnouncementsService,
    public readonly _cd: ChangeDetectorRef,
    public readonly _announcementsHttpAdapter: AnnouncementsHttpAdapter,
    public readonly _httpAnnouncementsService: HttpAnnouncementsService,
    public readonly _permissionsService: PermissionsService,
  ) {}

  public ngOnInit(): void {
    this.getAnnouncement();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public getAnnouncement(): void {
    this.isDataLoading = true;
    this._announcementsService
      .getAnnouncementById(this._data.announcementId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (announcement) => {
          this.announcement = {
            ...announcement,
            content: this._announcementsService.replaceContentSrcToAwsLink(
              announcement?.content,
              announcement?.attachments,
              this.acceptFileType,
            ),
          };
          this.currentFilesList = this._announcementsHttpAdapter.transformToAnnouncementsUploadFile(
            announcement.attachments,
            this.acceptFileType,
          );
          this.isDataLoading = false;
          this._cd.detectChanges();
        },
        error: () => {
          this.isDataLoading = false;
        },
      });
  }

  public closed(): void {
    this._dialogRef.close('test');
  }

  public updateAnnouncementStatus(): void {
    this.isDataLoading = true;
    this._announcementsService
      .setAnnouncementAcknowledgeStatus(this._data.announcementId)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.isDataLoading = false)),
      )
      .subscribe({
        next: () => {
          this._dialogRef.close(true);
        },
      });
  }

  public updateAwsUrlDataBeforeDownloadFile(file: IUploadFile): void {
    const transformData: IAwsUploadApi = this._announcementsHttpAdapter.transformToAwsUploadApiData({
      id: file.id,
      awsLink: file.awsLink,
      src: file.src,
      fileName: file.file_name,
      fileType: file.file_type,
    });
    this._httpAnnouncementsService
      .getFileFromAwsS3(transformData)
      .pipe(map((res) => this._announcementsHttpAdapter.transformToAwsUploadData(res)))
      .subscribe((data) => {
        const link = document.createElement('a');
        link.href = data.awsLink;
        link.download = data.fileName;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  public get currentUserRole(): IRole {
    return this._permissionsService.role;
  }
}
