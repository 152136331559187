import { Injectable } from '@angular/core';
import { HormonesKeysEnum } from '@sp-core/agreement-keys/hormones-api.enum';
import DateUtils from '@sp-helpers/date-time/date-utils';
import Utils from '@sp-helpers/utils';

@Injectable({
  providedIn: 'root',
})
export class InsertionHttpAdapter {
  public transformToPellets(pellet: IPelletsApi): IPellets {
    const res: any = {};
    Object.keys(pellet.hormones).forEach((key: string) => {
      const frontKey = HormonesKeysEnum[key];
      res[frontKey] = this.transformToPellet(pellet.hormones[key], frontKey);
    });
    return res;
  }

  public transformToPellet(pellet: IPelletApi, controlName: string): IPellet {
    if (!pellet) {
      return null;
    }
    return {
      controlName,
      title: pellet.title,
      pellets: pellet.values.map((item) => this.transformToLot(item)),
    };
  }

  public transformToLot(pellet: IPelletValueApi): ICalculatorPelletValue {
    return {
      id: pellet.id,
      hormoneId: pellet.hormone_id,
      title: pellet.title,
      capacity: pellet.capacity,
      value: (pellet && pellet.value) || 0,
    };
  }

  public transformToInsertion(data: IInsertionApi): IInsertion {
    return {
      id: data.id,
      calculationId: data.calculation_id,
      testosteroneCalculatedValue: data.testosterone_calculated_value,
      estradiolCalculatedValue: data.estradiol_calculated_value,
      testosteronePrescribedValue: data.testosterone_prescribed_value,
      estradiolPrescribedValue: data.estradiol_prescribed_value,
      note: data.note,
      date: data.date,
      insertionHistory: this.transformToInsertionHistory(data.insertion_history),
      insertionLocation: data.insertion_location,
      insertionSide: data.insertion_side,
      insertionType: data.insertion_type,
      updateCounter: data.update_counter,
      pellets: this.transformToSavedPellets(data.pellets),
      lots: this.transformToSavedLots(data.lots),
      anesthesia: {
        withEpinephrine: data?.anesthesia?.with_epinephrine,
        withoutEpinephrine: data?.anesthesia?.without_epinephrine,
      },
      bandaging: data?.bandaging || {},
      location: data?.location,
      side: data?.side,
    };
  }

  public transformToSavedLots(data: ILotsSavedApi): ILotsSaved {
    if (!data) {
      return null;
    }
    const res: ILotsSaved = {};
    Object.keys(data).forEach((key) => {
      const tempLots = data[key].map((lot) => ({
        id: lot.pellet_id,
        insertionId: lot.insertion_id,
        hormoneId: lot.hormone_id,
        pelletId: lot.pellet_id,
        value: lot.value,
        capacity: lot.pellet_capacity,
        title: lot.pellet_title,
        valueFromApi: lot.value,
      }));
      res[HormonesKeysEnum[key]] = tempLots;
    });
    return res;
  }

  public transformToSavedPellets(data: IPelletsSavedApi): IPelletsSaved {
    if (!data) {
      return null;
    }
    const res: IPelletsSaved = {};
    Object.keys(data).forEach((key) => {
      const tempPellets = data[key].map((pellet: ISinglePelletApi) => ({
        id: pellet.pellet_id,
        hormoneId: pellet.hormone_id,
        title: pellet.pellet_title,
        value: pellet.count,
        capacity: pellet.pellet_capacity,
      }));
      res[HormonesKeysEnum[key]] = tempPellets;
    });
    return res;
  }

  public transformToInsertionHistory(insertionHistory: InsertionHistoryApi): InsertionHistory {
    if (!insertionHistory) {
      return null;
    }
    const historyHeaders = this.transformToInsertionHistoryHeaders(insertionHistory.headers);
    return {
      headersIndex: historyHeaders.headersIndex,
      headersValue: historyHeaders.headersValue,
      data: insertionHistory.data.map((value) => ({ values: [...value.values] })),
      calculation: insertionHistory?.calculation || null,
      insertion: insertionHistory?.insertion || null,
      siteNote: insertionHistory?.site_note || null,
      parent: insertionHistory?.parent
        ? insertionHistory.parent.map((item) => ({
            parentId: item?.parent_id,
            type: item?.type,
          }))
        : null,
    };
  }

  public transformToInsertionHistoryHeaders(data: string[]): IHeadersTable {
    const tempValue = {
      headersIndex: [],
      headersValue: [],
    };
    data.forEach((header, index) => {
      tempValue.headersIndex.push(`a-${index}`);
      tempValue.headersValue.push(header);
    });
    return tempValue;
  }

  public transformToInsertionPatch(prescribe: IPrescribeFormValue): IPrescribeValueApi {
    return {
      side: prescribe.side,
      location: prescribe.location,
      anesthesia: {
        with_epinephrine: prescribe.anesthesia.withEpinephrine,
        without_epinephrine: prescribe.anesthesia.withoutEpinephrine,
      },
      bandaging: prescribe.bandaging,
      estradiol: this.transformToHormonesCommonValue(prescribe.estradiol),
      testosterone: this.transformToHormonesCommonValue(prescribe.testosterone),
      testosterone_anastrozole: this.transformToHormonesCommonValue(prescribe.anastrozole),
      estradiol_lots: this.transformToHormonePelletLotsApi(prescribe.estradiolLots),
      testosterone_anastrozole_lots: this.transformToHormonePelletLotsApi(prescribe.anastrozoleLots),
      testosterone_lots: this.transformToHormonePelletLotsApi(prescribe.testosteroneLots),
      date: DateUtils.formatDateToApi(prescribe.date),
      note: prescribe.note,
    };
  }

  public transformToInsertionData(data: { date: string }): { date: string } {
    return {
      date: DateUtils.formatDateToApi(data.date),
    };
  }

  public transformToHormonesCommonValue(data: IHormoneCommon[]): IHormoneCommonApi[] {
    if (!data) {
      return null;
    }
    return data.map((item) => ({
      pellet_id: item.id,
      hormone_id: item.hormoneId,
      value: item.value,
    }));
  }

  public transformToHormonePelletLotsApi(data: IHormonePelletLots): IHormoneCommonApi[] {
    const res = [];
    if (!data) {
      return null;
    }
    Object.values(data).forEach((item) => {
      item.forEach((pellet) => {
        res.push({
          pellet_id: pellet.id,
          hormone_id: pellet.hormoneId,
          value: pellet.lotNumber || null,
        });
      });
    });
    return res.map((value) => Utils.removeNullAndUndefinedPropertiesFromObj(value));
  }

  public transformToLastInsertion(data: ILastInsertionApi): ILastInsertion {
    if (!data) {
      return null;
    }
    return {
      insertionDate: DateUtils.format(data.insertion_date, 'YYYY-MM-DD'),
      insertionUsage: data.insertion_usage,
      parentId: data.parent_id,
      insertionId: data.insertion_id,
      parentUsage: data.parent_usage,
      type: data.type,
    };
  }

  public transformToUnfinishedInsertion(data: IUnfinishedInsertionItemApi[]): IUnfinishedInsertionItem[] {
    if (!data || data.length === 0) {
      return [];
    }
    return data.map((item) => ({
      parentId: item.parent_id,
      type: item.type,
    }));
  }

  public transformToInsertionExportByParams(data: any): IInsertionExportByParams {
    return {
      format: data?.format[0],
      providers: data?.providers[0],
      date_start: data?.date_start[0],
      date_end: data?.date_end[0],
      reason: data?.reason[0],
    };
  }
}
