import { Injectable } from '@angular/core';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { UserService } from '@sp-core-services';
import { StickyNotesService } from '../../core/services/sticky-notes/sticky-notes.service';
import { StickyNotesContextService } from './sticky-notes-context.service';
import { StickyNoteComponent } from '../containers/sticky-note/sticky-note.component';
import { MultipleDialogService } from './multiple-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class StickyNotesControllerService {
  public context;

  constructor(
    private dialog: MultipleDialogService,
    private contextService: StickyNotesContextService,
    private stickyNotesService: StickyNotesService,
    private userService: UserService,
  ) {
    this.updateContextFlow$.subscribe();
  }

  public get context$(): Observable<IStickyNoteContext> {
    return this.contextService.getContext$();
  }

  public get updateContextFlow$(): Observable<IStickyNote[]> {
    return this.context$.pipe(
      tap((context) => {
        this.context = context;
      }),
      switchMap((context) => {
        if (context) {
          return this.getStickyNotes(context);
        }
        return of(null);
      }),
      tap((stickyNotes) => {
        this.dialog.closeAll();
        if (stickyNotes && stickyNotes.length > 0) {
          stickyNotes.forEach((stickyNote) => this.addNote(stickyNote));
        }
      }),
      catchError(() => {
        this.dialog.closeAll();
        return of(null);
      }),
    );
  }

  public addNote(stickyNote?: IStickyNote): void {
    const data = {
      stickyNote: stickyNote || { context: this.context },
      userId: this.userService?.user?.id,
    };
    this.dialog.open(StickyNoteComponent, {
      data,
      relativePosition: stickyNote?.coordinates || null,
      panelClass: 'sticky-note',
    });
  }

  private getStickyNotes(context: IStickyNoteContext): Observable<IStickyNote[]> {
    return this.stickyNotesService.getStickyNotes(context).pipe(catchError(() => of(null)));
  }
}
