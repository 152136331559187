import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { QaModule } from '@sp-shared/directives/data-cy/qa.module';
import { InfoPopupComponent } from './containers/info-popup/info-popup.component';
import { InfoPopupService } from './services/info-popup.service';

@NgModule({
  declarations: [InfoPopupComponent],
  imports: [CommonModule, MatDialogModule, MatDividerModule, MatButtonModule, TranslateModule, QaModule],
  providers: [InfoPopupService],
})
export class InfoPopupModule {}
