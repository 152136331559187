import { Injectable } from '@angular/core';
import { from, Observable, of, throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { InfoPopupComponent } from '@sp-shared/containers/info-popup/containers/info-popup/info-popup.component';
import { IInfoPopupData } from '@sp-shared/containers/info-popup/interfaces/info-popup-data-interface';
import { InsertionTypes } from '@sp-core/agreement-keys/insertion-types.enum';
import { ServerStatusCode } from '@sp-core/agreement-keys/server-status-code.enum';
import { InsertionService } from '@sp-core/services/insertion/insertion.service';
import { Gender } from '@sp-core/agreement-keys/gender.enum';
import { CalcService } from './calc.service';
import { ServerErrorModel } from '../../models/app-models/server-error.model';

@Injectable({ providedIn: 'root' })
export class CalculationInitService {
  constructor(
    private calcService: CalcService,
    private dialog: MatDialog,
    private router: Router,
    private insertionService: InsertionService,
  ) {}

  public getInitCalculation(patientId: string, insertionType: string, gender?: string): Observable<boolean> {
    return this.initCalculationPopupWrapper(patientId, insertionType, gender).pipe(
      switchMap((res) => (res ? this.gotoCalculationInitFlow(patientId, insertionType) : of(false))),
      catchError((serverError: ServerErrorModel) => {
        this.processedInitCalculationServerError(serverError);
        return throwError(serverError);
      }),
    );
  }

  private initCalculationPopupWrapper(patientId: string, insertionType: string, gender?: string): Observable<boolean> {
    if ((gender === Gender.male || gender === Gender.female) && insertionType === InsertionTypes.returnInsertion) {
      return this.checkBoosterLastInsertionTypeForMaleAndFemaleForReturnDose(patientId);
    }
    if (insertionType === InsertionTypes.boosterInsertion) {
      return this.getConfirmPopup('CALC.BOOSTER_DOSAGE_FLOW').afterClosed();
    }
    return of(true);
  }

  private checkBoosterLastInsertionTypeForMaleAndFemaleForReturnDose(patientId: string): Observable<boolean> {
    return this.getLastInsertion(patientId).pipe(
      switchMap((res) => {
        if (res) {
          return res.type === InsertionTypes.boosterInsertion
            ? this.getStreamConfirmPopup('CALC.DOSING_A_RETURN_VISIT_AFTER_A_BOOSTER')
            : of(true);
        }

        return of(true);
      }),
    );
  }

  private gotoCalculationInitFlow(patientId: string, insertionType: string): Observable<boolean> {
    return this.calcService
      .calculationInit({ patientId, insertionType })
      .pipe(switchMap((calculation) => this.redirectToCalculationPage(patientId, calculation)));
  }

  private processedInitCalculationServerError(serverError: ServerErrorModel) {
    if (!serverError) {
      return;
    }
    if (serverError.status === ServerStatusCode.customError) {
      this.getConfirmPopup(serverError.message);
    }
  }

  private getConfirmPopup(text: string, title = '') {
    const data: IInfoPopupData = {
      title,
      text,
      actions: {
        acknowledge: 'ACKNOWLEDGE',
      },
    };
    return this.dialog.open(InfoPopupComponent, { disableClose: true, data });
  }

  private redirectToCalculationPage(patientId: string, calculation: ICalculationInitResponseApi): Observable<boolean> {
    return from(this.router.navigateByUrl(`/patients/${patientId}/calculations/${calculation.id}`));
  }

  private getStreamConfirmPopup(text: string, title = ''): Observable<boolean> {
    const data: IInfoPopupData = {
      title,
      text,
      actions: {
        acknowledge: 'ACKNOWLEDGE',
      },
    };
    return this.dialog.open(InfoPopupComponent, { disableClose: true, data }).afterClosed();
  }

  public getLastInsertion(patientId: string): Observable<ILastInsertion> {
    return this.insertionService.getLastInsertion(patientId);
  }
}
