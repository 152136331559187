/* eslint-disable */
export const RegExpValidator = {
  lowerCase: RegExp(/^(?=.*?[a-z])/),
  upperCase: RegExp(/^(?=.*?[A-Z])/),
  digit: RegExp(/^(?=.*?[0-9])/),
  specialChar: RegExp(/^(?=.*?[" !"#$%&'()*+,-.\\\/:;<=>?@[\]^_`{|}~"])/),
};

export const email =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // tslint:disable-line;
export const url =
  /^((?:http|ftp)s?:\/\/)(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::\d+)?(?:\/?|[\/?]\S+)$/i;
export const urlWithOutProtocol =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
export const strNoStartAndEndWhitespace = /^[^\s]+(\s+[^\s]+)*$/;
export const specialCharacters = /^(?=.*?["!"#$%&()*+,;<=>?@[\]^_{|}~"])/;
export const integer = /^\d+$/;
export const numbers = /^[1-9]\d*$/;
export const digital = /^(-)?(\d+|(\d*(\.\d*)))?$/;
export const subDomainUrl = /^[a-zA-Z0-9]+[a-zA-Z0-9-._]*[a-zA-Z0-9]+$/;
export const allowedOnlySpecialCharacters = /^[ A-Za-z0-9./`'#,:-]*$/;
export const citySpecialCharacters = /^[ A-Za-z0-9./`'-]*$/;
export const zipCodeAllowLettersNumbersSpaceDash = /^[a-zA-Z0-9-\s]*$/;
