import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BadgeTypeEnum } from '@sp-core/agreement-keys/badge-type.enum';

@Component({
  selector: 'sp-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  public BadgeTypeEnum = BadgeTypeEnum;
  @Input() public badgeType: BadgeTypeEnum = BadgeTypeEnum.quantity;
  @Input() public quantity: number;
  @Input() public isNew: boolean;
}
