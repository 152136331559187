import { Injectable } from '@angular/core';
import { ITableService } from '@sp-shared/components/table';
import { Observable, throwError } from 'rxjs';
import { HttpLabsService } from '@sp-core/http-services/http-labs/http-labs.service';
import { catchError, map } from 'rxjs/operators';
import { MetaHttpAdapter } from '@sp-core/http-adapters/meta-http-adapter';
import { LabsHttpAdapterService } from '@sp-core/http-adapters/labs-http-adapter.service';
import { CalcHttpAdapter } from '@sp-core/http-adapters/calc-http-adapter';
import { ServerStatusCode } from '@sp-core/agreement-keys/server-status-code.enum';
import { ServerErrorModel } from '@sp-core/models/app-models/server-error.model';

@Injectable({
  providedIn: 'root',
})
export class LabsService implements ITableService<ILabDraw> {
  constructor(
    public httpLabsService: HttpLabsService,
    public labsHttpAdapter: LabsHttpAdapterService,
    public httpCalcAdapter: CalcHttpAdapter,
  ) {}

  public getTableData(params: HttpParamsOptions, apiParams: ApiParams): Observable<ITableData<ILabDraw>> {
    return this.httpLabsService.getListLabs(params, apiParams).pipe(
      map((data) => ({
        data: data.data.map((lab) => this.labsHttpAdapter.transformToLab(lab)),
        meta: MetaHttpAdapter.transformToMeta(data.meta),
      })),
    );
  }

  public getLabsConfig(genderId: string, isRequired?: boolean): Observable<IProcessedLabs> {
    const optionalParams = this.labsHttpAdapter.getOptionalParamsValidation(isRequired);
    return this.httpLabsService
      .getLabsConfig(genderId, optionalParams)
      .pipe(map((data) => this.httpCalcAdapter.transformToProcessedLabs(data)));
  }

  public createLabs(data: ISeparateLabsForm, patientId: string): Observable<ISeparateLabsDraw> {
    const sendData = this.httpCalcAdapter.transformToSeparateLabsApi(data);
    return this.httpLabsService.createLabs(sendData, patientId).pipe(
      map((res) => this.labsHttpAdapter.transformToLabDraw(res)),
      this.labsServerErrorHandler(),
    );
  }

  public updateLabsDraw(data: ISeparateLabsForm, patientId: string, labsDrawId: string): Observable<any> {
    const sendData = this.httpCalcAdapter.transformToSeparateLabsApi(data);
    return this.httpLabsService.updateLabsDraw(sendData, patientId, labsDrawId).pipe(this.labsServerErrorHandler());
  }

  public getLabDraw(patientId: string, labDrawId: string, calculationId?: string): Observable<ISeparateLabsDraw> {
    const params = this.labsHttpAdapter.transformToOptionalParams(calculationId);
    return this.httpLabsService
      .getLabDraw(patientId, labDrawId, params)
      .pipe(map((data) => this.labsHttpAdapter.transformToLabDraw(data)));
  }

  public getUsedDates(patientId: string): Observable<ILabsUsedDates> {
    return this.httpLabsService
      .getUsedDates(patientId)
      .pipe(map((usedDate) => this.httpCalcAdapter.transformToUsedDate(usedDate.data)));
  }

  public getLabsDrawDates(patientId: string): Observable<ILabsDates> {
    return this.httpLabsService
      .getLabsDrawDates(patientId)
      .pipe(map((labsFreeDate) => this.httpCalcAdapter.transformToUsedDate(labsFreeDate.data)));
  }

  public labsServerErrorHandler(): any {
    return catchError((serverError: ServerErrorModel) => {
      if (serverError.status === ServerStatusCode.validationError) {
        serverError.validationErrors = this.labsHttpAdapter.transformToLabsFormError(serverError.validationErrors);
      }
      return throwError(serverError);
    });
  }
}
