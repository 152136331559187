<div class="badge">
  <ng-container [ngSwitch]="badgeType">
    <ng-container *ngIf="quantity">
      <div *ngSwitchCase="BadgeTypeEnum.quantity" class="badge__quantity">
        <span class="badge__value">{{ quantity }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="isNew">
      <div *ngSwitchCase="BadgeTypeEnum.new" class="badge__new">
        <span class="badge__value">{{ 'NEW' | translate }}</span>
      </div>
    </ng-container>
  </ng-container>
</div>
