import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ReferralsHttpAdapter {
  public transformToReferral(data: IReferralApi): IReferral {
    return {
      id: data?.id,
      name: data?.name,
      email: data?.email,
      phone: data?.phone,
      city: data?.city,
      state: data?.state,
      countryId: data?.country?.id || null,
      zip: data?.zip,
      status: data?.status,
      tag: data?.tag,
      practice: {
        id: data?.practice?.id,
        name: data?.practice?.name,
      },
      notesCount: data?.notes_count || null,
      assigned_at: data?.assigned_at || null,
      gender: data?.gender || null,
      createdAt: data?.created_at || null,
    };
  }

  public transformReferralAssignRequestApi(data: IProviderAssignData): IReferralAssignRequestApi {
    return {
      practice_id: data?.practiceId,
      employee_id: data?.id,
    };
  }

  public transformReferralAssignRequest(data: IReferralAssignRequestApi): IReferralAssignRequest {
    return {
      practiceId: data?.practice_id,
      employeeId: data?.employee_id,
    };
  }

  public transformToReferralNote(data: IReferralNoteApi): IReferralNote {
    return {
      creator: {
        firstName: data?.creator?.first_name,
        lastName: data?.creator?.last_name,
      },
      text: data?.text,
      createdAt: data?.created_at,
    };
  }

  public transformToReferralContactRequest(data: IReferralContactRequestApi): IReferralContactRequest {
    return {
      tag: data?.tag,
      note: data?.note,
    };
  }

  public transformToReferralContactRequestApi(data: IReferralContactRequest): IReferralContactRequestApi {
    return {
      tag: data?.tag,
      note: data?.note,
    };
  }

  public transformToReferralEditRequest(data: IReferralEditRequestApi): IReferralEditRequest {
    return {
      name: data?.name,
      email: data?.email,
      phone: data?.phone,
      city: data?.city,
      state: data?.state,
      countryId: data?.country_id,
      zip: data?.zip,
      gender: data?.gender,
    };
  }

  public transformToReferralEditRequestApi(data: IReferralEditRequest): IReferralEditRequestApi {
    return {
      name: data?.name,
      email: data?.email,
      phone: data?.phone,
      city: data?.city,
      state: data?.state,
      country_id: data?.countryId,
      zip: data?.zip,
      gender: data?.gender,
    };
  }

  public transformToReferralQuiz(data: IReferralQuizApi): IReferralQuiz {
    return {
      question: data?.question,
      answer: data?.answer,
    };
  }
}
