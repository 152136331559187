import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SettingsHttpAdapter {
  public transformToChangePasswordApi(passwords: IChangePassword): IChangePasswordApi {
    return {
      current_password: passwords.currentPassword,
      new_password: passwords.newPassword,
      new_password_confirmation: passwords.confirmPassword,
    };
  }

  public transformToProfileApi(profile: IProfile): IProfileApi {
    return {
      first_name: profile.firstName,
      last_name: profile.lastName,
      email: profile.email,
      timezone_id: profile.timeZone,
      datetime_format_id: profile.timeFormat,
      measurement_preferences_id: profile.measurement,
    };
  }
}
