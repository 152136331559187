<button spQaId="two-factor-login_button_close" class="close" (click)="clickClose(false)">
</button>
<h1 mat-dialog-title class="popup-title d-flex justify-content-center">
  <span
    class="confirm-title-text popup-title">{{(userTypeAuth?.key === twoTypeAuth.phone ? 'AUTH.PHONE_VERIFICATION' : 'AUTH.EMAIL_VERIFICATION') | translate}}</span>
  <!--use for authorization-->

</h1>
<mat-dialog-content>
  <section class="content">
    <div class="verification-code">
      <div class="card-code">
        <p class="email-title title-bold text-decoration-underline col-12">
          <!--BY PHONE-->
          <span class="title-bold d-flex flex-column"
                *ngIf="userTypeAuth?.key === twoTypeAuth.phone">{{'AUTH.AN_SMS_WAS_SENT_TO_YOUR_SPECIFIC_NUMBER' | translate}}</span>
          <!--BY EMAIL-->
          <span class="title-bold d-flex flex-column" *ngIf="userTypeAuth?.key === twoTypeAuth.email">
              {{'AUTH.AN_EMAIL_WAS_SENT_TO_YOUR_SPECIFIED_EMAIL' | translate}}
            </span>

          {{'AUTH.PLEASE_ENTER_THE_VERIFICATION_CODE' | translate}}
        </p>
        <sp-otp-input (otpChange)="onOtpChange($event)">
          <mat-error class="token-error text-center" *ngIf="serverError">{{serverError}}</mat-error>
        </sp-otp-input>
      </div>
    </div>

    <div class="remember-me">
      <mat-checkbox class="title-bold" color="primary"
                    [(ngModel)]="rememberMe">{{'AUTH.REMEMBER_ME_FOR_15_DAYS' |  translate}}</mat-checkbox>
    </div>

    <div class="actions justify-content-between code-actions">
      <button spQaId="two-factor-login_button_resend-code"
              class="btn-primary-accept"
              (click)="clickResendCode()"
              [disabled]="isDisableResendBtn()">
        {{'SETTINGS.RESEND_CODE' | translate}}
      </button>
      <button spQaId="two-factor-login_button_send-code"
              class="btn-primary-accept"
              (click)="clickSendCode()"
              [disabled]="isInvalidCodeVerification || isSend">
        {{'SETTINGS.CONFIRM' | translate}}
      </button>
    </div>
    <mat-card appearance="outlined" class="time-resend-block">
      {{'SETTINGS.THE_CODE_HAS_SENT' | translate}} {{timer?.date?.minutes}}:{{timer?.date?.seconds }}.
    </mat-card>

  </section>
</mat-dialog-content>
