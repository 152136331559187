import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserDateFormatModule } from '@sp-shared/pipes/user-date-format/user-date-format.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { QaModule } from '@sp-shared/directives/data-cy/qa.module';
import { StickyNoteComponent } from './containers/sticky-note/sticky-note.component';

@NgModule({
  declarations: [StickyNoteComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    DragDropModule,
    MatTooltipModule,
    UserDateFormatModule,
    FormsModule,
    TranslateModule,
    MatProgressBarModule,
    MatInputModule,
    QaModule,
  ],
  exports: [StickyNoteComponent],
})
export class StickyNotesModule {}
