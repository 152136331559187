<form class="forgot-password auth-form w-100"
      spQaId="forgot-password-form_form"
      [formGroup]="forgotPassword"
      (ngSubmit)="onSubmit()"
      id="sign-in-form">
  <div class="forgot-password__control">
    <mat-form-field class="w-100">
      <input spQaId="forgot-password-form_input_user-name" matInput id="user-name" formControlName="email" [placeholder]="'AUTH.EMAIL' | translate">
      <mat-error spErrors [backendError]="serverError"></mat-error>
    </mat-form-field>
  </div>
  <div class="auth-action">
    <button spQaId="forgot-password-form_button_submit"
            type="submit"
            form="sign-in-form"
            class="w-100 btn-primary-accept"
            [disabled]="forgotPassword.invalid">
      {{'BTN_SUBMIT' | translate}}
    </button>
  </div>
</form>
