<div class="announcement-popup__container position-relative">
  <div class="announcement-popup__date" *ngIf="!isDataLoading">
    {{'ANNOUNCEMENTS.CREATED_DATE' | translate}}:  {{announcement?.date | dateFormat}}
  </div>

  <button spQaId="announcement-popup_button_close"
          [mat-dialog-close]="true"
          class="close" (click)="closed()">
  </button>

  <div class="preloader" *ngIf="isDataLoading">
    <sp-pre-loader></sp-pre-loader>
  </div>

  <div class="announcement-popup__content-wrapper" *ngIf="!isDataLoading">
    <div>
      <div class="announcement-popup__title text-center" *ngIf="_data?.currentIndex; else onePopup">
        {{'ANNOUNCEMENTS.ANNOUNCEMENT' | translate}} {{_data?.currentIndex}} {{'ANNOUNCEMENTS.OF' | translate}} {{_data?.announcementsLength}}
      </div>
      <ng-template #onePopup>
        <div class="announcement-popup__title text-center">
          {{'ANNOUNCEMENTS.ANNOUNCEMENT' | translate}}
        </div>
      </ng-template>
      <p class="announcement-popup__name text-center word-break">{{announcement?.name}}</p>
    </div>

    <mat-dialog-content class="announcement-popup__dialog-content">
      <div class="col-6 col-md-6 col-no-padding mb-3">
        <ng-container *ngIf="currentFilesList?.length">
          <div class="announcement-popup__file-item" *ngFor="let file of currentFilesList">
            <button class="announcement-popup__file-download-btn d-flex align-items-center common-btn" (click)="updateAwsUrlDataBeforeDownloadFile(file)">
              <mat-icon class="announcement-popup__file-icon mr-2" aria-hidden="true">insert_drive_file</mat-icon>
              <span class="announcement-popup__file text-ellipsis">{{file?.file_name}}</span>
            </button>
          </div>
        </ng-container>
      </div>

      <div class="announcement-popup__content word-break" [innerHTML]="announcement?.content | spSaveHtml"></div>
    </mat-dialog-content>


    <div class="d-flex justify-content-center" *ngIf="currentUserRole.key !== userRoles.masterAdmin">
      <button *ngIf="!announcement?.isAcknowledged"
              spQaId="announcement-popup_button_acknowledge"
              class="announcement-popup__acknowledge-btn btn-primary-accept acknowledge-btn d-flex align-items-center"
              [disabled]="isDataLoading"
              (click)="updateAnnouncementStatus()" cdkFocusInitial>
        <span class="announcement-popup__acknowledge-btn-text">{{'ANNOUNCEMENTS.ACKNOWLEDGE' | translate}}</span>
        <div>
          <img alt="icon" src="assets/images/check.svg">
        </div>
      </button>
    </div>
  </div>
</div>
