import { Injectable, ErrorHandler, NgZone } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '@sp-environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { PermissionsService, UserService } from '@sp-core-services';
import { combineLatest, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
// eslint-disable-next-line import/no-extraneous-dependencies
import { User } from '@sentry/types';
import { ServerErrorModel } from '@sp-core/models/app-models/server-error.model';

Sentry.init({
  dsn: environment.dsn,
  environment: environment.env,
  integrations: [
    new Sentry.Integrations.TryCatch({
      XMLHttpRequest: false,
    }),
  ],

  // disabling Sentry for local development: https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-before-send
  beforeSend(event: Sentry.Event): PromiseLike<Sentry.Event | null> | Sentry.Event | null {
    if (!environment.production) {
      return null;
    }
    return event;
  },
});

@Injectable({ providedIn: 'root' })
export class SentryService implements ErrorHandler {
  constructor(
    private ngZone: NgZone,
    private userService: UserService,
    private permissionsService: PermissionsService,
  ) {
    this.listenUserChanges();
  }

  /**
   * Method called for every value captured through the ErrorHandler
   */
  public handleError(error: any): void {
    // processed chunk Failed Message
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error?.message)) {
      window.location.reload();
      return;
    }

    if (error instanceof SyntaxError) {
      window.location.reload();
      return;
    }

    const extractedError = this.extractError(error) || 'Handled unknown error';

    // Capture handled exception and send it to Sentry.
    const eventId = this.ngZone.runOutsideAngular(() => Sentry.captureException(extractedError));

    // When in development mode, log the error to console for immediate feedback.
    if (!environment.production) {
      console.error(extractedError);
    }

    // When in development mode, show user dialog to provide details on what happened.
    if (!environment.production) {
      Sentry.showReportDialog({ eventId });
    }
  }

  /**
   * Default implementation of error extraction that handles default error wrapping, HTTP responses, ErrorEvent and few other known cases.
   */
  private extractError(errorCandidate: unknown): unknown {
    let error: any = errorCandidate;

    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && (error as { ngOriginalError: Error }).ngOriginalError) {
      error = (error as { ngOriginalError: Error }).ngOriginalError;
    }

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    // Handle API Server Error
    if (error instanceof ServerErrorModel) {
      return `
      API Server Error
      status code: ${error?.status}
      message: ${error?.message}
      validation: ${JSON.stringify(error?.validationErrors)}`;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // Handle API Server Error
      if (error?.error?.error && error?.status) {
        return `Server returned code ${error.status} with body "${JSON.stringify(error?.error?.error)}"`;
      }

      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent && error.error.message) {
        return error.error.message;
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // Nothing was extracted, fallback to default error message.
    return null;
  }

  private listenUserChanges() {
    combineLatest([this.userService.user$, this.permissionsService.role$])
      .pipe(catchError(() => of([null, null])))
      .subscribe(([user, role]) => {
        this.clearUser();
        if (user) {
          this.setUser(user, role);
        }
      });
  }

  private setUser(user, role): void {
    const sentryUser: User = {
      id: user?.id,
      email: user?.email,
      username: `${user?.firstName} ${user?.lastName}`,
      role: role?.name || 'none',
    };
    this.ngZone.runOutsideAngular(() => Sentry.setUser(sentryUser));
  }

  private clearUser(): void {
    this.ngZone.runOutsideAngular(() => Sentry.configureScope((scope) => scope.setUser(null)));
  }
}
