import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ApiConfig } from '@sp-core/api-config';
import { IUploadFile } from '@sp-core/models/app-models/upload-file.interface';

@Injectable({
  providedIn: 'root',
})
export class HttpAnnouncementsService {
  constructor(public readonly _http: HttpClient) {}

  public getAnnouncements(params: HttpParamsOptions): Observable<ITableApiData<IAnnouncementApi>> {
    return this._http.get<ITableApiData<IAnnouncementApi>>(ApiConfig.announcementsPath, { params });
  }

  public getAnnouncementsHistory(params: HttpParamsOptions): Observable<ITableApiData<IAnnouncementApi>> {
    return this._http.get<ITableApiData<IAnnouncementApi>>(`${ApiConfig.announcementsPath}/history`, { params });
  }

  public getAnnouncementsAcknowledged(params: HttpParamsOptions): Observable<ITableApiData<IAnnouncementApi>> {
    return this._http.get<ITableApiData<IAnnouncementApi>>(`${ApiConfig.announcementsPath}/acknowledged`, { params });
  }

  public getAnnouncementsUnaccepted(params: HttpParamsOptions): Observable<ITableApiData<IAnnouncementApi>> {
    return this._http.get<ITableApiData<IAnnouncementApi>>(`${ApiConfig.announcementsPath}/unaccepted`, { params });
  }

  public changeAnnouncementStatus(data: IAnnouncementChangeStatusApi): Observable<IAnnouncementApi> {
    return this._http.patch<IAnnouncementApi>(`${ApiConfig.announcementsPath}/${data.id}`, {
      is_active: data.is_active,
    });
  }

  public deleteAnnouncement(id: string): Observable<void> {
    return this._http.delete<void>(`${ApiConfig.announcementsPath}/${id}`);
  }

  public getListOfNewAnnouncements(): Observable<string[]> {
    return this._http.get<string[]>(`${ApiConfig.announcementsPath}/new`);
  }

  public getAnnouncementById(id: string): Observable<IAnnouncementApi> {
    return this._http.get<IAnnouncementApi>(`${ApiConfig.announcementsPath}/${id}`);
  }

  public setAnnouncementAcknowledgeStatus(id: string): Observable<void> {
    return this._http.post<void>(`${ApiConfig.announcementsPath}/${id}`, {});
  }

  public createAnnouncement(data: IAnnouncementRequestApi): Observable<IAnnouncementApi> {
    return this._http.post<IAnnouncementApi>(ApiConfig.announcementsPath, data);
  }

  public updateAnnouncement(id: string, data: IAnnouncementRequestApi): Observable<IAnnouncementApi> {
    return this._http.put<IAnnouncementApi>(`${ApiConfig.announcementsPath}/${id}`, data);
  }

  public getAWSUploadLink(data: IUploadFile): Observable<IAwsUploadApi> {
    return this._http.post<IAwsUploadApi>(`${ApiConfig.announcementsPath}/attachments/upload`, {
      file_name: data.file_name,
      file_type: data.file_type,
    });
  }

  public uploadFileToAWS(data: IAwsUploadApi, file: File): Observable<void> {
    return this._http.put<void>(data.cloud_link, file, {
      headers: {
        'Content-Disposition': `attachment;filename=${file?.name}`,
      },
    });
  }

  public getFileFromAwsS3(data: IAwsUploadApi): Observable<IAwsUploadApi> {
    const params: HttpParamsOptions = {
      src: data.src,
    };
    return this._http.get<IAwsUploadApi>(`${ApiConfig.announcementsPath}/attachments`, { params });
  }
}
