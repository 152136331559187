import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IForgotPasswordFrom } from '@sp-core/models/forms-models/forgot-password-from.interface';

@Component({
  selector: 'sp-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordFormComponent {
  @Input() public forgotPassword: FormGroup<IForgotPasswordFrom>;
  @Input() public serverError: IServerValidationErrors;
  @Output() public wasSubmitted: EventEmitter<{ email: string }> = new EventEmitter<{
    email: string;
  }>();

  public onSubmit(): void {
    if (this.forgotPassword.valid) {
      this.wasSubmitted.emit(this.forgotPassword.getRawValue());
    }
  }
}
