import { Injectable } from '@angular/core';
import { Observable, of, throwError, timer } from 'rxjs';
import { map, pluck, switchMap } from 'rxjs/operators';
import { ITableService } from '@sp-shared/components/table';
import { MetaHttpAdapter } from '@sp-core/http-adapters/meta-http-adapter';
import TransformServerValidationUtils from '@sp-helpers/validation/transform-server-validation-utils';
import { ReferralStatuses } from '@sp-core/agreement-keys/referral-statuses.enum';
import { ExportFormats } from '@sp-core/agreement-keys/export-formats.enum';
import { ServerStatusCode } from '@sp-core/agreement-keys/server-status-code.enum';
import { HttpReferralsService } from '../../http-services/http-referrals/http-referrals.service';
import { ReferralsHttpAdapter } from '../../http-adapters/referrals-http-adapter';

@Injectable({
  providedIn: 'root',
})
export class ReferralsService implements ITableService<IReferral> {
  private readonly downloadAttemptsCount = 10;
  private readonly downloadAttemptsDebounceTime = 2000;

  constructor(private httpReferralsService: HttpReferralsService, private referralsHttpAdapter: ReferralsHttpAdapter) {}

  public getTableData(params: HttpParamsOptions): Observable<ITableData<IReferral>> {
    return this.httpReferralsService.getReferrals(params).pipe(
      map((data) => ({
        data: data.data.map((item) => this.referralsHttpAdapter.transformToReferral(item)),
        meta: MetaHttpAdapter.transformToMeta(data.meta),
      })),
    );
  }

  public getHasUnassignedReferrals(): Observable<boolean> {
    const params = {
      'search[status]': ReferralStatuses.unassigned,
      'page[number]': '1',
      'page[size]': '1',
    };
    return this.httpReferralsService.getReferrals(params).pipe(map((data) => data?.data?.length > 0));
  }

  public assignReferral(id: string, data: IProviderAssignData): Observable<void> {
    const sendData = this.referralsHttpAdapter.transformReferralAssignRequestApi(data);
    return this.httpReferralsService
      .assignReferral(id, sendData)
      .pipe(
        TransformServerValidationUtils.catchAndTransformValidationError((error) =>
          this.referralsHttpAdapter.transformReferralAssignRequest(error),
        ),
      );
  }

  public contactReferral(id: string, data: IReferralContactRequest): Observable<void> {
    const sendData = this.referralsHttpAdapter.transformToReferralContactRequestApi(data);
    return this.httpReferralsService
      .contactReferral(id, sendData)
      .pipe(
        TransformServerValidationUtils.catchAndTransformValidationError((error) =>
          this.referralsHttpAdapter.transformToReferralContactRequest(error),
        ),
      );
  }

  public editReferral(id: string, data: IReferralEditRequest): Observable<void> {
    const sendData = this.referralsHttpAdapter.transformToReferralEditRequestApi(data);
    return this.httpReferralsService
      .editReferral(id, sendData)
      .pipe(
        TransformServerValidationUtils.catchAndTransformValidationError((error) =>
          this.referralsHttpAdapter.transformToReferralEditRequest(error),
        ),
      );
  }

  public archiveReferral(id: string): Observable<void> {
    return this.httpReferralsService.updateStatusReferral(id, ReferralStatuses.archived);
  }

  public getReferralQuizExportFile(referralId: string): Observable<Blob> {
    return this.httpReferralsService.getReferralQuizExportId(ExportFormats.pdf, referralId).pipe(
      pluck('process_id'),
      switchMap((processId: string) => this.getReferralQuizExportFileFlow(processId, referralId)),
    );
  }

  public getReferralQuizExportFileFlow(
    processId: string,
    referralId: string,
    counter: number = this.downloadAttemptsCount,
    delayTime: number = this.downloadAttemptsDebounceTime,
  ): Observable<Blob> {
    return timer(delayTime).pipe(
      switchMap(() => this.httpReferralsService.getReferralQuizExportFile(processId, referralId)),
      switchMap((response) => {
        if (response.status !== ServerStatusCode.noContent) {
          return of(response.body);
        }
        if (counter <= 0) {
          return throwError(new Error('Something went wrong. Please try again download file.'));
        }
        return this.getReferralQuizExportFileFlow(processId, referralId, counter - 1, delayTime);
      }),
    );
  }

  public getReferralQuiz(id: string): Observable<IReferralQuiz[]> {
    return this.httpReferralsService
      .getReferralQuiz(id)
      .pipe(map((data) => data.map((item) => this.referralsHttpAdapter.transformToReferralQuiz(item))));
  }
}
