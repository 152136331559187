import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { TranslateModule } from '@ngx-translate/core';

import { ErrorsModule } from '@sp-shared/directives/errors/errors.module';
import { InfoPopupModule } from '@sp-shared/containers/info-popup/info-popup.module';
import { TwoFactorLoginModule } from '@sp-shared/containers/two-factor-login/two-factor-login.module';
import { QaModule } from '@sp-shared/directives/data-cy/qa.module';
import { PasswordStrengthModule } from '@sp-shared/components/password-strength/password-strength.module';
import { AuthLayoutModule } from '@sp-shared/layout/auth-layout/auth-layout.module';
import { SignUpComponent } from './containers/sign-up/sign-up.component';
import { SignInComponent } from './containers/sign-in/sign-in.component';
import { SignUpFormComponent } from './components/sign-up-form/sign-up-form.component';
import { SignInFormComponent } from './components/sign-in-form/sign-in-form.component';

import { ForgotPasswordFormComponent } from './components/forgot-password-form/forgot-password-form.component';
import { ResetPasswordComponent } from './containers/reset-password/reset-password.component';
import { ResetPasswordFormComponent } from './components/reset-password-form/reset-password-form.component';
import { ChangePasswordComponent } from './containers/change-password/change-password.component';
import { RequiredChangePasswordFormComponent } from './components/required-change-password-form/required-change-password-form.component';
import { RequiredChangePasswordFormService } from './containers/services/required-change-password-form.service';
import { ForgotPasswordComponent } from './containers/forgot-password/forgot-password.component';

@NgModule({
  declarations: [
    SignUpComponent,
    SignInComponent,
    SignUpFormComponent,
    SignInFormComponent,
    ForgotPasswordComponent,
    ForgotPasswordFormComponent,
    ResetPasswordComponent,
    ResetPasswordFormComponent,
    ChangePasswordComponent,
    RequiredChangePasswordFormComponent,
  ],
  exports: [SignUpComponent, ChangePasswordComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    ErrorsModule,
    InfoPopupModule,
    TwoFactorLoginModule,
    QaModule,
    PasswordStrengthModule,
    AuthLayoutModule,
  ],
  providers: [RequiredChangePasswordFormService],
})
export class AuthModule {}
