import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SettingsHttpAdapter } from '../../http-adapters/settings-http-adapter';
import { HttpSettingsService } from '../../http-services/http-settings/http-settings.service';
import { AuthHttpAdapter } from '../../http-adapters/auth-http-adapter';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(
    private settingsHttpAdapter: SettingsHttpAdapter,
    private httpSettingsService: HttpSettingsService,
    private authHttpAdapter: AuthHttpAdapter,
    private userService: UserService,
  ) {}

  public changePassword(data: IChangePassword): Observable<IUser> {
    const sentData = this.settingsHttpAdapter.transformToChangePasswordApi(data);
    return this.httpSettingsService.updateProfile(sentData).pipe(map((user) => this.prepareProfileData(user)));
  }

  public updateProfile(passwords: IProfile): Observable<IUser> {
    const sendData = this.settingsHttpAdapter.transformToProfileApi(passwords);
    return this.httpSettingsService.updateProfile(sendData).pipe(
      map((user) => this.prepareProfileData(user)),
      tap((user) => {
        this.userService.user = user;
      }),
    );
  }

  public getTimZone(): Observable<ITimeZone[]> {
    return this.httpSettingsService
      .getTimeZone()
      .pipe(map((data) => data.data.map((item) => this.authHttpAdapter.transformToTimeZone(item))));
  }

  public getDateTime(): Observable<IDatetimeFormat[]> {
    return this.httpSettingsService
      .getDateTime()
      .pipe(map((data) => data.data.map((item) => this.authHttpAdapter.transformToDateTimeFormat(item))));
  }

  public getMeasurement(): Observable<IMeasurementPreferences[]> {
    return this.httpSettingsService
      .getMeasurement()
      .pipe(map((data) => data.data.map((item) => this.authHttpAdapter.transformToMeasurementPreferences(item))));
  }

  public toggle2FA(isActive: boolean): Observable<any> {
    return this.httpSettingsService.toggle2FA(isActive);
  }

  public getStatus2FA(): Observable<I2FAStatus> {
    return this.httpSettingsService.getStatus2FA().pipe(map((res) => ({ isEnabled: res.is_enabled })));
  }

  private prepareProfileData(data: IUserApi): IUser {
    return this.authHttpAdapter.transformToUser(data);
  }
}
