import { Injectable } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { CValidators } from '@sp-helpers/validation/validators';
import { IChangePasswordForm } from '@sp-core/models/forms-models/change-password-form.interface';

@Injectable()
export class RequiredChangePasswordFormService {
  public getRequiredChangePasswordForm(): FormGroup<IChangePasswordForm> {
    const validatorsPassword = [
      CValidators.required,
      CValidators.passwordMatchPattern,
      CValidators.noStartAndEndWhitespace,
      CValidators.maxLength(30),
    ];
    const newPasswordValidation = [...validatorsPassword, CValidators.minLength(12)];
    return new FormGroup<IChangePasswordForm>(
      {
        email: new FormControl<null | string>({ value: null, disabled: true }),
        currentPassword: new FormControl<null | string>(null, validatorsPassword),
        newPassword: new FormControl<null | string>(null, newPasswordValidation),
        newPasswordConfirm: new FormControl<null | string>(null, newPasswordValidation),
      },
      CValidators.mustMatch('newPassword', 'newPasswordConfirm'),
    );
  }

  public patchRequiredChangePasswordForm(form: UntypedFormGroup, value: { email: string }): void {
    form.patchValue(value);
  }
}
