import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanViewDirective } from '@sp-shared/directives/can-view/can-view.directive';

@NgModule({
  declarations: [CanViewDirective],
  exports: [CanViewDirective],
  imports: [CommonModule],
})
export class CanViewModule {}
