import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, UserService, PermissionsService } from '@sp-core-services';
import { CValidators } from '@sp-helpers/validation/validators';
import { catchError, finalize, map, switchMap, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { InfoPopupComponent } from '@sp-shared/containers/info-popup/containers/info-popup/info-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subject, throwError } from 'rxjs';
import { TwoFactorLoginComponent } from '@sp-shared/containers/two-factor-login/containers/two-factor-login/two-factor-login.component';
import { WindowService } from '@sp-core/services/window/window.service';
import { ServerErrorModel } from '@sp-core/models/app-models/server-error.model';
import { ServerStatusCode } from '@sp-core/agreement-keys/server-status-code.enum';
import { IInfoPopupData } from '@sp-shared/containers/info-popup/interfaces/info-popup-data-interface';
import { Roles } from '@sp-core/agreement-keys/roles.enum';
import { ISignInForm } from '@sp-core/models/forms-models/sign-in-form.interface';

@Component({
  selector: 'sp-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInComponent implements OnInit, OnDestroy {
  public destroy$: Subject<void> = new Subject<void>();
  public signInForm: FormGroup<ISignInForm>;
  public serverError: string;
  public isSend = false;
  public shouldChooseRole = false;
  public user: IUser;
  public role: IRole;
  public userRoles: typeof Roles = Roles;

  public userRolesImages: { [key: string]: string } = {
    [Roles.masterAdmin]: 'practice-admin-role.png',
    [Roles.provider]: 'provider-role.png',
    [Roles.supportStaff]: 'support-staff-role.png',
    [Roles.practiceAdmin]: 'practice-admin-role.png',
    [Roles.dosingProvider]: 'provider-role.png',
    [Roles.limitedProvider]: 'provider-role.png',
    [Roles.limitedPracticeAdmin]: 'practice-admin-role.png',
    [Roles.limitedSupportStaff]: 'support-staff-role.png',
  };

  constructor(
    private router: Router,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    public dialog: MatDialog,
    public translate: TranslateService,
    public window: WindowService,
    private userService: UserService,
    private permissionsService: PermissionsService,
  ) {
    this.checkRedirectionAndClearAuthData();
  }

  public ngOnInit(): void {
    this.authService.saveRoutePath();
    this.createSignInForm();
    this.listenLogoutEvent();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public updateRole(role: IRole): void {
    this.permissionsService.updateRole(role).pipe(takeUntil(this.destroy$)).subscribe();
  }

  public onSubmit(): void {
    if (this.isSend) {
      return;
    }
    this.isSend = true;

    this.authService
      .checkExpiredPassword(this.signInForm.getRawValue())
      .pipe(
        switchMap((data) => {
          if (data.checkExpiredPassword) {
            this.router.navigateByUrl(
              `/change-password?email=${this.window.encodeURIComponent(this.signInForm.value.email)}`,
            );
            return of(null);
          }
          return this.authService.checkSessionUser(this.signInForm.getRawValue());
        }),
      )
      .pipe(
        switchMap((res) => this.prepareDataForInfoPopup(res)),
        switchMap((data) => this.handlerShowInfoPopupOrSignIn(data)),
        switchMap((answer) => {
          if (!answer) {
            return of(null);
          }
          return this.getTypeAuth().pipe(switchMap((data) => this.handlerTwoFactorAuth(data)));
        }),
        finalize(() => {
          this.isSend = false;
          this.cd.detectChanges();
        }),
      )
      .subscribe(
        (user: IUser) => {
          if (!user) {
            this.serverError = null;
            this.cd.markForCheck();
            this.userService.user = user;
          }
        },
        (error: ServerErrorModel) => {
          if (error.status === ServerStatusCode.customError) {
            this.serverError = error.message;
          }
        },
      );
  }

  private handlerShowInfoPopupOrSignIn(data: any): Observable<boolean> {
    if (data) {
      return this.dialog
        .open(InfoPopupComponent, {
          data,
          disableClose: true,
        })
        .afterClosed();
    }
    return of(true);
  }

  private createSignInForm(): void {
    this.signInForm = new FormGroup<ISignInForm>({
      email: new FormControl<null | string>(null, [
        CValidators.required,
        CValidators.maxLength(250),
        CValidators.noStartAndEndWhitespace,
      ]),
      password: new FormControl<null | string>(null, [CValidators.required, CValidators.maxLength(30)]),
    });
  }

  private prepareDataForInfoPopup(res: ISessionsCheck): Observable<IInfoPopupData> {
    const data: IInfoPopupData = {
      title: 'AUTH.LOGGED_IN_FROM_A_DIFFERENT_IP',
      text: 'AUTH.THE_FOLLOWING_ISSUE_OCCURRED',
      actions: {
        acknowledge: 'AUTH.LOGIN',
      },
    };
    if (res.sessionsFromAnotherIp) {
      return this.translate.get('AUTH.THE_FOLLOWING_ISSUE_OCCURRED', { ipAddress: res.ip }).pipe(
        map((text) => {
          data.text = text;
          return data;
        }),
      );
    }
    return of(null);
  }

  public handlerTwoFactorAuth(data: ITwoFactorAuthorization): Observable<any> {
    if (!data) {
      return of(null);
    }
    const hash = this.authService.getRememberMeHash(this.signInForm.value.email);
    if (hash && hash !== 'null') {
      return this.authService
        .signIn({
          ...this.signInForm.getRawValue(),
          remember_me_hash: hash,
        })
        .pipe(
          catchError((error: ServerErrorModel) => {
            if (error.status === ServerStatusCode.customError) {
              this.authService.cleanRememberMeHash(this.signInForm.value.email);
              if (data.is2faAvailable) {
                return this.openTwoFactorAuthPopup();
              }
            }
            return throwError(error);
          }),
        );
    }

    if (data.is2faAvailable) {
      return this.openTwoFactorAuthPopup();
    }
    return this.authService.signIn({
      ...this.signInForm.getRawValue(),
    });
  }

  public openTwoFactorAuthPopup(): Observable<any> {
    return this.dialog
      .open(TwoFactorLoginComponent, {
        data: {
          login: this.signInForm.value,
        },
        disableClose: true,
      })
      .afterClosed();
  }

  public getTypeAuth(): Observable<ITwoFactorAuthorization> {
    return this.authService.getUserType2FANonLogin(this.signInForm.getRawValue());
  }

  // clear auth data if user redirect to page after init application
  private checkRedirectionAndClearAuthData() {
    if (this.router.navigated) {
      this.userService.user = null;
      this.permissionsService.role = null;
    }
  }

  // needs for reset page states when user logout on current page
  private listenLogoutEvent() {
    combineLatest([this.userService.user$, this.permissionsService.role$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([user, role]) => {
        if (!user) {
          this.isSend = false;
          this.user = null;
          this.role = null;
          this.shouldChooseRole = false;
          this.serverError = null;
          if (this.signInForm) {
            this.signInForm.reset();
          }
          this.cd.detectChanges();
          return;
        }

        if (user && role) {
          this.authService.handlerNavigation(role);
          return;
        }

        if (user && !user.isMultipleRoles) {
          this.updateRole(user.roles[0]);
          return;
        }

        if (user && user.isMultipleRoles) {
          this.user = user;
          this.shouldChooseRole = true;
          this.serverError = null;
          this.cd.detectChanges();
        }
      });
  }
}
