<div class="ng-opt-wrap">
  <div class="ng-opt-input d-flex justify-content-center">
    <ng-otp-input (onInputChange)="onOtpChange($event)"
                  [config]="config"
                  #ngOtpInput
    ></ng-otp-input>
  </div>
  <div class="error">
    <ng-content select="mat-error"></ng-content>
  </div>
</div>
