import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService, NotificationService } from '@sp-core-services';
import { Subject } from 'rxjs';
import { Timer } from '@sp-helpers/date-time/timer';
import { TwoTypeAuth } from '@sp-core/agreement-keys/two-type-auth.enum';
import { OtpInputComponent } from '@sp-shared/containers/otp-input/containers/otp-input/otp-input.component';
import { ServerErrorModel } from '@sp-core/models/app-models/server-error.model';
import { ServerStatusCode } from '@sp-core/agreement-keys/server-status-code.enum';

@Component({
  selector: 'sp-two-factor-login',
  templateUrl: './two-factor-login.component.html',
  styleUrls: ['./two-factor-login.component.scss'],
})
export class TwoFactorLoginComponent implements OnInit, OnDestroy {
  @ViewChild(OtpInputComponent, { static: false }) spOtpInput: OtpInputComponent;

  public timer: Timer;

  public destroy$: Subject<void> = new Subject<void>();

  public verificationCodeLength = 5;

  public isInvalidCodeVerification = true;

  public code: string;

  public isSend = false;

  public rememberMe = false;

  public twoTypeAuth: typeof TwoTypeAuth = TwoTypeAuth;

  public userTypeAuth: ITwoFactorAuthorization;

  public serverError: string;

  constructor(
    public dialogRef: MatDialogRef<TwoFactorLoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { login: ISignIn; isLogin: boolean },
    private authService: AuthService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.sendAuthCode();
    this.getTypeAuth();
    this.createTimer();
    this.runTimer();
  }

  public ngOnDestroy(): void {
    if (this.timer) {
      this.timer.destroy();
    }
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public clickSendCode(): void {
    if (this.isSend || this.isInvalidCodeVerification) {
      return;
    }
    this.isSend = true;
    this.authService
      .signIn({
        ...this.data.login,
        remember_me: this.rememberMe,
        code: this.code,
      })
      .subscribe(
        (res) => {
          this.clickClose(res);
          this.isSend = false;
        },
        (error: ServerErrorModel) => {
          this.isSend = false;
          if (error.status === ServerStatusCode.notAcceptable) {
            this.dialogRef.close();
            this.showNotification(error.message, 'warning');
          }
          if (error.status === ServerStatusCode.customError) {
            this.serverError = error.message;
          }
        },
      );
  }

  public clickResendCode(): void {
    if (this.isDisableResendBtn()) {
      return;
    }
    this.createTimer();
    this.runTimer();
    this.sendAuthCode();
  }

  public clickClose(confirm: any): void {
    this.dialogRef.close(confirm);
  }

  public onOtpChange(code: string) {
    if (code && typeof code === 'string') {
      this.serverError = null;
      this.isInvalidCodeVerification = !(code.length === this.verificationCodeLength);
    }
    this.code = code;
  }

  public isDisableResendBtn(): boolean {
    return !(this.timer?.date?.minutes === 0 && this.timer?.date?.seconds === '00');
  }

  public createTimer(): void {
    this.timer = new Timer(1);
  }

  public runTimer(): void {
    this.timer.runTime();
  }

  public sendAuthCode(): void {
    this.authService.sendAuthCode(this.data.login).subscribe((res) => {
      // @ts-ignore
      if (res?.code) {
        // eslint-disable-next-line no-restricted-globals,no-alert
        const agree = confirm(`DO_YOU_WANT_TO_COPY_CODE${res.code}`);
        if (agree) {
          this.code = res.code;
          this.setVal(res.code);
        }
      }
    });
  }

  public setVal(val) {
    if (val && this.spOtpInput) {
      this.spOtpInput.setVal(val);
    }
  }

  public getTypeAuth(): void {
    this.authService.getUserType2FANonLogin(this.data.login).subscribe((res) => {
      this.userTypeAuth = res;
    });
  }

  public showNotification(text: string, type = 'success'): void {
    this.notificationService.showNotification(text, type);
  }
}
