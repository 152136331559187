import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { fromEvent, merge, Observable, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[spInactive]',
})
export class InactiveDirective implements OnDestroy {
  @Output() public inActiveEvent: EventEmitter<any> = new EventEmitter();

  @Input() public inActiveTime = 500;

  public destroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.stop();
    this.destroy$.complete();
  }

  public handlerEvents(): void {
    const mouseMoveEvent = fromEvent(document, 'mousemove');
    const keyPressEvent = fromEvent(window, 'keyup');
    merge(mouseMoveEvent, keyPressEvent)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.reset();
      });
  }

  public run(): void {
    this.handlerEvents();
    this.runInActive();
  }

  public reset(): void {
    this.stop();
    this.run();
  }

  public stop(): void {
    this.destroy$.next(null);
  }

  private getTimer(): Observable<number> {
    return timer(0, 1000);
  }

  private runInActive(): void {
    this.getTimer()
      .pipe(takeUntil(this.destroy$))
      .subscribe((timeCounter) => {
        if (timeCounter === this.inActiveTime) {
          this.inActiveEvent.emit({
            inActive: true,
            time: this.inActiveTime,
          });
        }
      });
  }
}
